import axios from "../utils/axios";

export function getChangeHistory(entity, entityId) {
  const queryParams = new URLSearchParams({ entity, entityId });
  return axios.get(`/ChangeHistory/GetChangeHistory?${queryParams}`);
}

export function getTicketStatusChangeHistory(ticketId) {
  return axios.get(
    `/TicketStatus/GetTicketStatusChangeHistory?ticketId=${ticketId}`
  );
}

export function filterChangeHistory(filter) {
  return axios.post(`/ChangeHistory/FilterChangeHistory`, filter);
}

export function GetSnapshotReport(report) {
  return axios.post(`/ChangeHistory/GetSnapshotReport`, report);
}
