/**
 * @param {{ size?: number; } & import("react").SVGProps} props
 * icon is from http://www.onlinewebfonts.com/icon
 */
const AddSubItemIcon = ({ size = 16, ...props }) => (
  <svg
    viewBox="0 0 256 256"
    enableBackground="new 0 0 256 256"
    width={size}
    height={size}
    {...props}
  >
    <g>
      <g>
        <path
          fill="currentColor"
          d="M69,157.5h60.3l0,0c3.1,8.6,11.3,14.8,20.9,14.8h73.7c12.2,0,22.2-9.9,22.2-22.2v-14.7c0-12.2-9.9-22.2-22.2-22.2h-73.7c-12.2,0-22.2,9.9-22.2,22.2v7.3H69V128h29c16.5,0,30-13.4,30-30V40c0-16.5-13.4-30-30-30H40c-16.5,0-30,13.4-30,30V98c0,16.5,13.4,30,30,30h14.3v96.1c0,4.1,3.3,7.4,7.4,7.4c0.6,0,1.1-0.1,1.6-0.2h66.1l0,0c3.1,8.6,11.3,14.8,20.9,14.8h73.7c12.2,0,22.2-9.9,22.2-22.2v-14.7c0-12.2-9.9-22.2-22.2-22.2h-73.7c-12.2,0-22.2,9.9-22.2,22.2v7.3H69V157.5z M142.8,135.4c0-4.1,3.3-7.4,7.4-7.4h73.7c4.1,0,7.4,3.3,7.4,7.4v14.7c0,4.1-3.3,7.4-7.4,7.4h-73.7c-4.1,0-7.4-3.3-7.4-7.4V135.4z M24.8,98V39.9c0-8.4,6.8-15.2,15.2-15.2h58.1c8.4,0,15.2,6.8,15.2,15.2V98c0,8.4-6.8,15.2-15.2,15.2H40C31.6,113.2,24.8,106.4,24.8,98z M142.8,209.1c0-4.1,3.3-7.4,7.4-7.4h73.7c4.1,0,7.4,3.3,7.4,7.4v14.7c0,4.1-3.3,7.4-7.4,7.4h-73.7c-4.1,0-7.4-3.3-7.4-7.4V209.1z"
        />
        <path
          fill="currentColor"
          d="M157.5,69h25.8v25.8c0,4.1,3.3,7.4,7.4,7.4s7.4-3.3,7.4-7.4V69h25.8c4.1,0,7.4-3.3,7.4-7.4s-3.3-7.4-7.4-7.4h-25.8V28.4c0-4.1-3.3-7.4-7.4-7.4s-7.4,3.3-7.4,7.4v25.8h-25.8c-4.1,0-7.4,3.3-7.4,7.4C150.1,65.7,153.4,69,157.5,69z"
        />
      </g>
    </g>
  </svg>
);

export default AddSubItemIcon;
