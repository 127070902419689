import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Card, Form } from "react-bootstrap";
import GoogleAutoComplete from "../Tickets/PropertyInfo/GoogleAutoComplete";
import GoogleStreetMap from "../../maps/GoogleMaps/Streetview";

const inputs = [
  // { name: "fullAddress", label: "Address" },
  { name: "city", label: "City" },
  { name: "state", label: "State" },
  { name: "zip", label: "Zip" },
  { name: "streetNumber", label: "Neighborhood" },
  { name: "county", label: "Country" },
  { name: "lng", label: "Longitude" },
  { name: "lat", label: "Latitude" },
];

const AddressDetails = forwardRef((props, ref) => {
  const [inputValues, setInputValues] = useState({});

  useImperativeHandle(ref, () => ({
    getData: () => ({ ...inputValues }),
    setData: (data) => setInputValues({ ...inputValues, ...data }),
  }));

  const handleAddressChange = (e) => {
    const fullAddress = e.target.value;
    setInputValues({ ...inputValues, fullAddress });
  };

  const handleAddressSelect = (location) => {
    if (!location) {
      return;
    }
    setInputValues({
      ...inputValues,
      ...location,
      fullAddress: location.address || inputValues.fullAddress,
      placeId: location.googlePlaceId,
      streetNumber: location.neighborhood || location.houseNumber || inputValues.streetNumber,
    });

    props.addressSelected(location.googlePlaceId);
  };

  return (
    <Card className="px-0">
      <Card.Header as="h3" className="text-dark p-4 pb-0">
        Address Details
      </Card.Header>
      <Card.Body className="address-details px-0">
        <div className="scrollable px-4">
          <Form.Group className="mb-2" controlId="addressDetails">
            <Form.Label className="mb-1">Address</Form.Label>
            <GoogleAutoComplete
              disableUnit
              name="fullAddress"
              value={inputValues.fullAddress}
              properties={inputValues}
              onChange={handleAddressChange}
              onLocationSelect={handleAddressSelect}
            />
          </Form.Group>
          {inputs.map((item) => (
            <Form.Group
              key={item.name}
              className="mb-2"
              controlId="addressDetails"
            >
              <Form.Label className="mb-1">{item.label}</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) =>
                  setInputValues({
                    ...inputValues,
                    [item.name]: e.target.value,
                  })
                }
                value={inputValues[item.name] || ""}
              />
            </Form.Group>
          ))}
          {inputValues.lat && inputValues.lng && (
            <Form.Group className="mb-2" controlId="latitude">
              <Form.Label className="mb-1">Street View</Form.Label>
              <GoogleStreetMap
                latPos={inputValues.lat}
                lngPos={inputValues.lng}
              />
            </Form.Group>
          )}
        </div>
      </Card.Body>
    </Card>
  );
});

export default AddressDetails;
