import { useDrop } from "react-dnd";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExternalLink, Folder } from "react-feather";

const FolderItem = ({ item, getFolderFiles }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "file",
    drop: () => ({ ...item }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    options: { id: item.id }
  }));

  return (
    <div ref={drop} className="ps-1 folder-item">
      <Button
        variant={canDrop && isOver ? "info" : canDrop ? "light" : "link"}
        className="text-break"
        size="sm"
        onClick={() => getFolderFiles({ id: item.id, name: item.name })}
      >
        <Folder size={18} /> {item.name}
      </Button>
      <OverlayTrigger
        placement="right"
        delay={{ show: 200 }}
        overlay={<Tooltip>Open in SharePoint</Tooltip>}
      >
        <Button
          variant="link"
          size="sm"
          onClick={() => window.open(item.webUrl)}
        >
          <ExternalLink size={18} />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default FolderItem;
