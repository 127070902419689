//** Properties Initial State */
export const createNewProp = () => ({ address: '', type: '', title: '', parcelId: '' })
const propertiesInitialState = [createNewProp()]

const propertiesReducer = (state = propertiesInitialState, action) => {
    switch (action.type) {
        case 'ADD_NEW_PROPERTY':
            let _properties = [...state]
            _properties.push(createNewProp())
            return _properties
        case 'UPDATE_PROPERTIES':
            return action.payload
        default:
            return state
    }
}

export default propertiesReducer