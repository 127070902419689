import React, { useState, useEffect, useMemo, useRef } from 'react'

import InspectionContact from '../inspectionsContacts'
import InspectionQuestion from '../inspectionQuestion'

import { Form, Button, Col } from 'react-bootstrap'
import { faCheckCircle, faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import InspectionModal from '../inspectionForm/InspectionModal'
import ImageUpload from './ImageUpload'
import useAppSelector from "../../../hooks/useAppSelector"
import DataTable from "react-data-table-component"
import { toast } from "react-toastify"

import { columns } from "./columns"
import { INSPECTION_TYPES } from '../../../constants'
import { getInspectionChangeHistory } from '../../../services/inspection'
import { updateInspectionNotes } from '../../../services/ticket'
import ShowImages from './ShowImages'
import { getFormattedInspectionDate } from '../../../utils'
import useJobInspectionDetails from '../useJobInspectionDetails'
import useJobDetails from '../useJobDetails'
import InspectorNotes from '../NotesAndMemos';
import InspectionQuestionForm from '../../jobViewMobile/tabs/Questions';
import Files from '../../jobViewMobile/tabs/Files';

import { Prompt } from "react-router-dom";

const ScheduledInspection = ({ ticketId, handleInspectionFormChange, deleteScheduledInspection, handleSubmit, isActiveTab }) => {
    const [inspectionsHistory, setInspectionsHistory] = useState([]);
    const usersMap = useAppSelector(state => state.appData.usersMap);
    const inspectors = useAppSelector(state => state.appData.inspectors);
    const [editingInternalInsp, setEditingInternalInsp] = useState(false);
    const memoSavedRef = useRef(true);

    const {
        scheduledInspection: data,
        setEditingInspection,
        inspectionImages,
        initInspectionImagesData,
    } = useJobInspectionDetails();
    const { ticketInfo, handleInspectionStatusNoteChange } = useJobDetails();
    const { isInspectionRequired, inspectionStatusNotes } = ticketInfo;

    useEffect(() => {
        /** @param {BeforeUnloadEvent} e */
        function onUnload(e) {
            if (!memoSavedRef.current) {
                e.preventDefault();
                e.returnValue = "";
            }
        }
        window.addEventListener("beforeunload", onUnload);

        return () => {
            window.removeEventListener("beforeunload", onUnload);
        }
    }, [])

    useEffect(() => {
        initInspectionImagesData();
    }, [initInspectionImagesData]);

    useEffect(() => {
        if (!isActiveTab) {
            return;
        }
        getInspectionChangeHistory(data.id).then((result) => {
            const history = result.map((_record) => {
                const record = { ..._record };
                if (record.newValue) {
                    const user = usersMap[record.newValue];
                    if (user)
                        record.newValue = `${user.firstName} ${user.lastName}`
                }

                if (record.oldValue) {
                    const user = usersMap[record.oldValue];
                    if (user)
                        record.oldValue = `${user.firstName} ${user.lastName}`
                }
                return record;
            })
            setInspectionsHistory(history)
        }).catch((err) => {
            console.error(err)
        })
    }, [data, isActiveTab]);

    const getInspectionName = (id) => {
        if (typeof (id) === "string")
            id = Number(id)
        let _temp = INSPECTION_TYPES.find((type) => type.id === id)
        return _temp ? _temp.type : ""
    }

    const handleCBChange = (key, value) => {
        handleInspectionFormChange(key, value, handleSubmit)
    }

    const handleInspectionDelete = () => {
        if (window.confirm('Are you sure you want to delete the scheduled inspection ?'))
            deleteScheduledInspection()
    }

    const updateInspectionRequiredFlag = (val) => {
        if (!val) {
            if (window.confirm("Unchecking the checkbox will delete the existing inspection and this ticket will be marked as 'No Inspection Required'. Are you sure you want to continue ?")) {
                deleteScheduledInspection()
            }
        }
    }

    const saveInspectionNotes = (value) => {
        updateInspectionNotes(ticketId, value).then(() => {
            setEditingInternalInsp(false)
            toast.success("Inspection memo saved successfully !")
            memoSavedRef.current = true;
        }).catch(() => toast.error("Failed to save inspection memo, please contact administrator !"))
    }

    const inspector = useMemo(
        () => inspectors.find((inspector) => inspector.id === data.inspectorId),
        [inspectors, data.inspectorId]
    );

    return (
        <>
            <Prompt
                when={!memoSavedRef.current}
                message="Changes you made may not be saved. Leave?"
            />
            <div>
                {/* <Form.Group className="mb-2">
                    <Form.Check
                        type="checkbox"
                        id="isInspectionRequired"
                        label="Inspection Required"
                        disabled
                        checked={isInspectionRequired === true || isInspectionRequired === null}
                        onChange={(e) => updateInspectionRequiredFlag(e.target.checked)}
                    />
                </Form.Group> */}
            </div>
            <div className="d-flex inspection-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5 style={{ width: "fit-content", margin: "auto 0" }}>
                    Inspection Status: {inspector ? 'Scheduled' : 'Pending'}
                    {inspector ? <FontAwesomeIcon style={{ height: 20, width: 30, paddingLeft: 0, color: "green" }} icon={faCheckCircle} /> : null}
                </h5>

                <div style={{ display: "flex", justifyContent: "space-around" }} className="inspection-actions">
                    <div className="inspection-edit-container">
                        <Button variant="link" style={{ cursor: "pointer" }} onClick={() => setEditingInspection(true)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </div>
                    <div className="inspection-delete-container">
                        <Button variant="link" style={{ cursor: 'pointer' }} onClick={handleInspectionDelete}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </div>
                </div>
            </div>

            <div className="inspection-content">
                <div style={{ marginTop: 4 }} className="inspection-content-inspector">
                    <Col>
                        <h5>
                            Inspector: {inspector && inspector.firstName ? `${inspector.firstName} ${inspector.lastName}` : ""}
                        </h5>
                    </Col>
                    <Col>
                        <h5>
                            Inspection Date: {getFormattedInspectionDate(data.date + (data.time ? ` ${data.time.format("hh:mm a")}` : ""), {
                                short: !data.time,
                            })}
                        </h5>
                    </Col>
                    <Col>
                        <h5>
                            Type: {getInspectionName(data.type)}
                        </h5>
                    </Col>
                    <Col>
                        <h5>
                            Note: {data.note ?? "N/A"}
                        </h5>
                    </Col>
                    {editingInternalInsp ?
                        <>
                            <Col sm={12}>
                                <h5>Inspection Memo (Internal)</h5>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        as="textarea"
                                        value={inspectionStatusNotes || ""}
                                        onChange={
                                            (e) => {
                                                memoSavedRef.current = false;
                                                handleInspectionStatusNoteChange(e.target.value);
                                            }
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} className="d-flex justify-content-end">
                                <Button size="sm" className="px-4 rounded" onClick={() => saveInspectionNotes(inspectionStatusNotes)}>
                                    Save Memo
                                </Button>
                            </Col>

                        </>
                        : <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h5>
                                Inspection Memo (Internal): {inspectionStatusNotes || ""}
                            </h5>
                            <Button variant="link" style={{ cursor: "pointer", paddingTop: 0 }} onClick={() => setEditingInternalInsp(true)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </div>
                    }
                    <Col>
                        <InspectorNotes inspectionId={data.id} inspectionApi />
                        {/* <h5>
                            Inspector Notes: {data.note}
                        </h5> */}
                    </Col>
                </div>
                <hr />
                <div className="inspection-content-checkboxes">
                    <Col>
                        <h5>
                            <Form.Check id="cb1" name="completed" type="checkbox" label="Inspection Completed" onChange={(e) => handleCBChange(e.target.name, e.target.checked)} checked={data.completed} />
                        </h5>
                    </Col>
                    <Col>
                        <h5>
                            <Form.Check id="cb2" name="officeReceivedPhotosAndInfo" type="checkbox" label="Office received the photos and info" onChange={(e) => handleCBChange(e.target.name, e.target.checked)} checked={data.officeReceivedPhotosAndInfo} />
                        </h5>
                    </Col>
                    <Col>
                        <h5>
                            <Form.Check id="cb3" name="scheduled" type="checkbox" label="Updated Client - Inspection Scheduled" onChange={(e) => handleCBChange(e.target.name, e.target.checked)} checked={data.scheduled} />
                        </h5>
                    </Col>
                    <Col>
                        <h5>
                            <Form.Check id="cb4" name="done" type="checkbox" label="Updated Client - Inspection Done" onChange={(e) => handleCBChange(e.target.name, e.target.checked)} checked={data.done} />
                        </h5>
                    </Col>
                </div>
                <hr />
                {/* <div className="mt-1 inspection-content-button-wrapper">
                    <InspectionModal title="Inspection Questions">
                        <InspectionQuestion id={data.id} />
                    </InspectionModal>
                </div> */}
                <div className="mt-1 inspection-content-button-wrapper">
                    <InspectionModal title="Inspection Question Form">
                        <InspectionQuestionForm />
                    </InspectionModal>
                </div>
                <div className="mt-1 inspection-content-button-wrapper">
                    <InspectionModal title="Inspection Contacts" size="lg">
                        <InspectionContact id={ticketId} />
                    </InspectionModal>
                </div>
                <div className="py-3 inspection-content-button-wrapper">
                    <InspectionModal title="Inspection History">
                        <DataTable
                            style={{ height: 670, overflow: "auto" }}
                            noHeader
                            className="react-dataTable"
                            columns={columns}
                            data={inspectionsHistory}
                        />
                    </InspectionModal>
                </div>
                <div className="my-1 inspection-content-button-wrapper">
                    <Files uploadEnabled hideCamera />
                    {/*                     
                    <ShowImages /> */}
                </div>
                {/* <div className="pb-3 inspection-content-button-wrapper">
                    <ImageUpload inspectionId={data.id} />
                </div> */}
            </div>
        </>)
}

export default ScheduledInspection;