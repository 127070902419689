import React, { useState } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

const DeliTimePicker = ({ className, onChange, value, disabled, ...rest }) => {
    const [open, setOpen] = useState(false);
    return (<TimePicker
        {...rest}
        className={className}
        popupClassName={className}
        showSecond={false}
        onChange={onChange}
        hideDisabledOptions
        disabled={disabled}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        minuteStep={15}
        value={value}
        use12Hours
        addon={() => (
            <div style={{ position: "absolute", right: "8px", top: "150px" }}>
                <Button variant="info" onClick={() => setOpen(false)}>Apply</Button>
            </div>
        )}
    />)
};

DeliTimePicker.propTypes = {
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.instanceOf(moment)
};

export default DeliTimePicker;
