/**
 * @typedef {{
 *  id: number;
 *  note: string;
 *  ticketId: number;
 *  ticketStatusTypeId: number;
 *  created: string;
 *  updated?: string;
 *  userId: string;
 * }} TicketStatusNote
 */
import axios from "../utils/axios";


/**
 * @param {number} ticketId
 * @param {number} ticketStatusTypeId
 * @returns {Promise<Array<TicketStatusNote>>}
 */
export function getTicketStatusNotes(ticketId, ticketStatusTypeId) {
    const queryParams = new URLSearchParams({ ticketId, ticketStatusTypeId });
    return axios.get(`/TicketStatusNote/GetTicketStatusNotes?${queryParams}`);
}

/**
 * @param {number} ticketId
 * @param {number} ticketStatusTypeId
 * @param {string} note
 * @returns {Promise<TicketStatusNote>}
 */
export function createTicketStatusNote(ticketId, ticketStatusTypeId, note) {
    return axios.post("/TicketStatusNote/CreateTicketStatusNote", { ticketId, ticketStatusTypeId, note });
}

/**
 * @param {number} id
 * @param {string} note
 * @returns {Promise<TicketStatusNote>}
 */
export function updateTicketStatusNote(id, note) {
    return axios.post("/TicketStatusNote/UpdateTicketStatusNote", { id, note });
}

/**
 * @param {number} id
 * @returns {Promise<TicketStatusNote>}
 */
export function deleteTicketStatusNote(id) {
    const queryParams = new URLSearchParams({ id });
    return axios.post(`/TicketStatusNote/DeleteTicketStatusNote?${queryParams}`);
}
