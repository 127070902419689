import React from "react";

const SidePopup = ({ children, show, onHide }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="popup-background" onClick={onHide}>
      <div className="popup-container" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default SidePopup;
