import React, { useState } from "react";

import { LAYOUT } from "../constants";
import useSettingsState from "../hooks/useSettingsState";

const initialState = {
  layout: LAYOUT.FLUID,
  showNavbar: false,
  /** @type {React.Dispatch<React.SetStateAction<boolean>>} */
  setShowNavbar: () => {},
};

const LayoutContext = React.createContext(initialState);

function LayoutProvider({ children }) {
  const [layout, setLayout] = useSettingsState("layout", LAYOUT.FLUID);
  const [showNavbar, setShowNavbar] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        layout,
        setLayout,
        showNavbar,
        setShowNavbar,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export { LayoutProvider, LayoutContext };
