import { Card } from "react-bootstrap";

const CustomCard = ({
  title = "",
  children,
  className = "",
  bodyClassName = "",
  ...rest
}) => (
  <Card className={`p-0 border rounded-lg shadow-none ${className}`} {...rest}>
    <Card.Body className={`p-3 pt-3 ${bodyClassName}`}>
      {title && (
        <div className="mb-2">
          <span className="text-dark fw-bold">{title}</span>
        </div>
      )}
      {children}
    </Card.Body>
  </Card>
);

export default CustomCard;
