/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} OutdoorSpaceType */
import axios from "../../utils/axios";

/** @returns {Promise<Array<OutdoorSpaceType>>} */
export function getOutdoorSpaceTypes() {
  return axios.get("/OutdoorSpaceType/GetOutdoorSpaceTypes");
}

/**
 * @param {string} name
 * @returns {Promise<OutdoorSpaceType>}
 */
export function createOutdoorSpaceType(name) {
  return axios.post("/OutdoorSpaceType/CreateOutdoorSpaceType", { name });
}
