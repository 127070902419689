const compFields = [
  { name: "borough", type: "string" },
  { name: "block", type: "string" },
  { name: "lot", type: "string" },
  { name: "isEstimated", type: "boolean" },
  { name: "dateOfSale", type: "date" },
  { name: "salePrice", type: "number" },
  { name: "saleStatus", type: "string" },
  { name: "grantor", type: "string" },
  { name: "grantee", type: "string" },
  { name: "yearBuilt", type: "number" },
  { name: "capRate", type: "number" },
  { name: "lotSizeSF", type: "number" },
  { name: "lotSizeAcres", type: "number" },
  { name: "outdoorSpaceSF", type: "number" },
  { name: "wideStreet", type: "boolean" },
  /*{ name: "far", type: "number" },*/
  { name: "residentialFar", type: "number" },
  { name: "commercialFar", type: "number" },
  { name: "communityFacilityFar", type: "number" },
  { name: "manufacturingFar", type: "number" },
  { name: "buildable", type: "number" },
  { name: "totalRemaining", type: "number" },
  // { name: "totalBuildable", type: "number" },
  { name: "totalRemainingPercentage", type: "number" },
  { name: "numberOfUnits", type: "number" },
  { name: "elevator", type: "number" },
  { name: "grossBuildingAreaSF", type: "number" },
  { name: "pricePerSF", type: "number" },
  { name: "financedBy", type: "string" },
  { name: "mortgageAmount", type: "number" },
  { name: "landBuildingRatio", type: "number" },
  { name: "ceilingHeight", type: "number" },
  { name: "squareFeet", type: "number" },
  { name: "bathrooms", type: "number" },
  { name: "pricePerBuildableSF", type: "number" },
  { name: "averageUnitSize", type: "number" },
  { name: "rentStabilized", type: "number" },
  { name: "rentStabilizedPercentage", type: "number" },
  { name: "pricePerUnit", type: "number" },
  { name: "realEstateTaxReimbursement", type: "number" },
  { name: "insuranceReimbursement", type: "number" },
  { name: "otherReimbursement", type: "number" },
  { name: "residentialVacancyAndCollectionLoss", type: "number" },
  { name: "commercialVacancyAndCollectionLoss", type: "number" },
  { name: "realEstateTax", type: "number" },
  { name: "realEstateTaxPerUnit", type: "number" },
  { name: "realEstateTaxPerSF", type: "number" },
  { name: "insurance", type: "number" },
  { name: "insurancePerUnit", type: "number" },
  { name: "insurancePerSF", type: "number" },
  { name: "managementPercentage", type: "number" },
  { name: "managementPercentagePerUnit", type: "number" },
  { name: "managementPercentagePerSF", type: "number" },
  { name: "management", type: "number" },
  { name: "managementPerUnit", type: "number" },
  { name: "managementPerSF", type: "number" },
  { name: "repairsAndMaintenance", type: "number" },
  { name: "waterAndSewer", type: "number" },
  { name: "commonAreaUtilities", type: "number" },
  { name: "gasAndHeat", type: "number" },
  { name: "utilities", type: "number" },
  //{ name: "buildingReservesCostPerSF", type: "number" },
  //{ name: "buildingReserves", type: "number" },
  { name: "legalAndAccounting", type: "number" },
  { name: "otherAndMiscellaneous", type: "number" },
  { name: "totalExpenses", type: "number" },
  { name: "incomeAndExpenseRatio", type: "number" },
  { name: "netOperatingIncome", type: "number" },
  { name: "overallRate", type: "number" },
  { name: "unitNumber", type: "string" },
  { name: "unitSF", type: "number" },
  { name: "monthlyRent", type: "number" },
  { name: "annualRent", type: "number" },
  { name: "leaseStart", type: "date" },
  { name: "leaseEnd", type: "date" },
  { name: "floorLocation", type: "number" },
  { name: "rentPerSF", type: "number" },
  { name: "rentalType", type: "string" },
  { name: "residentialIncome", type: "number" },
  { name: "presentValue", type: "number" },
  { name: "notes", type: "string" },

  { name: "tenant", type: "string" },
  { name: "proximityToSubject", type: "string" },
  { name: "yearRenovated", type: "number" },
  { name: "basementSquareFeet", type: "number" },
  { name: "numberOfRentStabilizedUnits", type: "number" },
  { name: "numberOfStories", type: "number" },
  { name: "qualityOfConstruction", type: "string" },
  { name: "taxBenefits", type: "boolean" },
  { name: "parking", type: "boolean" },
  { name: "amenities", type: "string" },
  { name: "priorSalesDate", type: "date" },
  { name: "deedDocumentId", type: "string" },
  { name: "verification", type: "boolean" },
  { name: "pgi", type: "number" },
  { name: "egi", type: "number" }
];

export default compFields;
