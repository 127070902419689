import { useEffect, useState } from 'react';
import { checkIfTicketExportedAsComp, exportTicketAsComp } from '../../../services/comp';
import { Button } from "react-bootstrap";
import moment from 'moment';

const ExportTicketAsComp = ({ ticketId }) => {

    const [loading, setLoading] = useState(false);
    const [exportInfo, setExportInfo] = useState();

    useEffect(() => {
        if (!ticketId) {
            return;
        }

        async function load() {
            setLoading(true);
            const data = await checkIfTicketExportedAsComp(ticketId);
            if (data == '') {
                setExportInfo(null);
                setLoading(false);
                return;
            }

            setExportInfo(data);
            setLoading(false);
        }

        load();
    }, [ticketId]);

    const exportTicketAsCompHandler = async () => {
        setLoading(true);
        try {
            const response = await exportTicketAsComp(ticketId);
            setExportInfo(response);
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
        }
    }

    return (
        <div className="mt-2 mb-2 d-flex justify-content-center w-100">
            {!exportInfo && (
                <Button disabled={loading} onClick={exportTicketAsCompHandler}>Export as comp</Button>
            )}
            {exportInfo && (
                <div className="d-flex flex-column">
                    <Button disabled={loading}>Sync with exported comp</Button>
                    <p className="mt-1">Last Sync Date: {moment(exportInfo.lastSyncDate).format('MM/DD/YYYY hh:mm A')}</p>
                </div>
            )}
        </div>
    )
}

export default ExportTicketAsComp;