import React, { useState, useEffect, useMemo } from "react"
import { Form, Button, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import axios from "axios"

import useAppSelector from "../../../hooks/useAppSelector"
import useAppDispatch from "../../../hooks/useAppDispatch"
import { loadTicketGroups } from "../../../redux/actions/ticketGroups"
import { SERVER_URL } from "../../../configs"
import { IS_PAID_ENUM } from "../../../utils"
import NotesAndMemos from "../NotesAndMemos"
import { createTicketStatusConversation } from "../../../utils/msteams";
import useScreenSize from "../../../hooks/useScreenSize"
import ExportTicketAsComp from "../export/exportTicketAsComp"

const GBA_ID = 4;
const VALUE_ID = 5;

const Finalization = ({ ticketGroup, ticket, ticketId, isPaid, handleUpdateAffiliateInfo, handleSendTicketGroupAffiliateEmail, handleUpdateIsPaid, selectConversation, loadTicket }) => {
    const [questions, setQuestions] = useState([])
    const [affiliateInfo, setAffiliateInfo] = useState(ticketGroup?.affiliateInfo || {})
    const [disableCTB, setDisableCTB] = useState(false)
    const [createConversationDisabled, setCreateConversationDisabled] = useState(false);
    const { isMobile } = useScreenSize();

    const dispatch = useAppDispatch()
    const { ticketStatusTypes } = useAppSelector(state => state.appData)
    const { pageIndex, pageSize } = useAppSelector(state => state.ticketGroups)
    const { filters } = useAppSelector(state => state.search)
    const [ticketStatus, setTicketStatus] = useState();
    const [ticketStatusObject, setTicketStatusObject] = useState();
    const [conversationId, setConversationId] = useState(null);
    const [assignedUser, setAssignedUser] = useState();

    useEffect(() => {
        if (ticketId)
            axios.get(`${SERVER_URL}/api/TicketQuestion/GetTicketQuestionAnswers?ticketId=${ticketId}`).then((res) => {
                setQuestions(res.data)
            })
    }, [ticketId])

    useEffect(() => {
        if (ticket && ticket.ticketStatuses) {
            const statusObject = ticket.ticketStatuses.length > 0 ? ticket.ticketStatuses[ticket.ticketStatuses.length - 1] : null;
            if (statusObject) {
                setConversationId(statusObject.microsoftTeamsConversationId);
            }

            setTicketStatusObject(statusObject);
            setTicketStatus(ticket.ticketStatuses.length > 0 ? ticket.ticketStatuses[ticket.ticketStatuses.length - 1].ticketStatusTypeId : 0)
            setAssignedUser(getAssignedUser())
        }
    }, [ticket])

    const saveAnswer = (question) => {
        axios.post(`${SERVER_URL}/api/TicketQuestion/UpdateTicketQuestionAnswer`, question).catch((err) => {
            console.error(err)
        })
    }

    const getAssignedUser = () => {
        if (ticket.ticketStatuses && ticket.ticketStatuses.length > 0)
            return ticket.ticketStatuses[ticket.ticketStatuses.length - 1].assignedToId;
    }

    const createConversation = async () => {
        setCreateConversationDisabled(true);

        const response = await createTicketStatusConversation(ticket.id, ticketStatus);
        if (selectConversation) {
            await selectConversation(response.data.id)
        }

        if (loadTicket) {
            loadTicket();
        }

        setConversationId(response.data.id);
        setCreateConversationDisabled(false);
    }

    const handleAnswerBlur = (question) => {
        saveAnswer(question)
    }

    const handleAnswerChange = (value, index) => {
        const _questions = [...questions]
        _questions[index].answer = String(value)

        if (_questions[index].ticketQuestion.type === 1)
            saveAnswer(_questions[index])

        setQuestions(_questions)
    }

    const completeTicket = () => {
        let ticketStatus = ticketStatusTypes.find((status) => status.name === "Completed")
        if (ticketStatus) {
            setDisableCTB(true)
            let _obj = {
                ticketId,
                ticketStatusTypeId: ticketStatus.id,
                assignedToUserId: "",
                assignmentNote: ""
            }
            axios.post(`${SERVER_URL}/api/Ticket/ChangeTicketStatus`, _obj, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(() => {
                dispatch(loadTicketGroups(pageIndex, pageSize, filters))
                toast.success("Ticket completed successfully !!")
            }).catch((err) => {
                console.error(err)
                toast.error("Failed to update ticket status, please contact administrator !!!")
            }).finally(() => setDisableCTB(false))
        } else {
            toast.error("Failed to load ticket statuses, please contact administrator!")
        }
    }

    const value_gba = useMemo(() => {
        if (!questions.length) {
            return;
        }
        const gba = questions.find(question => question.ticketQuestion.id === GBA_ID)?.answer;
        const value = questions.find(question => question.ticketQuestion.id === VALUE_ID)?.answer;
        if (!gba || !value) {
            console.log("!gba || !value")
            return;
        }
        if (!Number(gba) || !Number(value)) {
            console.log("!Number(gba) || !Number(value)", gba, value)
            return "N/A";
        }
        return Math.round(Number(gba) / Number(value) * 100) / 100;
    }, [questions]);

    const saveAffiliateInfo = () => {
        handleUpdateAffiliateInfo(affiliateInfo)
    }
    
    const sendAffiliateEmail = () => {
        handleSendTicketGroupAffiliateEmail()
    }

    return (
        <>
            {ticketGroup?.affiliateId &&
                <div className="mb-2">
                    <h4>Partner</h4>
                    <div className="mb-1 finalization-item">
                        <div style={{ display: "flex", gap: "5", justifyContent: "space-between", alignItems: "center" }}>
                            <h5 className="mb-0">Payment Status:</h5>
                            <Form.Select
                                style={{ maxWidth: 200 }}
                                value={affiliateInfo.paymentStatus}
                                onChange={(e) => setAffiliateInfo({ ...affiliateInfo, paymentStatus: e.target.value })}
                                onBlur={saveAffiliateInfo}
                            >
                                <option value={0}>Job yet to be finalized</option>
                                <option value={1}>Payment authorized</option>
                                <option value={2}>Waiting for signed IRS</option>
                                <option value={3}>Preparing payment</option>
                                <option value={4}>Payment sent</option>
                                <option value={5}>Partial Payment sent</option>
                            </Form.Select>
                        </div>
                    </div>
                    {affiliateInfo.paymentStatus == 5 &&
                        <>
                            <div className="mb-1 finalization-item">
                                <div style={{ display: "flex", gap: "5", justifyContent: "space-between", alignItems: "center" }}>
                                    <h5 className="mb-0">State Amount:</h5>
                                    <input
                                        type="number"
                                        style={{ maxWidth: 200 }}
                                        className="form-control"
                                        value={affiliateInfo.partialPaymentAmount}
                                        onChange={(e) => setAffiliateInfo({ ...affiliateInfo, partialPaymentAmount: e.target.value })}
                                        onBlur={saveAffiliateInfo}
                                    />
                                </div>
                            </div>
                            <div className="mb-1 finalization-item">
                                <div style={{ display: "flex", gap: "5", justifyContent: "space-between", alignItems: "center" }}>
                                    <h5 className="mb-0">Partial Reason:</h5>
                                    <input
                                        type="text"
                                        style={{ maxWidth: 200 }}
                                        className="form-control"
                                        value={affiliateInfo.partialPaymentReason}
                                        onChange={(e) => setAffiliateInfo({ ...affiliateInfo, partialPaymentReason: e.target.value })}
                                        onBlur={saveAffiliateInfo}
                                    />
                                </div>
                            </div>
                            <hr />
                        </>
                    }
                    <div className="mb-1 finalization-item">
                        <div style={{ display: "flex", gap: "5", justifyContent: "space-between", alignItems: "center" }}>
                            <h5 className="mb-0">Commission:</h5>
                            <input
                                type="number"
                                style={{ maxWidth: 200 }}
                                className="form-control"
                                value={affiliateInfo.commission}
                                onChange={(e) => setAffiliateInfo({ ...affiliateInfo, commission: e.target.value })}
                                onBlur={saveAffiliateInfo}
                            />
                        </div>
                    </div>
                    <div className="mb-1 finalization-item">
                        <div style={{ display: "flex", gap: "5", justifyContent: "space-between", alignItems: "center" }}>
                            <h5 className="mb-0">Amount Paid:</h5>
                            <input
                                type="number"
                                style={{ maxWidth: 200 }}
                                className="form-control"
                                value={affiliateInfo.amountPaid}
                                onChange={(e) => setAffiliateInfo({ ...affiliateInfo, amountPaid: e.target.value })}
                                onBlur={saveAffiliateInfo}
                            />
                        </div>
                    </div>
                    <div className="mb-1 finalization-item">
                        <div style={{ display: "flex", gap: "5", justifyContent: "space-between", alignItems: "center" }}>
                            <h5 className="mb-0">Payment Reference:</h5>
                            <input
                                type="text"
                                style={{ maxWidth: 200 }}
                                className="form-control"
                                value={affiliateInfo.paymentReference}
                                onChange={(e) => setAffiliateInfo({ ...affiliateInfo, paymentReference: e.target.value })}
                                onBlur={saveAffiliateInfo}
                            />
                        </div>
                    </div>
                    <div className="mb-1 finalization-item">
                        <div style={{ display: "flex", gap: "5", justifyContent: "space-between", alignItems: "center" }}>
                            <h5 className="mb-0">Date Paid:</h5>
                            <Form.Control
                                type="date"
                                style={{ maxWidth: 200 }}
                                className="form-control"
                                value={affiliateInfo.datePaid ? (new Date(affiliateInfo.datePaid)).toISOString().split('T')[0] : ""}
                                onChange={(e) => setAffiliateInfo({ ...affiliateInfo, datePaid: e.target.value })}
                                onBlur={saveAffiliateInfo}
                            />
                        </div>
                    </div>
                    <div className="mb-1 finalization-item">
                        <div style={{ display: "flex", gap: "5", justifyContent: "space-between", alignItems: "center" }}>
                            <h5 className="mb-0">Payment Note:</h5>
                            <input
                                type="text"
                                style={{ maxWidth: 200 }}
                                className="form-control"
                                value={affiliateInfo.paymentNote}
                                onChange={(e) => setAffiliateInfo({ ...affiliateInfo, paymentNote: e.target.value })}
                                onBlur={saveAffiliateInfo}
                            />
                        </div>
                    </div>
                    <div className="mb-1 finalization-item">
                        <Button onClick={sendAffiliateEmail} className="btn-sm">Send email</Button>
                    </div>
                    <hr />
                </div>
            }
            <div className="mb-1 finalization-item">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h4>Is Paid:</h4>
                    <Form.Select
                        style={{ width: "fit-content" }}
                        id={`${ticketId}-is-paid`}
                        value={isPaid ?? "All"}
                        onChange={(e) => handleUpdateIsPaid(IS_PAID_ENUM[e.target.value])}
                    >
                        <option>All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </Form.Select>
                </div>
            </div>
            {
                questions.length > 0 && <div className="mb-2 finalization-container">
                    {questions.map((question, index) => (
                        <div key={index} className="mb-1 finalization-item">
                            {
                                question.ticketQuestion.type === 0 ?
                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <b>{question.ticketQuestion.description}</b>
                                        </div>
                                        <Form.Control
                                            type="text"
                                            name={`answer-${index}`}
                                            onBlur={() => handleAnswerBlur(question)}
                                            onChange={(e) => handleAnswerChange(e.target.value, index)}
                                            autoComplete="off"
                                            value={question.answer || ""}
                                        />
                                    </> :
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Form.Check
                                            type="checkbox"
                                            style={{ width: "fit-content" }}
                                            id={`${question.id}-answer-${index}`}
                                            label={question.ticketQuestion.description}
                                            onBlur={() => handleAnswerBlur(question)}
                                            onChange={(e) => handleAnswerChange(e.target.checked, index)}
                                            checked={question.answer === 'true'}
                                        />
                                    </div>
                            }
                        </div>
                    ))}
                    {value_gba && (
                        <div className="mb-1 finalization-item">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                Value per S / F = {value_gba}
                            </div>
                        </div>
                    )}
                </div>
            }
            {!isMobile && <NotesAndMemos ticketId={ticketId} ticketStatusTypeId={10} isTicketStatusMemo></NotesAndMemos>}

            {ticketStatus == 10 && !conversationId && (<div className="mt-2">
                <Button onClick={createConversation} disabled={createConversationDisabled}>Create conversation</Button>
            </div>)}

            <Button style={{ width: "95%", marginLeft: 10 }} onClick={completeTicket} disabled={disableCTB} className="finalization-button mt-2">
                {disableCTB && <Spinner animation="border" style={{ height: 13, width: 13, marginRight: 5 }} />}
                Complete Ticket</Button>

            <ExportTicketAsComp ticketId={ticketId} />
        </>
    )
}

export default Finalization