import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Filters from "./Filters";
import { columns } from "./columns";

import { filterCustomers } from "../../../services/customer";

const Customers = () => {
  const [customers, setCustomerData] = useState([]);

  const getDataByFilters = (filters = {}) => {
      filters.includeArchived = true;
      filterCustomers(filters).then(setCustomerData)
  };

  useEffect(() => {
    getDataByFilters();
  }, []);

  return (
    <>
      <Filters onFilter={getDataByFilters} />
      <DataTable
        style={{ height: 670, overflow: "auto" }}
        fixedHeader
        fixedHeaderScrollHeight="calc(100vh - 200px)"
        columns={columns}
        className="react-dataTable"
        data={customers}
      />
    </>
  );
};

export default Customers;
