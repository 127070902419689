import React from 'react';
import "./jobView.css";

import { ReactComponent as UncheckedIcon } from "./../../assets/icons/switch-unchecked.svg";
import { ReactComponent as CheckedIcon } from "./../../assets/icons/switch-checked.svg";

const Switch = ({ isOn, handleToggle }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="switch-checkbox"
        id={`switch`}
        type="checkbox"
      />
      <label
        className="switch-label"
        htmlFor={`switch`}
      >
        <CheckedIcon />
        <span className={`switch-button`} />
        <UncheckedIcon />
      </label>
    </>
  );
};

export default Switch;