import { useState } from "react";
import { Formik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import LoadingButton from "../../components/LoadingButton";
import { TypeEnum } from ".";

/**
 * @param {Object} props
 * @param {boolean} props.show
 * @param {() => void} props.close
 * @param {(values: any) => Promise<void>} props.onSubmit
 * @param {any} props.initialValues
 * @param {string} props.title
 * @param {boolean} props.hasType
 * @param {boolean} props.hasDays
 * @param {boolean} props.hasLabel
 * @param {boolean} props.hasDisplayOrder Default `true`
 * @param {((props: import("formik").FormikProps<any>) => React.ReactNode)} [props.children]
 */
export default function ValueTypesFormModal({
  show,
  close,
  onSubmit,
  initialValues,
  title,
  hasType = false,
  hasDays = false,
  hasLabel = false,
  hasName = true,
  hasDisplayOrder = true,
  children,
}) {
  const [loading, setLoading] = useState(false);

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setLoading(true);
            try {
              if (values.type?.value) {
                values.type = values.type.value;
              }
              await onSubmit(values);
              close();
            } catch (error) {
              const message =
                error?.[0]?.description ||
                error.message ||
                "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
            setLoading(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            ...restProps
          }) => (
            <Form id={`${title}-form`} onSubmit={handleSubmit}>
              {errors.submit && (
                <div className="text-danger mb-2">{errors.submit}</div>
              )}
              {hasName && (<Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  size="lg"
                  placeholder="Name"
                  value={values.name || ""}
                  isInvalid={Boolean(touched.name && errors.name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>)}
              {hasDisplayOrder && (
                <Form.Group className="mb-3">
                  <Form.Label>Display Order</Form.Label>
                  <Form.Control
                    type="number"
                    name="displayOrder"
                    size="lg"
                    placeholder="Display Order"
                    value={values.displayOrder || ""}
                    isInvalid={Boolean(
                      touched.displayOrder && errors.displayOrder
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
              {hasType && (
                <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Select
                    className="react-select-container mt-2"
                    classNamePrefix="react-select"
                    value={values.type}
                    placeholder="Type"
                    options={Object.keys(TypeEnum).map((key) => ({
                      value: +key,
                      label: TypeEnum[key],
                    }))}
                    onBlur={handleBlur}
                    onChange={(value) =>
                      handleChange({ target: { value, name: "type" } })
                    }
                  />
                </Form.Group>
              )}
              {hasDays && (
                <Form.Group className="mb-3">
                  <Form.Label>Days</Form.Label>
                  <Form.Control
                    name="days"
                    type="number"
                    size="lg"
                    placeholder="Days"
                    value={values.days || ""}
                    isInvalid={Boolean(touched.days && errors.days)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
              {hasLabel && (
                <Form.Group className="mb-3">
                  <Form.Label>Label</Form.Label>
                  <Form.Control
                    name="label"
                    size="lg"
                    placeholder="Label"
                    value={values.label || ""}
                    isInvalid={Boolean(touched.label && errors.label)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
              {children &&
                children({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  ...restProps,
                })}
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <LoadingButton type="submit" loading={loading} form={`${title}-form`}>
          Save
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
}
