import { keyById } from "../../../utils";
import {
  LOAD_COMPS,
  LOADING_COMPS,
  LOAD_COMP_KEYS,
  LOADING_COMP_KEYS,
  LOAD_COMP_TYPES,
  LOADING_COMP_TYPES,
  LOAD_COMP_CONDITIONS,
  LOADING_COMP_CONDITIONS,
  LOAD_UNIT_TYPES,
  LOADING_UNIT_TYPES,
  LOAD_LEASE_TYPES,
  LOADING_LEASE_TYPES,
  LOAD_BASEMENT_TYPES,
  LOADING_BASEMENT_TYPES,
  LOAD_BEDROOMS_CLASSIFICATIONS,
  LOADING_BEDROOMS_CLASSIFICATIONS,
  LOAD_LOT_SHAPES,
  LOADING_LOT_SHAPES,
  LOAD_LOT_TYPES,
  LOADING_LOT_TYPES,
  LOAD_RENT_REGULATION_STATUSES,
  LOADING_RENT_REGULATION_STATUSES,
  LOAD_BUILDING_CLASSES,
  LOADING_BUILDING_CLASSES,
  ADD_COMP_KEY,
  LOAD_OUTDOOR_SPACE_TYPES,
  LOADING_OUTDOOR_SPACE_TYPES,
  LOAD_ZONES,
  LOADING_ZONES,
  LOAD_ZONE_OVERLAYS,
  LOADING_ZONE_OVERLAYS,
  LOAD_PROPERTY_RIGHTS,
  LOADING_PROPERTY_RIGHTS,
  SET_COMP_FILTERS,
  LOAD_FLOOR_LOCATIONS,
  LOADING_FLOOR_LOCATIONS
} from "../../actions/comps";

/**
 * @typedef {{
 *  comps: Array<import("../../../services/comp").Comp>;
 *  loadingComps: boolean;
 *  compKeys: Array<import("../../../services/comp/CompKey").CompKey>;
 *  loadingCompKeys: boolean;
 *  compTypes: Array<import("../../../services/comp").CompType>;
 *  compTypesMap: Record<string, import("../../../services/comp").CompType>;
 *  loadingCompTypes: boolean;
 *  compConditions: Array<import("../../../services/comp").CompCondition>;
 *  loadingCompConditions: boolean;
 *  unitTypes: Array<import("../../../services/comp").UnitType>;
 *  loadingUnitTypes: boolean;
 *  leaseTypes: Array<import("../../../services/comp").LeaseType>;
 *  loadingLeaseTypes: boolean;
 *  outdoorSpaceTypes: Array<import("../../../services/comp").OutdoorSpaceType>;
 *  loadingOutdoorSpaceTypes: boolean;
 *  zones: Array<import("../../../services/comp").OutdoorSpaceType>;
 *  loadingZones: boolean;
 *  zoneOverlays: Array<import("../../../services/comp").OutdoorSpaceType>;
 *  loadingZoneOverlays: boolean;
 *  compFilters: Record<string, any>;
 *  floorLocations: Array<import("../../../services/floorLocation").FloorLocation>;
 *  loadingFloorLocations: boolean;
 * }} CompsStateType
 */
const data = {
  comps: [],
  loadingComps: false,
  compKeys: [],
  loadingCompKeys: false,
  compTypes: [],
  compTypesMap: {},
  loadingCompTypes: false,
  compConditions: [],
  loadingCompConditions: false,
  unitTypes: [],
  loadingUnitTypes: false,
  leaseTypes: [],
  loadingBasementTypes: false,
  basementTypes: [],
  loadingBedroomsClassifications: false,
  bedroomsClassifications: [],
  loadingLotShapes: false,
  lotShapes: [],
  loadingLotTypes: false,
  lotTypes: [],
  loadingRentRegulationStatuses: false,
  rentRegulationStatuses: [],
  loadingBuildingClasses: false,
  buildingClasses: [],
  loadingLeaseTypes: false,
  outdoorSpaceTypes: [],
  loadingOutdoorSpaceTypes: false,
  zones: [],
  loadingZones: false,
  zoneOverlays: [],
  loadingZoneOverlays: false,
  propertyRights: [],
  loadingPropertyRights: false,
  compFilters: { searchWithin: { radius: null } },
};

const compsReducer = (state = data, action) => {
  switch (action.type) {
    case LOAD_COMPS:
      return { ...state, comps: action.payload };
    case LOADING_COMPS:
      return { ...state, loadingComps: action.payload };
    case LOAD_COMP_TYPES:
      const compTypesMap = keyById(action.payload);
      return { ...state, compTypes: action.payload, compTypesMap };
    case LOADING_COMP_TYPES:
      return { ...state, loadingCompTypes: action.payload };
    case LOAD_COMP_KEYS:
      return { ...state, compKeys: action.payload };
    case LOADING_COMP_KEYS:
      return { ...state, loadingCompKeys: action.payload };
    case LOAD_COMP_CONDITIONS:
      return { ...state, compConditions: action.payload };
    case LOADING_COMP_CONDITIONS:
      return { ...state, loadingCompConditions: action.payload };
    case LOAD_UNIT_TYPES:
      return { ...state, unitTypes: action.payload };
    case LOADING_UNIT_TYPES:
      return { ...state, loadingUnitTypes: action.payload };
    case LOAD_LEASE_TYPES:
      return { ...state, leaseTypes: action.payload };
    case LOAD_BASEMENT_TYPES:
        return { ...state, basementTypes: action.payload };
    case LOAD_BEDROOMS_CLASSIFICATIONS:
        return { ...state, bedroomsClassifications: action.payload };
    case LOAD_LOT_SHAPES:
        return { ...state, lotShapes: action.payload };
    case LOAD_LOT_TYPES:
        return { ...state, lotTypes: action.payload };
    case LOAD_RENT_REGULATION_STATUSES:
        return { ...state, rentRegulationStatuses: action.payload };
    case LOAD_BUILDING_CLASSES:
        return { ...state, buildingClasses: action.payload };
    case LOADING_LEASE_TYPES:
          return { ...state, loadingLeaseTypes: action.payload };
    case LOADING_BASEMENT_TYPES:
        return { ...state, loadingBasementTypes: action.payload };
    case LOADING_BEDROOMS_CLASSIFICATIONS:
        return { ...state, loadingBedroomsClassifications: action.payload };
    case LOADING_LOT_SHAPES:
        return { ...state, loadingLotShapes: action.payload };
    case LOADING_LOT_TYPES:
        return { ...state, loadingLotTypes: action.payload };
    case LOADING_RENT_REGULATION_STATUSES:
        return { ...state, loadingRentRegulationStatuses: action.payload };
    case LOADING_BUILDING_CLASSES:
        return { ...state, loadingBuildingClasses: action.payload };
    case LOAD_OUTDOOR_SPACE_TYPES:
      return { ...state, outdoorSpaceTypes: action.payload };
    case LOADING_OUTDOOR_SPACE_TYPES:
      return { ...state, loadingOutdoorSpaceTypes: action.payload };
    case LOAD_ZONES:
      return { ...state, zones: action.payload };
    case LOADING_ZONES:
      return { ...state, loadingZones: action.payload };
    case LOAD_ZONE_OVERLAYS:
      return { ...state, zoneOverlays: action.payload };
    case LOADING_ZONE_OVERLAYS:
      return { ...state, loadingZoneOverlays: action.payload };
    case LOADING_PROPERTY_RIGHTS:
      return { ...state, loadingPropertyRights: action.payload };
    case LOAD_PROPERTY_RIGHTS:
      return { ...state, propertyRights: action.payload };
    case LOADING_FLOOR_LOCATIONS:
      return { ...state, loadingFloorLocations: action.payload };
    case LOAD_FLOOR_LOCATIONS:
      return { ...state, floorLocations: action.payload };
    case ADD_COMP_KEY:
      return { ...state, compKeys: [...state.compKeys, action.payload] };
    case SET_COMP_FILTERS:
      return {
        ...state,
        compFilters: action.payload
      };
    default:
      return state;
  }
};

export default compsReducer;
