import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import useAppSelector from "../../hooks/useAppSelector";
import { MentionsInput, Mention } from "react-mentions";

import { useBeforeunload } from "react-beforeunload";
import { AuthContext } from "../../contexts/JWTContext";

import { Col, InputGroup, Spinner, Row } from "react-bootstrap";
import { Send } from "react-feather";

import ChatMessage from "./ChatMessage";

import CustomNav, { CustomNavItem } from "../../components/CustomNav";
import avatar1 from "../../assets/img/avatars/avatar.jpg";
import avatar2 from "../../assets/img/avatars/avatar-2.jpg";

import { SERVER_URL } from "../../configs";

import { HubConnectionBuilder } from "@microsoft/signalr";
import {
  refreshMSTeamsAccessToken,
  loadThreads,
  getReplies,
  replyTo,
  replyToRepublic
} from "../../utils/msteams";
import { getFormattedDateTimeStr } from "../../utils";

const replaceBodyIfNeeded = (text) => {
  const match = text.matchAll(/<[^<>]+>/g);
  if (!match) {
    return text;
  }

  for (let t of match) {
    if (t.toString().includes("id")) {
      text = text.replace(t, "<strong>");
    }
    else {
      text = text.replace(t, "</strong>");
    }
  }

  return text;
}

const Chat = forwardRef((props, ref) => {
  const { users } = useAppSelector(state => state.appData)

  const { teamId, channelId, showThreadIds, initSocket, loadingMessages, setLoadingMessages, activeStep } = props;
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(-1);
  const [messages, setMessages] = useState([]);
  const [replyMessage, setReplyMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [loadingThreads, setLoadingThreads] = useState(false);
  const [error, setError] = useState('');
  const [teamsError, setTeamsError] = useState(false);
  const [currentUser, setCurrentUser] = useState('');
  const [mentions, setMentions] = useState([]);
  const chatRef = useRef();
  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const testRef = useRef();
  const authContext = useContext(AuthContext);

  const socketRef = useRef();
  const messagesRef = useRef();
  messagesRef.current = messages;
  socketRef.current = socket;

  useEffect(() => {
    if (authContext.user)
      setCurrentUser(authContext.user.firstName + " " + authContext.user.lastName);
  }, [authContext]);

  useBeforeunload(e => {
    disposeSocket();
    if (socket !== '') {
      //e.preventDefault();
    }
  });

  useEffect(() => {
    if (messages && chatRef?.current)
      chatRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
  }, [messages])

  useEffect(() => {
    if (threads.length > 0) {
      let _currentStepThreads = threads.filter((thread) => thread.subject.endsWith(activeStep))
      if (_currentStepThreads?.length > 0) {
        let lastThreadId = _currentStepThreads[_currentStepThreads.length - 1].id
        selectThread(lastThreadId)
      } else {
        setMessages([])
        setSelectedThread(-1)
      }
    }
  }, [threads, activeStep])

  useImperativeHandle(ref, () => ({
    reload: async () => {
      await _loadThreads();
    },
    select: async (id) => {
      await selectThread(id, threads);
    }
  }))

  useEffect(() => {

    if (!initSocket) {
      return;
    }

    let hubUrl = `${SERVER_URL}/hubs/teams-channel?teamId=${teamId}&channelId=${channelId}`;
    let hubConnection = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        withCredentials: false
      })
      .build();

    setSocket(hubConnection);

    hubConnection.on('messageReceived', message => {
      if (!messagesRef.current) {
        return;
      }

      console.log("Message received", message);

      try {
        let json = JSON.parse(message);
        let id = json.id;

        let existing = messagesRef.current.find(x => x.id === json.id);
        if (existing) {
          return;
        }
        let newMessage = { id, message: json.body.content, from: json.from.user.displayName, created: json.createdDateTime };
        setMessages(prevMessages => { return sort([...prevMessages, newMessage]) });
      }
      catch (e) {

      }
    })

    hubConnection.onclose(() => {
      setError("Microsoft Teams error please refresh the page");
      setLoadingThreads(false);
    });

    try {
      hubConnection.start().then(x => {
        console.log("Connection established");
      }).catch((err) => {
        //console.log('error while establishing signalr connection: ' + err);
      });
    }
    catch {
      console.log("Error");
      return;
    }
    return () => { disposeSocket(); }
  }, []);

  const _loadThreads = async () => {
    setThreads([]);
    setLoadingThreads(true);
    const timout = setTimeout(() => {
      setTeamsError(true);
    }, 30 * 1000);
    try {
      const response = await loadThreads(teamId, channelId);

      let _threads = response.value.map(x => ({ id: x.id, subject: x.subject, user: x.from.user.displayName }));
      if (showThreadIds && showThreadIds.length !== 0) {
        _threads = _threads.filter(x => showThreadIds.includes(x.id));
      }
      setLoadingThreads(false);
      setThreads(_threads);
    } catch (e) {
      if (e.response && Number(e.response.status) === 401) {
        await refreshMSTeamsAccessToken();
        const response = await loadThreads(teamId, channelId);
        const _threads = response.value.map(x => ({ id: x.id, subject: x.subject, user: x.from.user.displayName }));
        setThreads(_threads);
        setLoadingThreads(false);
      }
    }
    setTeamsError(false);
    clearTimeout(timout);
  }

  const sort = (messages) => {
    return messages.slice().sort((a, b) => new Date(b.created) - new Date(a.created)).reverse();
  }

  const disposeSocket = () => { socketRef.current.stop(); };

  useEffect(() => {
    _loadThreads();
  }, [teamId, channelId]);

  const selectThread = async (id, _threads = threads) => {
    setMessages([]);

    if (_threads.length === 0) {
      return;
    }

    if (id !== -1)
      setLoadingMessages(true);
    setSelectedThread(id);

    try {
      const response = await getReplies(teamId, channelId, id);
      let { value } = response;
      setMessages(sort(value.map(x => ({ id: x.id, message: replaceBodyIfNeeded(x.body.content), from: x.from.user.displayName, created: x.createdDateTime }))));
      setLoadingMessages(false);
    }
    catch (e) {
      if (e.response && Number(e.response.status) === 401) {
        const response = await getReplies(teamId, channelId, id);
        let { value } = response;
        setMessages(sort(value.map(x => ({ id: x.id, message: replaceBodyIfNeeded(x.body.content), from: x.from.user.displayName, created: x.createdDateTime }))));
        setLoadingMessages(false);
      }
    }
  }

  const reply = async () => {
    setSendMessageLoading(true);

    let mentionsReq;
    if (mentions) {
      let __mentions = [];
      for (let mention of mentions) {
        if (replyMessage.search(mention.display) === -1) {
          continue;
        }

        __mentions.push(mention);
      }

      mentionsReq = __mentions.map((x, index) => {
        const user = users.find(u => u.id === x.id);
        return ({
          id: index,
          mentionText: `@${user.firstName} ${user.lastName}`,
          mentioned: {
            user: {
              id: user.microsoftUserId,
              displayName: `${user.firstName} ${user.lastName}`,
              userIdentityType: "aadUser"
            }
          }
        })
      });
    }

    let msg = replyMessage;
    let index = 0;
    for (let m of mentionsReq) {
      msg = msg.replace(m.mentionText.trim(), `<at id='${index}'>_${m.mentioned.user.displayName}</at>`);
      index++;
    }
    for (let m of mentionsReq) {
      msg = msg.replace(">_", ">@");
    }

    try {
      const response = await replyTo(teamId, channelId, selectedThread, msg, mentionsReq);
      const _ = await replyToRepublic(teamId, channelId, selectedThread, msg, mentionsReq, response.id);

      const data = response;
      setMessages(sort([...messages, { id: data.id, message: replaceBodyIfNeeded(data.body.content), from: data.from.user.displayName, created: data.createdDateTime }]))
      setReplyMessage('');
      setMentions([]);
      setSendMessageLoading(false);
    }
    catch (e) {
      if (e.response && Number(e.response.status) === 401) {
        const response = await replyTo(teamId, channelId, selectedThread, msg, mentionsReq);
        const _ = await replyToRepublic(teamId, channelId, selectedThread, msg, mentionsReq, response.id);

        const data = response;
        setMessages(sort([...messages, { id: data.id, message: replaceBodyIfNeeded(data.body.content), from: data.from.user.displayName, created: data.createdDateTime }]))
        setReplyMessage('');
        setMentions([]);
        setSendMessageLoading(false);
      }
    }
  }

  const handleMessage = (e) => {
    if (e.ctrlKey && (e.key === "Enter" || e.code === "Enter") && replyMessage !== "") {
      reply();
    }
  }

  const processMentionText = (e, newValue, newPlainTextValue, _mentions) => {
    setReplyMessage(newPlainTextValue);
    if (_mentions.length === 0) {
      // setMentions([]);
    }
    else if (!newPlainTextValue) {
      setMentions([]);
    }
    else {
      setMentions(prev => ([...prev, ..._mentions]));
    }

    // console.log(mentions);
  }

  // const onAddMention = (id, display, startPos, endPos) => {
  //   console.log(id, display, startPos, endPos);
  // }

  const scrollToSuggestionIndex = useCallback((index) => {
    /** NOTE: this item value shall be synched with _react-mentions.scss variables */
    const HEIGHT = 30;
    /** @type {HTMLDivElement} */
    const suggestionsDiv = testRef.current.firstChild;
    if (!suggestionsDiv) {
      return;
    }
    // -2 cuz we show 3 suggestion in container full height
    if ((index - 2) * HEIGHT > suggestionsDiv.scrollTop) {
      suggestionsDiv.scrollTo({ top: (index - 2) * HEIGHT });
    }
    if (index * HEIGHT < suggestionsDiv.scrollTop) {
      suggestionsDiv.scrollTo({ top: index * HEIGHT });
    }
  }, []);

  return (
    <>
      {loadingThreads && (
        <div className="px-3 mb-0 mt-3">
          <Spinner animation="border" style={{ height: 15, width: 15 }} />{" "}
          Please wait while loading conversations
        </div>
      )}
      <Row className="g-0 px-3">
        {!!error && (
          <span className="text-center text-danger pt-2">{error}</span>
        )}
        {!error && (
          <>
            <Col className="border-end chat-conversations pt-2">
              {!loadingThreads && threads && threads.length === 0 && (
                <span>No available conversations</span>
              )}
              {teamsError && (
                <div className="text-center pt-2">
                  <div>
                    <span>Teams issue</span>
                  </div>
                  <span>Problem fetching conversation</span>
                </div>
              )}
              {threads &&
                threads.length > 0 &&
                threads.length !== 1 &&
                selectedThread === -1 ? (
                <span>
                  Please select one of the listed conversations in order to
                  start
                </span>
              ) : null}
              <CustomNav
                name="communication"
                activeKey={selectedThread}
                onActiveChange={selectThread}
              >
                {threads.map((thread) => (
                  <CustomNavItem
                    key={thread.id}
                    eventKey={thread.id}
                    className="w-100 justify-content-between"
                  >
                    <span>{thread.user}</span>
                    <span>{thread.subject ?? "N/A"}</span>
                  </CustomNavItem>
                ))}
              </CustomNav>
              <hr className="d-block d-lg-none mt-1 mb-0" />
            </Col>
            <Col sm={12}>
              {!loadingThreads && selectedThread != -1 && messages && messages.length === 0 && (
                <>
                  <br />
                  <span>No conversations in the selected thread</span>
                </>
              )}

              <div className="py-2 border-bottom d-none d-lg-block" />

              {loadingMessages && (
                <>
                  <br />
                  <span>
                    <Spinner
                      animation="border"
                      style={{ height: 15, width: 15 }}
                    />{" "}
                    Please wait while loading the messages
                  </span>
                </>
              )}
              <div>
                <div className="position-relative">
                  <div className="chat-messages p-2">
                    {messages?.length != 0 ? (
                      messages.map((x, index) => (
                        <ChatMessage
                          key={index}
                          index={index}
                          position={x.from === currentUser ? "right" : "left"}
                          name={x.from === currentUser ? "You" : x.from}
                          avatar={x.from === currentUser ? avatar1 : avatar2}
                          time={getFormattedDateTimeStr(x.created, {
                            chat: true,
                          })}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: x.message.split("\n").join("<br>"),
                            }}
                            className="m-0"
                          />
                        </ChatMessage>
                      ))) : ""}
                    <div ref={chatRef}></div>
                  </div>
                </div>
              </div>

              <div className="user-mention-list" ref={testRef} />
              {selectedThread !== -1 && (
                <div className="flex-grow-0 py-3">
                  <InputGroup style={{ position: "relative", height: 150 }}>
                    <MentionsInput
                      type="text"
                      classNames={{
                        mentions: "flex-fill",
                        mentions__input: "form-control pe-4",
                        // mentions__suggestions__list: "",
                        // mentions__suggestions__item: "",
                        // "mentions__suggestions__item--focused": "",
                        // "mentions--multiLine": "",
                        // "mentions--singleLine": "",
                        // mentions__control: "",
                        // mentions__highlighter: "",
                      }}
                      style={{ input: { height: 150 } }}
                      allowSpaceInQuery
                      value={replyMessage}
                      onChange={processMentionText}
                      onKeyPress={(e) => handleMessage(e)}
                      suggestionsPortalHost={testRef.current}
                    >
                      <Mention
                        trigger="@"
                        appendSpaceOnAdd
                        renderSuggestion={(
                          entry,
                          search,
                          highlightedDisplay,
                          index,
                          focused
                        ) => {
                          if (focused) {
                            scrollToSuggestionIndex(index);
                          }
                          return (
                            <div
                              className={`user-mention-item${focused ? " focused" : ""
                                }`}
                            >
                              <span className="text-truncate">
                                {entry.display}
                              </span>
                            </div>
                          );
                        }}
                        displayTransform={(id, display) => {
                          const user = users.find((x) => x.id === id);
                          return `@${user.firstName} ${user.lastName}`;
                        }}
                        data={users.filter(u => u.active).map((user) => ({
                          ...user,
                          display: `${user.firstName} ${user.lastName}`,
                        }))}
                      />
                    </MentionsInput>
                    {sendMessageLoading ? (
                      <Spinner
                        animation="border"
                        size="sm"
                        style={{
                          position: "absolute",
                          right: 5,
                          bottom: 5,
                          borderTopLeftRadius: "50%",
                          borderBottomLeftRadius: "50%",
                        }}
                      />
                    ) : (
                      <Send
                        size={20}
                        className="text-dark"
                        style={{
                          ...(replyMessage
                            ? { cursor: "pointer" }
                            : { opacity: 0.4 }),
                          position: "absolute",
                          right: 5,
                          bottom: 5,
                        }}
                        onClick={() => !!replyMessage && reply()}
                      />
                    )}
                  </InputGroup>
                </div>
              )}
            </Col>
          </>
        )}
      </Row>
    </>
  );
});

export default Chat;
