import { useEffect, useRef, useState } from "react";

const getScreenSize = () => ({
  width: document.body.clientWidth,
  height: document.body.clientHeight,
  isMobile: document.body.clientWidth <= 768,
});

export default function useScreenSize() {
  const [screenSize, setScreenSize] = useState(getScreenSize);
  const timeout = useRef();

  useEffect(() => {
    function onResize() {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => setScreenSize(getScreenSize), 250);
    }

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return screenSize;
}
