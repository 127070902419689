import axios from "axios";
import { SERVER_URL } from "../configs";

const axiosInstance = axios.create({
  baseURL: `${SERVER_URL}/api`,
});

axiosInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        var errorMessage = (error.response && error.response.data) || "Something went wrong";
        if (Array.isArray(error.response?.data)) {
            errorMessage = error.response.data.map(d => d.description ?? '').filter(d => d).join(', ');
        }
        return Promise.reject(errorMessage)
    }
);

export default axiosInstance;

/**
 * Creates a default **CRUD** apis by the provided `path`
 * @param {string} path
 * @param {Object} [params]
 * @param {boolean} [params.useQuery] will send `create` and `update` data with query params when `true`
 */
export const generateCRUD = (path, params = { useQuery: false }) => ({
  get: () => axiosInstance.get(`/${path}/Get${path}${path.endsWith('s') ? 'es' : 's'}`),
  create: (data) =>
    params.useQuery
      ? axiosInstance.post(
          `/${path}/Create${path}?${new URLSearchParams(data)}`
        )
      : axiosInstance.post(`/${path}/Create${path}`, data),
  update: (data) =>
    params.useQuery
      ? axiosInstance.post(
          `/${path}/Update${path}?${new URLSearchParams(data)}`
        )
      : axiosInstance.post(`/${path}/Update${path}`, data),
  delete: (idQuery) =>
    axiosInstance.post(
      `/${path}/Delete${path}?${new URLSearchParams(idQuery)}`
    ),
});
