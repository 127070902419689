import React, { useMemo, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import useAppSelector from "../../../hooks/useAppSelector";
import compFields from "../Comps/compFields";
import { getFormattedDateTimeStr, numberWithCommas, getFormattedAsOfPreviousDate } from "../../../utils";
import startCase from "lodash/startCase";

const getValueByType = (value, type) => {
  switch (type) {
    case "boolean": {
      return value ? "Yes" : "No";
    }
    case "date": {
      return getFormattedAsOfPreviousDate(value, { short: true });
    }
    case "number":
    default: {
      return value;
    }
  }
};

/**
 * @param {Object} props
 * @param {import("../../../services/comp").Comp} props.comp
 * @param {boolean} [props.checkable] `false` by default
 * @param {boolean} [props.removable] `false` by default
 * @param {(checked: boolean) => void} [props.onChange] valid ony if `checkable` is `true`
 * @param {boolean} [props.checked] valid ony if `checkable` is `true`
 * @param {() => void} [props.onCardClick]
 * @param {string} [props.compTypeName]
 * @returns
 */
const CompCard = ({
  comp,
  checkable = false,
  onChange,
  removable = false,
  checked,
  onCardClick,
  compTypeName,
}) => {
  const compTypesMap = useAppSelector((state) => state.comps.compTypesMap);
  const [expandFields, setExpandFields] = useState(false);

  const fieldsToRender = useMemo(
    () => compFields.filter((field) => !!comp[field.name]),
    [comp]
  );

  return (
    <div className="comp-card card-comp mb-2" onClick={onCardClick}>
      <div className="d-flex justify-content-between align-items-center mb-2">
        {checkable && (
          <div onClick={(e) => e.stopPropagation()}>
            <Form.Check
              id={`${comp.id}-search-comp-card`}
              className="custom-checkbox ps-0 m-0 text-black"
              label={
                <span className="align-middle ms-n1">
                  {comp.salePrice
                    ? `$${numberWithCommas(comp.salePrice)}`
                    : "N/A"}
                </span>
              }
              onChange={(e) => onChange(e.target.checked)}
              checked={checked}
            />
          </div>
        )}
        <Badge bg="info" className="fs-6">
          {compTypeName}
        </Badge>
      </div>
      <div className="d-flex flex-wrap align-items-center gap-1">
        {fieldsToRender
          .slice(0, expandFields ? fieldsToRender.length : 2)
          .map((field) => (
            <Badge key={field.name} bg="brown" className="comp-badge fw-light">
              <span className="fw-bold">{startCase(field.name)}:</span>{" "}
              {getValueByType(comp[field.name], field.type)}
            </Badge>
          ))}
        {fieldsToRender.length > 2 && (
          <button
            type="button"
            className="btn btn-link p-0 link-brown"
            onClick={(e) => {
              e.stopPropagation();
              setExpandFields(!expandFields);
            }}
          >
            {expandFields ? "show less" : `+${fieldsToRender.length - 2}`}
          </button>
        )}
      </div>
      <div className="d-flex align-items-end justify-content-between mt-1">
        <div className="fw-bold comp-address">
          {comp.compAddress.fullAddress}
        </div>
        <div className="comp-date">
          {!!comp.dateOfSale && 
            getFormattedAsOfPreviousDate(comp.dateOfSale, { short: true })}
        </div>
      </div>
      {removable && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onChange(false);
          }}
          type="button"
          className="btn-close"
          aria-label="Close"
        />
      )}
    </div>
  );
};

export default CompCard;
