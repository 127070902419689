import { Link } from "react-router-dom";
import { Button } from "react-bootstrap"

export const getColumns = (handleDeleteContact) => ([
    {
        name: <b>ID</b>,
        sortable: false,
        cell: row => row.id
    },
    {
        name: 'First Name',
        sortable: false,
        cell: row => <div style={{ wordBreak: "initial" }}>{row.firstName}</div>
    },
    {
        name: 'Last Name',
        sortable: false,
        cell: row => row.lastName
    },
    {
        name: 'Primary Email',
        sortable: false,
        cell: row => <div>{row.customerContactEmails.length > 0 ? row.customerContactEmails[0].email : ""}</div>
    },
    {
        name: 'Primary Phone Number',
        sortable: false,
        cell: row => <div>{row.customerContactPhoneNumbers.length > 0 ? row.customerContactPhoneNumbers[0].phone : ""}</div>
    },
    {
        name: 'Edit',
        sortable: false,
        cell: row => <Link to={`/dashboard/editContacts/${row.id}`}>Edit Contact</Link>
    },
    {
        name: 'Delete',
        sortable: false,
        cell: row => <Button onClick={() => handleDeleteContact(row.id)}>Delete</Button>
    }
])
