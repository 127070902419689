import React from "react";
import { Card, Container } from "react-bootstrap";
import BasicInfo from "./BasicInfo";

const CustomerInfo = (props) => {
  return (
    <Container fluid className="p-0 customer-info-card-container">
      <h1 className="h4 title">Company Info</h1>
      <Card className="scrollable br-radius">
        <Card.Body className="pb-0 pt-4 job-card-body">
          <BasicInfo {...props} />
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CustomerInfo;
