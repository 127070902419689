import Icon from "./Icon";

const Property = (props) => (
  <Icon {...props}>
    <path d="M2.69531 1.94922V3.89844L2.43359 3.94141C2.28516 3.96875 1.98438 4.01953 1.75781 4.05469L1.34766 4.125L1.33594 11.4766L1.32812 18.8281H0.664062H0V19.4141V20H10H20V19.4141V18.8281H19.3359H18.6719V12.1094V5.39062H14.6289H10.5859V3.98438V2.57812L10.5 2.59766C10.4492 2.60547 10.1914 2.65234 9.92188 2.69531C9.65234 2.73828 9.39453 2.78516 9.34766 2.79297L9.25781 2.8125V1.40625V0H5.97656H2.69531V1.94922ZM8.08594 2.08984C8.08594 2.59375 8.07812 3.00781 8.06641 3.00781C8.03125 3.00781 4.04688 3.67188 3.95703 3.69141L3.86719 3.71094V2.44141V1.17188H5.97656H8.08594V2.08984ZM9.41406 11.4062V18.8281H8.65234H7.89062V17.4805V16.1328H5.95703H4.02344V17.4805V18.8281H3.26172H2.5V11.9766V5.125L2.76562 5.07812C2.91016 5.05469 4.42578 4.80078 6.13281 4.51562C7.83984 4.22656 9.27734 3.99219 9.32812 3.98828L9.41406 3.98438V11.4062ZM17.5 12.6953V18.8281H14.043H10.5859V12.6953V6.5625H14.043H17.5V12.6953ZM6.71875 18.0664V18.8281H5.95703H5.19531V18.0664V17.3047H5.95703H6.71875V18.0664Z" />
    <path d="M6.71875 5.97656V6.5625H7.30469H7.89062V5.97656V5.39062H7.30469H6.71875V5.97656Z" />
    <path d="M4.03125 8.66016L4.04297 9.23828L4.62109 9.25L5.19531 9.26172V8.67188V8.08594H4.60938H4.01953L4.03125 8.66016Z" />
    <path d="M6.72656 8.66016L6.73828 9.23828L7.3164 9.25L7.89062 9.26172V8.67188V8.08594H7.30468H6.71484L6.72656 8.66016Z" />
    <path d="M4.05078 10.7695C4.03516 10.7812 4.02344 11.0469 4.02344 11.3555V11.9141H4.60938H5.19531V11.3281V10.7422H4.63672C4.32812 10.7422 4.0625 10.7539 4.05078 10.7695Z" />
    <path d="M6.74609 10.7695C6.73047 10.7812 6.71875 11.0469 6.71875 11.3555V11.9141H7.30469H7.89062V11.3281V10.7422H7.33203C7.02344 10.7422 6.75781 10.7539 6.74609 10.7695Z" />
    <path d="M4.02344 14.0234V14.6094H4.60938H5.19531V14.0234V13.4375H4.60938H4.02344V14.0234Z" />
    <path d="M6.71875 14.0234V14.6094H7.30469H7.89062V14.0234V13.4375H7.30469H6.71875V14.0234Z" />
    <path d="M12.1094 8.67188V9.26172L12.6875 9.25L13.2617 9.23828L13.2734 8.66016L13.2852 8.08594H12.6953H12.1094V8.67188Z" />
    <path d="M14.8047 8.67188V9.26172L15.3828 9.25L15.957 9.23828L15.9687 8.66016L15.9805 8.08594H15.3906H14.8047V8.67188Z" />
    <path d="M12.1094 11.3281V11.9141H12.6953H13.2852L13.2734 11.3359L13.2617 10.7617L12.6875 10.75L12.1094 10.7383V11.3281Z" />
    <path d="M14.8047 11.3281V11.9141H15.3906H15.9805L15.9687 11.3359L15.957 10.7617L15.3828 10.75L14.8047 10.7383V11.3281Z" />
    <path d="M12.1094 14.0234V14.6094H12.6953H13.2812V14.0234V13.4375H12.6953H12.1094V14.0234Z" />
    <path d="M14.8047 14.0234V14.6094H15.3906H15.9766V14.0234V13.4375H15.3906H14.8047V14.0234Z" />
    <path d="M12.1094 16.7188V17.3047H12.6953H13.2812V16.7188V16.1328H12.6953H12.1094V16.7188Z" />
    <path d="M14.8047 16.7188V17.3047H15.3906H15.9766V16.7188V16.1328H15.3906H14.8047V16.7188Z" />
  </Icon>
);

export default Property;
