import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

import dropzoneIcon from "./../../assets/icons/dropzoneIcon.svg";
import './dropzone.css';

const Dropzone = (props) => {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (!props.files) {
      return;
    }
    const _temp = props.files.map(x => ({ ...x, custom: !x.name.match(/\.(jpg|jpeg|png|gif)$/) }));
    const sortedFirst = _temp.sort((a, b) => a.custom - b.custom);

    setFiles(sortedFirst);
  }, [props.files])

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      '*/*': []
    },
    onDrop: acceptedFiles => {
      const _acceptedFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));

      if (files.find(x => x.custom)) {
        setFiles([...files.filter(x => x.custom) ?? [], ..._acceptedFiles]);
      }
      else {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }

      props?.filesUpdated(acceptedFiles);
    }
  });

  const thumbs = files.map(file => {

    if (file.custom) {
      return (<div className="file-item" key={file.name}>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <Button variant="danger" className="me-1" onClick={() => props.deleteFile(file.id)}>X</Button>
            <a href={file.webUrl} target="blank">{file.name}</a>
          </div>
        </div>
      </div>);
    }
    else {
      return (<div className="file-item" key={file.name}>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <Button variant="danger" className="me-1" onClick={() => props.deleteFile(file.id)}>X</Button>
            <a href={file.webUrl} target="blank">{file.name}</a>
          </div>
          {file.name.match(/\.(jpg|jpeg|png|gif)$/) ? (<img
            className="mt-1"
            width={100}
            height={100}
            src={file.downloadUrl}
            //className="thumbImg"
            // Revoke data uri after image is loaded
            onLoad={() => { URL.revokeObjectURL(file.downloadUrl) }}
            alt=""
          />) : (<span>{file.name}</span>)}
        </div>
      </div >);
    }

    // if (file.custom) {
    //   return (<div className="thumb" key={file.name}>
    //     <div className="thumbInner">
    //       <Button variant="danger" onClick={() => props.deleteFile(file.id)} style={{ marginTop: '-70px', marginRight: '5px' }}>X</Button>
    //       <a href={file.webUrl} target="blank">{file.name}</a>
    //     </div>
    //   </div>);
    // }

    // return (<div className="thumb" key={file.name}>
    //   <div className="thumbInner">
    //     {file.name.match(/\.(jpg|jpeg|png|gif)$/) ? (<img
    //       src={file.downloadUrl}
    //       className="thumbImg"
    //       // Revoke data uri after image is loaded
    //       onLoad={() => { URL.revokeObjectURL(file.downloadUrl) }}
    //       alt=""
    //     />) : (<span>{file.name}</span>)}
    //   </div>
    // </div>);
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.filter(x => !x.custom).forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div className="dropzone-wrapper">
      {props.allowDrop &&
        (<div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <img src={dropzoneIcon} className="dropzoneIcon" alt="" />
          <p className="dropzone-title">Drop Files here</p>
        </div>)}
      <aside className="thumbsContainer">
        {thumbs}
      </aside>
    </div>
  );
}
export default Dropzone;