import React, { useCallback, useMemo } from "react";
import NotesAndMemos from "../../jobDetails/NotesAndMemos";
import { updateInspectionNotes } from "../../../services/ticket";
import { useParams } from "react-router-dom";
import useJobDetails from "../../jobDetails/useJobDetails";
import useJobInspectionDetails from "../../jobDetails/useJobInspectionDetails";

const Notes = () => {
  const { ticketId } = useParams();
  const { ticketInfo } = useJobDetails();
  const { scheduledInspection } = useJobInspectionDetails();
  const { inspectionStatusNotes } = ticketInfo;

  const saveInspectionNotes = useCallback(
    (value) => updateInspectionNotes(ticketId, value),
    [ticketId]
  );

  const inspectionApi = useMemo(
    () => ({
      get: async () =>
        inspectionStatusNotes
          ? [
              {
                id: 1,
                note: inspectionStatusNotes,
              },
            ]
          : [],
      create: async note => {
        await saveInspectionNotes(note)
        return {
          id: 1,
          note: inspectionStatusNotes,
        }
      },
      update: (_id, note) => saveInspectionNotes(note),
      delete: () => saveInspectionNotes(""),
    }),
    [inspectionStatusNotes, saveInspectionNotes]
  );

  return (
    <div className="pt-80">
      <NotesAndMemos 
        inspectionApi={inspectionApi}
        inspectionId={scheduledInspection.id}
      />
    </div>
  );
};

export default Notes;
