import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import LoadingWrapper from "../../../components/LoadingWrapper";
import dragula from "react-dragula";
import compColumnLabels from '../../dashboards/Comps/compLabels';
import { lowerFirst } from "lodash";

const CompTypeColumns = ({
  title,
  columnsTitle,
  allColumnsTitle,
  compTypeColumns,
  allCompTypesColumns,
  onUpdate,
  loading,
}) => {
  const containers = useRef([]);

  useEffect(() => {
    const drake = dragula(containers.current);
    drake.on("drop", (_el, target, source) => {
      const from = source.getAttribute("data-column-list");
      const to = target.getAttribute("data-column-list");

      if (from === to && from === "all-comp-type-columns") {
        drake.cancel(true);
        return;
      }

      const compTypeColumnsChildren =
        from === "all-comp-type-columns" ? target.children : source.children;

      console.log(compTypeColumnsChildren);

      const compTypes = [];
      for (let i = 0; i < compTypeColumnsChildren.length; i++) {
        const childElem = compTypeColumnsChildren.item(i);
        const col = childElem.getAttribute('data-column')
        console.log(col);

        compTypes.push(col);
      }

      onUpdate(compTypes);
    });

    return () => {
      drake.destroy();
    };
  }, [onUpdate]);

  const initRef = (ref) => containers.current.push(ref);

  return (
    <Container className="h-100">
      <Helmet title={title} />
      <div className="d-flex justify-content-between mb-3">
        <h2>{title}</h2>
      </div>
      <LoadingWrapper loading={loading}>
        <Row>
          <CompTypeColumnsCard
            name="comp-type-columns"
            title={columnsTitle}
            initRef={initRef}
            columns={compTypeColumns}
          />
          <CompTypeColumnsCard
            name="all-comp-type-columns"
            title={allColumnsTitle}
            initRef={initRef}
            columns={allCompTypesColumns.filter(
              (item) => !compTypeColumns.includes(item)
            )}
          />
        </Row>
      </LoadingWrapper>
    </Container>
  );
};

const CompTypeColumnsCard = ({ name, title, initRef, columns }) => {
  const [filter, setFilter] = useState("");

  return (
    <Col>
      <Card style={{ height: "calc(100vh - 216px)" }}>
        <Card.Header className="px-3">
          <Card.Title tag="h5">{title}</Card.Title>
          <div className="px-2">
            <Form.Control
              name="comp-filter"
              placeholder="Type to filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        </Card.Header>
        <Card.Body className="pt-0" style={{ overflow: "auto" }}>
          <div
            ref={initRef}
            data-column-list={name}
            className="h-100 pb-3 d-flex flex-wrap align-items-start align-content-start"
          >
            {columns.map((column) => (
              <CompTypeColumnItem
                key={column}
                column={column}
                filter={filter}
              />
            ))}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

const CompTypeColumnItem = ({ column, filter }) => (
  <div
    className="m-1 border rounded px-2 py-1"
    role="button"
    hidden={!!filter && !column.toLowerCase().includes(filter.toLowerCase())}
    data-column={column}
  >
    <span>{compColumnLabels[lowerFirst(column.slice())] ?? column}</span>
  </div>
);

export default CompTypeColumns;
