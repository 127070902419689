import { useSelector } from "react-redux";

// Use throughout your app instead of plain `useSelector`

/**
 * @type {import("react-redux").TypedUseSelectorHook<{
 *  properties: any;
 *  ticketGroups: any;
 *  appData: import("../redux/reducers/app").AppDataStateType;
 *  user: any;
 *  comps: import("../redux/reducers/comps").CompsStateType;
 *  search: any;
 * }>}
 */
const useAppSelector = useSelector;

export default useAppSelector;
