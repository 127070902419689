import { useEffect, useState } from "react";
import { Form, Button, Spinner, Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { cancelSnoozedTicket, snoozeTicket } from "../../services/ticket";
import ConfirmationModal from "../jobs/confirmationModal";
import useAppSelector from '../../hooks/useAppSelector';
import { getFormattedInspectionDate } from "../../utils";

const SnoozeTicketModal = ({
    ticketId,
    snoozeUntil,
    snoozeReason,
    snoozedByUserId,
    snoozedCreatedDate,
    handleCancelSnoozeModal,
    reloadListWithCurrentFilters,
    ...rest }) => {

    const [minDate, setMinDate] = useState();
    const [_snoozeUntil, _setSnoozeUntil] = useState('')
    const [_snoozeReason, _setSnoozeReason] = useState('')

    const [cancelSnoozeDisabled, setCancelSnoozeDisabled] = useState();

    const usersMap = useAppSelector(state => state.appData.usersMap);

    useEffect(() => {
        const now = new Date();
        now.setDate(now.getDate() + 1);
        setMinDate(now.toISOString().split("T")[0]);
    }, [])

    useEffect(() => {
        _setSnoozeReason(snoozeReason);
        if (snoozeUntil) {
            _setSnoozeUntil(snoozeUntil.split("T")[0]);
        }

        return () => {
            _setSnoozeReason();
            _setSnoozeUntil();
        }

    }, [snoozeUntil, snoozeReason]);

    const cancelSnooze = async () => {
        setCancelSnoozeDisabled(true);
        const answer = window.confirm("Are you sure you want to cancel snooze?");
        if (!answer) {
            setCancelSnoozeDisabled(false);
            return;
        }
        try {
            const response = await cancelSnoozedTicket(ticketId);
            if (response.success) {
                toast.success("Snooze cancelled successfully.");
                reloadListWithCurrentFilters();
            }
        }
        catch (e) {
            toast.error('Failed to cancel the snoozed job.');
        }
        finally {
            setCancelSnoozeDisabled(false);
            handleCancelSnoozeModal();
        }
    }

    const handleConfirm = async () => {
        if (!_snoozeUntil) {
            toast.error("Please specify until when the job should be snoozed.");
            return;
        }

        if (_snoozeReason == '' || !_snoozeReason) {
            const answer = window.confirm('The snooze reason was left empty, are you sure you want to continue?');
            if (!answer) {
                return;
            }
        }

        try {
            const response = await snoozeTicket(ticketId, _snoozeUntil, _snoozeReason);
            if (response.success) {
                toast.success(`Job snoozed succesfully until ${_snoozeUntil}.`);
                reloadListWithCurrentFilters();
            }
        }
        catch (e) {
            toast.error('Failed to snooze the job.');
        }
        finally {
            handleCancelSnoozeModal();
        }
    }

    return (
        <ConfirmationModal
            title="Snooze Ticket"
            handleConfirm={handleConfirm}
            handleCancel={handleCancelSnoozeModal}
            {...rest}>
            <Form.Group>
                <Form.Label>Snooze until</Form.Label>
                <Form.Control min={minDate} type='date' value={_snoozeUntil} onChange={e => _setSnoozeUntil(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>Snooze reason</Form.Label>
                <Form.Control as='textarea' rows={5} value={_snoozeReason} onChange={e => _setSnoozeReason(e.target.value)} />
            </Form.Group>
            {snoozeUntil &&
                (<>
                    <Card style={{ backgroundColor: 'lightgray' }}>
                        <Card.Body>
                            <Card.Title>Snooze Information</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Snoozed by - <b>{usersMap[snoozedByUserId].firstName} {usersMap[snoozedByUserId].lastName}</b></Card.Subtitle>
                            <Card.Subtitle className="mb-2 text-muted">Snoozed at - <b>{getFormattedInspectionDate(snoozedCreatedDate)}</b></Card.Subtitle>
                            <Card.Text>

                            </Card.Text>
                            <Button
                                variant='warning'
                                disabled={cancelSnoozeDisabled}
                                onClick={cancelSnooze}>
                                {cancelSnoozeDisabled && (
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        className="me-2 align-middle"
                                    />
                                )}Cancel Snooze</Button>
                        </Card.Body>
                    </Card>
                </>)}
        </ConfirmationModal>
    )
}

export default SnoozeTicketModal;