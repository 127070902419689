import axios from "../utils/axios";

export function getPropertySubTypes() {
  return axios.get("/PropertySubType/GetPropertySubTypes");
}

export function getPropertySubTypesByParentId(parentId) {
  const queryParams = new URLSearchParams({ parentId });
  return axios.get(`/PropertySubType/GetPropertySubTypesByParentId?${queryParams}`);
}

/**
 *
 * @param {{
 *  id: number,
 *  name: string,
 *  displayOrder: number,
 *  propertyTypeId: number,
 *  propertyType: {
 *    id: number,
 *    name: string,
 *    displayOrder: number,
 *    type: number
 *  }
 * }} data
 * @returns
 */
export function createPropertySubType(data) {
  return axios.post("/PropertySubType/CreatePropertySubType", data);
}
