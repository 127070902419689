import React, { useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const initialState = {
  filesCount: 0,
  setFilesCount: (filesCount = 0) => {},
  showUnresolved: false,
  onUnresolvedChange: (e) => {},
};

const UtilityContext = React.createContext(initialState);

function UtilityProvider({ children }) {
  const [state, setState] = useState(initialState);
  const [showUnresolved, setShowUnresolved] = useLocalStorage(
    "show-unresolved",
    false
  );

  const onUnresolvedChange = (e) => {
    setShowUnresolved(!e.target.checked);
  };

  const setFilesCount = (filesCount) => {
    setState((state) => ({ ...state, filesCount }));
  }
    

  return (
    <UtilityContext.Provider
      value={{
        ...state,
        setFilesCount,
        showUnresolved,
        onUnresolvedChange,
      }}
    >
      {children}
    </UtilityContext.Provider>
  );
}

export { UtilityProvider, UtilityContext };
