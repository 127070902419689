import { Button, Card, Table } from "react-bootstrap";
import { Edit2, Trash } from "react-feather";
import { TypeEnum } from ".";
import { getFormattedDateTimeStr } from "../../utils";

/**
 * @param {Object} props
 * @param {Array} props.data
 * @param {boolean} props.hasType
 * @param {boolean} props.hasDays
 * @param {boolean} props.hasLabel
 * @param {boolean} props.hasUpdate
 * @param {boolean} props.hasDelete
 * @param {import("react").FC} [props.CustomAction]
 * @param {(item: any) => void} props.onEdit
 * @param {(item: any) => void} props.onDelete
 */
const ValueTypesList = ({
  data,
  onEdit,
  onDelete,
  hasType = false,
  hasDays = false,
  hasLabel = false,
  hasDisplayOrder = true,
  hasCreated = false,
  hasUpdate = false,
  hasDelete = false,
  CustomAction,
}) => (
  <Card>
    <Table striped>
      <thead>
        <tr>
          <th>Name</th>
          {hasDisplayOrder && <th>Display Order</th>}
          {hasCreated && <th>Created</th>}
          {hasType && <th>Type</th>}
          {hasDays && <th>Days</th>}
          {hasLabel && <th>Label</th>}
          {(hasUpdate || hasDelete || CustomAction) && (
            <th style={{ width: (+hasUpdate + +hasDelete + +!!CustomAction*3) * 60 }}>
              Actions
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>{item.name}</td>
            {hasDisplayOrder && <td>{item.displayOrder}</td>}
            {hasCreated && <td>{getFormattedDateTimeStr(item.created)}</td>}
            {hasType && <td>{TypeEnum[item.type]}</td>}
            {hasDays && <td>{item.days}</td>}
            {hasLabel && <td>{item.label}</td>}
            {(hasUpdate || hasDelete || CustomAction) && (
              <td className="table-action">
                {CustomAction && <CustomAction item={item} />}
                {hasUpdate && (
                  <Button variant="link" size="sm">
                    <Edit2
                      size={18}
                      className="align-middle"
                      onClick={() => onEdit(item)}
                    />
                  </Button>
                )}
                {hasDelete && (
                  <Button variant="link" size="sm">
                    <Trash
                      size={18}
                      className="align-middle text-danger"
                      onClick={() => onDelete(item)}
                    />
                  </Button>
                )}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  </Card>
);

export default ValueTypesList;
