import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import * as ReactDOM from 'react-dom';
import { Button, Card, Form, ButtonGroup, Modal } from "react-bootstrap";
import Select from "react-select";
import { Edit3, Trash2 } from "react-feather";
import LoadingWrapper from "../../components/LoadingWrapper";
import Popconfirm from "../../components/Popconfirm";
import { AuthContext } from "../../contexts/JWTContext";
import useAppSelector from "../../hooks/useAppSelector";
import {
  createTicketReview,
  getTicketReviews,
  updateTicketReview,
  deleteTicketReview,
} from "../../services/ticketReview";

import { apiErrorHandler, getFormattedDateTimeStr } from "../../utils";
import { USER_ROLES } from "../../constants";
import "./notesAndMemos.css";

const Reviews = ({ ticket }) => {

  const usersMap = useAppSelector((state) => state.appData.usersMap);
  const users = useAppSelector((state) => state.appData.users);
  const { user } = useContext(AuthContext);
  const isAdmin = user?.roles.includes(USER_ROLES.ADMIN);
  const isReviewer = user?.roles.includes(USER_ROLES.REVIEWER);
  const hasWriteAccess = isAdmin || isReviewer;
  const hasReadAccess = isAdmin || isReviewer;
  const userId = users.find(item => item.username === user.userName)?.id;

  const ticketId = ticket.id;

  let reviewedIds = users.map(u => u.id);

  let step2And3Completed = ticket.ticketSteps
    .filter(s => s.ticketStepTypeId === 2 || s.ticketStepTypeId === 3)
    .every(s => s.completedDate);

  if (step2And3Completed) {
    reviewedIds = ticket.ticketSteps
      .filter(s =>
        (s.ticketStepTypeId === 2 || s.ticketStepTypeId === 3)
        && s.completedDate
        && s.assignedToId
      )
      .map(s => s.assignedToId);

    reviewedIds = [...new Set(reviewedIds)];
  }

  const emptyReview = {
    reviewedId: (reviewedIds && reviewedIds.length > 0) ? reviewedIds[0] : null,
    ticketId: ticketId,
    grade: 0,
    insertions: 0,
    deletions: 0,
    moves: 0,
    formatting: 0,
    comments: 0,
    notes: ''
  }

  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({ ...emptyReview });
  const [showAddNewReview, setShowAddNewReview] = useState(false);
  const [newReviewTitle, setNewReviewTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const [reviewToDelete, setReviewToDelete] = useState(0);
  const [reviewToUpdate, setReviewToUpdate] = useState();

  const api = useMemo(() => {

    if (!ticketId) {
      return;
    }

    return {
      create: (review) => createTicketReview(review),
      get: () => getTicketReviews(ticketId),
      update: updateTicketReview,
      delete: deleteTicketReview,
    };

  }, [ticketId]);

  useEffect(() => {
    setNewReviewTitle(`Review ${reviews.length + 1}`)
  }, [reviews])

  useEffect(() => {
    if (!api) {
      return;
    }
    if (!hasReadAccess) {
      return;
    }

    setLoading(true);
    api
      .get(ticketId)
      .then(setReviews)
      .finally(() => {
        setLoading(false);
      });
  }, [api]);

  const handleSave = async (review) => {
    setLoading(true);
    try {
      if (review.id) {
        await api.update(review);
        setReviewToUpdate(null);
        setReviews(reviews.map(r => r.id === review.id ? review : r));
      } else {
        const response = await api.create(review);
        setNewReview({...emptyReview})
        setReviews([...reviews, response]);
        setShowAddNewReview(false);
      }
    } catch (err) {
      apiErrorHandler(err);
    }
    setLoading(false);
  };

  const handleReviewDelete = () => {
    setLoading(true);
    const deleteId = reviewToDelete;
    setReviewToDelete(0);
    api
      .delete(deleteId)
      .then(() => {
        setReviews(reviews.filter((item) => item.id !== deleteId));
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  };

  const ReviewForm = ({ title, buttonText, initialData, handleSave }) => {

    const [review, setReview] = useState({ ...initialData });

    const selectedReviewedItem = {
      value: `${review.reviewedId}`,
      label: `${usersMap[review.reviewedId]?.firstName} ${usersMap[review.reviewedId]?.lastName}`,
    }

    const revisions = Number(review.insertions) + Number(review.deletions) + Number(review.moves) + Number(review.formatting) + Number(review.comments);

    const handleChange = (event) => {
      const { name, value } = event.target;
      setReview((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    };

    return (
      <>
        <div>
          <h4>{title}</h4>
        </div>
        <div>
            <label>Grade</label>
        </div>
        <div className="d-flex mb-1">
          {['A', 'B', 'C'].map((g, index) =>
            <label className={"btn me-3 " + (review.grade === index ? 'btn-primary' : 'btn-light')}>
              {g}
              <Form.Check
                type="radio"
                style={{ display: 'none' } }
                name="grade"
                className="me-4"
                id={`grade-${index}`}
                label={g}
                value={index}
                onChange={(e) => setReview({ ...review, grade: Number(e.target.value) })}
                checked={review.grade === index}
              />
            </label>
          )}
        </div>

        {!review.id && 
          <Form.Group className="mb-3">
            <label>Whom to review</label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              value={selectedReviewedItem}
              options={reviewedIds.map((reviewedId) => ({ label: `${usersMap[reviewedId]?.firstName} ${usersMap[reviewedId]?.lastName}`, value: `${reviewedId}` }))}
              onChange={(selectedItem) =>
                handleChange({ target: { value: selectedItem.value, name: "reviewedId" } })
              }
            />
          </Form.Group>
        }

        <Form.Group className="d-flex flex-wrap justify-content-between mb-3">
          
          <div style={{ flexBasis: '30%' }}>
            <div>
              <label>Insertions</label>
              <input
                name="insertions"
                type="number"
                style={{ maxWidth: 200 }}
                className="form-control"
                value={review.insertions}
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={{ flexBasis: '30%' }}>
            <div>
              <label>Deletions</label>
              <input
                name="deletions"
                type="number"
                style={{ maxWidth: 200 }}
                className="form-control"
                value={review.deletions}
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={{ flexBasis: '30%' }}>
            <div>
              <label>Moves</label>
              <input
                name="moves"
                type="number"
                style={{ maxWidth: 200 }}
                className="form-control"
                value={review.moves}
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={{ flexBasis: '30%' }}>
            <div>
              <label>Formatting</label>
              <input
                name="formatting"
                type="number"
                style={{ maxWidth: 200 }}
                className="form-control"
                value={review.formatting}
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={{ flexBasis: '30%' }}>
            <div>
              <label>Comments</label>
              <input
                name="comments"
                type="number"
                style={{ maxWidth: 200 }}
                className="form-control"
                value={review.comments}
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={{ flexBasis: '30%' }}>
            <div>
              <label>Revisions</label>
              <input
                name="revisions"
                readOnly={true}
                type="number"
                style={{ maxWidth: 200 }}
                className="form-control"
                value={revisions}
              />
            </div>
          </div>
          <Form.Control
            name="notes"
            className="mb-1 mt-2"
            as="textarea"
            placeholder="Review notes.."
            rows="9"
            style={{ width: "99%", margin: "auto", height: 100 }}
            value={review.notes}
            onChange={handleChange}
          />
        </Form.Group>

        <div className="note-or-memo-footer">
          <Button
            className="note-or-memo-save-button"
            onClick={() => handleSave(review)}
            variant="secondary"
          >
            {buttonText}
          </Button>
        </div>
      </>
    );
  }

  return (
    <LoadingWrapper loading={loading} className={`notes-or-memos-container`}>
      <Popconfirm
        title="Are you sure?"
        show={!!reviewToDelete}
        onOk={handleReviewDelete}
        onCancel={() => setReviewToDelete(0)}
      />

      {hasWriteAccess &&
        <div>
          {!showAddNewReview &&
            <Button
              className="note-or-memo-save-button mb-3"
              onClick={() => setShowAddNewReview(true)}
              variant="secondary"
            >
              Add review
            </Button>  
          }
          {showAddNewReview && 
            <div className="mb-3">
              <ReviewForm title={newReviewTitle} buttonText="Save Review" initialData={newReview} handleSave={handleSave} />
            </div>
          } 
        </div>
      }

      {hasReadAccess && 
        <div className="notes-or-memos mb-3">
          {reviews.map((item, index) => {

            const revisions = Number(item.insertions) + Number(item.deletions) + Number(item.moves) + Number(item.formatting) + Number(item.comments);

            return (
              <Card key={item.id} id={`note-${item.id}`} className="note-or-memo-card mb-0">
                <Card.Body className="note-or-memo-card-body mb-2">
                  <h4 className="mb-0">Review {index + 1}</h4>
                  <div className="d-flex gap-2 align-items-center">
                    <div style={{ fontSize: 35, fontHeight: 35 }}>
                      {item.grade === 0 ? 'A' : item.grade === 1 ? 'B' : item.grade === 2 ? 'C' : ''}
                    </div>
                    <div className="flex-grow-1">
                      <div className="d-flex flex-grow-1 justify-content-between align-items-center">
                        <div>
                          {item.userId &&
                            <span className="memo-created-by-user-name">
                              {usersMap[item.userId]?.firstName} {usersMap[item.userId]?.lastName}
                            </span>
                          }
                          {item.reviewedId &&
                            <span>
                              <span>reviewed </span>
                              <span className="memo-created-by-user-name">
                                {usersMap[item.reviewedId]?.firstName} {usersMap[item.reviewedId]?.lastName}
                              </span>
                            </span>
                          }
                          {(item.updated || item.created) && <span>
                            @
                            {" "}
                            {getFormattedDateTimeStr(item.updated || item.created)}
                          </span>}
                        </div>
                        {hasWriteAccess &&
                          <div className="d-flex">
                            <Edit3
                              size={13}
                              className="action-icon me-1"
                              onClick={() => setReviewToUpdate(item)}
                            />
                            <Trash2
                              size={13}
                              className="action-icon text-danger"
                              onClick={() => setReviewToDelete(item.id)}
                            />
                          </div>
                        }
                      </div>
                      <div>
                        Insertions: {item.insertions},
                        Deletions: {item.deletions},
                        moves: {item.moves},
                        formatting: {item.formatting},
                        comments: {item.comments},
                        Revisions: {revisions}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="review-comment-item">
                      {(item.notes ?? '').split("\n").map((commentLine, i) => (
                        <React.Fragment key={i}>
                          {!!i && <br />}
                          <span>{commentLine}</span>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            )
          }
          )}
        </div>
      }

      {reviewToUpdate &&
        <Modal
          show={true}
          onCancel={() => setReviewToUpdate(null)}
          onHide={() => setReviewToUpdate(null)}
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <ReviewForm title="Update Review" buttonText="Save Changes" initialData={reviewToUpdate} handleSave={handleSave} />
          </Modal.Body>
        </Modal>
      }

    </LoadingWrapper>
  );
};

export default Reviews;
