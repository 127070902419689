/** @typedef {{ id: number; name: string }} CompType */
import axios from "../../utils/axios";

/** @returns {Promise<Array<CompType>>} */
export function getCompTypes() {
  return axios.get("/CompType/GetCompTypes");
}

/**
 * @param {string} name
 * @returns {Promise<CompType>}
 */
export function createCompType(name) {
  return axios.post("/CompType/CreateCompType", { name });
}

/**
 * @param {number} id
 * @param {string} name
 * @returns {Promise<CompType>}
 */
export function updateCompType(id, name) {
  return axios.post(`/CompType/UpdateCompType`, { id, name });
}

/**
 * @param {number} compTypeId
 * @returns {Promise<Array<string>>}
 */
export function getCompTypeColumns(compTypeId) {
  const queryParams = new URLSearchParams({ compTypeId });
  return axios.get(`/CompType/GetCompTypeColumns?${queryParams}`);
}

/**
 * @param {number} compTypeId
 * @returns {Promise<Array<string>>}
 */
export function getCompTypeExportColumns(compTypeId) {
  const queryParams = new URLSearchParams({ compTypeId });
  return axios.get(`/CompType/GetCompTypeExportColumns?${queryParams}`);
}

export function getAllCompTypeColumns() {
  return axios.get(`/CompType/GetAllCompTypeColumns`);
}

export function updateCompTypeColumns(compTypeId, columns) {
  const queryParams = new URLSearchParams({ compTypeId });
  return axios.post(`/CompType/UpdateCompTypeColumns?${queryParams}`, columns);
}

export function updateCompTypeExportColumns(compTypeId, columns) {
  const queryParams = new URLSearchParams({ compTypeId });
  return axios.post(`/CompType/UpdateCompTypeExportColumns?${queryParams}`, columns);
}
