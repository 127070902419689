import React, { useState } from "react";
import { Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import LoadingWrapper from "../../../components/LoadingWrapper";
import useAppSelector from "../../../hooks/useAppSelector";
import axios from "axios";
import { SERVER_URL } from "../../../configs";
import { toast } from "react-toastify";
import useAppDispatch from "../../../hooks/useAppDispatch"
import { updateTicketStatusType } from "../../../redux/actions/app/index"
import { useEffect } from "react";

const TicketStatusTypesPage = () => {

  const ticketStatusTypesData = useAppSelector(state => state.appData.ticketStatusTypes)
  const [ticketStatusTypes, setTicketStatusTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ticketStatusTypesData.length > 0) {
      setTicketStatusTypes([...ticketStatusTypesData]);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [ticketStatusTypesData]);

  const handleAffiliateAliasChange = (id, value) => {
    const updatedStatusTypes = ticketStatusTypes.map(s =>
      s.id === id ? { ...s, affiliateAlias: value } : s
    );
    setTicketStatusTypes(updatedStatusTypes);
  }

  const handleAffiliateAliasBlur = (statusType, value) => {

    const updatedStatusType = { ...statusType, affiliateAlias: value };

    dispatch(updateTicketStatusType(updatedStatusType));

    setLoading(true);
    axios.post(`${SERVER_URL}/api/TicketStatusType/UpdateTicketStatusType`, updatedStatusType, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      toast.success("Alias updated successfully!");
    }).catch((err) => {
      console.error(err)
      toast.error("Failed to update, please contact administrator !!!");
    })
    setLoading(false);
  }

  return (
    <Container className="h-100">
      <Helmet title='Ticket Status Types' />
      <div className="d-flex justify-content-between mb-3">
        <h2>Ticket Status Types</h2>
      </div>
      <LoadingWrapper loading={loading}>
        <Table striped>
          <thead>
            <tr>
              <th>Status</th>
              <th>Affiliate Alias</th>
            </tr>
          </thead>
          <tbody>
            {ticketStatusTypes.map(s => {
              return (
                <tr key={s.id}>
                  <td>{s.name}</td>
                  <td>
                    <input value={s.affiliateAlias ?? ''} className='form-control' onBlur={e => handleAffiliateAliasBlur(s, e.target.value)} onChange={e => handleAffiliateAliasChange(s.id, e.target.value)} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </LoadingWrapper>
    </Container>
  );
};

export default TicketStatusTypesPage;
