import React, { useEffect, useMemo, useRef, useState } from "react";
import { Prompt, useNavigate, useSearchParams } from "react-router-dom";
import {
  Container,
  Button,
  Card,
  Col,
  Form,
  Row,
  Accordion,
} from "react-bootstrap";
import LoadingWrapper from "../../../components/LoadingWrapper";
import Popconfirm from "../../../components/Popconfirm";
import AddressDetails from "./AddressDetails";
import AccordionItem from "./AccordionItem";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import {
  loadCompConditions,
  loadCompKeys,
  loadLeaseTypes,
  loadBasementTypes,
  loadBedroomsClassifications,
  loadLotShapes,
  loadLotTypes,
  loadRentRegulationStatuses,
  loadBuildingClasses,
  loadOutdoorSpaceTypes,
  loadPropertyRights,
  loadUnitTypes,
  loadZoneOverlays,
  loadZones,
  loadCompTypes,
  loadFloorLocations,
} from "../../../redux/actions/comps";
import {
  createComps,
  getRelatedComps,
  updateComps,
  deleteComp,
  getCompById,
} from "../../../services/comp";
import { getCompTypeColumns } from "../../../services/comp/CompType";
import { toast } from "react-toastify";
import { Plus, Save, X } from "react-feather";
import moment from "moment";
import { uploadCompFile } from "../../../services/microsoftTeamsFiles";

const Comps = () => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(0);
  const [accordionArr, setAccordionArr] = useState([
    { name: "Comp", id: 0, compId: 0 },
  ]);
  const [loading, setLoading] = useState(false);
  const [compTypeColumns, setCompTypeColumns] = useState({});
  const [currentComp, setCurrentComp] = useState({});
  const [compTypeValueByIndex, setCompTypeValueByIndex] = useState({});
  const [relatedComps, setRelatedComps] = useState([]);
  const [alreadySaved, setAlreadySaved] = useState([]);
  const [compToDeleteId, setCompToDeleteId] = useState(0);
  const [filesPerIndex, setFilesPerIndex] = useState([]);

  const [notSavedChanges, setNotSavedChanges] = useState(false);
  const notSavedChangesRef = useRef(notSavedChanges);
  notSavedChangesRef.current = notSavedChanges;

  const compTypesMap = useAppSelector((state) => state.comps.compTypesMap);
  const usersMap = useAppSelector((state) => state.appData.usersMap);

  const dispatch = useAppDispatch();
  const compDetailsRef = useRef([]);
  const relatedCompDetailsRef = useRef([]);
  const addressDetailsRef = useRef();
  const [searchParams] = useSearchParams();

  const compId = searchParams.get("compId");

  useEffect(() => {
    dispatch(loadBuildingClasses());
    dispatch(loadFloorLocations());
    dispatch(loadCompTypes());
    dispatch(loadUnitTypes());
    dispatch(loadCompConditions());
    dispatch(loadLeaseTypes());
    dispatch(loadBasementTypes());
    dispatch(loadBedroomsClassifications());
    dispatch(loadLotShapes());
    dispatch(loadLotTypes());
    dispatch(loadRentRegulationStatuses());
    dispatch(loadOutdoorSpaceTypes());
    dispatch(loadCompKeys());
    dispatch(loadZones());
    dispatch(loadZoneOverlays());
    dispatch(loadPropertyRights());
  }, [dispatch]);

  useEffect(() => {
    /** @param {BeforeUnloadEvent} e */
    function onUnload(e) {
      if (notSavedChangesRef.current) {
        e.preventDefault();
        e.returnValue = "";
      }
    }
    window.addEventListener("beforeunload", onUnload);

    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, []);

  useEffect(() => {
    if (!compId || !Object.keys(compTypesMap).length || currentComp.id) {
      return;
    }
    setLoading(true);
    getCompById(compId)
      .then((comp) => {
        // const comp = comps.find((item) => item.id === Number(compId));
        if (!comp) {
          return;
        }
        setCurrentComp(comp);
        const { compAddress, compTypeId, ...details } = comp;
        const compType = compTypesMap[compTypeId];
        setAccordionArr([compType]);
        setCompTypeValueByIndex({ 0: compType.id });
        getCompTypeColumns(compType.id).then((result) => {
          setCompTypeColumns((oldValue) => ({
            ...oldValue,
            [compType.id]: result,
          }));
          setTimeout(() => {
            compDetailsRef.current[0].setData(details);
          }, 400);
        });
        addressDetailsRef.current.setData(compAddress);
      })
      .finally(() => setLoading(false));
  }, [compId, compTypesMap, currentComp]);

  const addAccordionComp = () => {
    setAccordionArr((oldArray) => [...oldArray, { name: "Comp" }]);
    setActiveKey(accordionArr.length);
  };

  const removeAccordionComp = (index) => {
    const newAccordionArr = [...accordionArr];
    newAccordionArr.splice(index, 1);
    setAccordionArr(newAccordionArr);
    setActiveKey(accordionArr.length);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const compDetails = compDetailsRef.current
      .map((item, index) => {
        if (alreadySaved.includes(index)) {
          return null;
        }
        return item.getData();
      })
      .filter(Boolean);
    const addressDetails = addressDetailsRef.current.getData();
    try {
      const data = compDetails.map((compDetail, index) => ({
        ...compDetail,
        compAddress: addressDetails,
        compTypeId: accordionArr[index].id,
      }));
      if (!compId) {
        const createdComps = await createComps(data);
        for (let i = 0; i < createdComps.length; i++) {
          const files = filesPerIndex[i];
          const compId = createdComps[i].id;

          if (files) {
            for (let file of files) {
              await uploadCompFile(compId, file)
            }
          }
        }

        const newAlreadySaved = compDetails.map((_, index) => index);
        setAlreadySaved(newAlreadySaved);
      } else {
        await updateComps(data);
        for (let i = 0; i < data.length; i++) {
          const files = filesPerIndex[i];
          const compId = data[i].id;

          if (files) {
            for (let file of files) {
              await uploadCompFile(compId, file)
            }
          }
        }
      }
      setNotSavedChanges(false);
      toast.success("Successfully saved!");
    } catch (err) {
      console.log(err);
      const message =
        err?.[0]?.description || err.message || "Something went wrong";
      toast.error(message);
    }
    setLoading(false);
  };

  const handleCompDetailsRef = (index) => (ref) =>
    (compDetailsRef.current[index] = ref);

  const handleRelatedCompDetailsRef = (index) => (ref) =>
    (relatedCompDetailsRef.current[index] = ref);

  const handleCompTypeChange = (index) => (value) => {
    setNotSavedChanges(true);
    const compType = compTypesMap[value];
    if (!compType) {
      return;
    }
    setCompTypeValueByIndex((oldValue) => ({
      ...oldValue,
      [index]: value,
    }));
    accordionArr[index] = { ...compType };
    setAccordionArr([...accordionArr]);
    getCompTypeColumns(compType.id).then((result) => {
      setCompTypeColumns((oldValue) => ({
        ...oldValue,
        [compType.id]: result.filter(x => x.toLowerCase() != "compaddressid"),
      }));
    });
  };

  const addressSelected = async (placeId) => {
    setNotSavedChanges(true);
    const comps = await getRelatedComps(placeId);
    setRelatedComps(comps);
    const relatedCompTypeColumns = await Promise.all(
      comps.map((comp) => getCompTypeColumns(comp.compTypeId))
    );
    const newCompTypeColumns = {};
    for (const index in comps) {
      newCompTypeColumns[comps[index].compTypeId] =
        relatedCompTypeColumns[index];
    }
    setCompTypeColumns((oldValue) => ({
      ...oldValue,
      ...newCompTypeColumns,
    }));
    comps.map(({ compAddress, compTypeId, ...details }, index) => {
      relatedCompDetailsRef.current[index].setData(details);
    });
  };

  // TODO: review
  const getCreationDate = (dateOfSale) => {
    if (!dateOfSale) {
      return "";
    }

    if (dateOfSale) {
      return moment(dateOfSale).format("MM/DD/YYYY");
    }
  };

  const relatedCompsAccordion = useMemo(
    () =>
      relatedComps.map((relatedComp) => {
        const compType = compTypesMap[relatedComp.compTypeId] || {};

        return {
          compId: relatedComp.id,
          createdById: relatedComp.createdById,
          dateOfSale: relatedComp.dateOfSale,
          related: true,
          ...compType,
        };
      }),
    [relatedComps]
  );

  const handleCompDelete = async () => {
    setLoading(true);
    await deleteComp(compToDeleteId);
    if (compToDeleteId === currentComp.id) {
      navigate(-1);
    }
    setRelatedComps((oldValue) =>
      oldValue.filter((item) => item.id !== compToDeleteId)
    );
    setCompToDeleteId(0);
    setLoading(false);
  };

  const compToDelete = useMemo(() => {
    const item = relatedComps.find((i) => i.id === compToDeleteId);

    return item;
  }, [compToDeleteId]);

  const filesUpdated = (i, files) => {
    const newData = [...filesPerIndex];
    newData[i] = files;
    setFilesPerIndex(newData);
  }

  const accordionItemProps = {

    accordionArrLength: accordionArr.length,
    currentComp,
    removeComp: setCompToDeleteId,
    removeNotSavedComp: removeAccordionComp,
    handleCompTypeChange,
    compTypeValueByIndex,
    handleRelatedCompDetailsRef,
    handleCompDetailsRef,
    setNotSavedChanges,
    compTypeColumns,
    getCreationDate,
    setActiveKey,
    activeKey,
    filesUpdated
  };

  console.log(compId);

  return (
    <LoadingWrapper loading={loading}>
      <Prompt
        message="Changes you made may not be saved."
        when={notSavedChanges}
      />
      <Popconfirm
        title={
          compToDelete ? (
            <>
              <div>{`Are you sure you want to delete comp created by ${usersMap[compToDelete.createdById]?.firstName
                }?`}</div>
              <div className="mt-3 h4">
                {compToDelete.compAddress?.fullAddress}
              </div>
            </>
          ) : (
            "Are you sure?"
          )
        }
        show={!!compToDeleteId}
        onCancel={() => setCompToDeleteId(0)}
        onOk={handleCompDelete}
      />
      <Container fluid className="p-0">
        <Row>
          <Col md={3}>
            <h1 className="text-dark mb-4">
              <Button
                size="sm"
                className="p-0 me-3 rounded"
                onClick={() => navigate(-1)}
              >
                <X size={19} />
              </Button>
              {compId ? "Edit" : "New"} Comp
            </h1>

            <Form className="pb-1">
              <AddressDetails
                ref={addressDetailsRef}
                addressSelected={addressSelected}
              />
            </Form>
          </Col>

          <Col md={9}>
            <Card className="pb-4">
              <Accordion
                className="comps-accordion"
                activeKey={activeKey}
                flush
              >
                {relatedCompsAccordion.map(
                  (
                    {
                      id,
                      name: label,
                      related = false,
                      compId,
                      createdById,
                      dateOfSale,
                    },
                    i
                  ) => (
                    <AccordionItem
                      i={i}
                      key={i}
                      related={related}
                      compId={compId}
                      createdById={createdById}
                      dateOfSale={dateOfSale}
                      id={id}
                      label={label}
                      {...accordionItemProps}
                    />
                  )
                )}
                {accordionArr.map(
                  ({ id, name: label, compId, createdById, dateOfSale }, i) => (
                    <AccordionItem
                      i={i}
                      key={i}
                      compId={compId}
                      createdById={createdById}
                      dateOfSale={dateOfSale}
                      id={id}
                      label={label}
                      {...accordionItemProps}
                    />
                  )
                )}
              </Accordion>

              <div className="d-flex justify-content-between w-100 px-4">
                <Button
                  variant="info"
                  onClick={addAccordionComp}
                  className="fs-5 px-2 py-1"
                  size="sm"
                >
                  <Plus className="me-1" size={22} /> Add comp
                </Button>
                <Button
                  className="mx-2 fs-5 px-3 py-1"
                  size="sm"
                  variant="secondary"
                  onClick={handleSubmit}
                  disabled={accordionArr.some((item) => !item.id)}
                >
                  <Save className="me-1" size={20} /> Save
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </LoadingWrapper>
  );
};

export default Comps;
