import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Popconfirm from "../../../components/Popconfirm";
import QuestionItem from "../components/QuestionItem";
import {
  getInspectionQuestionForms,
  getInspectionFormQuestions,
  apiUseInspectionFormForInspection,
  getInspectionQuestionAnswers,
  answerInspectionQuestionFormQuestion,
  answerMultipleQuestions,
} from "../../../services/inspectionQuestionForm";
import useKeyBy from "../../../hooks/useKeyBy";
import useGroupBy from "../../../hooks/useGroupBy";
import useJobInspectionDetails from "../../jobDetails/useJobInspectionDetails";
import LoadingWrapper from "../../../components/LoadingWrapper";
import { toast } from "react-toastify";

const Questions = () => {
  const { scheduledInspection } = useJobInspectionDetails();
  const [questionForms, setQuestionForms] = useState([]);
  const [selectedQuestionFormId, setSelectedQuestionFormId] = useState();
  const [changeQuestionFormId, setChangeQuestionFormId] = useState(false);
  const [formQuestions, setFormQuestions] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [saveAllLoading, setSaveAllLoading] = useState(false);

  const questionFormsMap = useKeyBy(questionForms, "id");
  const subQuestionsByParentId = useGroupBy(formQuestions, "parentId");

  useEffect(() => {
    getInspectionQuestionForms().then(setQuestionForms);
  }, []);

  useEffect(() => {
    if (!scheduledInspection.id) {
      return;
    }
    getInspectionQuestionAnswers(scheduledInspection.id).then((res) => {
      if (!res) {
        return;
      }

      res.sort((a, b) => a.inspectionQuestionFormQuestion.displayOrder - b.inspectionQuestionFormQuestion.displayOrder);

      setFormQuestions(
        res.map((i) => ({
          ...i.inspectionQuestionFormQuestion,
          value: i.answer,
          answerId: i.id,
        })));

      setSelectedQuestionFormId(
        res[0]?.inspectionQuestionFormQuestion.inspectionQuestionFormId
      );
    });
  }, [scheduledInspection.id, selectedQuestionFormId]);

  const handleFormChange = async (formId) => {
    if (Object.keys(scheduledInspection).length == 0) {
      toast.error("There's no scheduled inspection, please schedule an inspection and then fill out the form");
      return;
    }

    setSaveAllLoading(true);
    const response = await apiUseInspectionFormForInspection(scheduledInspection.id, formId)
    const questions = await getInspectionFormQuestions(formId);
    setFormQuestions(questions);
    setSelectedQuestionFormId(formId);
    setSaveAllLoading(false);
  };

  const handleQuestionSelect = (questionForm) => {
    if (selectedQuestionFormId === questionForm.value) {
      return;
    }
    if (selectedQuestionFormId) {
      setChangeQuestionFormId(questionForm.value);
      return;
    }
    handleFormChange(questionForm.value);
    setFormQuestions([]);
  };

  const handleQuestionAnswer = (id, answer, answerId) => {
    answerInspectionQuestionFormQuestion(answerId, answer);
    setFormQuestions(
      formQuestions.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value: answer,
          };
        }

        return item;
      })
    );
  };

  const saveAllAnswers = async (e) => {
    setSaveAllLoading(true);
    const data = formQuestions.map(question => ({ answerId: question.answerId, answer: question.value }));
    try {
      const response = await answerMultipleQuestions(data);
      if (response && response.status == 200) {
        toast.success("Saved successfully");
      }
    }
    catch (e) {
      toast.error(e);
    }

    setSaveAllLoading(false);
    setEditMode(false);
  }

  const editModeHandler = async () => {
    if (editMode) {
      await saveAllAnswers();
    }

    setEditMode(!editMode)
  }

  return (
    <LoadingWrapper loading={saveAllLoading} className="mb-6">
      <>
        <Popconfirm
          title={
            <div>
              <div>
                By changing question form all current answers will be lost
              </div>
              <div>Are you sure you want to continue?</div>
            </div>
          }
          show={!!changeQuestionFormId}
          onCancel={() => setChangeQuestionFormId(0)}
          onOk={() => {
            handleFormChange(changeQuestionFormId);
            setChangeQuestionFormId(0);
          }}
        />
        {Object.keys(scheduledInspection).length > 0 && (<div className="d-flex justify-content-between my-3">
          {editMode ? (
            <Select
              className="react-select-container mt-2 w-75"
              classNamePrefix="react-select"
              value={{
                value: selectedQuestionFormId,
                label: questionFormsMap[selectedQuestionFormId]?.name,
              }}
              placeholder="Question form"
              options={questionForms.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={handleQuestionSelect}
              isDisabled={!editMode}
            />
          ) : (
            <div style={{ minHeight: 46, fontSize: 14, color: "black", display: 'flex', flexWrap: 'wrap', alignContent: 'center' }}>
              <span>{questionFormsMap[selectedQuestionFormId]?.name}</span>
            </div>
          )}
          <Button
            style={{ display: 'flex', alignContent: 'center' }}
            // variant={editMode ? "light" : "primary"}
            size="sm"
            onClick={editModeHandler}
          >
            <span className="px-2">{editMode ? 'Save' : 'Edit'}</span>
          </Button>
        </div>)}
        {Object.keys(scheduledInspection).length > 0 && (<div>
          {formQuestions && formQuestions.length > 0 && <p className="text-center my-2">Questions</p>}
          {formQuestions
            .filter((question) => !question.option)
            .map((question, index) => (
              <QuestionItem
                key={index}
                question={question}
                onChange={handleQuestionAnswer}
                disabled={!editMode}
                subQuestions={subQuestionsByParentId[question.id] || []}
              />
            ))}
          {editMode && <div className="d-flex justify-content-center my-3">
            <Button variant="primary" onClick={saveAllAnswers}>Save</Button>
          </div>}
        </div>)}
        {Object.keys(scheduledInspection).length == 0 && <p className="text-center h5">There's no scheduled inspection, please schedule an inspection and then fill out the form</p>}
      </>
    </LoadingWrapper>
  );
};

export default Questions;
