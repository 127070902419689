import axios from "../../utils/axios";

/**
 * @typedef {import("./CompCondition").CompCondition} CompCondition
 * @typedef {import("./CompType").CompType} CompType
 * @typedef {import("./UnitType").UnitType} UnitType
 * @typedef {import("./LeaseType").LeaseType} LeaseType
 * @typedef {import("./OutdoorSpaceType").OutdoorSpaceType} OutdoorSpaceType
 * @typedef {{
 *    "id": number,
 *    "name": string,
 *    "displayOrder": number,
 *    "type": number,
 * }} PropertyType
 * @typedef {{
 *    "id": number,
 *    "name": string,
 *    "displayOrder": number,
 *    "propertyTypeId": number,
 *    "propertyType": PropertyType,
 * }} PropertySubType
 * @typedef {{
 *  "id": number,
 *  "createdById": string,
 *  "compAddressId": number,
 *  "compAddress": {
 *    "id": number,
 *    "fullAddress": string,
 *    "placeId": string,
 *    "lat": number,
 *    "lng": number,
 *    "city": string,
 *    "state": string,
 *    "zip": string,
 *    "county": string,
 *    "streetNumber": string,
 *    "route": string
 *  },
 *  "compTypeId": number,
 *  "compType": CompType,
 *  "propertyTypeValue": number,
 *  "propertyTypeId": number,
 *  "propertyType": PropertyType,
 *  "propertySubTypeId": number,
 *  "propertySubType": PropertySubType,
 *  "isEstimated": boolean,
 *  "dateOfSale": string|Date,
 *  "salePrice": number,
 *  "grantor": string,
 *  "grantee": string,
 *  "yearBuilt": number,
 *  "compConditionId": number,
 *  "compCondition": CompCondition,
 *  "lotSizeSF": number,
 *  "lotSizeAcres": number,
 *  "zoneMappings": [
 *    {
 *      "id": number,
 *      "compId": number,
 *      "zoneId": number,
 *      "zone": {
 *        "id": number,
 *        "name": string,
 *        "displayOrder": number,
 *        "label": string
 *      },
 *      "manualInput": string
 *    }
 *  ],
 *  "zoneOverlayMappings": [
 *    {
 *      "id": number,
 *      "compId": number,
 *      "zoneOverlayId": number,
 *      "manualInput": string
 *    }
 *  ],
 *  "wideStreet": boolean,
 *  "far": number,
 *  "buildable": number,
 *  "totalBuildable": number,
 *  "totalRemainingPercentage": number,
 *  "numberOfUnits": number,
 *  "elevator": number,
 *  "grossBuildingAreaSF": number,
 *  "pricePerSF": number,
 *  "financedBy": string,
 *  "mortgageAmount": number,
 *  "landBuildingRatio": number,
 *  "ceilingHeight": number,
 *  "squareFeet": number,
 *  "bedrooms": number,
 *  "bathrooms": number,
 *  "outdoorSpaceId": number,
 *  "outdoorSpaceType": OutdoorSpaceType,
 *  "outdoorSpaceSF": number,
 *  "pricePerBuildableSF": number,
 *  "averageUnitSize": number,
 *  "rentStabilized": number,
 *  "rentStabilizedPercentage": number,
 *  "pricePerUnit": number,
 *  "realEstateTaxReimbursement": number,
 *  "insuranceReimbursement": number,
 *  "otherReimbursement": number,
 *  "pgi": number,
 *  "residentialVacancyAndCollectionLoss": number,
 *  "commercialVacancyAndCollectionLoss": number,
 *  "egi": number,
 *  "realEstateTax": number,
 *  "realEstateTaxPerUnit": number,
 *  "realEstateTaxPerSF": number,
 *  "insurance": number,
 *  "insurancePerUnit": number,
 *  "insurancePerSF": number,
 *  "managementPercentage": number,
 *  "managementPercentagePerUnit": number,
 *  "managementPercentagePerSF": number,
 *  "management": number,
 *  "managementPerUnit": number,
 *  "managementPerSF": number,
 *  "repairsAndMaintenance": number,
 *  "waterAndSewer": number,
 *  "commonAreaUtilities": number,
 *  "gasAndHeat": number,
 *  "utilities": number,
 *  "buildingReservesCostPerSF": number,
 *  "buildingReserves": number,
 *  "legalAndAccounting": number,
 *  "otherAndMiscellaneous": number,
 *  "totalExpenses": number,
 *  "incomeAndExpenseRatio": number,
 *  "netOperatingIncome": number,
 *  "overallRate": number,
 *  "unitNumber": string,
 *  "unitSF": number,
 *  "monthlyRent": number,
 *  "annualRent": number,
 *  "leaseStart": string|Date,
 *  "leaseEnd": string|Date,
 *  "floorLocation": number,
 *  "rentPerSF": number,
 *  "unitTypeId": number,
 *  "unitType": UnitType,
 *  "leaseTypeId": number,
 *  "leaseType": LeaseType,
 *  "rentalType": string,
 *  "residentialIncome": number,
 *  "presentValue": number,
 *  "notes": string
 *}} Comp
 */

/** @returns {Promise<Array<Comp>>} */
export function getComps() {
  return axios.get("/Comp/GetComps");
}

/**
 * @param {Comp} data
 * @returns {Promise<Comp>}
 */
export function createComp(data) {
  return axios.post("/Comp/CreateComp", data);
}

/**
 * @param {Array<Comp>} data
 * @returns {Promise<Array<Comp>>}
 */
export function createComps(data) {
  return axios.post("/Comp/CreateComps", data);
}

/**
 * @param {Array<Comp>} data
 * @returns {Promise<Array<Comp>>}
 */
export function updateComps(data) {
  return axios.post("/Comp/UpdateComps", data);
}

/**
 * @param {{ keyword: string }} filters
 * @returns {Promise<Array<Comp>>}
 */
export function filterComps(filters, pageIndex = 0, pageSize = 10) {
  const queryParams = new URLSearchParams({ pageIndex, pageSize });
  return axios.post(`/Comp/FilterCompsV2?${queryParams}`, filters);
}

/**
 * @param {{ keyword: string }} filters
 * @returns {Promise<Comp>}
 */
export function getCompById(id) {
  const queryParams = new URLSearchParams({ id });
  return axios.get(`/Comp/GetCompById?${queryParams}`);
}

/**
 * @param {string} placeId
 * @returns {Promise<Array<Comp>>}
 */
export function getRelatedComps(placeId) {
  const queryParams = new URLSearchParams({ placeId });
  return axios.get(`/Comp/GetRelatedComps?${queryParams}`);
}

/**
 * @param {number} id
 * @returns {Promise<Array<Comp>>}
 */
export function deleteComp(id) {
  const queryParams = new URLSearchParams({ id });
  return axios.post(`/Comp/DeleteComp?${queryParams}`);
}

export async function exportComps(data, name) {
  const response = await axios.post("/Comp/ExportComps", data, { responseType: "blob" });
  const link = document.createElement("a");
  const url = window.URL.createObjectURL(new Blob([response]));
  link.href = url;
  link.setAttribute("download", `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
}

export async function getDownloadUrls(compId, fileIds) {
  const queryParams = new URLSearchParams({ compId });
  return axios.post(`/Comp/GetCompImageDownloadUrls?${queryParams}`, fileIds);
}

export function getCompExportURL(compId) {
  const queryParams = new URLSearchParams({ id: compId });
  return axios.get(`/Comp/GetCompToken?${queryParams}`)
}

export function checkIfTicketExportedAsComp(ticketId) {
  const queryParams = new URLSearchParams({ ticketId });
  return axios.get(`/Comp/CheckIfTicketExportedAsComp?${queryParams}`)
}

export function exportTicketAsComp(ticketId) {
  const queryParams = new URLSearchParams({ ticketId });
  return axios.post(`/Comp/ExportTicketAsComp?${queryParams}`)
}