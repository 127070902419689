/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} BedroomsClassification */
import axios from "../../utils/axios";

/** @returns {Promise<Array<BedroomsClassification>>} */
export function getBedroomsClassifications() {
  return axios.get("/BedroomsClassification/GetBedroomsClassifications");
}

/**
 * @param {string} name
 * @returns {Promise<BedroomsClassification>}
 */
export function createBedroomsClassification(name) {
  return axios.post("/BedroomsClassification/CreateBedroomsClassification", { name });
}
