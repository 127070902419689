import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const InspectionModal = ({ title, children, disabled, ...rest }) => {
  const [showModal, setShowModal] = useState();

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} {...rest}>
        <Modal.Header closeButton><h4 className="m-0">{title}</h4></Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        size="lg"
        className="w-100"
        variant="light"
        disabled={disabled}
        onClick={() => setShowModal(true)}
      >
        {title}
      </Button>
    </>
  );
};

export default InspectionModal;
