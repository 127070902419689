import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import LoadingWrapper from "../../../components/LoadingWrapper";
import { GetSnapshotReport } from "../../../services/changeHistory"
import useAppSelector from "../../../hooks/useAppSelector";
import { Form, Table } from "react-bootstrap";
import { useEffect } from "react";

const SnapshotPage = () => {

  const [loading, setLoading] = useState(false);

  const users = useAppSelector((state) => state.appData.users);

  const [broken, setBroken] = useState([]);
  const [data, setData] = useState();

  const [userId, setUserId] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedFromDate, setSelectedFromDate] = useState(new Date(2024, 0, 25));
  const [selectedToDate, setSelectedToDate] = useState(new Date(2024, 0, 31));

  const handleSelectedFromDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedFromDate(newDate);
  };
  
  const handleSelectedToDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedToDate(newDate);
  };

  const loadData = () => {

    setUserId(selectedUserId);
    setFromDate(selectedFromDate);
    setToDate(selectedToDate);

    setLoading(true);

    let filter = {
      userId: selectedUserId,
      fromDate: selectedFromDate != null ? new Date(selectedFromDate) : '',
      toDate: selectedToDate != null ? new Date(selectedToDate) : '',
    };

    GetSnapshotReport(filter).then(data => {
      setData(data.results);
      setLoading(false);
    });
  }

  const ItemsList = ({ label, items }) => {

    // Grouping the array by ticketId
    const groupedArray = items.reduce((acc, curr) => {
      // If the ticketId already exists in the accumulator, add the current item to that group
      if (acc[curr.ticket.ticketId]) {
        acc[curr.ticket.ticketId].push(curr);
      } else {
        // If not, create a new group with the current item
        acc[curr.ticket.ticketId] = [curr];
      }
      return acc;
    }, {});

    // Converting the grouped object back to an array

    items = Object.values(groupedArray);

    return (
      <div className="mb-2">
        <h5 className="mb-0">{label}: {items.length}</h5>
        <div style={{ maxHeight: 80, overflowY: 'auto' }}>
          {items.map((item, index) =>
            <div key={index}>
              <b>[Id: {item[0].ticket.ticketId}]</b> {item[0].ticket.ticketTitle}
              <span style={{ display: 'none' }}>- {item[0].startDate} - {item[0].endDate}</span>
            </div>
          )}
        </div>
      </div>
    )
  }

  const breakUserData = (userId) => {
    if (!broken.includes(userId)) {
      let updated = [...broken, userId];
      setBroken(updated);
    }
  }

  function areDatesEqual(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  function formatTwoDates(startDate, endDate) {

    const startMonth = startDate.toLocaleString('default', { month: 'long' });
    const endMonth = endDate.toLocaleString('default', { month: 'long' });

    if (areDatesEqual(startDate, endDate)) {
      return `${startMonth} ${startDate.getDate()}`;
    }

    if (startMonth === endMonth && startDate.getFullYear() === endDate.getFullYear()) {
      return `${startMonth} ${startDate.getDate()}-${endDate.getDate()}`;
    } else {
      return `${startMonth} ${startDate.getDate()} - ${endMonth} ${endDate.getDate()}`;
    }
  }

  function getAllDatesBetween(fromDate, toDate) {
    let dates = [];
    let currentDate = new Date(fromDate);

    while (currentDate <= toDate) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  function breakDownByDates(userData, fromDate, toDate) {
    let dates = getAllDatesBetween(new Date(fromDate), new Date(toDate));
    let result = {};

    // Initialize result object with empty arrays for each date
    dates.forEach(date => {
      result[date] = [];
    });

    // Iterate through userData
    userData.forEach(item => {
      let startDate = new Date(item.startDate);
      startDate.setHours(0, 0, 0, 0);
      let endDate = new Date(item.endDate);
      endDate.setHours(23, 59, 59, 999);

      // Iterate through dates and check if the item falls within each date
      dates.forEach(date => {
        let currentDate = new Date(date);

        if (currentDate >= startDate && currentDate <= endDate) {
          result[date].push(item);
        }
      });
    });

    return result;
  }

  const UserCard = ({ user, cardData, fromDate, toDate }) => {

    let isBroken = broken.includes(user.id);

    return (
      <div className="col-md-3 mb-3">
        <div className="card">
          <div className="card-header mb-0">
            <div className="d-flex justify-content-between">
              <h4>{user.firstName}</h4>
              <div className="text-end">
                <h5 className="mb-0">
                  <div>{formatTwoDates(fromDate, toDate)}</div>
                </h5>
                {!isBroken &&
                  <a onClick={() => breakUserData(user.id)} style={{ textDecoration: 'underline' }}>Break up by date</a>
                }
              </div>
            </div>
          </div>
          <div className="card-body">
            <ItemsList label="Assigned" items={cardData} />
            <ItemsList label="New assignments" items={cardData.filter(d => new Date(d.startDate) >= fromDate && new Date(d.startDate) <= toDate)} />
            <ItemsList label="Completed" items={cardData.filter(d => d.endResult === 1 && new Date(d.endDate) >= fromDate && new Date(d.endDate) <= toDate)} />
            <ItemsList label="Un Assigned" items={cardData.filter(d => d.endResult === 2 && new Date(d.endDate) >= fromDate && new Date(d.endDate) <= toDate)} />
          </div>
        </div>
      </div>
    )
  }

  const UserCards = ({ user }) => {

    let userData = data.filter(d => d.userId === user.id);
    let isBroken = broken.includes(user.id);

    if (!isBroken) {
      return (
        <UserCard user={user} cardData={userData} fromDate={fromDate} toDate={toDate} />
      )
    } else {
      let brokenData = breakDownByDates(userData, fromDate, toDate);
      return (
        <>
          {
            Object.entries(brokenData).map(([date, cardData]) => {
              var toD = new Date(date);
              toD.setHours(23, 59, 59, 999);

              return <UserCard key={date} user={user} cardData={cardData} fromDate={new Date(date)} toDate={toD} />
            })
          }
        </>
      )
    }
  }

  const handleUndoBreakout = () => {
    setBroken([]);
  }

  return (
    <div className="h-100 mt-4">
      <Helmet title='Change History' />
      <LoadingWrapper loading={loading}>

        <div className="d-flex mb-3">
          <div>
            <div className="input-group">
              <span className="input-group-text">Select User</span>
              <Form.Select
                value={selectedUserId}
                onChange={(e) => setSelectedUserId(e.target.value)}
              >
                <option value="">All Users</option>
                {users.map((user, index) => (
                  <option key={index} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>
                ))}
              </Form.Select>
              <span className="input-group-text">From</span>
              <Form.Control
                type="date"
                onChange={handleSelectedFromDateChange}
                value={selectedFromDate.toISOString().split('T')[0] || ""}
              />
              <span className="input-group-text">To</span>
              <Form.Control
                type="date"
                onChange={handleSelectedToDateChange}
                value={selectedToDate.toISOString().split('T')[0] || ""}
              />
              <button className="btn btn-primary" onClick={() => loadData()}>Filter</button>
            </div>
          </div>
        </div>

        <div className="mb-2" style={{ color: 'red' }}>
          Some jobs may be cancelled
        </div>
        {broken.length > 0 && 
          <div className="mb-2">
            <a onClick={handleUndoBreakout}>Undo breakout</a>
          </div>
        }

        <div className="mb-5">
          {data &&
            <div className="mb-5">
              <div className="row">
                {users.filter(u => (!userId || u.id === userId) && data.filter(d => d.userId === u.id).length > 0).map(user =>
                  <UserCards user={user} />
                )}
              </div>
            </div>  
          }
        </div>

        <div className="mb-5">
          {
            false && data ?
              <Table striped>
                <thead>
                  <tr>
                    <th>UserId</th>
                    <th>User Name</th>
                    <th>Ticket Id</th>
                    <th>Ticket Title</th>
                    <th>Steps Ids</th>
                    <th>Steps Numbers</th>
                    <th>StartDate</th>
                    <th>EndDate</th>
                    <th>EndResult</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map(h =>
                      <tr key={h.id}>
                        <td>{h.userId}</td>
                        <td>{users.find(u => u.id === h.userId)?.firstName}</td>
                        <td>{h.ticket.ticketId}</td>
                        <td>{h.ticket.ticketTitle}</td>
                        <td>{h.activeStepIds.join(', ')}</td>
                        <td>{h.activeStepNames.join(', ')}</td>
                        <td>{h.startDate}</td>
                        <td>{h.endDate}</td>
                        <td>{h.endResult === 0 ? 'Not Completed' : h.endResult === 1 ? 'Completed' : h.endResult === 2 ? 'UnAssigned' : ''}</td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
              :
              <></>
          }
        </div>
        

      </LoadingWrapper>
    </div>
  );
};

export default SnapshotPage;
