import React from "react";

//** Off the shelf components */
import { Dropdown } from "react-bootstrap";
import { Settings } from "react-feather";
import UserIcon from "./assets/UserIcon";

//** Local Assets & Imports*/
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const NavbarUser = ({ user }) => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
  };

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link text-white">
          <UserIcon className="me-1" width={28} height={28} />
          <span className="text-white">{user?.firstName && `Welcome ${user?.firstName}`}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={() => navigate("/change-password")}>Change Password</Dropdown.Item>
        <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
