import React, { useEffect, useMemo, useRef, useState, useContext } from "react";
import { Table } from "react-bootstrap";
import Datetime from "react-datetime";
import dragula from "react-dragula";
import { toast } from "react-toastify";
import TicketListItem from "./TicketListItem";
import TicketStatusModal from "../../components/modal/ticketStatus";
import ConfirmationModal from "../../components/jobs/confirmationModal";
import Popconfirm from "../../components/Popconfirm";
import { useNavigate } from "react-router-dom";
import {
  addTicketToPortfolio,
  removeTicketFromPortfolio,
} from "../../services/portfolio";
import AddToPortfolioModal from "./AddToPortfolioModal";
import LoadingWrapper from "../../components/LoadingWrapper";
import { changeDueDate } from "../../services/ticketGroup";
import { deleteTicket, updatePriority } from "../../services/ticket";
import moment from "moment";
import useAppSelector from "../../hooks/useAppSelector";
import { updateTicketGroups } from "../../redux/actions/ticketGroups";
import useAppDispatch from "../../hooks/useAppDispatch";
import { sortBy } from "lodash";
import SnoozeTicketModal from "../../components/modal/snoozeTicketModal";
import { AuthContext } from "../../contexts/JWTContext";
import { USER_ROLES } from "../../constants";
import { getAssignedUserModel } from "./helpers";

const removeFromPortfolioInitial = {
  ticketId: 0,
  portfolioId: 0,
  visible: false,
};

const TicketList = ({ ticketGroups, refreshTicketGroups, reloadListWithCurrentFilters, employeeIds }) => {
  const filters = useAppSelector(state => state.search.filters)
  const { pageSize } = useAppSelector(state => state.ticketGroups)

  const context = useContext(AuthContext);
  console.log(context);

  const usersMap = useAppSelector((state) => state.appData.usersMap);

  const [gsData, handleGSChange] = useState("")
  const ticketStatusTypesMap = useAppSelector(state => state.appData.ticketStatusTypesMap)
  const [dateChangeGroupId, setDateChangeGroupId] = useState(0);
  const [addToPortfolioId, setAddToPortfolioId] = useState(0);
  const [removeFromPortfolio, setRemoveFromPortfolio] = useState(
    removeFromPortfolioInitial
  );
  /** @type {[moment.Moment, React.Dispatch<(prevState: moment.Moment) => moment.Moment>]} */
  const [editJobDueDate, setEditJobDueDate] = useState();
  const [cancelJobId, setCancelJobId] = useState(0);
  const [statusModalTicket, setStatusModalTicket] = useState();
  const [showTicketStatusModal, setShowTicketStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  /** @type {React.MutableRefObject<HTMLTableSectionElement>} */
  const tbodyRef = useRef();
  /** @type {React.MutableRefObject<dragula.Drake>} */
  const drake = useRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ticketGroupsRef = useRef(ticketGroups);
  ticketGroupsRef.current = ticketGroups;

  const [snoozeModalTicket, setSnoozeModalTicket] = useState({});
  const [showSnoozeModal, setShowSnoozeModal] = useState(false);

  const getTicketStatusType = (ticket) => {
    const statuses = [...ticket.ticketStatuses];
    const src = sortBy(statuses, 'id');
    return ticketStatusTypesMap[src[(src?.length || 0) - 1]?.ticketStatusTypeId];
  }

  const { tickets: sortedTickets, ticketGroupsMap } = useMemo(() => {
    const ticketGroupsMap = {};
    let tickets = ticketGroups
      .map((item) => {
        ticketGroupsMap[item.id] = item;
        return item.tickets;
      })
      .flat();
    const direction = Number(filters.sortOptions.type) === 1 ? -1 : 1
    tickets.sort(
      filters.sortOptions.property === "priority"
        ? (a, b) =>
          !a.priorityIndex && !b.priorityIndex
            ? 0
            : a.priorityIndex && b.priorityIndex
              ? a.priorityIndex < b.priorityIndex
                ? -1 * direction
                : 1 * direction
              : a.priorityIndex && !b.priorityIndex
                ? -1 * direction
                : 1 * direction
        : () => 0
    );

    tickets.forEach(ticket => {
      ticket.user = getAssignedUserModel(ticket, usersMap);
    })
    tickets = tickets.filter(item => !employeeIds || employeeIds.length === 0 || employeeIds.indexOf(item.user?.id) > -1)

    return { tickets, ticketGroupsMap };
  }, [ticketGroups, filters.sortOptions.property, filters.sortOptions.type, employeeIds]);

  useEffect(() => {
    // if (
    //   user.roles.some((role) =>
    //     [USER_ROLES.ADMIN, USER_ROLES.PRIORITIZER].includes(role)
    //   )
    // ) {
    drake.current = dragula([tbodyRef.current]);
    // }
  }, []);

  useEffect(() => {
    if (!drake.current) {
      return;
    }
    const tickets = ticketGroupsRef.current.map((item) => item.tickets).flat();
    drake.current.on("drop", (el, target, source, sibling) => {
      
      if (context.user && context.user.roles && !([...context.user.roles].includes(USER_ROLES.PRIORITIZER)) && !filters.showMyJobs) {
        drake.current.cancel(true);
        toast.error("To be able to change priorities of the tickets, please turn on 'Show My Jobs' toggle.");
        return;
      }

      if (pageSize != 100) {
        drake.current.cancel(true);
        toast.error("To be able to change priorities of the tickets, please change the 'Rows Per Page' value to 100.");
        return;
      }

      setLoading(true);
      const currentTicketId = el?.getAttribute("data-id");

      let testIndex
      for (let i = 0; i < tbodyRef.current.children.length; i++) {
        const child = tbodyRef.current.children.item(i);
        if (child.getAttribute("data-id") === currentTicketId) {
          testIndex = i + 1;
        }
      }
      if (!testIndex) {
        testIndex = tickets.length;
      }

      updatePriority(+currentTicketId, testIndex).then(() => {
        const promises = [];
        for (let i = 0; i < testIndex - 1; i++) {
          const child = tbodyRef.current.children.item(i);
          const childTicketId = child.getAttribute('data-id');
          promises.push(updatePriority(+childTicketId, (+i + 1)))
        }

        for (let i = testIndex; i < tbodyRef.current.children.length; i++) {
          const child = tbodyRef.current.children.item(i);
          const childPriority = child.getAttribute('data-priority');
          const childTicketId = child.getAttribute('data-id');
          if (childPriority == null) {
            continue;
          }

          promises.push(updatePriority(+childTicketId, (+i + 1)))
        }
        Promise.all(promises).then(x => {
          setLoading(false);
          reloadListWithCurrentFilters();
        });
      });
    });
  }, []);

  const handleRowClicked = (groupId, id) => {
    navigate(`/dashboard/job/${groupId}/${id}`, { state: { from: "/" } });
  };

  const openDueDateChangeModal = (ticket, ticketGroup) => {
    setEditJobDueDate(
      moment(ticket.dueDateOverride || ticketGroup.dueDate).format("MM/DD/YYYY")
    );
    setDateChangeGroupId(ticketGroup.id);
  };

  const handleJobDateConfirm = async () => {
    try {
      await changeDueDate(
        dateChangeGroupId,
        editJobDueDate.utcOffset("-0500").toISOString(true)
      );
      refreshTicketGroups();
      toast.success("Due date changed successfully!");
    } catch (error) {
      console.log("The Error", error);
      const message =
        error[0]?.description ||
        error.message ||
        (typeof error === "string" && error) ||
        "Something went wrong";
      toast.error(message);
    }
    setDateChangeGroupId(0);
  };

  const confirmJobCancel = async () => {
    await deleteTicket(cancelJobId);
    reloadListWithCurrentFilters();
  };

  const handleRemoveFromPortfolio = () => {
    removeTicketFromPortfolio(
      removeFromPortfolio.ticketId,
      removeFromPortfolio.portfolioId
    ).then(refreshTicketGroups);
    setRemoveFromPortfolio({ ...removeFromPortfolioInitial });
  };

  const handleAddToPortfolioId = async (portfolioId) => {
    try {
      await addTicketToPortfolio(addToPortfolioId, portfolioId);
      refreshTicketGroups();
    } catch (error) {
      console.log("The Error", error);
      const message =
        error[0]?.description ||
        error.message ||
        (typeof error === "string" && error) ||
        "Something went wrong";
      toast.error(message);
    }
    setAddToPortfolioId(0);
  };

  const openSnoozeModal = (ticket) => {
    setSnoozeModalTicket(ticket);
    setShowSnoozeModal(true);
  }

  const handleCancelSnoozeModal = () => {
    setSnoozeModalTicket({});
    setShowSnoozeModal(false);
  }

  return (
    <div className="ticket-list-wrapper">
      <TicketStatusModal
        ticket={statusModalTicket}
        show={showTicketStatusModal}
        setChangeTicketDialogStatus={setShowTicketStatusModal}
      />
      <Popconfirm
        title="Are you sure do you want to cancel the job?"
        show={!!cancelJobId}
        onCancel={() => setCancelJobId(false)}
        onOk={confirmJobCancel}
      />
      <AddToPortfolioModal
        show={!!addToPortfolioId}
        onCancel={() => setAddToPortfolioId(0)}
        onOk={handleAddToPortfolioId}
      />
      <Popconfirm
        title="Are you sure do you want to remove this job from the portfolio?"
        show={removeFromPortfolio.visible}
        onCancel={() =>
          setRemoveFromPortfolio({ ...removeFromPortfolioInitial })
        }
        onOk={handleRemoveFromPortfolio}
      />
      <ConfirmationModal
        title="Modify Ticket Duedate"
        show={!!dateChangeGroupId}
        setShow={() => setDateChangeGroupId(0)}
        handleConfirm={handleJobDateConfirm}
      >
        <div>
          <h5>Change Ticket DueDate:</h5>
          <Datetime
            closeOnSelect={true}
            dateFormat="MM/DD/YYYY"
            timeFormat=""
            value={editJobDueDate}
            onChange={setEditJobDueDate}
          />
        </div>
      </ConfirmationModal>
      <SnoozeTicketModal
        show={showSnoozeModal}
        ticketId={snoozeModalTicket.id}
        snoozeReason={snoozeModalTicket.snoozeReason}
        snoozeUntil={snoozeModalTicket.snoozeUntil}
        snoozedByUserId={snoozeModalTicket.snoozedByUserId}
        snoozedCreatedDate={snoozeModalTicket.snoozedCreatedDate}
        setShow={setShowSnoozeModal}
        reloadListWithCurrentFilters={reloadListWithCurrentFilters}
        handleCancelSnoozeModal={handleCancelSnoozeModal} />

      <LoadingWrapper loading={loading}>
        <Table className="bg-white">
          <thead className="bg-light">
            <tr>
              <th>Ticket status</th>
              <th>Neighborhood</th>
              <th>Address</th>
              <th>Property Type</th>
              <th>Unit</th>
              <th>Inspection date</th>
              <th>By</th>
              <th>Step</th>
              <th>Now on file</th>
              <th>File number</th>
              <th>Due at</th>
              <th style={{ width: 24 }} />
              <th>Client</th>
              <th>Priority</th>
              {/* <th>Group status</th> */}
            </tr>
          </thead>
          <tbody ref={tbodyRef} className="jobs">
            {sortedTickets.map((ticket) => {
              const ticketStatus = getTicketStatusType(ticket);
              return (
                <TicketListItem
                  key={ticket.id}
                  onAddToPortfolio={setAddToPortfolioId}

                  onRemoveFromPortfolio={(ticketId, portfolioId) =>
                    setRemoveFromPortfolio({
                      ticketId,
                      portfolioId,
                      visible: true,
                    })
                  }
                  {...{
                    ticket,
                    ticketGroup: ticketGroupsMap[ticket.ticketGroupId],
                    openDueDateChangeModal,
                    setShowTicketStatusModal,
                    setStatusModalTicket,
                    setCancelJobId,
                    handleRowClicked,
                    ticketStatusTypeName: ticketStatus?.name,
                    ticketStatusTypeId: ticketStatus?.id,
                    ticketStatusTypeColor: ticketStatus?.color,
                    openSnoozeModal
                  }}
                />
              )
            })}
          </tbody>
        </Table>
      </LoadingWrapper>
    </div>
  );
};

export default TicketList;
