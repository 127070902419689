import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import CreateKeyCard from "./CreateKeyCard";
import useAppSelector from "../../../hooks/useAppSelector";
import fieldCalculation from './fieldCalculation';
import compFields from "./compFields";
import compLabels from "./compLabels";
import useKeyBy from "../../../hooks/useKeyBy";
import startCase from "lodash/startCase";
import upperFirst from "lodash/upperFirst";
import NumberFormat from 'react-number-format';
import { levenshteinDistance } from "../../../utils";

const selectOptionKeys = [
  "PropertyTypeId",
  "PropertySubTypeId",
  "UnitTypeId",
  "CompConditionId",
  "LeaseTypeId",
  "BasementTypes",
  "BedroomsClassifications",
  "LotShapes",
  "LotTypes",
  "RentRegulationStatuses",
  "BuildingClassId",
  "OutdoorSpaceTypeId",
  "Zones",
  "ZoneOverlays",
  "PropertyRightsId",
  "FloorLocationId"
]

const inputType = {
  string: "text",
  boolean: "checkbox",
  date: "date",
  number: "number",
};

const priceInputs = [
  "saleprice",
  "priceperbuildablesf",
  "pricepersf",
  "priceperunit",
  "monthlyrent",
  "annualrent",
  "residentialincome",
  "managementpersf",
  "managementperunit",
  "management",
  "realestatetax",
  "realestatetaxperunit",
  "realEstateTaxPerSF",
  "waterandsewer",
  "gasandheat",
  "totalexpenses",
  "monthlyrent",
  "annualrent",
  "insurance",
  "insurancePerUnit",
  "legalAndAccounting",
  "mortgageAmount",
  "buildingReservesCostPerSF",
  "otherAndMiscellaneous",
  "rentPerSF",
  "utilities",
  "commonAreaUtilities",
  "repairsAndMaintenance"
].map(x => x.toLowerCase());

const getValueByType = (value, type) => {
  switch (type) {
    case "number": {
      return Number(value);
    }
    case "date": {
      return new Date(value).toISOString();
    }
    default: {
      return value;
    }
  }
};

/** @type {React.ForwardRefExoticComponent<{ compTypeColumns: Array<string>; setNotSavedChanges: (value: boolean) => void, compTypeId: (value: Number); }>}*/
const CompDetails = forwardRef((props, ref) => {
  const propertyTypes = useAppSelector((state) => state.appData.propertyTypes);
  const propertySubTypes = useAppSelector(
    (state) => state.appData.propertySubTypes
  );
  const unitTypes = useAppSelector((state) => state.comps.unitTypes);
  const compConditions = useAppSelector((state) => state.comps.compConditions);
  const leaseTypes = useAppSelector((state) => state.comps.leaseTypes);
  const basementTypes = useAppSelector((state) => state.comps.basementTypes);
  const bedroomsClassifications = useAppSelector((state) => state.comps.bedroomsClassifications);
  const lotShapes = useAppSelector((state) => state.comps.lotShapes);
  const lotTypes = useAppSelector((state) => state.comps.lotTypes);
  const rentRegulationStatuses = useAppSelector((state) => state.comps.rentRegulationStatuses);
  const buildingClasses = useAppSelector((state) => state.comps.buildingClasses);
  const compKeys = useAppSelector((state) => state.comps.compKeys);
  const outdoorSpaceTypes = useAppSelector((state) => state.comps.outdoorSpaceTypes);
  const zones = useAppSelector((state) => state.comps.zones);
  const zoneOverlays = useAppSelector((state) => state.comps.zoneOverlays);
  const propertyRights = useAppSelector((state) => state.comps.propertyRights);
  const floorLocations = useAppSelector((state) => state.comps.floorLocations);

  const zonesMap = useKeyBy(zones, "id");
  const zoneOverlaysMap = useKeyBy(zoneOverlays, "id");
  const basementTypesMap = useKeyBy(basementTypes, "id");
  const lotShapesMap = useKeyBy(lotShapes, "id");
  const lotTypesMap = useKeyBy(lotTypes, "id");
  const rentRegulationStatusesMap = useKeyBy(rentRegulationStatuses, "id");
  const bedroomsClassificationsMap = useKeyBy(bedroomsClassifications, "id");

  const [inputValues, setInputValues] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldValues, setCustomFieldValues] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  /** @type {React.MutableRefObject<HTMLDivElement|null>} */
  const listEndRef = useRef();

  useImperativeHandle(ref, () => ({
    getData: () => {
      const data = { ...inputValues, compKeyValues: [] };

      for (const input of compFields) {
        if (inputValues[input.name]) {
          data[input.name] = getValueByType(
            inputValues[input.name],
            input.type
          );
        }
      }
      const reversedCompKeys = [...compKeys].reverse();
      for (const customField of customFields) {
        const compKey = reversedCompKeys.find(
          (item) => item.name === customField.name
        );
        if (!compKey) {
          continue;
        }
        data.compKeyValues.push({
          compKeyId: compKey.id,
          value: Number(customFieldValues[compKey.name]),
        });
      }
      for (const key of ["zoneMappings", "zoneOverlayMappings", "basementTypeMappings", "bedroomsClassificationMappings", "lotShapeMappings", "lotTypeMappings", "rentRegulationStatusMappings"]) {
        if (!inputValues[key]?.length) {
          continue;
        }
        data[key] = inputValues[key].map(({ value, __isNew__ }) =>
          __isNew__
            ? { manualInput: value }
                : {
                    [key === "zoneMappings" ? "zoneId"
                        : key === "basementTypeMappings" ? "basementTypeId"
                        : key === "bedroomsClassificationMappings" ? "bedroomsClassificationId"
                        : key === "lotShapeMappings" ? "lotShapeId"
                        : key === "lotTypeMappings" ? "lotTypeId"
                        : key === "rentRegulationStatusMappings" ? "rentRegulationStatusId"
                            : "zoneOverlayId"]: value
                }
        );
      }

      return data;
    },
    setData: (data) => {
      const newInputValues = {};
      for (const key in data) {
        if (
          data[key] &&
          compFields.find((i) => i.name === key)?.type === "date"
        ) {
          newInputValues[key] = data[key].substring(0, 10);
        } else {
          newInputValues[key] = data[key];
        }
      }
      for (const key of ["zoneMappings", "zoneOverlayMappings", "basementTypeMappings", "bedroomsClassificationMappings", "lotShapeMappings", "lotTypeMappings", "rentRegulationStatusMappings"]) {
        if (!data[key]?.length) {
          continue;
        }
        
        const valueKey = key === "zoneMappings" ? "zoneId"
            : key === "basementTypeMappings" ? "basementTypeId"
                : key === "bedroomsClassificationMappings" ? "bedroomsClassificationId"
                    : key === "lotShapeMappings" ? "lotShapeId"
                        : key === "lotTypeMappings" ? "lotTypeId"
                            : key === "rentRegulationStatusMappings" ? "rentRegulationStatusId"
                                  : "zoneOverlayId"

          const mapping = key === "zoneMappings" ? zonesMap
              : key === "basementTypeMappings" ? basementTypesMap
                  : key === "bedroomsClassificationMappings" ? bedroomsClassificationsMap
                      : key === "lotShapeMappings" ? lotShapesMap
                          : key === "lotTypeMappings" ? lotTypesMap
                              : key === "rentRegulationStatusMappings" ? rentRegulationStatusesMap
                                  : zoneOverlaysMap

        newInputValues[key] = data[key].map(({ [valueKey]: value }) => ({
          value,
          label: mapping[value]?.name,
        }));
      }
      setInputValues({ ...inputValues, ...newInputValues });
    },
  }));

  const handleSelectInputChange = (name) => (obj) => {
    props.setNotSavedChanges(true);
    setInputValues({ ...inputValues, [name]: obj?.value });
  };

  const selectOptions = useMemo(() => {
    const notFilteredOptions = {
      propertyType: propertyTypes,
      propertySubType: propertySubTypes[inputValues.propertyTypeId] || [],
      unitType: unitTypes,
      compCondition: compConditions,
      leaseType: leaseTypes,
      buildingClass: buildingClasses,
      outdoorSpaceType: outdoorSpaceTypes,
      propertyRights: propertyRights,
      floorLocation: floorLocations
    };

    if (props.compTypeColumns.length == 0) {
      return notFilteredOptions;
    }

    if (showAll) {
      return notFilteredOptions;
    }

    for (const optKey of Object.keys(notFilteredOptions)) {
      if (!props.compTypeColumns.includes(upperFirst(`${optKey}Id`))) {
        delete notFilteredOptions[optKey];
      }
    }

    return notFilteredOptions;
  }, [props.compTypeColumns, showAll, inputValues.propertyTypeId]);

  const handleCompKeyAdd = (compKeyName, compKeyValue) => {
    const newCompKey = { name: compKeyName };

    setCustomFields((fields) => [...fields, newCompKey]);
    setCustomFieldValues((values) => ({
      ...values,
      [compKeyName]: compKeyValue,
    }));

    if (listEndRef.current?.scrollIntoView)
      setImmediate(() => listEndRef.current?.scrollIntoView());
  };

  const filteredCompTypeColumns = useMemo(() => {
    return props.compTypeColumns
      .filter(
        (column) =>
          !compFields.some((item) => item.name.toLowerCase() === column.toLowerCase()) &&
          !selectOptionKeys.includes(column)
      )
      .map((name) => ({ name }));
  }, [props.compTypeColumns, selectOptions]);

  const valueChanged = (propName, e, state) => {
    if (!e?.target) {
      return;
    }
    props.setNotSavedChanges(true);
    const { value } = e.target;

    let data = fieldCalculation(propName, value, state, props.compTypeId);
    setInputValues((old) => ({ ...old, ...data }));
  };

  const finalColumns = [
    ...compFields.filter(
      (inp) =>
        (searchTerm.length > 0 ? true : showAll) ||
        !props.compTypeColumns.length ||
        !!inputValues[inp.name] ||
        props.compTypeColumns.some(
          (i) => i.toLowerCase() === inp.name.toLowerCase()
        ) || inputValues[inp.name]
    ),
    ...filteredCompTypeColumns,
  ];

  const compTypeCols = props.compTypeColumns.map(x => x.toLowerCase());
  const sortedColumns = finalColumns
    .slice()
    .sort((a, b) => compTypeCols.indexOf(a.name.toLowerCase()) - compTypeCols.indexOf(b.name.toLowerCase()))
    .filter(x => {
      if (searchTerm.length == 0) {
        return true;
      }

      return x.name.trim().toLowerCase().includes(searchTerm.replaceAll(" ", "").toLowerCase());
    });

  const filteredSelectOptionKeys = Object.keys({ ...selectOptions })
    .filter(x => {
      if (searchTerm.length == 0) {
        return true;
      }
      return x.trim().toLowerCase().includes(searchTerm.replaceAll(" ", "").toLowerCase());
    });

  const filteredSelectOptions = {};
  for (const option of filteredSelectOptionKeys) {
    filteredSelectOptions[option] = selectOptions[option];
  }

  const getSelectFieldValue = (selectKey) => {
    const idKey = `${selectKey}Id`;
    const valueOption = selectOptions[selectKey]?.find(
      (i) => i.id == inputValues[idKey]
    )
    if (!valueOption) {
      return null;
    }

    return {
      label: valueOption.name,
      value: inputValues[idKey],
    }
  };

  return (
    <>
      <Accordion className="comps-accordion comps-accordion-compat mt-2" defaultActiveKey="-1">
        <Accordion.Item>
          <Accordion.Header>Custom fields</Accordion.Header>
          <Accordion.Body className="pt-1">
            <CreateKeyCard onCompKeyAdd={handleCompKeyAdd} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="comp-details-grid mt-2">
        <div className="scrollable px-2">
          <Row className="mb-3">
            <Col>
                <Row>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Search field"
                          name="searchTerm"
                          autoComplete="off"
                          value={searchTerm}
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {!!props.compTypeColumns.length && (
                      <Col>
                        <Form.Check
                          id="show-all-comp-fields"
                          onChange={(e) => setShowAll(e.target.checked)}
                          checked={showAll}
                          type="switch"
                          label="Show all fields"
                        />
                      </Col>
                    )}
                </Row>
                <Row>
                    {Object.keys(filteredSelectOptions).map((selectKey) => (
                      <Col key={selectKey} data-key={selectKey} xs={6} lg={3}>
                        <Form.Group className="mb-2" controlId="">
                          <Form.Label className="mb-0">
                            {startCase(selectKey)}
                          </Form.Label>
                          <Select
                            className="react-select-container mt-0"
                            classNamePrefix="react-select"
                            placeholder={startCase(selectKey)}
                            onChange={handleSelectInputChange(`${selectKey}Id`)}
                            value={getSelectFieldValue(selectKey)}
                            isClearable
                            options={selectOptions[selectKey]?.map(({ id, name }) => ({
                              value: id,
                              label: name,
                            }))}
                            menuPortalTarget={document.querySelector('body')}
                          />
                        </Form.Group>
                      </Col>
                    ))}
                    {(props.compTypeColumns.includes("Zones") ||
                      showAll ||
                      props.compTypeColumns.length == 0) && (
                      <Col xs={6} lg={3}>
                        <Form.Group className="mb-0" controlId="">
                          <Form.Label className="mb-0">Zoning District</Form.Label>
                          <CreatableSelect
                            className="react-select-container mt-0"
                            classNamePrefix="react-select"
                            value={inputValues.zoneMappings}
                            placeholder="Zoning District"
                            options={zones.map((zone) => ({
                              value: zone.id,
                              label: zone.name,
                            }))}
                            isMulti
                            onChange={(value) =>
                              setInputValues((oldValue) => ({
                                ...oldValue,
                                zoneMappings: value,
                              }))
                            }
                            menuPortalTarget={document.querySelector('body')}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    {(props.compTypeColumns.includes("BasementTypes") ||
                      showAll ||
                      props.compTypeColumns.length == 0) && (
                      <Col xs={6} lg={3}>
                        <Form.Group className="mb-0" controlId="">
                          <Form.Label className="mb-0">Basement Type</Form.Label>
                          <CreatableSelect
                            className="react-select-container mt-0"
                            classNamePrefix="react-select"
                            value={inputValues.basementTypeMappings}
                            placeholder="Basement Type"
                            options={basementTypes.map((basementType) => ({
                              value: basementType.id,
                              label: basementType.name,
                            }))}
                            isMulti
                            onChange={(value) =>
                              setInputValues((oldValue) => ({
                                ...oldValue,
                                basementTypeMappings: value,
                              }))
                            }
                            menuPortalTarget={document.querySelector('body')}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    {(props.compTypeColumns.includes("BedroomsClassifications") ||
                      showAll ||
                      props.compTypeColumns.length == 0) && (
                      <Col xs={6} lg={3}>
                        <Form.Group className="mb-0" controlId="">
                          <Form.Label className="mb-0">Bedrooms</Form.Label>
                          <CreatableSelect
                            className="react-select-container mt-0"
                            classNamePrefix="react-select"
                            value={inputValues.bedroomsClassificationsMap}
                            placeholder="Bedrooms"
                            options={bedroomsClassifications.map((bedroomsClassification) => ({
                              value: bedroomsClassification.id,
                              label: bedroomsClassification.name,
                            }))}
                            isMulti
                            onChange={(value) =>
                              setInputValues((oldValue) => ({
                                ...oldValue,
                                bedroomsClassificationMappings: value,
                              }))
                            }
                            menuPortalTarget={document.querySelector('body')}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    {(props.compTypeColumns.includes("LotShapes") ||
                      showAll ||
                      props.compTypeColumns.length == 0) && (
                      <Col xs={6} lg={3}>
                        <Form.Group className="mb-0" controlId="">
                          <Form.Label className="mb-0">Lot Shapes</Form.Label>
                          <CreatableSelect
                            className="react-select-container mt-0"
                            classNamePrefix="react-select"
                            value={inputValues.lotShapeMappings}
                            placeholder="Lot Shapes"
                            options={lotShapes.map((lotShape) => ({
                              value: lotShape.id,
                              label: lotShape.name,
                            }))}
                            isMulti
                            onChange={(value) =>
                              setInputValues((oldValue) => ({
                                ...oldValue,
                                lotShapeMappings: value,
                              }))
                            }
                            menuPortalTarget={document.querySelector('body')}
                          />
                        </Form.Group>
                      </Col>
                    )}
                     {(props.compTypeColumns.includes("LotTypes") ||
                      showAll ||
                      props.compTypeColumns.length == 0) && (
                      <Col xs={6} lg={3}>
                        <Form.Group className="mb-0" controlId="">
                          <Form.Label className="mb-0">Lot Types</Form.Label>
                          <CreatableSelect
                            className="react-select-container mt-0"
                            classNamePrefix="react-select"
                            value={inputValues.lotTypeMappings}
                            placeholder="Lot Types"
                            options={lotTypes.map((lotType) => ({
                              value: lotType.id,
                              label: lotType.name,
                            }))}
                            isMulti
                            onChange={(value) =>
                              setInputValues((oldValue) => ({
                                ...oldValue,
                                lotTypeMappings: value,
                              }))
                            }
                            menuPortalTarget={document.querySelector('body')}
                          />
                        </Form.Group>
                      </Col>
                    )}
                     {(props.compTypeColumns.includes("RentRegulationStatuses") ||
                      showAll ||
                      props.compTypeColumns.length == 0) && (
                      <Col xs={6} lg={3}>
                        <Form.Group className="mb-0" controlId="">
                          <Form.Label className="mb-0">Rent Regulation Status</Form.Label>
                          <CreatableSelect
                            className="react-select-container mt-0"
                            classNamePrefix="react-select"
                            value={inputValues.rentRegulationStatusMappings}
                            placeholder="Rent Regulation Status"
                            options={rentRegulationStatuses.map((rentRegulationStatus) => ({
                              value: rentRegulationStatus.id,
                              label: rentRegulationStatus.name,
                            }))}
                            isMulti
                            onChange={(value) =>
                              setInputValues((oldValue) => ({
                                ...oldValue,
                                rentRegulationStatusMappings: value,
                              }))
                            }
                            menuPortalTarget={document.querySelector('body')}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    {(props.compTypeColumns.includes("ZoneOverlays") ||
                      showAll ||
                      props.compTypeColumns.length == 0) && (
                      <Col xs={6} lg={3}>
                        <Form.Group className="mb-0" controlId="">
                          <Form.Label className="mb-0">Zone Overlays</Form.Label>
                          <CreatableSelect
                            className="react-select-container mt-0"
                            classNamePrefix="react-select"
                            value={inputValues.zoneOverlayMappings}
                            placeholder="Zone Overlays"
                            options={zoneOverlays.map((zoneOverlay) => ({
                              value: zoneOverlay.id,
                              label: zoneOverlay.name,
                            }))}
                            isMulti
                            onChange={(value) =>
                              setInputValues((oldValue) => ({
                                ...oldValue,
                                zoneOverlayMappings: value,
                              }))
                            }
                            menuPortalTarget={document.querySelector('body')}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    {sortedColumns.map((item) => (
                      <Col
                        key={item.name}
                        xs={6}
                        lg={3}
                        className={
                          item.type === "boolean" ? "d-flex align-items-center" : ""
                        }
                      >
                        <Form.Group className="mb-0" controlId="">
                          {item.type === "boolean" ? (
                            <Form.Check
                              id={`comp-details-${item.name}`}
                              name={item.name}
                              checked={inputValues[item.name]}
                              label={startCase(item.name)}
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  [item.name]: e.target.checked,
                                })
                              }
                            />
                          ) : (
                            <>
                              <Form.Label className="mb-0">
                                {compLabels[item.name] || startCase(item.name)}
                              </Form.Label>
                              {priceInputs.includes(item.name.toLowerCase()) ? (
                                <NumberFormat
                                  className="form-control"
                                  // style={{ height: '20px' }}
                                  value={inputValues[item.name]}
                                  thousandSeparator={true}
                                  prefix="$"
                                  allowNegative={false}
                                  onValueChange={(values, srcInfo) => {
                                    setInputValues((oldState) => {
                                      valueChanged(item.name, srcInfo.event, {
                                        ...inputValues,
                                        [item.name]: values.value,
                                      });
                                      return {
                                        ...oldState,
                                        [item.name]: values.value,
                                      };
                                    });
                                  }}
                                />
                              ) : (
                                <Form.Control
                                  type={inputType[item.type]}
                                  name={item.name}
                                  autoComplete={"off"}
                                  value={inputValues[item.name]}
                                  onChange={(e) => {
                                    if (item.type == "number" && item.value < 0) {
                                      return;
                                    }
                                    setInputValues((oldState) => {
                                      valueChanged(item.name, e, {
                                        ...inputValues,
                                        [item.name]: e.target.value,
                                      });
                                      return {
                                        ...oldState,
                                        [item.name]: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Form.Group>
                      </Col>
                    ))}
                    {customFields.map((item) => (
                      <Col key={item.name} xs={6} lg={3}>
                        <Form.Group className="mb-0" controlId="">
                          <Form.Label className="mb-0">
                            {compLabels[item.name] || startCase(item.name)}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={item.name}
                            value={customFieldValues[item.name]}
                            onChange={(e) =>
                              setCustomFieldValues({
                                ...customFieldValues,
                                [item.name]: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Col>
                    ))}
                    <div ref={listEndRef} />
                </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
});

export default CompDetails;
