import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { openRoute } from "../../contexts/TicketRouteContext";

import { Building, GoogleMaps, Pin } from "./icons";
import marker from "./icons/marker.png";

const InspectionMapView = ({ inspections }) => {
  const [maps, setMaps] = useState();
  const [mapCenter, setMapCenter] = useState();
  const [zoom, setZoom] = useState(14);
  const mapRef = useRef();
  /** @type {React.MutableRefObject<GoogleMapReact.Maps} */
  const mapsRef = useRef();
  const [showDetailsId, setShowDetailsId] = useState(0);
  const mapWrapperRef = useRef();

  const onGoogleApiLoaded = (map, maps) => {
    mapRef.current = map;
    mapsRef.current = maps;
    setMaps(maps);
  };

  useEffect(() => {
    if (!inspections.length || !maps) {
      return;
    }

    centerMapByTickets(
      inspections.map((inspections) => ({
        lat: inspections.ticket?.property?.lat,
        lng: inspections.ticket?.property?.lng,
        ticketId: inspections.ticket?.id,
        title: inspections.ticket?.property?.address,
      }))
    );
  }, [inspections, maps]);

  const centerMapByTickets = (newMarkers) => {
    try {
      const bounds = new mapsRef.current.LatLngBounds();

      for (const { lat, lng } of newMarkers) {
        bounds.extend(new mapsRef.current.LatLng(lat, lng));
      }
      if (newMarkers.length === 1) {
        setMapCenter({
          lat: newMarkers[0].lat,
          lng: newMarkers[0].lng,
        });
        setZoom(14);
      } else {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        const mapWrapperRect = mapWrapperRef.current?.getBoundingClientRect();

        const { center, zoom } = fitBounds(
          {
            ne: { lat: ne.lat(), lng: ne.lng() },
            sw: { lat: sw.lat(), lng: sw.lng() },
          },
          {
            width: mapWrapperRect?.width || 640,
            height: mapWrapperRect?.height || 380,
          }
        );
        setMapCenter(center);
        zoom !== 1 && setZoom(zoom - 1);
      }
    } catch (err) {
      console.error("render marker error", err);
    }
  };

  const openGoogleMap = () => {
    openRoute(inspections.map((inspection) => inspection.ticket));
  };

  return (
    <div
      ref={mapWrapperRef}
      style={{
        height: "calc(100vh - 125px)",
        margin: "0 -16px -24px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <GoogleMapReact
        options={(maps) => ({
          fullscreenControl: true,
          mapTypeControl: true,
          mapTypeId: maps.MapTypeId.ROADMAP,
          scaleControl: true,
          scrollwheel: false,
          streetViewControl: true,
        })}
        bootstrapURLKeys={{
          key: "AIzaSyA-aWrwgr64q4b3TEZwQ0lkHI4lZK-moM4",
        }}
        defaultCenter={{
          lat: 40.712784,
          lng: -74.005941,
        }}
        center={mapCenter}
        onChange={({ center, zoom }) => {
          mapCenter && setMapCenter(center);
          setZoom(zoom);
        }}
        defaultZoom={14}
        zoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
      >
        {inspections.map((inspection) => (
          <CustomMarker
            key={inspection.ticket.id}
            lat={inspection.ticket.property.lat}
            lng={inspection.ticket.property.lng}
            inspection={inspection}
            showDetails={showDetailsId === inspection.id}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowDetailsId(
                inspection.id === showDetailsId ? 0 : inspection.id
              );
            }}
          />
        ))}
      </GoogleMapReact>
      <Button
        style={{ position: "absolute", bottom: 16, borderRadius: 8 }}
        variant="secondary"
        className="py-2 px-3"
        onClick={openGoogleMap}
      >
        <GoogleMaps width={24} height={24} />
        <span
          className="ms-2"
          style={{ fontSize: 18, fontWeight: 600, verticalAlign: "middle" }}
        >
          Open in Google Maps
        </span>
      </Button>
    </div>
  );
};

const markerWidth = 40;
const markerHeight = 52;

const CustomMarker = forwardRef(({ inspection, showDetails, onClick }, ref) => (
  <div ref={ref} onClick={onClick}>
    <img
      src={marker}
      alt="marker"
      style={{
        width: markerWidth,
        height: markerHeight,
        position: "absolute",
        top: -markerHeight,
        left: -markerWidth / 2,
      }}
    />
    {showDetails && <InspectionMarkerDetails inspection={inspection} />}
  </div>
));

const InspectionMarkerDetails = ({ inspection }) => {
  const infoCardRef = useRef();
  const [containerHeight, setContainerHeight] = useState(0);
  const navigate = useNavigate();

  const { id: ticketId, ticketGroupId } = inspection.ticket || {};

  useLayoutEffect(() => {
    setContainerHeight(
      infoCardRef.current?.getBoundingClientRect().height || 0
    );
  }, []);

  return (
    <div
      ref={infoCardRef}
      className="rounded bg-white p-2"
      style={{
        position: "absolute",
        top: -markerHeight - containerHeight - 6,
        left: -116,
        width: 220,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div className="d-flex justify-content-between">
        <b className="text-dark">{inspection.hour}</b>
        <Button
          variant="info"
          size="sm"
          onClick={() =>
            navigate(`/dashboard/job/${ticketGroupId}/${ticketId}`)
          }
        >
          View Details
        </Button>
      </div>
      <div className="mt-2">
        <div className="px-2">
          <Building />
          <span className="ms-2">{inspection.propertyTypeString}</span>
        </div>
        <div className="px-2">
          <Pin />
          <span className="ms-2">{inspection.formattedAddress}</span>
        </div>
      </div>
    </div>
  );
};

export default InspectionMapView;
