import React, { memo, useEffect, useMemo, useRef } from "react";
import InspectionListItem from "./InspectionListItem";
import moment from "moment";
import useCustomCss from "../../hooks/useCustomCss";

// import styles from './inspectionsList.module.scss';

const css = `
  .content {
    padding: unset !important;
    overflow-y: scroll !important
  }

  .loading-container {
      padding: 0.5rem 2.5rem 0.5rem !important;
  }`;

const InspectionList = ({ inspections }) => {
  // hooks 
  useCustomCss(css);

  const groupedInspections = useMemo(() => {
    const sorted = [...inspections].sort((a, b) =>
      new Date(a.date) < new Date(b.date) ? -1 : 1
    );
    const data = {};

    for (const inspection of sorted) {
      if (!inspection?.date) {
        continue;
      }
      const [day] = inspection.date.split("T");
      const yearAndMonth = day.substring(0, 7);

      if (!data[yearAndMonth]) {
        data[yearAndMonth] = {};
      }
      if (!data[yearAndMonth][day]) {
        data[yearAndMonth][day] = [];
      }
      data[yearAndMonth][day].push(inspection);
    }

    return data;
  }, [inspections]);

  return (
    <div>
      {Object.keys(groupedInspections).map((month) => (
        <InspectionMonthGroup
          key={month}
          date={month}
          inspectionGroups={groupedInspections[month]}
        />
      ))}
      {Object.keys(groupedInspections).length == 0 ? (<h4 className="mt-2 text-center">No data to show</h4>) : null}
    </div>
  );
};

export default InspectionList;

const InspectionMonthGroup = memo(({ inspectionGroups, date }) => {
  const monthName = useMemo(
    () => moment(date, "YYYY-MM").format("MMMM"),
    [date]
  );

  const year = useMemo(
    () => moment(date, "YYYY").format("YYYY"),
    [date]
  );

  return (
    <div className="mt-4 inspection-m onth-group" id={`event-view-month-${monthName}-${year}`}>
      <h3>{monthName} <small style={{ fontSize: '12px', color: 'gray' }}>{year}</small></h3>
      {Object.keys(inspectionGroups).map((day) => (
        <InspectionDayGroup
          key={day}
          date={day}
          inspections={inspectionGroups[day]}
        />
      ))}
    </div>
  );
});

const InspectionDayGroup = memo(({ inspections, date }) => {
  const { day, weekDay } = useMemo(() => {
    const dateMoment = moment(date, "YYYY-MM-DD");

    return {
      day: dateMoment.format("D"),
      weekDay: dateMoment.format("ddd"),
    };
  }, [date]);

  return (
    <div className="d-flex">
      <div className="d-flex flex-column align-items-center pe-4">
        <span style={{ fontSize: 14, fontWeight: 500, color: "#A25C47" }}>
          {weekDay}
        </span>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: 40,
            width: 40,
            borderRadius: 20,
            backgroundColor: "#A25C47",
          }}
        >
          <span style={{ fontSize: 20 }} className="text-white">
            {day}
          </span>
        </div>
      </div>
      <div style={{ width: "-webkit-fill-available" }}>
        {inspections.map((inspection) => (
          <InspectionListItem key={inspection.id} inspection={inspection} />
        ))}
      </div>
    </div>
  );
});
