import { UPDATE_SEARCH_FILTER, UPDATE_APPLIED_FILTERS } from "../../actions/search"
import { getJobsFilterObj, stringToJSON } from "../../../utils"

let filters = { filters: stringToJSON(localStorage.getItem("ValuFlow_Filters")) || getJobsFilterObj(), appliedFilters: [] }

const searchFiltersReducer = (state = filters, action) => {
    switch (action.type) {
        case UPDATE_SEARCH_FILTER:
            return { ...state, filters: { ...action.payload } }
        case UPDATE_APPLIED_FILTERS:
            return { ...state, appliedFilters: [...action.payload] }
        default:
            return state
    }
}

export default searchFiltersReducer