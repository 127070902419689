import axios from "../utils/axios";

export function getTicketReviews(ticketId) {
  const queryParams = new URLSearchParams({ ticketId });
  return axios.get(`/TicketReview/GetTicketReviews?${queryParams}`);
}

export function createTicketReview(review) {
  return axios.post("/TicketReview/CreateTicketReview", review);
}

export function updateTicketReview(review) {
  return axios.post("/TicketReview/UpdateTicketReview", review);
}

export function deleteTicketReview(id) {
  const queryParams = new URLSearchParams({ id });
  return axios.post(`/TicketReview/DeleteTicketReview?${queryParams}`);
}
