const compLabels = {
    "compTypeId": "Comp Type",
    "borough": "Borough",
    "block": "Block",
    "lot": "Lot",
    "compAddress": "Address",
    "city": "City",
    "state": "State",
    "zip": "Zip",
    "county": "County",
    "dateOfSale": "Date Of Sale",
    "salePrice": "Sale Price",
    "saleStatus": "Sale Status",
    "grantor": "Grantor",
    "grantee": "Grantee",
    "yearBuilt": "Year Built",
    "capRate": "Cap Rate",
    "lotSizeSF": "Lot Size SF",
    "lotSizeAcres": "Lot Size Acres",
    "outdoorSpaceSF": "Outdoor space SF",
    "wideStreet": "Wide street",
    "far": "FAR",
    "buildable": "Total Buildable SQ",
    // "totalBuildable": "Total Remaining Buildable",
    "totalRemaining": "Total Remaining Buildable",
    "totalRemainingPercentage": "Total Remaining Percentage",
    "numberOfUnits": "Number Of Units",
    "elevator": "Elevator",
    "grossBuildingAreaSF": "Gross Building Area SF",
    "pricePerSF": "Price Per SF",
    "financedBy": "Financed By",
    "mortgageAmount": "Mortgage Amount",
    "landBuildingRatio": "Land Building Ratio",
    "ceilingHeight": "Ceiling Height",
    "squareFeet": "Net Square Feet",
    "bedrooms": "Bedrooms",
    "bathrooms": "Bathrooms",
    "pricePerBuildableSF": "Price Per Buildable SF",
    "averageUnitSize": "Average Unit Size",
    "rentStabilized": "Rent Stabilized",
    "rentStabilizedPercentage": "Rent Stabilized Percentage",
    "pricePerUnit": "Price Per Unit",
    "realEstateTaxReimbursement": "Real Estate Tax Reimbursement",
    "insuranceReimbursement": "Insurance Reimbursement",
    "otherReimbursement": "Other Reimbursement",
    "pgi": "PGI",
    "residentialVacancyAndCollectionLoss": "Residential Vacancy And Collection Loss",
    "commercialVacancyAndCollectionLoss": "Commercial Vacancy And Collection Loss",
    "egi": "EGI",
    "realEstateTax": "Real Estate Tax",
    "realEstateTaxPerUnit": "Real Estate Tax Per Unit",
    "realEstateTaxPerSF": "Real Estate Tax Per SF",
    "insurance": "Insurance",
    "insurancePerUnit": "Insurance Per Unit",
    "insurancePerSF": "Insurance Per SF",
    "managementPercentage": "Management Percentage",
    "managementPercentagePerUnit": "Management Percentage Per Unit",
    "managementPercentagePerSF": "Management Percentage Per SF",
    "management": "Management",
    "managementPerUnit": "Management Per Unit",
    "managementPerSF": "Management Per SF",
    "repairsAndMaintenance": "Repairs And Maintenance",
    "waterAndSewer": "Water And Sewer",
    "commonAreaUtilities": "Common Area Utilities",
    "gasAndHeat": "Gas And Heat",
    "utilities": "Utilities",
    "buildingReservesCostPerSF": "Building Reserves Cost Per SF",
    "buildingReserves": "Building Reserves",
    "legalAndAccounting": "Legal And Accounting",
    "otherAndMiscellaneous": "Other And Miscellaneous",
    "totalExpenses": "Total Expenses",
    "incomeAndExpenseRatio": "Income And Expense Ratio",
    "netOperatingIncome": "Net Operating Income",
    "overallRate": "Overall Rate",
    "unitNumber": "Unit Number",
    "unitSF": "Unit SF",
    "monthlyRent": "Monthly Rent",
    "annualRent": "Annual Rent",
    "leaseStart": "Lease Start",
    "leaseEnd": "Lease End",
    "floorLocation": "Floor Location",
    "rentPerSF": "Rent Per SF",
    "rentalType": "Rental Type",
    "residentialIncome": "Residential Income",
    "presentValue": "Present Value",
    "notes": "Notes",
    "buildingReserves": "Building Reserves (.25 PSF)",
    "isEstimated": "Is Estimated",

    "tenant": "Tenant",
    "proximityToSubject": "Proximity To Subject",
    "yearRenovated": "Year Renovated",
    "communityFacilityFAR": "Community Facility FAR",
    "basementSquareFeet": "Basement SF",

    "compTypeId": "Comp Type",
    "outdoorSpaceId": "Outdoor Space",
    "compConditionId": "Comp Condition",
    "leaseTypeId": "Lease Type",
    "basementTypes": "Basement Types",
    "bedroomsClassifications": "Bedrooms",
    "lotShapes": "Lot Shapes",
    "lotTypes": "Lot Types",
    "rentRegulationStatuses": "Rent Regulation Statuses",
    "buildingClassId": "Building Class",
    "unitTypeId": "Unit Type",
    "propertyTypeId": "Property Type",
    "propertySubTypeId": "Property Sub Type",
    "outdoorSpaceTypeId": "Outdoor Space Type",
    "propertyRightsId": "Property Rights",

    "compAddress.fullAddress": "Full Address",
    "compAddress.city": "City",
    "compAddress.state": "State",
    "compAddress.zip": "Zip",
    "compAddress.county": "County",
    "compAddress.streetNumber": "Street number",
    "compAddress.route": "Route",
    "zones": "Zoning District",
};

export default compLabels;