import React, { useMemo, useState } from "react";

import TextQuestion from "./TextQuestion";
import DropdownQuestion from "./DropdownQuestion";
import RadioQuestion from "./RadioQuestion";
import MultiSelectableQuestion from "./MultiSelectableQuestion";

const QuestionComponents = {
  1: TextQuestion,
  2: DropdownQuestion,
  3: RadioQuestion,
  4: MultiSelectableQuestion
};

const QuestionItem = ({ question, onChange, disabled, subQuestions }) => {
  const [answer, setAnswer] = useState("");
  const QuestionComponent = useMemo(
    () => QuestionComponents[question.type],
    [question.type]
  );

  const handleChange = (answer) => {
    setAnswer(answer);
    onChange(question.id, answer, question.answerId);
  }

  return (
    <>
      <QuestionComponent
        {...question}
        onChange={handleChange}
        disabled={disabled}
      />
      {!!subQuestions && !!subQuestions.length && (
        <div className="ps-4">
          {subQuestions
            .filter((subQuestion) => answer && subQuestion.option === answer)
            .map((subQuestion, index) => (
              <QuestionItem
                key={index}
                question={subQuestion}
                onChange={onChange}
                disabled={disabled}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default QuestionItem;
