import React, { useEffect, useState } from "react"

import { FloatingLabel, Form } from "react-bootstrap";
import ConfirmationModal from "../jobs/confirmationModal";

//** Libraries */
import axios from "axios";
import { toast } from "react-toastify";

//** Hooks & actions*/
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { loadTicketGroups } from "../../redux/actions/ticketGroups";

//** Configs */
import { ENABLE_SNOOZE, SERVER_URL } from "../../configs";
import { clearUserTasks, loadUserTasks } from "../../redux/actions/user";
import { TICKET_STATUSES } from "../../constants";

import { getQuotesByTicketId } from "../../services/ticketQuote";

const TicketStatus = ({ ticket, show, reloadTicket, setChangeTicketDialogStatus }) => {

  const getAssignedUser = () => {
    if (ticket.ticketStatuses && ticket.ticketStatuses.length > 0)
      return ticket.ticketStatuses[ticket.ticketStatuses.length - 1].assignedToId;
  }

  const dispatch = useAppDispatch()

  const [originalTicketStatus, setOriginalTicketStatus] = useState();
  const [ticketStatus, setTicketStatus] = useState();
  const [assignedUser, setAssignedUser] = useState();
  const [applyToAllPortfolio, setApplyToAllPortfolio] = useState(false);
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState();
  const [dueDate, setDueDate] = useState('');
  const [snoozeUntil, setSnoozeUntil] = useState('');
  const [snoozeReason, setSnoozeReasnon] = useState('');
  const [quotes, setQuotes] = useState([]);

  const { users, ticketStatusTypes } = useAppSelector(state => state.appData)
  const { pageIndex, pageSize } = useAppSelector(state => state.ticketGroups)
  const { filters } = useAppSelector(state => state.search)
  const quoteTimeSlots = useAppSelector(state => state.appData.quoteTimeSlots);

  useEffect(() => {
    if (ticket && ticket.ticketStatuses) {
      setOriginalTicketStatus(ticket.ticketStatuses.length > 0 ? ticket.ticketStatuses[ticket.ticketStatuses.length - 1].ticketStatusTypeId : 0)
      setTicketStatus(ticket.ticketStatuses.length > 0 ? ticket.ticketStatuses[ticket.ticketStatuses.length - 1].ticketStatusTypeId : 0)
      setAssignedUser(getAssignedUser())

      getQuotesByTicketId(ticket.id)
        .then((data) => {
          data.forEach(q => {
            q.quoteTimeSlot = quoteTimeSlots.find(s => s.id === q.quoteTimeSlotId);
          })
          let accepted = data.find(q => q.isAccepted);
          if (accepted) {
            setSelectedTimeSlotId(accepted.quoteTimeSlotId);
          }
          setQuotes(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [ticket, show])

  const updateTicketGroup = (index = pageIndex, size = pageSize) => {
    dispatch(loadTicketGroups(index, size, filters))
    if (reloadTicket)
      reloadTicket()
  }

  useEffect(() => {
    let timeSlot = quoteTimeSlots.find(s => s.id === Number(selectedTimeSlotId));
    if (timeSlot) {
      var days = Number(timeSlot.days);
      var date = new Date();
      date.setDate(date.getDate() + days);

      var dayOfWeek = date.getDay();
      if (dayOfWeek === 0) { // Sunday
        date.setDate(date.getDate() - 2);
      } else if (dayOfWeek === 6) { // Saturday
        date.setDate(date.getDate() - 1); 
      }

      var formattedDate = date.toISOString().slice(0, 10);
      setDueDate(formattedDate);
    }
  }, [selectedTimeSlotId])

  const handleTicketDelete = () => {
    if (window.confirm('Are you sure you want to delete the ticket?')) {
      axios.post(`${SERVER_URL}/api/Ticket/DeleteTicket?ticketId=${ticket.id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(() => {
        updateTicketGroup()
        toast.success("Ticket deleted successfully !!");
      }).catch((err) => {
        console.error(err)
        toast.error("Failed to delete ticket, please contact administrator !!!");
      })
      setChangeTicketDialogStatus(false)
    }
  }

  const handleTicketStatusChange = () => {

    // if (ticketStatus == TICKET_STATUSES.ON_HOLD && (!snoozeUntil || snoozeUntil == '')) {
    //     toast.error('Please specify the snooze duration...');
    //     return;
    // }

    if (originalTicketStatus === TICKET_STATUSES.PENDING && Number(ticketStatus) === TICKET_STATUSES.ACTIVE) {
      if (!dueDate) {
        toast.error('Please select a due date');
        return;
      }
    }

    if (applyToAllPortfolio) {
        if (!window.confirm("Are you sure you want to change status to all portfolio tickets?")) {
            return;
        }
    }

    let _obj = {
      ticketId: ticket.id,
      ticketStatusTypeId: ticketStatus,
      assignedToUserId: assignedUser,
      assignmentNote: "",
      snoozeUntil,
      snoozeReason,
      dueDate,
      applyToAllPortfolio
    }
    axios.post(`${SERVER_URL}/api/Ticket/ChangeTicketStatus`, _obj, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      updateTicketGroup()
      dispatch(clearUserTasks());
      dispatch(loadUserTasks());
      toast.success("Ticket status updated successfully !!");
    }).catch((err) => {
      console.error(err)
      toast.error("Failed to update ticket status, please contact administrator !!!");
    })
    setChangeTicketDialogStatus(false)
  }

  return (
    <ConfirmationModal
      title="Ticket Status"
      show={show}
      deleteBtn={true}
      handleDelete={handleTicketDelete}
      setShow={setChangeTicketDialogStatus}
      handleConfirm={handleTicketStatusChange}
    >
      <FloatingLabel label="Select Ticket Status">
        <Form.Select
          aria-label="Select Ticket Status"
          value={ticketStatus}
          onChange={(e) => setTicketStatus(e.target.value)}
        >
          {ticketStatusTypes.map((statusType) => (
            <option key={statusType.id} value={statusType.id}>
              {statusType.name}
            </option>
          ))}
        </Form.Select>
        <br />
        <FloatingLabel label="Select User">
          <Form.Select
            aria-label="Select User"
            value={assignedUser || ""}
            onChange={(e) => setAssignedUser(e.target.value)}
          >
            <option value="">Select User</option>
            {users.filter(u => u.active).map((user) => (
              <option key={user.id} value={user.id}>
                {user.firstName ? `${user.firstName} ${user.lastName || ""}` : user.userName}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>

        {originalTicketStatus === TICKET_STATUSES.PENDING && Number(ticketStatus) === TICKET_STATUSES.ACTIVE && (
          <>
            <br />
            <FloatingLabel label="Select Quote">
              <Form.Select
                aria-label="Select Quote"
                value={selectedTimeSlotId || ""}
                onChange={(e) => setSelectedTimeSlotId(e.target.value)}
              >
                <option value="">Select Quote</option>
                {quoteTimeSlots.map((timeSlot) => {
                  var selectedQuote = quotes.find(q => q.quoteTimeSlotId === timeSlot.id);
                  return (
                    <option key={timeSlot.id} value={timeSlot.id}>
                      {timeSlot.name}
                      {
                        selectedQuote?.isAccepted && (` (Accepted)`)
                      }
                    </option>
                  )
                })}
              </Form.Select>
            </FloatingLabel>
            <br />
            <h5 className="ms-1">Due Date</h5>
            <FloatingLabel label="Due Date">
              <Form.Control type='date'
                aria-label="Due Date"
                value={dueDate}
                onChange={e => setDueDate(e.target.value)} />
            </FloatingLabel>
          </>
        )
        }

        {ENABLE_SNOOZE && (ticketStatus == TICKET_STATUSES.ON_HOLD || ticketStatus == TICKET_STATUSES.PENDING) && (
          <>
            <br />
            <h5 className="ms-1">Snooze job</h5>
            <FloatingLabel label="Snooze until">
              <Form.Control type='date'
                aria-label="Snooze Until"
                value={snoozeUntil}
                onChange={e => setSnoozeUntil(e.target.value)} />
            </FloatingLabel>
            <FloatingLabel label="Snooze reason" className="mt-2">
              <Form.Control as='textarea' rows={5} value={snoozeReason} onChange={e => setSnoozeReasnon(e.target.value)} />
            </FloatingLabel>
          </>
        )}

        {ticket && ticket.portfolioId &&
            <div>
                <br />
                <Form.Check
                    type="checkbox"
                    label="Apply to all portfolio tickets"
                    checked={applyToAllPortfolio}
                    onChange={(e) => setApplyToAllPortfolio(e.target.checked)}
                />
            </div>
        }
      </FloatingLabel>
    </ConfirmationModal>
  );
}

export default TicketStatus;