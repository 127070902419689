import React, { useEffect, useState } from "react";
import { PlusCircle, MinusCircle } from "react-feather";
import { Button, Row, Col, Form, FormGroup, Modal } from "react-bootstrap";
import { faMinusCircle, faPlusCircle, faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCustomerContactObj } from "../../../../utils/index";
import FilterDropdown from './FilterDropdown'
import AffiliatesDropdown from './AffiliatesDropdown'
import HistoryTable from "../../../jobDetails/history";
import { getChangeHistory } from "../../../../services/changeHistory";
import useAppSelector from "../../../../hooks/useAppSelector";

export default function BasicInfo({ data, setData, dataAffiliateId, affiliatePaymentMethod, companySelectedFlag, handleCompanySelection, handleAffiliateSelection, handleUpdateAffiliatePaymentMethod, detailsChangedListener, selectedContactIndex, selectContactListener }) {
  const { users, customerRules } = useAppSelector(state => state.appData);

  const [focus, setFocus] = useState(false)
  const [customerHistory, setCustomerHistory] = useState([]);
  const [historyModalShow, setHistoryModalShow] = useState(false);

  useEffect(() => {
    if (!data.customer.id) {
      return;
    }
    getChangeHistory("customer", data.customer.id).then(setCustomerHistory);
  }, [data.customer.id, users]);

  const addItemToCustomerContacts = (key, parentIndex) => {
    let rootKey = "customerContactEmails"
    if (key === "phone") {
      rootKey = "customerContactPhoneNumbers"
    }

    let _data = JSON.parse(JSON.stringify(data))
    _data["customerContacts"][parentIndex][rootKey].push({
      [key]: ""
    })
    setData(_data)
  }

  const removeItemFromCustomerContact = (key, parentIndex, childIndex) => {
    let rootKey = "customerContactEmails"
    if (key === "phone") {
      rootKey = "customerContactPhoneNumbers"
    }
    let _data = JSON.parse(JSON.stringify(data))
    _data["customerContacts"][parentIndex][rootKey].splice(childIndex, 1)
    setData(_data)
  }

  const addNewCustomerContact = () => {
    let _data = JSON.parse(JSON.stringify(data))
    _data["customerContacts"].push(getCustomerContactObj())
    setData(_data)
  }

  const removeCustomerContact = (index) => {
    let _data = JSON.parse(JSON.stringify(data))
    _data["customerContacts"].splice(index + 1, 1)
    setData(_data)
  }

  const handleCustomerRuleChange = (rule, checked) => {
    const newData = JSON.parse(JSON.stringify(data));
    const oldRules = newData.customerRules;
    newData.customerRules = checked
      ? [...oldRules, rule]
      : oldRules.filter((item) => item.id !== rule.id);
    setData(newData);
  };

  //Handling Change Events of Phone Numbers and Emails
  const handleContactDetailsChange2 = (key, value, parentIndex, childIndex) => {
    let rootKey = "customerContactEmails"
    if (key === "phone") {
      rootKey = "customerContactPhoneNumbers"
    }
    let _data = JSON.parse(JSON.stringify(data))
    _data["customerContacts"][parentIndex][rootKey][childIndex][key] = value
    setData(_data)

    if (parentIndex === 0) {
      detailsChangedListener(_data)
    }
  }

  //Handling Change Events of First Name and LastName
  const handleContactDetailsChange = (key, value, index) => {
    let _data = JSON.parse(JSON.stringify(data))
    _data["customerContacts"][index][key] = value
    setData(_data)

    if (index === 0) {
      detailsChangedListener(_data)
    }
  }

  const handleUpdateCompanyInfo = (e) => {
    let _customer = { ...data.customer, [e.target.name]: e.target.value }
    setData({ ...data, "customer": _customer })
    detailsChangedListener({ ...data, "customer": _customer })
  }

  const handleBlur = () => {
    setTimeout(function () {
      setFocus(false)
    }, 500)
  }

  const clearCustomerFilterFields = () => {
    let _customer = {
      ...data.customer,
      id: 0,
      name: "",
      address: "",
      phone: "",
      email: ""
    }

    const customerContacts = [getCustomerContactObj()]
    setData({ ...data, "customer": _customer, customerContacts })
    detailsChangedListener({ ...data, "customer": _customer, customerContacts })
  }

  const closeHistoryModal = () => setHistoryModalShow(false);
  
  return (
    <div className="pt-1">
      <Modal show={historyModalShow} onHide={closeHistoryModal} size="lg">
        <Modal.Header closeButton>
          <h3>Customer History</h3>
        </Modal.Header>
        <Modal.Body>
          <HistoryTable data={customerHistory} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeHistoryModal}>close</Button>
        </Modal.Footer>
      </Modal>
      <div className="contact-info-container">
        
        <h5 className="tk-font mb-3">
          Contact info <span>(point of contact)</span>
        </h5>
        <div className="jobs-input-block mb-2">
          <Row>
            <Col xl={12}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Company Name *"
                  onClick={() => setFocus(true)}
                  onBlur={handleBlur}
                  value={data.customer.name}
                  autoComplete="off"
                  onChange={handleUpdateCompanyInfo}
                />
              </Form.Group>
              <FilterDropdown
                show={focus}
                company={data}
                handleCompanySelection={handleCompanySelection}
              />
            </Col>
            <Col
              xl={1}
              style={{ padding: 0 }}
              className="jobs-icon jobs-input-icon"
            >
              <FontAwesomeIcon
                icon={faEraser}
                onClick={clearCustomerFilterFields}
                style={{ cursor: "pointer" }}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              name="address"
              placeholder="Company Address *"
              value={data.customer.address || ""}
              autoComplete="off"
              onChange={handleUpdateCompanyInfo}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              name="email"
              placeholder="Company Email"
              value={data.customer.email || ""}
              autoComplete="off"
              onChange={handleUpdateCompanyInfo}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              name="phone"
              placeholder="Company Phone"
              value={data.customer.phone || ""}
              autoComplete="off"
              onChange={handleUpdateCompanyInfo}
            />
          </Form.Group>
        </Row>
        <Row>
          <h5 className="my-2">Customer Rules</h5>
          {customerRules.map((customerRule) => (
            <Form.Group key={customerRule.id} className="mb-2">
              <Form.Check
                id={customerRule.id}
                label={customerRule.name}
                checked={
                  !!data.customerRules.find(
                    (item) => customerRule.id === item.id
                  )
                }
                onChange={(e) =>
                  handleCustomerRuleChange(customerRule, e.target.checked)
                }
              />
            </Form.Group>
          ))}
        </Row>
        <Row>
          <Form.Group className="mb-2">
            <Button
              variant="light"
              className="w-100"
              onClick={() => setHistoryModalShow(true)}
            >
              Customer history
            </Button>
          </Form.Group>
        </Row>

      </div>
      <Form.Group className="mb-2">
        <div name="contacts">
          <div>
            {data?.customerContacts &&
              data?.customerContacts.length > 0 &&
              data.customerContacts.map((contact, index) => (
                <div key={index} className="contact-details-container">

                  <div className="d-flex justify-content-between">
                    <h5 className="my-2">Contact Details {index + 1}</h5>
                    {selectContactListener && selectedContactIndex !== index &&
                      <a style={{textDecoration: 'underline', fontWeight: '500'}} onClick={() => selectContactListener(index)}>Select Contact</a>
                    }
                    {selectContactListener && selectedContactIndex === index &&
                      <div style={{color: '#a25c47', fontWeight: '500'}}>Selected</div>
                    }
                  </div>
                  
                  <Row className="my-1">
                    <Col xs={12} sm={6}>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="text"
                          id={`firstName.${index}`}
                          name={`firstName.${index}`}
                          placeholder="First Name"
                          value={contact?.firstName}
                          onChange={(e) =>
                            handleContactDetailsChange(
                              "firstName",
                              e.target.value,
                              index
                            )
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} sm={6}>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="text"
                          id={`lastName.${index}`}
                          name={`lastName.${index}`}
                          placeholder="Last Name"
                          value={contact.lastName}
                          onChange={(e) =>
                            handleContactDetailsChange(
                              "lastName",
                              e.target.value,
                              index
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {contact.customerContactPhoneNumbers.length > 0 ? (
                    contact.customerContactPhoneNumbers.map((obj, index1) => (
                      <React.Fragment key={index1}>
                        <div className="jobs-input-block mb-2">
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Form.Control
                                  type="text"
                                  value={obj["phone"]}
                                  name="phone"
                                  placeholder="Phone Number"
                                  onChange={(e) =>
                                    handleContactDetailsChange2(
                                      "phone",
                                      e.target.value,
                                      index,
                                      index1
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>

                            <Col
                              xs={1}
                              className="p-0 jobs-icon jobs-input-icon"
                            >
                              <FormGroup>
                                {index1 ===
                                  contact.customerContactPhoneNumbers.length -
                                  1 ? (
                                  <PlusCircle
                                    type="button"
                                    className="align-middle my-1"
                                    size={22}
                                    onClick={() =>
                                      addItemToCustomerContacts("phone", index)
                                    }
                                  />
                                ) : (
                                  <MinusCircle
                                    type="button"
                                    className="align-middle my-1"
                                    size={22}
                                    onClick={() =>
                                      removeItemFromCustomerContact(
                                        "phone",
                                        index,
                                        index1
                                      )
                                    }
                                  />
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </React.Fragment>
                    ))
                  ) : (
                    <a
                      className="my-2"
                      onClick={() => addItemToCustomerContacts("phone", index)}
                    >
                      Add Phone
                    </a>
                  )}

                  {contact.customerContactEmails.length > 0 ? (
                    contact.customerContactEmails.map((obj, index1) => (
                      <React.Fragment key={index1}>
                        <div className="jobs-input-block mb-2">
                          <Row>
                            <Col xs={12}>
                              <FormGroup className="mb-2">
                                <Form.Control
                                  type="text"
                                  value={obj["email"]}
                                  name={"email"}
                                  placeholder="Email"
                                  onChange={(e) =>
                                    handleContactDetailsChange2(
                                      "email",
                                      e.target.value,
                                      index,
                                      index1
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>

                            <Col
                              xs={1}
                              className="p-0 jobs-icon jobs-input-icon"
                            >
                              <FormGroup className="mb-2">
                                {index1 ===
                                  contact.customerContactEmails.length - 1 ? (
                                  <PlusCircle
                                    type="button"
                                    className="align-middle my-1"
                                    size={22}
                                    onClick={() =>
                                      addItemToCustomerContacts("email", index)
                                    }
                                  />
                                ) : (
                                  <MinusCircle
                                    type="button"
                                    className="align-middle my-1"
                                    size={22}
                                    onClick={() =>
                                      removeItemFromCustomerContact(
                                        "email",
                                        index,
                                        index1
                                      )
                                    }
                                  />
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </React.Fragment>
                    ))
                  ) : (
                    <a
                      className="my-2"
                      onClick={() => addItemToCustomerContacts("email", index)}
                    >
                      Add Email
                    </a>
                  )}

                  <Row className="mx-0">
                    {data.customerContacts.length === index + 1 ? (
                      <Button
                        variant="primary"
                        className="mb-2 me-1"
                        onClick={addNewCustomerContact}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} className="me-1" />
                        Add Additional Customer Contacts
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        className="mb-2 me-1"
                        onClick={() => removeCustomerContact(index)}
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </Button>
                    )}
                  </Row>
                </div>
              ))}
          </div>
        </div>
      </Form.Group>
      {!companySelectedFlag && (
        <Form.Group>
          <div name="companies_address">
            <div>
              <Row className="justify-content-end ">
                <Button
                  className="base-width mx-1 my-2"
                  style={{ display: "none" }}
                  type="submit"
                >
                  Save Customer
                </Button>
              </Row>
            </div>
          </div>
        </Form.Group>
      )}

      <h5 className="tk-font mb-3">
        Partner
      </h5>
      <Row className="mb-2">
        <Col xl={12}>
          <AffiliatesDropdown
            affiliateId={dataAffiliateId}
            handleAffiliateSelection={handleAffiliateSelection}
          />
        </Col>
      </Row>
      {dataAffiliateId &&
        <Row>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              name="paymentMethod"
              placeholder="Payment Method"
              value={affiliatePaymentMethod || ""}
              autoComplete="off"
              onChange={(e) => handleUpdateAffiliatePaymentMethod(e.target.value)}
            />
          </Form.Group>
        </Row> 
      }
    </div>
  );
}
