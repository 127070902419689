import React, { useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import useScreenSize from "../../hooks/useScreenSize";
import { CalendarCheck } from "./icons";

import "./calendar.css"

const EventContent = (eventInfo) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center bg-info text-white pt-2 rounded">
      <CalendarCheck />
      <span style={{ fontSize: 24, fontWeight: 500 }}>
        {eventInfo.event.title}
      </span>
    </div>
  );
};

const InspectionCalendarView = ({ inspections }) => {
  const inspectionEvents = useMemo(() => {
    const data = {};

    for (const inspection of inspections) {
      if (!inspection?.date) {
        continue;
      }
      const [day] = inspection.date.split("T");

      if (!data[day]) {
        data[day] = [];
      }
      data[day].push(inspection);
    }

    return Object.keys(data).map((day) => ({
      start: day,
      title: data[day].length,
    }));
  }, [inspections]);
  const { height } = useScreenSize();

  // const handleEventClick = (info) => {
  //   const _inspection = inspections.find((_ins) => String(_ins.id) === info.event.id)
  //   navigate(`/dashboard/job/${_inspection.ticket.ticketGroupId}/${_inspection.ticket.id}?selectedInfoTab=2`)
  // };

  return (
    <div style={{ margin: -16 }}>
      <FullCalendar
        plugins={[bootstrapPlugin, dayGridPlugin]}
        themeSystem="bootstrap"
        initialView="dayGridMonth"
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
        }}
        height={height - 134}
        initialDate={new Date().toISOString().split("T")[0]}
        headerToolbar={{
          left: "",
          center: "",
          right: "",
        }}
        events={inspectionEvents}
        eventContent={EventContent}
        // eventClick={handleEventClick}
        bootstrapFontAwesome={false}
        eventDidMount={(eventArg) => {
          if (eventArg.isToday) {
            eventArg.el.scrollIntoView();
          }
        }}
      />
    </div>
  );
};

export default InspectionCalendarView;
