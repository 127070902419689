import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomAccordion from "../../../components/accordion";

const filterFields = [
  { name: "customerName", label: "Customer Name" },
  { name: "customerAddress", label: "Customer Address" },
  { name: "firstName", label: "First Name" },
  { name: "lastName", label: "Last Name" },
  { name: "emails", label: "email" },
  { name: "phoneNumbers", label: "Phone Number" },
];

const Filters = ({ onFilter }) => {
  const [filters, setFilters] = useState({});

  const handleFilterChange = (e) => {
    filters[e.target.name] = ["emails", "phoneNumbers"].includes(e.target.name)
      ? [e.target.value]
      : e.target.value;
    setFilters({ ...filters });
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      onFilter(filters);
    }
  };

  const handleApply = (e) => {
    e.stopPropagation();
    onFilter(filters);
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setFilters({});
    onFilter({});
  };

  return (
    <CustomAccordion
      header={
        <div>
          <Button
            className="ms-4 text-black-50"
            variant="light"
            onClick={handleApply}
          >
            Apply
          </Button>
          <Button
            className="ms-2 btn-white-custom"
            onClick={handleReset}
            type="reset"
          >
            Reset
          </Button>
        </div>
      }
    >
      <Row>
        {filterFields.map(({ name, label }) => (
          <Col key={name}>
            <Form.Control
              name={name}
              onKeyUp={handleKeyUp}
              placeholder={label}
              onChange={handleFilterChange}
            />
          </Col>
        ))}
      </Row>
    </CustomAccordion>
  );
};

export default Filters;
