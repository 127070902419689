import React, { useEffect, useRef } from "react";
import { updateAllPriority } from "../../../services/ticket";
import dragula from "react-dragula";
import { sortBy } from "lodash";
import useAppSelector from "../../../hooks/useAppSelector";
import { getDateTime } from "../../../pages/frontDesk/helpers";
import { Badge } from "react-bootstrap";
import './tasks-board.css'
import { useNavigate } from "react-router-dom";
import { Calendar, TrendingDown } from "react-feather";

const TasksBoardComponent = ({ data, dragEnabled, employeeIds }) => {

  const navigate = useNavigate();
  const boardsRefs = useRef([]);

  
  useEffect(() => {

    if (dragEnabled) {
      let drakes = [];

      boardsRefs.current.forEach(ref => {
        let drake = dragula([ref]);
        drakes.push(drake);

        drake.on("drop", (el, target, source, sibling) => {

          let ids = [];
          for (let i = 0; i <= ref.children.length - 1; i++) {
            let child = ref.children.item(i);
            let id = child.getAttribute("data-id");
            ids.push(+id);
          }

          updateAllPriority(ids).then(x => {

          });

        });

      });

      return () => {
        drakes.forEach(drake => {
          drake.destroy();
        })
      };
    }
    
  }, [data, employeeIds]);

  const ticketStatusTypesMap = useAppSelector(state => state.appData.ticketStatusTypesMap)
  const getTicketStatusType = (ticket) => {
    const statuses = [...ticket.ticketStatuses];
    const src = sortBy(statuses, 'id');
    return ticketStatusTypesMap[src[(src?.length || 0) - 1]?.ticketStatusTypeId];
  }

  const TaskItem = ({ ticket }) => {

    const ticketStatus = getTicketStatusType(ticket);
    const status = ticketStatus?.name || "In Progress";
    
    const onClick = () => {
      navigate(`/dashboard/job/${ticket.ticketGroupId}/${ticket.id}`, { state: { from: "/" } });
    };

    return (
      <div onClick={onClick} data-id={ticket.id} data-priority={ticket.priorityIndex} className="tasks-board-item">
        <div className="mb-1">
          <Badge bg="primary" className="status-span">
            {status}
          </Badge>
          <span className="text-span">{ticket.property.address}</span>
        </div>
        <div className="d-flex gap-1 justify-content-between">
          {
            ticket.dueDateOverride || ticket.ticketGroupDueDate ?
              <div className="d-flex">
                <Calendar size={14} />
                <Badge title="Due Date" bg="light" className="text-dark">
                  {getDateTime(ticket.dueDateOverride || ticket.ticketGroupDueDate)}
                </Badge>
              </div>
              :
              <></>
          }
          {
            ticket.internalDueDate ?
              <div className="d-flex">
                <TrendingDown size={14} />
                <Badge title="Internal Due Date" bg="light" className="text-dark">
                  {getDateTime(ticket.internalDueDate)}
                </Badge>
              </div>
              :
              <></>
          }
        </div>
      </div>
    );
  }

  return (
    data && data.length > 0 ? (
      <div className="row">
        {
          data.filter(item => !employeeIds || employeeIds.length === 0 || employeeIds.indexOf(item.user.id) > -1).map((item, index) =>
            <div key={index} className="col-md-2" style={{ marginBottom: 15 }}>
              <div className="card tasks-board-card">
                <div className="card-header tasks-board-card-header">
                  <div>{`${item.user.firstName} ${item.user.lastName}`}</div>
                </div>
                <div ref={(el) => boardsRefs.current[index] = el} className="tasks-board-items">
                  {
                    item.tickets.map(ticket =>
                      <TaskItem key={ticket.id} ticket={ticket} />
                    )
                  }
                </div>
              </div>
            </div>
          )
        }
      </div >
    )
    :
    (
      <div className="alert alert-warning p-3">No data</div>
    )

  );
};

export default TasksBoardComponent;