import React from "react";

/** @param {{ label?: string } & React.InputHTMLAttributes<HTMLInputElement>} props */
const CustomSwitch = ({ id, label, marginRight = true, ...props }) => (
  <div className={`d-flex ms-1 ${marginRight ? 'me-2 justify-content-end' : ''}`}>
    <span className="custom-switch-span">{label}</span>
    <input id={id} type="checkbox" className="custom-switch-input" {...props} />
    <label htmlFor={id} className="custom-switch-label">
      {label}
    </label>
  </div>
);

export default CustomSwitch;
