import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomNav, { CustomNavItem } from "../../components/CustomNav";
import useAppSelector from "../../hooks/useAppSelector";

const StepsCustomNav = ({
  ticketSteps = [],
  stepsActiveKey,
  setStepsActiveTabKey,
  disabled,
}) => {
  const usersMap = useAppSelector(state => state.appData.usersMap);

  return (
    <div
      className="my-2"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gridGap: 12,
      }}
    >
      <CustomNav
        name="steps"
        activeKey={stepsActiveKey}
        onActiveChange={setStepsActiveTabKey}
      >
        {ticketSteps.map((step) => (
          <CustomNavItem
            key={step.ticketStepTypeId}
            eventKey={step.ticketStepTypeId}
            disabled={disabled}
            className="flex-column justify-content-evenly h-100 step-nav-button"
          >
            <span>Step {step.ticketStepTypeId}</span>
            <span className="d-flex justify-content-between align-items-center h-100">
              {!!step.assignedToId && !!step.completedDate && (
                <div
                  className={`me-1 rounded-circle border ${
                    stepsActiveKey === step.ticketStepTypeId
                      ? "bg-white text-secondary border-secondary"
                      : "border-dark"
                  }`}
                  style={{ height: 20, width: 20 }}
                >
                  <FontAwesomeIcon icon={faCheck} height={8} width={8} />
                </div>
              )}
              <small>{usersMap[step.assignedToId]?.firstName || ""}</small>
            </span>
            {/* <small>{!!step.completedDate && "Done "} {step.assignedTo?.firstName || ""}</small> */}
          </CustomNavItem>
        ))}
      </CustomNav>
    </div>
  );
};

export default StepsCustomNav;
