import React from "react"
import { columns } from "./columns"
import DataTable from "react-data-table-component"

const InspectorInspections = ({ data }) => {
    return (
        <DataTable
            style={{ height: 670, overflow: "auto" }}
            noHeader
            className="react-dataTable"
            columns={columns}
            data={data}
        />)
}

export default InspectorInspections