import React, { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";

import { AuthProvider } from "./contexts/JWTContext";
import { DragProvider } from "./contexts/DragContext";
import { UtilityProvider } from "./contexts/UtilityContext";
import { TicketRouteProvider } from "./contexts/TicketRouteContext";

const App = () => {
  const location = useLocation();
  const content = useRoutes(routes);

  useEffect(() => {
    document.body.id =
      location.pathname
        .replace(/[0-9]/g, "")
        .split("/")
        .filter(Boolean)
        .join("-") || "dashboard";
  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | ValuFlow" defaultTitle="ValuFlow" />
      <Provider store={store}>
        <ThemeProvider>
          <SidebarProvider>
            <DragProvider>
              <LayoutProvider>
                <UtilityProvider>
                  <TicketRouteProvider>
                    <ChartJsDefaults />
                    <AuthProvider>{content}</AuthProvider>
                  </TicketRouteProvider>
                </UtilityProvider>
              </LayoutProvider>
            </DragProvider>
          </SidebarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
