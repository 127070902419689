import React, { useState, useEffect } from "react";
import CustomNavItem from "./CustomNavItem";

/**
 * @type {React.FC<{
 *  name: string;
 *  defaultActiveKey?: string;
 *  activeKey?: string;
 *  itemCommonProps?: Parameters<typeof CustomNavItem>[0];
 *  onActiveChange?: (activeKey: string) => void;
 * }>}
 */
const CustomNav = ({
  name = "custom-nav",
  children,
  defaultActiveKey,
  activeKey,
  itemCommonProps = {},
  onActiveChange = () => {},
}) => {
  const [activeKeyLocal, setActiveKeyLocal] = useState(defaultActiveKey);

  useEffect(() => {
    if (!activeKey) {
      return;
    }
    setActiveKeyLocal(activeKey);
  }, [activeKey]);

  const handleActiveChange = (eventKey) => {
    onActiveChange(eventKey);
    if (activeKey) {
      return;
    }
    setActiveKeyLocal(eventKey);
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          id: `${name}-${child.props.eventKey}`,
          active: child.props.eventKey === activeKeyLocal,
          onClick: (...args) => {
            child.props.onClick && child.props.onClick(...args);
            handleActiveChange(child.props.eventKey);
          },
          ...itemCommonProps,
        })
      )}
    </>
  );
};

export default CustomNav;

export { CustomNavItem };
