import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { ListGroup } from "react-bootstrap";
import { Check, Minus, Plus } from "react-feather";
import CustomMarker from "../../components/CutomMarker";
import { TicketRouteContext } from "../../contexts/TicketRouteContext";
import { useNavigate } from "react-router-dom";
import useContextMenu from "../../hooks/useContextMenu";

const TicketMapView = ({ ticketGroups }) => {
  const { addTicket, removeTicket, isTicketOnRoute } = useContext(TicketRouteContext);

  const [maps, setMaps] = useState();
  const [mapCenter, setMapCenter] = useState();
  const [zoom, setZoom] = useState(14);
  const [selectedTicket, setSelectedTicket] = useState();
  const mapRef = useRef();
  /** @type {React.MutableRefObject<GoogleMapReact.Maps} */
  const mapsRef = useRef();
  // const markersRef = useRef([]);
  const mapWrapperRef = useRef();
  const markerRefs = useRef([]);
  const navigate = useNavigate();

  const { anchorPoint, show } = useContextMenu(event => {
    const marker = markerRefs.current.find(item => item.ref?.contains(event.target))

    setSelectedTicket(marker?.ticket);

    return !!marker
  });

  const tickets = useMemo(
    () => ticketGroups.map((item) => item.tickets).flat().filter(x => x.property.lat != 0 && x.property.lng != 0),
    [ticketGroups]
  );

  useEffect(() => {
    // for (const item of markersRef.current) {
    //   if (item?.marker?.setMap) {
    //     item.marker.setMap(null);
    //   }
    // }

    if (!tickets.length || !maps) {
      return;
    }

    centerMapByTickets(
      tickets.map((ticket) => ({
        lat: ticket.property.lat,
        lng: ticket.property.lng,
        ticketId: ticket.id,
        title: ticket.property.address,
      }))
    );
  }, [tickets, maps]);

  const onGoogleApiLoaded = (map, maps) => {
    mapRef.current = map;
    mapsRef.current = maps;
    setMaps(maps);
  };

  const centerMapByTickets = (newMarkers) => {
    try {
      const bounds = new mapsRef.current.LatLngBounds();
      // const newMarkersState = [];

      for (const { ticketId, lat, lng, title } of newMarkers) {
        // const newMarker = new mapsRef.current.Marker({
        //   position: {
        //     lat,
        //     lng,
        //   },
        //   map: mapRef.current,
        //   title,
        // });
        // newMarkersState.push({
        //   ticketId,
        //   lat,
        //   lng,
        //   marker: newMarker,
        // });
        bounds.extend(new mapsRef.current.LatLng(lat, lng));
      }
      if (newMarkers.length === 1) {
        setMapCenter({
          lat: newMarkers[0].lat,
          lng: newMarkers[0].lng,
        });
        setZoom(14);
      } else {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        // const nw = { lat: ne.lat(), lng: sw.lng() };
        // const se = { lat: sw.lat(), lng: ne.lng() };

        const mapWrapperRect = mapWrapperRef.current?.getBoundingClientRect();

        const { center, zoom } = fitBounds(
          {
            ne: { lat: ne.lat(), lng: ne.lng() }, // se,
            sw: { lat: sw.lat(), lng: sw.lng() }, // nw,
          },
          {
            width: mapWrapperRect?.width || 640,
            height: mapWrapperRect?.height || 380,
          }
        );
        setMapCenter(center);
        zoom !== 1 && setZoom(zoom - 1);
      }
      // markersRef.current = newMarkersState;
    } catch (err) {
      console.error("render marker error", err);
    }
  };

  const handleMarkerRef = (markerRef, ticket) => {
    markerRefs.current.push({
      ref: markerRef,
      ticket,
    });
  };

  return (
    <div ref={mapWrapperRef} style={{ height: "calc(100vh - 240px)" }}>
      {show && (
        <ListGroup
          className="shadow position-absolute"
          style={{
            top: anchorPoint.y,
            left: anchorPoint.x,
            zIndex: 999999999999999n,
            minWidth: 128,
          }}
        >
          {!isTicketOnRoute(selectedTicket.id) &&
            <ListGroup.Item
              action
              onClick={() => addTicket(selectedTicket)}>
              <span>
                <Plus size={12} className="mb-1" /> Add to route
              </span>
            </ListGroup.Item>}
          {isTicketOnRoute(selectedTicket.id) && <ListGroup.Item
            action
            onClick={() => removeTicket(selectedTicket.id)}
          >
            <span>
              <Minus size={12} className="mb-1" /> Remove from route
            </span>
          </ListGroup.Item>}
        </ListGroup>
      )}
      <GoogleMapReact
        options={(maps) => ({
          fullscreenControl: true,
          mapTypeControl: true,
          mapTypeId: maps.MapTypeId.ROADMAP,
          scaleControl: true,
          scrollwheel: false,
          streetViewControl: true,
        })}
        bootstrapURLKeys={{
          key: "AIzaSyA-aWrwgr64q4b3TEZwQ0lkHI4lZK-moM4",
        }}
        defaultCenter={{
          lat: 40.712784,
          lng: -74.005941,
        }}
        center={mapCenter}
        onChange={({ center, zoom }) => {
          mapCenter && setMapCenter(center);
          setZoom(zoom);
        }}
        defaultZoom={14}
        zoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
      >
        {tickets.map((ticket) => (
          <CustomMarker
            ref={(ref) => handleMarkerRef(ref, ticket)}
            key={ticket.id}
            lat={ticket.property.lat}
            lng={ticket.property.lng}
            onClick={() =>
              !show &&
              navigate(`/dashboard/job/${ticket.ticketGroupId}/${ticket.id}`, {
                state: { from: "/" },
              })
            }
            title={
              <>
                {isTicketOnRoute(ticket.id) && (
                  <Check size={12} color="#07bc0c" className="me-1" />
                )}
                {`${ticket.property.houseNumber || ""} ${ticket.property.street || ""
                  }`.trim()}
              </>
            }
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default TicketMapView;
