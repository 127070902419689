import axios from "../utils/axios";

export function registerUser(user) {
  return axios.post("/auth/register", user);
}

export function addUserToRoles(email, roles) {
  return axios.post(`/User/AddUserToRole?${new URLSearchParams({ email })}`, roles);
}

export function changePassword(currentPassword, newPassword) {
  return axios.post("/auth/ChangePassword", { currentPassword, newPassword });
}

export function updateUser(userToUpdate) {
  return axios.post("/User/UpdateUser", userToUpdate);
}

export function removeUserRoles(email) {
  return axios.post(`/User/RemoveUserRoles?email=${email}`);
}