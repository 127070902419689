import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { Container, Card, Col, Form, Row, Button, OverlayTrigger, ButtonGroup, Tooltip, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import LoadingWrapper from "../../../components/LoadingWrapper";
import GoogleAutoComplete from "../Tickets/PropertyInfo/GoogleAutoComplete";
import CompPopup from "./CompPopup";
import CompTypeFilter from "./CompTypeFilter";
import CompCard from "./CompCard";
import CustomCard from "../../../components/CustomCard";
import CompsMap from "./CompsMap";
import SearchByField from "./SearchByField";
import { exportComps, filterComps, getRelatedComps } from "../../../services/comp";
import { loadCompTypes, setCompFilters } from "../../../redux/actions/comps";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import useOuterClick from '../../../hooks/useOuterClick';
import compLabels from "../Comps/compTableLabels";
import debounce from "lodash/debounce";
import Overlay from 'react-bootstrap/Overlay';
import { getFormattedDateTimeStr, numberWithCommas } from "../../../utils";

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import './filters.css';
import compFields from "../Comps/compFields";
import BetweenIcon from "./BetweenIcon";
import { startCase } from "lodash";
// import { numberWithCommas } from "../../../utils";
import { toast } from "react-toastify";
import SearchByAddress from "./SearchByAddress";
import compFieldTypes from "../Comps/compFieldTypes";
import CompPopupSmall from "./CompPopupSmall";


const inputType = {
  string: "text",
  boolean: "checkbox",
  date: "date",
  number: "number",
};


const Filters = () => {
  const dispatch = useAppDispatch();

  const compTypesMap = useAppSelector((state) => state.comps.compTypesMap);
  const compTypesRef = useRef();
  compTypesRef.current = compTypesMap;

  const loading = useAppSelector((state) => state.comps.loadingComps);

  const searchFilters = useAppSelector((state) => state.comps.compFilters);
  // const [isCtrlOrCmdPressed, setIsCtrlOrCmdPressed] = useState(false);
  // const isCtrlOrCmdPressedRef = useRef(isCtrlOrCmdPressed);

  /**
   * @typedef {import("../../../services/comp").Comp} Comp
   * @type {[Array<Comp>, React.Dispatch<React.SetStateAction<Array<Comp>>>]}
   */
  const [filteredComps, setFilteredComps] = useState([]);
  /** @type {[Record<string, Comp>, React.Dispatch<React.SetStateAction<Record<string, Comp>>>]} */
  const [selectedComps, setSelectedComps] = useState([]);
  const [relatedComps, setRelatedComps] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  // const [filterCompsLoading, setFilterCompsLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [searchAddress, setSearchAddress] = useState("");
  /** @type {[Comp, React.Dispatch<React.SetStateAction<Comp>>]} */
  const [popupComp, setPopupComp] = useState({});
  const [tooltipComp, setTooltipComp] = useState({})
  const tooltipCompRef = useRef(tooltipComp);

  const [showPopup, setShowPopup] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);

  const [filterNodeSearch, setFilterNodeSearch] = useState();
  const [filteredFields, setFilteredFields] = useState([]);

  const overlayRef = useRef();
  const [showOverlay, setShowOverlay] = useState(false);

  const [filtersVisible, setFiltersVisible] = useState(false);

  // ag grid
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const perPage = 100;

  const filtersRef = useRef();
  filtersRef.current = searchFilters;

  // const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState('1');

  const radios = [
    { name: 'Grid', value: '1' },
    { name: 'Map', value: '2' },
  ];

  useEffect(() => {
    const init = async () => {
      const filters = { ...searchFilters };
      if (
        filters.searchWithin.radius &&
        !(filters.searchWithin.fromLat && filters.searchWithin.fromLng)
      ) {
        const getLocation = () =>
          new Promise((resolve, reject) => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((position) => {
                resolve(position.coords);
              });
            } else {
              reject();
            }
          });
        try {
          const location = await getLocation();
          filters.searchWithin.fromLat = location.latitude;
          filters.searchWithin.fromLng = location.longitude;
          //dispatch(setCompFilters(filters));
        } catch (err) { }
      }
      //getCompsByFilters(filters);
    };

    init();

    // const handleKeyDown = (event) => {
    //   if (event.ctrlKey || event.metaKey) {
    //     console.log(event.ctrlKey, event.metaKey);
    //     //setIsCtrlOrCmdPressed(true);
    //     //isCtrlOrCmdPressedRef.current = true;
    //   }
    // };

    // const handleKeyUp = () => {
    //   setIsCtrlOrCmdPressed(false);
    //   isCtrlOrCmdPressedRef.current = false;
    // };

    const handleMouseMove = (event) => {

      if (loading) {
        return;
      }

      if (tooltipCompRef.current == null || tooltipCompRef.current == {}) {
        return;
      }

      if (showPopup || popupComp) {
        return;
      }

      if (radioValue == "2") {
        return;
      }

      if (Object.keys(tooltipCompRef.current).length > 0) {
        return;
      }

      setGlobalMousePos({
        x: event.clientX,
        y: event.clientY,
      });
    };

    const handleClick = (e) => {
      if (overlayRef.current == e.target) {
        return;
      }

      if (e.target.classList.length != 0 && e.target.classList.contains('tooltip-toggler')) {
        return;
      }

      const el = document.getElementsByClassName('filter-tooltip')[0]
      if (el && el.contains(e.target)) {
        return;
      }

      setShowOverlay(false);
    }

    // document.addEventListener('keydown', handleKeyDown);
    // document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener(
        'mousemove',
        handleMouseMove
      );
      // document.removeEventListener('keydown', handleKeyDown);
      // document.removeEventListener('keyup', handleKeyUp);
    }
  }, []);

  useEffect(() => {
    // if (!filterNodeSearch) {
    //   setFilterNodeSearch('')
    //   return;
    // }

    const data = [...compFields];

    data.push({ name: 'compAddress.fullAddress', type: 'string' });
    data.push({ name: 'compAddress.city', type: 'string' });
    data.push({ name: 'compAddress.state', type: 'string' });
    data.push({ name: 'compAddress.zip', type: 'string' });
    data.push({ name: 'compAddress.streetNumber', type: 'string' });
    data.push({ name: 'compAddress.county', type: 'string' });
    data.push({ name: 'compAddress.route', type: 'string' });

    let filtered = [];
    if (filterNodeSearch && filterNodeSearch.length > 0) {
      filtered = data.filter(x => x.name.toLowerCase().includes(filterNodeSearch?.replaceAll(' ', '').toLowerCase()));
    }
    else {
      filtered = data.map(x => ({ ...x }));
    }

    setFilteredFields(filtered);
  }, [filterNodeSearch])

  useEffect(() => {
    if (!searchFilters) {
      return;
    }
    let filters = { ...searchFilters, ...searchFilters.searchWithin };
    delete filters.searchWithin;

    setFiltersVisible(Object.values(filters).some(x => !x));
  }, [searchFilters])


  useEffect(() => {
    if (!gridApi) {
      return;
    }

    const dataSource = {
      getRows: (params) => {
        gridApi.showLoadingOverlay();
        let body = { ...searchFilters };

        if (params.sortModel.length != 0) {
          body.sortProperty = params.sortModel[0].colId;
          body.sortOrder = params.sortModel[0].sort;
        }

        if (Object.keys(body).some(x => x.startsWith("compAddress."))) {
          body.compAddress = {};
          for (let key of Object.keys(body)) {
            if (key.startsWith('compAddress.')) {
              body.compAddress[key.split('.')[1]] = body[key].value;
              delete body[key];
            }
          }
        }
        const page = params.endRow / perPage;
        filterComps(body, page - 1, perPage).then(resp => {
          params.successCallback(resp.data, resp.totalCount);
          setFilteredComps(resp.data);
        }).catch(err => {
          params.successCallback([], 0);
        });

        autoSizeAll(true);
        gridApi.hideOverlay();
      }
    }

    gridApi.setDatasource(dataSource);

    if (!searchFilters) {
      return;
    }


    const columns = [
      {
        field: '',
        width: '50',
        maxWidth: '50',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: false,
      }
    ];

    for (let prop of Object.keys(searchFilters)) {
      if (prop == "searchWithin" || prop == "compTypeIds") {
        continue;
      }

      let newColumn = { field: prop, filter: true, headerName: compLabels[prop] };
      columns.push(newColumn);
    }

    for (let rest of Object.keys(compLabels)) {
      if (columns.find(x => x.field == rest)) {
        continue;
      }

      let newColumn = { field: rest, filter: true, headerName: compLabels[rest] }
      columns.push(newColumn);
    }

    for (let column of columns) {
      if (column.field.toLowerCase().includes('date') || column.field.toLowerCase().includes('lease')) {
        column.valueGetter = params => {
          if (params.data && params.data.hasOwnProperty(column.field)) {
            return params.data[column.field] ? getFormattedDateTimeStr(params.data[column.field], { short: true }) : "N/A";
          }
          return ''
        }
      }

      if (column.field.toLowerCase().includes('price')) {
        column.valueGetter = params => {
          if (params.data && params.data.hasOwnProperty(column.field)) {
            const val = numberWithCommas(params.data[column.field]);
            return val == "N/A" ? 'N/A' : `$${val}`;
          }
          return ''
        }
      }

      if (compFieldTypes[column.field] == 'number' && !column.field.toLowerCase().includes('price') && !column.field.toLocaleLowerCase().includes('year')) {
        column.valueGetter = params => {
          if (params.data && params.data.hasOwnProperty(column.field)) {
            const val = numberWithCommas(params.data[column.field]);
            return val;
          }
        }
      }

      if (column.field.toLowerCase() == "compaddress") {
        column.valueGetter = params => {
          if (params.data && params.data.compAddress) {
            return params.data.compAddress.fullAddress.split(`, ${params.data.compAddress.city}`)[0];
          }

          return '';
        }
      }

      if (column.field.toLocaleLowerCase() == "comptypeid") {
        column.valueGetter = params => {
          if (params && params.data && params.data.compTypeId && params.data.compTypeId != 0) {
            return compTypesRef.current[params.data.compTypeId]?.name;
          }

          return '';
        }
      }

      if (!columns.find(x => x.field == column.field) && column.field.toLowerCase() == "city") {
        column.field = 'compAddress.city';
      }

      if (column.field.toLowerCase() == "state") {
        column.field = 'compAddress.state';
      }

      if (column.field.toLowerCase() == "zip") {
        column.field = 'compAddress.zip';
      }

      if (column.field.toLowerCase() == "county") {
        column.field = 'compAddress.county';
      }

      if (column.field != '') {
        column.minWidth = 100;
        column.maxWidth = 300;
        column.resizable = true;
        column.sortable = true;
        column.filter = false;
      }
    }

    setColumnDefs(columns);
  }, [gridApi, searchFilters]);

  const getCompsByFilters = useCallback(
    ({ keyword, searchWithin, ...rest }, pageIndex, pageSize) => {
      const filters = { keyword, ...rest };
      if (
        searchWithin &&
        (searchWithin.fromLat || searchWithin.fromLng) &&
        searchWithin.radius
      ) {
        filters.searchWithin = searchWithin;
      }
      gridApi?.purgeInfiniteCache();
      //setFilterCompsLoading(true);
      // filterComps(filters, pageIndex, pageSize)
      //   .then((res ) => setFilteredComps())
      //   .finally(() => {
      //     setFilterCompsLoading(false);
      //     setFirstTime(false);
      //   });
    },
    []
  );

  const handleSearchKeywordChange = (e) => {
    handleFilterChange({ keyword: e.target.value }, true);
  };

  const handleCompTypeFilterChange = (compTypeIds) => {
    if (compTypeIds.length == 0) {
      handleFilterChange({ compTypeIds: null });
      return;
    }

    handleFilterChange({ compTypeIds });
  };

  const handleRadiusChange = (e) => {
    handleFilterChange(
      {
        searchWithin: {
          ...searchFilters.searchWithin,
          radius: Number(e.target.value),
        },
      },
      true
    );
  };

  const handleAddressSelect = (location) => {
    if (!location) {
      return;
    }

    dispatch(setCompFilters({
      ...filtersRef.current,
      searchWithin: {
        ...filtersRef.current.searchWithin,
        fromLat: location.lat,
        fromLng: location.lng,
      },
    }));
    setSearchAddress(location.address);

  };

  const handleSelectedCompsChange = (id, checked) => {
    if (checked) {
      //const comp = filteredComps.find((item) => item.id === id);
      //setSelectedComps({ ...selectedComps, [id]: comp });
    } else {
      // delete selectedComps[id];
      const selectedAGGridData = gridRef.current.api.getSelectedNodes();
      const item = selectedAGGridData.find(x => x.data.id == id);
      item.setSelected(checked);

      setSelectedComps([...selectedComps.filter(x => x.id != id)]);
    }
  };

  const addToExport = (compId) => {
    const rowNode = gridApi.getRowNode(String(compId));
    rowNode.setSelected(true);

    const comp = filteredComps.find(x => x.id == compId);

    if (!selectedComps.find(x => x.id == compId)) {
      setSelectedComps(old => [...old, comp]);
    }
  }

  const handleExport = () => {
    setExportLoading(true);
    exportComps(
      selectedComps.map((x) => {
        return { id: x.id }
      }),
      "file"
    ).catch(x => {
    }).finally(() => setExportLoading(false));
  };

  const handleCompCardClick = async (comp) => {
    const compPlaceId = comp.compAddress.placeId;
    const relatedComps = await getRelatedComps(compPlaceId);
    setRelatedComps(relatedComps.filter(x => x.id != comp.id));

    setPopupComp(comp);
    setShowPopup(true);

    setTooltipComp({});
    tooltipCompRef.current = {};
  };

  // const renderMessages = () => {
  //   if (firstTime) {
  //     return (
  //       <span className="text-center">Please select filters and apply...</span>
  //     );
  //   }

  //   if (Object.keys(searchFilters).length > 0 && filteredComps.length == 0) {
  //     return <span className="text-center">No comps were found.</span>;
  //   }
  // };

  const renderCompTypeFilters = () => {
    if (searchFilters.compTypeIds && searchFilters.compTypeIds.length > 0) {
      const values = searchFilters.compTypeIds.map(
        (x) => compTypesMap[x]?.name
      );
      return (
        <div
          className="d-flex align-items-center position-relative text-white comp"
          style={{ whiteSpace: "nowrap" }}
        >
          Comp Types: <span className="fw-light ms-1">{values.join(", ")}</span>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => handleFilterRemove("compTypeIds")}
          ></button>
        </div>
      );
    }
  };

  const renderSearchWithinFilter = () => {
    if (searchFilters.searchWithin &&
      searchFilters.searchWithin.radius &&
      searchFilters.searchWithin.radius != 0 &&
      searchFilters.searchWithin.fromLat &&
      searchFilters.searchWithin.fromLng) {
      return (
        <div
          className="d-flex align-items-center position-relative text-white comp"
          style={{ whiteSpace: "nowrap" }}
        >
          <span className="fw-light ms-1">
            Within {searchFilters.searchWithin.radius} miles from{" "}
            <i>{searchAddress}</i>
          </span>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => handleFilterRemove("searchWithin")}
          ></button>
        </div>
      );
    }
  };

  const renderKeywordSearchFilter = () => {
    if (searchFilters.keyword && searchFilters.keyword.length != 0) {
      return (
        <div
          className="d-flex align-items-center position-relative text-white comp"
          style={{ whiteSpace: "nowrap" }}
        >
          <span className="fw-light ms-1">
            Keyword: {searchFilters.keyword}
          </span>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => handleFilterRemove("keyword")}
          ></button>
        </div>
      );
    }
  };

  const renderFilterType = (type) => {
    switch (type) {
      case 1:
        return "Less than";
      case 2:
        return "Greater than";
      case 3:
        return "Equals";
      case 4:
        return "Contains";
      // case 5:
      //   return "Exact match";
    }
  };

  const handleFilterRemove = (key) => {
    const newFilters = { ...searchFilters };
    //const newFilteredFields = filteredFields.filter(x => x.name != key);
    delete newFilters[key];

    if (key === "searchWithin") {
      newFilters[key] = { radius: 0, lat: 0, lng: 0 };
      setSearchAddress("");
    }

    dispatch(setCompFilters({ ...newFilters }));
    //setFilteredFields([...newFilteredFields]);
    //getCompsByFilters({ ...newFilters });

  };

  // const getCompsByFiltersDebounce = useCallback(
  //   debounce(getCompsByFilters, 1000),
  //   [getCompsByFilters]
  // );

  const handleFilterChange = (filters, debounce = false) => {
    const newFilters = { ...searchFilters, ...filters };
    dispatch(setCompFilters(newFilters));

    // if (debounce) {
    //   getCompsByFiltersDebounce(newFilters);
    // } else {
    //   getCompsByFilters(newFilters);
    // }
  };

  const handleCompDelete = () => {
    setShowPopup(false);
    setFilteredComps((oldValue) =>
      oldValue.filter((item) => item.id !== popupComp.id)
    );
  };

  const handleFiltersChange = (
    name,
    value,
    option,
    secondValue,
    debounce = true
  ) => {
    const filter = {
      type: Number(option),
    };
    if (filter.type === 6) {
      filter.from = value;

      if (!secondValue) {
        handleFilterChange({ [name]: filter }, debounce);
        return;
      }

      filter.to = secondValue;
    } else {
      filter.value = value;
    }

    if ((!value || value == '') && option == 3) {
      handleFilterRemove(name);
    }
    else {
      handleFilterChange({ [name]: filter }, debounce);
    }
  };

  const handleValueChange = (name) => (e) => {
    let value = e.target.value;
    if (compFieldTypes[name] == 'boolean') {
      value = e.target.checked;
    }

    handleFiltersChange(
      name,
      value,
      searchFilters[name]?.type || 3,
      searchFilters[name]?.to
    );
  };

  const handleOptionChange = (name) => (e) => {
    const value = e.target.value;
    // console.log(name, value, getValueByOption(name, value));

    // console.log(searchFilters);

    // // if (getValueByOption(name, value) === undefined) {
    // //   handleFiltersChange(name, '', value, null, false);
    // // }

    // //if (getValueByOption(name, value)) {
    handleFiltersChange(
      name,
      getValueByOption(name, value) ?? '',
      value,
      searchFilters[name]?.to,
      false
    );
    //}
  };

  const handleSecondValueChange = (name) => (e) => {
    handleFiltersChange(
      name,
      getValueByOption(name),
      searchFilters[name]?.type || 3,
      e.target.value
    );
  };

  const getValueByOption = (name, option) =>
    (option || searchFilters[name]?.type) === 6
      ? searchFilters[name]?.from
      : searchFilters[name]?.value;

  // ag grid stuff

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedNodes();

    if (selectedRows.length == 0) {
      setSelectedComps([]);
      return;
    }

    const data = selectedRows.map(x => x.data);

    if (!data || data.length == 0) {
      return;
    }

    const selectedCompTypeId = data[0].compTypeId;
    // if (data.find(x => x.compTypeId != selectedCompTypeId)) {
    //   const toUnselect = selectedRows.filter(x => x.data.compTypeId != selectedCompTypeId);
    //   for (let toUn of toUnselect) {
    //     toUn.setSelected(false);
    //   }
    //   toast.error("Cannot have multiple comps with different comp types selected for export");
    // }

    setSelectedComps(data.filter(x => x.compTypeId == selectedCompTypeId));
  }, [])

  const onRowClicked = useCallback((e) => {

    if (e.colDef.field.startsWith("compAddress")) {
      const selectedRows = gridRef.current.api.getSelectedRows();
      handleCompCardClick(selectedRows[selectedRows.length - 1]);
    }
    // console.log(isCtrlOrCmdPressedRef.current);
    // if (isCtrlOrCmdPressedRef.current) {
    //   return;
    // }

    // const selectedRows = gridRef.current.api.getSelectedRows();
    // handleCompCardClick(selectedRows[selectedRows.length - 1]);
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);


  // end 

  const [globalMousePos, setGlobalMousePos] = useState({});
  const [cellTimeout, setCellTimeout] = useState();

  const showCellPopup = (params) => {
    const node = params.data;
    console.log(params);
    if (params.colDef.headerCheckboxSelection) {
      return;
    }
    
    // if (isCtrlOrCmdPressedRef.current) {
    //   return;
    // }

    setCellTimeout(setTimeout(() => {
      setTooltipComp(node);
      tooltipCompRef.current = node;
    }, 800));
  }

  const isRowSelectable = useCallback((row) => {
    const compTypeId = row.data.compTypeId;
    if (selectedComps.length === 0) {
      return true;
    }
    return selectedComps[0].compTypeId === compTypeId;
  }, [selectedComps]);

  const removeCellTimeout = (params) => {
    if (params && params.data && tooltipCompRef.current && params.data.id != tooltipCompRef.current?.id) {
      setTooltipComp({});
      tooltipCompRef.current = {};
    }

    clearTimeout(cellTimeout);
    setCellTimeout(null);
  }

  return (
    <LoadingWrapper loading={exportLoading}>
      <CompPopup
        comp={popupComp}
        show={showPopup}
        onHide={() => setShowPopup(false)}
        onDelete={handleCompDelete}
        otherComps={relatedComps}
        onAddToExport={addToExport}
      />
      <Container fluid className="p-0 comps-container">

        <Row className="me-0 ms-0 mt-2">
          <Card className="d-flex flex-row py-2" style={{ overflowX: 'scroll' }}>
            <CompTypeFilter
              handleCompTypeFilterChange={handleCompTypeFilterChange}
            />

            <SearchByAddress
              searchFilters={searchFilters}
              handleRadiusChange={handleRadiusChange}
              searchAddress={searchAddress}
              setSearchAddress={setSearchAddress}
              handleAddressSelect={handleAddressSelect} />
            {/* <CustomCard title="Search Parameters" className="me-1">
              <div className="d-flex flex-wrap align-items-center text-dark">
                <span>Within</span>
                <Form.Control
                  type="number"
                  min="1"
                  className="custom-inp"
                  value={searchFilters.searchWithin?.radius}
                  onChange={handleRadiusChange}
                />
                <span>Miles Radius of:</span>
              </div>
              <Form.Group className="mb-2" controlId="addressDetails">
                <GoogleAutoComplete
                  disableUnit
                  name="fullAddress"
                  value={searchAddress}
                  onChange={(e) => setSearchAddress(e.target.value)}
                  onLocationSelect={handleAddressSelect}
                />
              </Form.Group>
            </CustomCard> */}

            {/* <CustomCard title="Keyword Search">
              <Form.Control
                type="text"
                placeholder="Start typing...."
                value={searchFilters.keywrd || ""}
                onChange={handleSearchKeywordChange}
              />
            </CustomCard> */}

            <div className="border-0 border-bottom border-right px-2" style={{ minWidth: 200 }}>
              <div className="d-flex flex-wrap text-dark mb-1">
                <span>Filter by Field</span>
              </div>

              <Overlay
                target={overlayRef.current}
                show={showOverlay}
                placement="bottom">

                {(props) => (
                  <Tooltip {...props} className="filter-tooltip border-1 border rounded">
                    {filteredFields.map(field => (
                      <div className="d-flex flex-column mb-1" key={field.name}>
                        <span>{compLabels[field.name] ?? startCase(field.name)}</span>
                        <div className="d-flex justify-content-between align-items-center">
                          {field.type == 'boolean' ?
                            <Form.Check checked={searchFilters[field.name]?.value} onChange={handleValueChange(field.name)}></Form.Check> :
                            (<div className="d-flex flex-fill">
                              <Form.Control className="me-1 tooltip-node"
                                size="sm"
                                value={(searchFilters[field.name]?.type === 6 ? searchFilters[field.name]?.from : searchFilters[field.name]?.value) || ""}
                                onChange={handleValueChange(field.name)}
                                type={inputType[field.type]} />
                              {searchFilters[field.name]?.type == "6" && (
                                <Form.Control
                                  size="sm"
                                  type={inputType[field.type]}
                                  value={searchFilters[field.name]?.to || ""}
                                  onChange={handleSecondValueChange(field.name)}
                                  className="me-1 tooltip-node"
                                />
                              )}
                            </div>)
                          }

                          {inputType[field.type] != 'checkbox' && inputType[field.type] != 'text' &&
                            <Form.Select className="me-1 tooltip-node" id={`footage-${field.name}`} size="sm" onChange={handleOptionChange(field.name)} value={String(searchFilters[field.name]?.type || 3)}>
                              <option value={3}>&#61;</option>
                              <option value={1}>&#60;</option>
                              <option value={2}>&#62;</option>
                              <option value={6}>{'<=>'}</option>
                            </Form.Select>}

                          <button type="button" className="btn-close" aria-label="Close" onClick={() => handleFilterRemove(field.name)}></button>
                        </div>
                      </div>
                    ))}
                  </Tooltip>
                )}
              </Overlay>
              <Form.Control ref={overlayRef} className="tootip-toggler" onFocus={(e) => { setShowOverlay(true); }} type="text" value={filterNodeSearch} onChange={e => setFilterNodeSearch(e.target.value)}></Form.Control>
            </div>

            <div className="border-0 border-bottom d-flex flex-row justify-content-center" style={{ minWidth: 150 }}>
              <ButtonGroup className="mb-2 ms-3">
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={radioValue === radio.value ? "primary" : "secondary"}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </Card>
        </Row>
        {filtersVisible ? (<Row>
          <div className="d-flex flex-column">
            {Object.keys(searchFilters).length > 0 && (
              <div className="d-flex flex-wrap flex-xl-nowrap align-items-center">
                <Card className="px-2 py-1 mb-0 w-100">
                  <Row>
                    <Col>
                      <div
                        className="selected-items-wrapper d-flex gap-3 pt-1"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {renderCompTypeFilters()}
                        {renderSearchWithinFilter()}
                        {renderKeywordSearchFilter()}
                        {Object.keys(searchFilters)
                          .filter(
                            (x) =>
                              x.toLowerCase() !== "comptypeids" &&
                              x.toLowerCase() != "searchwithin" &&
                              x.toLowerCase() != "keyword" && (searchFilters[x].type == 6 ? (searchFilters[x].from) : searchFilters[x].value)
                          )
                          .map((key) => (
                            <div
                              className="d-flex align-items-center position-relative text-white comp"
                              key={key}
                            >
                              {compLabels[key]}:{" "}
                              <span className="fw-light ms-1">
                                {renderFilterType(searchFilters[key].type)}{" "}
                                {searchFilters[key].type === 6
                                  ? `From ${searchFilters[key].from} to ${searchFilters[key].to ?? 'N/A'}`
                                  : compFieldTypes[key] == 'boolean' ? (searchFilters[key].value ? "Yes" : "No") : searchFilters[key].value}
                              </span>
                              <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={() => handleFilterRemove(key)}
                              ></button>
                            </div>
                          ))}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            )}
          </div>
        </Row>) : null}
        <Row>
          {radioValue == "2" && <CompsMap
            comps={filteredComps}
            full={true}
            compClick={handleCompCardClick}
            style={{
              height: "100%",
              minHeight: !filteredComps.length ? 0 : 500,
              width: "100%",
            }} />}

          <div className="ag-theme-alpine mt-2" style={{ height: '65vh', display: radioValue == "1" ? 'block' : 'none' }}>
            {tooltipComp && Object.keys(tooltipComp).length > 0 && <div style={{ minWidth: 300, maxHeight: 400, maxWidth: 500, position: 'absolute', top: globalMousePos.y - 80, zIndex: 99999, left: globalMousePos.x - 50 }}>
              <CompPopupSmall
                comp={tooltipComp}
                show={true}
                onHide={() => { setTooltipComp({}); tooltipCompRef.current = {}; }}
                onDelete={handleCompDelete}
                onAddToExport={addToExport}
                otherComps={relatedComps}
              />
            </div>}

            <AgGridReact
              pagination={true}
              enableRangeSelection={true}
              rowModelType={'infinite'}
              paginationPageSize={perPage}
              cacheBlockSize={perPage}
              onGridReady={onGridReady}
              ref={gridRef}
              getRowId={params => `${params.data.id}`}
              onSelectionChanged={onSelectionChanged}
              onCellClicked={onRowClicked}
              // onRowClicked={onRowClicked}
              // onRowDoubleClicked={onRowClicked}
              // suppressCellSelection={true}
              onCellMouseOver={params => showCellPopup(params)}
              onCellMouseOut={removeCellTimeout}
              rowSelection={'multiple'}
              suppressRowClickSelection={true}
              // isRowSelectable={isRowSelectable}
              columnDefs={columnDefs}
              animateRows={true}
              overlayLoadingTemplate={
                '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
              }
              overlayNoRowsTemplate={
                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">This is a custom \'no rows\' overlay</span>'
              }
              suppressSizeToFit={true} />
          </div>
        </Row>
        <Row className="m-0 mt-1">
          <Card>
            <div className="d-flex flex-wrap justify-content-between align-items-center border-bottom py-2 px-3">
              <div className="fw-bolder fs-4 text-dark">
                Selected for export
              </div>
              <Form className="d-flex align-items-center">
                <Button
                  type="button"
                  onClick={handleExport}
                  variant="light"
                  className="ms-2"
                  disabled={!Object.keys(selectedComps).length}
                >
                  Export
                </Button>
              </Form>
            </div>

            <div className="selected-items-wrapper d-flex gap-3 p-3">
              {Object.values(selectedComps).map((comp, i) => (
                <CompCard
                  key={i}
                  removable

                  comp={comp}
                  onChange={(value) =>
                    handleSelectedCompsChange(comp.id, value)
                  }
                  onCardClick={() => handleCompCardClick(comp)}
                  compTypeName={compTypesMap[comp.compTypeId]?.name}
                />
              ))}
            </div>
          </Card>
        </Row>
        {/* {selectedComps && selectedComps.length > 0 && <Row className="mt-1">
          <Col md={11}>
            <h5>Selected comps</h5>
            <div className="selected-comps d-flex flex-row">
              {selectedComps.map(x => (
                <Card className="me-1">
                  <div className="d-flex" key={x.id}>
                    {x.compAddress.fullAddress}
                  </div>
                </Card>))}
            </div>
          </Col>
          <Col md={1} className="d-flex flex-row justify-content-around">
            <Button onClick={handleExport}>Export</Button>
          </Col>
        </Row>} */}
      </Container>
    </LoadingWrapper>
  );
};

export default Filters;
