export const getAssignedUser = (ticket, usersMap) => {
  let user = getAssignedUserModel(ticket, usersMap);
  return user?.firstName ?? '';
};

export const getAssignedUserModel = (ticket, usersMap) => {
  if (!ticket.ticketStatuses?.length) {
    return null;
  }

  const allSteps = [...ticket.ticketSteps];
  const step = allSteps.find(x => !x.completedDate);

  let assigned;
  if (step) {
    assigned = usersMap[step.assignedToId];
  }

  if (!assigned || assigned?.length == 0) {
    const statuses = [...ticket.ticketStatuses];
    const currentStatus = statuses.reverse().find(x => !x.completedDate);
    if (currentStatus) {
      assigned = usersMap[currentStatus.assignedToId];
    }
  }

  return assigned;
};

export const getDateTime = (dateTimeStr) =>
  dateTimeStr ? new Date(dateTimeStr).toLocaleDateString() : "-";
