import axios from "../utils/axios";

export function getPortfolios() {
  return axios.get("/Portfolio/GetPortfolios");
}

export function createPortfolio(name) {
  return axios.post("/Portfolio/CreatePortfolio", { name });
}

export function addTicketToPortfolio(ticketId, portfolioId) {
  const queryParams = new URLSearchParams({ ticketId, portfolioId });
  return axios.post(`/Portfolio/AddTicketToPortfolio?${queryParams}`);
}

export function removeTicketFromPortfolio(ticketId, portfolioId) {
  const queryParams = new URLSearchParams({ ticketId, portfolioId });
  return axios.post(`/Portfolio/RemoveTicketFromPortfolio?${queryParams}`);
}
