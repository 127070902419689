/**
 * @typedef {{
 *  id: number;
 *  note: string;
 *  ticketId: number;
 *  created: string;
 *  updated?: string;
 *  userId: string;
 * }} TicketNote
 */
import axios from "../utils/axios";

/**
 * @param {number} ticketId
 * @returns {Promise<Array<TicketNote>>}
 */
export function getTicketNotes(ticketId) {
  const queryParams = new URLSearchParams({ ticketId });
  return axios.get(`/TicketNote/GetTicketNotes?${queryParams}`);
}

/**
 * @param {number} ticketId
 * @param {string} note
 * @returns {Promise<TicketNote>}
 */
export function createTicketNote(ticketId, note) {
  return axios.post("/TicketNote/CreateTicketNote", { ticketId, note });
}

/**
 * @param {number} id
 * @param {string} note
 * @returns {Promise<TicketNote>}
 */
export function updateTicketNote(id, note) {
  return axios.post("/TicketNote/UpdateTicketNote", { id, note });
}

/**
 * @param {number} id
 * @returns {Promise<TicketNote>}
 */
export function deleteTicketNote(id) {
  const queryParams = new URLSearchParams({ id });
  return axios.post(`/TicketNote/DeleteTicketNote?${queryParams}`);
}
