import React from "react";
import { Helmet } from "react-helmet-async";

const ComingSoon = () => (
  <React.Fragment>
    <Helmet title="Coming Soon" />
    <div className="text-center">
      <h1 className="display-1 fw-bold">Coming Soon</h1>
      <p className="h1">Stay tuned!</p>
      <p className="h2 fw-normal mt-3 mb-4">
        We're working to bring something amazing your way.
      </p>
    </div>
  </React.Fragment>
);

export default ComingSoon;
