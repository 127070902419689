import Icon from "./Icon";

/** @type {typeof Icon} */
const CompanyDetails = (props) => (
  <Icon {...props}>
    <path d="M0.241071 0.0669641C0.180804 0.103794 0.103795 0.180803 0.0669643 0.241072C0 0.351562 0 0.425223 0 7.5C0 14.5748 0 14.6484 0.0669643 14.7589C0.103795 14.8192 0.180804 14.8962 0.241071 14.933C0.351562 15 0.425223 15 6.42857 15C12.4319 15 12.5056 15 12.6161 14.933C12.7768 14.8326 12.8571 14.6819 12.8571 14.4643C12.8571 14.2467 12.7768 14.096 12.6161 13.9955C12.5123 13.9319 12.4453 13.9286 11.6116 13.9286H10.7143V12.4955C10.7143 11.1295 10.7109 11.0625 10.6473 10.9554C10.6105 10.8951 10.5335 10.8181 10.4732 10.7812C10.3661 10.7176 10.2991 10.7143 7.5 10.7143C4.70089 10.7143 4.63393 10.7176 4.52679 10.7812C4.46652 10.8181 4.38951 10.8951 4.35268 10.9554C4.28906 11.0625 4.28571 11.1295 4.28571 12.4955V13.9286H2.67857H1.07143V7.60045C1.07143 4.1183 1.07478 1.27232 1.08147 1.27232C1.08482 1.27232 2.6183 1.70759 4.48661 2.2433C6.90737 2.93304 7.93192 3.21429 8.05246 3.21429C8.25 3.21429 8.40737 3.13058 8.50446 2.97321C8.56473 2.87277 8.57143 2.80245 8.57143 2.07924C8.57143 1.46987 8.58147 1.30245 8.61496 1.3125C8.63505 1.32254 9.8404 1.72433 11.2935 2.20647L13.9286 3.08705V8.86607C13.9286 14.5781 13.9286 14.6484 13.9955 14.7589C14.096 14.9196 14.2467 15 14.4643 15C14.6819 15 14.8326 14.9196 14.933 14.7589C15 14.6484 15 14.5748 15 8.57143C15 2.60826 15 2.49107 14.933 2.38728C14.8996 2.32701 14.8326 2.2567 14.7891 2.22656C14.7422 2.19643 13.2388 1.68415 11.4475 1.08817C8.89955 0.237723 8.14955 0 8.01897 0C7.82143 0 7.66406 0.0837049 7.56696 0.241072C7.5067 0.344866 7.5 0.41183 7.5 1.14509C7.5 1.5837 7.49665 1.94196 7.48996 1.94196C7.48661 1.94196 5.95312 1.50335 4.08482 0.970982C1.66406 0.28125 0.639509 0 0.518973 0C0.41183 0 0.311384 0.0234375 0.241071 0.0669641ZM6.96429 12.8571V13.9286H6.16071H5.35714V12.8571V11.7857H6.16071H6.96429V12.8571ZM9.64286 12.8571V13.9286H8.83929H8.03571V12.8571V11.7857H8.83929H9.64286V12.8571Z" />
    <path d="M2.38392 4.35268C2.22321 4.45313 2.14285 4.6038 2.14285 4.82143C2.14285 5.03907 2.22321 5.18974 2.38392 5.29018C2.49442 5.35715 2.56473 5.35715 7.5 5.35715C12.4353 5.35715 12.5056 5.35715 12.6161 5.29018C12.7768 5.18974 12.8571 5.03907 12.8571 4.82143C12.8571 4.6038 12.7768 4.45313 12.6161 4.35268C12.5056 4.28572 12.4353 4.28572 7.5 4.28572C2.56473 4.28572 2.49442 4.28572 2.38392 4.35268Z" />
    <path d="M2.38392 6.49554C2.22321 6.59598 2.14285 6.74665 2.14285 6.96429C2.14285 7.18192 2.22321 7.33259 2.38392 7.43304C2.49442 7.5 2.56473 7.5 7.5 7.5C12.4353 7.5 12.5056 7.5 12.6161 7.43304C12.7768 7.33259 12.8571 7.18192 12.8571 6.96429C12.8571 6.74665 12.7768 6.59598 12.6161 6.49554C12.5056 6.42857 12.4353 6.42857 7.5 6.42857C2.56473 6.42857 2.49442 6.42857 2.38392 6.49554Z" />
    <path d="M2.38392 8.63839C2.22321 8.73883 2.14285 8.8895 2.14285 9.10714C2.14285 9.32477 2.22321 9.47544 2.38392 9.57589C2.49442 9.64285 2.56473 9.64285 7.5 9.64285C12.4353 9.64285 12.5056 9.64285 12.6161 9.57589C12.7768 9.47544 12.8571 9.32477 12.8571 9.10714C12.8571 8.8895 12.7768 8.73883 12.6161 8.63839C12.5056 8.57142 12.4353 8.57142 7.5 8.57142C2.56473 8.57142 2.49442 8.57142 2.38392 8.63839Z" />
  </Icon>
);

export default CompanyDetails;
