import { Button } from "react-bootstrap";

/** @param {{ eventKey: string } & Omit<import("react-bootstrap").ButtonProps, "variant">} props */
const CustomNavItem = ({
  active = false,
  className,
  children,
  eventKey,
  ...props
}) => (
  <Button
    {...props}
    className={`mb-1 d-flex align-items-center ${
      className || ""
    }`}
    variant={active ? "secondary" : "light"}
  >
    {children}
  </Button>
);

export default CustomNavItem;
