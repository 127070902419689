export const proposalParagraphs = [
    {
        id: 1,
        header: "Objectives",
        text: "We understand that the objective of this assignment is to provide a professional opinion of the fair market value for "
    },
    {
        id: 2,
        header: "Professional fees",
        text: "The fee for the above service is: as above stated. A 50% deposit is required prior to commencement of the assignment and the balance is due upon completion of the report. In order to proceed on this valuation assignment in a timely manner we require as much of information possible on the subject property, including income and expenses, rent rolls, leases etc. Please provide the contact information for the inspection and financials of the above property."
    },
    {
        id: 3,
        header: "Confidentiality",
        text: "Republic Valuations shall not provide a copy of the written Appraisal Report to, or disclose the results of the appraisal prepared in accordance with this Agreement to, any party other than Client, unless Client authorizes, except as stipulated in the Confidentiality Section of the Ethics Rule of the Uniform Standards of Professional Appraisal Practice (USPAP)."
    },
    {
        id: 4,
        header: "Cancellation",
        text: "This Agreement may be cancelled by the Client at any time prior to Republic Valuations’s delivery of the Valuation Assignment upon written notification to Republic Valuations. Client shall pay Republic Valuations for work completed on assignment (fee may be adjusted according to assignment status), unless otherwise agreed upon by Republic Valuations and Client in writing. If this letter correctly states your understanding of the scope of work to be performed, please sign and return to us, as your authorization for us to proceed with this assignment. We are grateful for this opportunity to be of service, and please feel free to contact me if you have any questions or concerns."
    }
]

export const getNewParagraph = (id) => ({
    id,
    header: "",
    text: ""
})