/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} LeaseType */
import axios from "../../utils/axios";

/** @returns {Promise<Array<LeaseType>>} */
export function getLeaseTypes() {
  return axios.get("/LeaseType/GetLeaseTypes");
}

/**
 * @param {string} name
 * @returns {Promise<LeaseType>}
 */
export function createLeaseType(name) {
  return axios.post("/LeaseType/CreateLeaseType", { name });
}
