import React, { useState,useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Calendar, List, Search, Map } from "./icons";
import useOuterClick from "../../hooks/useOuterClick";
import "../../assets/scss/3-components/_calendar.scss"
/**
 * @param {{
 *  setCalendarMode: (calendarMode: import(".").CalendarMode) => void;
 *  onFiltersChange: (newFilters: import(".").MyInspectionsFilters) => void;
 *  filters: import(".").MyInspectionsFilters;
 *  calendarMode: import(".").CalendarMode;
 * }} props
 */
const InspectionsHeader = (props) => {
  const [searchMode, setSearchMode] = useState(false);
  const [sticky, setSticky] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 100 ? "is-sticky" : "";
    setSticky(stickyClass);
  };
  const classes = `d-flex justify-content-between p-3 shadow-sm bg-white ${sticky}`;
  return (
    <div
      className={classes}
      style={{ margin: "-24px 0 0", zIndex: 100, position: 'fixed', width: '100%' }}
    >
      {searchMode ? (
        <SearchInput
          value={props.filters.keyword}
          onSearch={(keyword) => props.onFiltersChange({ keyword })}
          exitSearchMode={() => setSearchMode(false)}
        />
      ) : (
        <>
          <Form.Select
            name="includeCompleted"
            onChange={(e) =>
              props.onFiltersChange({
                includeCompleted: e.target.value === "all",
              })
            }
            value={props.filters.includeCompleted ? "all" : "upcoming"}
            style={{ border: "none", width: 110 }}
          >
            <option value="upcoming">Upcoming</option>
            <option value="all">All</option>
          </Form.Select>
          <div>
            <Button variant="ghost" onClick={() => setSearchMode(true)}>
              <Search />
            </Button>
            <Button
              variant={props.calendarMode === "list" ? "primary" : "light"}
              onClick={() => props.setCalendarMode("list")}
              className="p-1 me-2"
            >
              <List />
            </Button>
            <Button
              variant={props.calendarMode === "calendar" ? "primary" : "light"}
              onClick={() => props.setCalendarMode("calendar")}
              className="p-1 me-2"
            >
              <Calendar />
            </Button>
            <Button
              variant={props.calendarMode === "map" ? "primary" : "light"}
              onClick={() => props.setCalendarMode("map")}
              className="p-1"
            >
              <Map />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

/**
 * @param {{
 *  onSearch: (searchTerm: string) => void;
 *  value: string|undefined;
 *  exitSearchMode: () => void;
 * }} props
 */
const SearchInput = (props) => {
  const [searchTerm, setSearchTerm] = useState(props.value || "");
  const inputGroupRef = useOuterClick(props.exitSearchMode);

  return (
    <InputGroup ref={inputGroupRef}>
      <Form.Control
        autoFocus
        className="box-shadow-none border-light"
        placeholder="Search . . ."
        value={searchTerm || ""}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Button
        variant="outline-light"
        onClick={() => {
          props.onSearch(searchTerm.trim());
          props.exitSearchMode();
        }}
      >
        <Search />
      </Button>
    </InputGroup>
  );
};

export default InspectionsHeader;
