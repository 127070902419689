import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import InspectionList from "./InspectionList";
import InspectionsHeader from "./InspectionsHeader";
import { getMyInspections } from "../../services/inspection";
import { AuthContext } from "../../contexts/JWTContext";
import InspectionCalendarView from "./InspectionCalendarView";
import LoadingWrapper from "../../components/LoadingWrapper";
import InspectionMapView from "./InspectionMapView";
import useAppSelector from "../../hooks/useAppSelector";
import moment from "moment";

const formatAddress = (address = "") =>
  address.endsWith(", USA")
    ? address.substring(0, address.length - 5)
    : address;

/**
 * @typedef {'list'|'calendar'|'map'} CalendarMode
 * @typedef {import("../../services/inspection").MyInspectionsFilters} MyInspectionsFilters
 */
const EventView = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const propertyTypes = useAppSelector((state) => state.appData.propertyTypes);

  const [myInspections, setMyInspections] = useState([]);
  const [loading, setLoading] = useState(false);
  /** @type {[MyInspectionsFilters, React.Dispatch<React.SetStateAction<MyInspectionsFilters>>]} */
  const [filters, setFilters] = useState({});
  /** @type {[CalendarMode, React.Dispatch<React.SetStateAction<CalendarMode>>]} */
  const [calendarMode, setCalendarMode] = useState("list");

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [myInspections]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    setLoading(true);
    getMyInspections(filters)
      .then((data) => setMyInspections(data))
      .finally(() => setLoading(false));
  }, [filters, isAuthenticated]);

  const getPropertyTypeString = useCallback(
    (ticket) => {
      const propertyType = propertyTypes.find(
        (type) =>
          ticket?.property?.propertyTypeId &&
          type.id === Number(ticket?.property?.propertyTypeId)
      );
      switch (propertyType?.type) {
        case 1:
          return `Commercial, ${propertyType.name}`;
        case 2:
          return `Residential, ${propertyType.name}`;
        case 3:
          return propertyType.name;
        default:
          return "";
      }
    },
    [propertyTypes]
  );

  const inspections = useMemo(
    () =>
      myInspections.map((item) => ({
        ...item,
        hour: moment(item.date).format("h:mm A"),
        propertyTypeString: getPropertyTypeString(item.ticket),
        formattedAddress: formatAddress(item.ticket?.property?.address),
      })),
    [getPropertyTypeString, myInspections]
  );

  const handleFiltersChange = (newFilters) =>
    setFilters((oldFilters) => ({
      ...oldFilters,
      ...newFilters,
    }));

  return (
    <>
      <Helmet title="Event View" />
      <InspectionsHeader
        filters={filters}
        onFiltersChange={handleFiltersChange}
        setCalendarMode={setCalendarMode}
        calendarMode={calendarMode}
      />
      <LoadingWrapper loading={loading} style={{ marginTop: '45px' }}>
        {calendarMode === "list" ? (
          <InspectionList inspections={inspections} />
        ) : calendarMode === "map" ? (
          <InspectionMapView inspections={inspections} />
        ) : (
          <InspectionCalendarView inspections={inspections} />
        )}
      </LoadingWrapper>
    </>
  );
};

export default EventView;
