import { useRef, useState } from "react";

/**
 * @returns {[React.MutableRefObject<any>, ((newState: any) => void)]}
 */
export default function useStateWithRef(defaultState) {
  const [state, _setState] = useState(defaultState);
  const stateRef = useRef(state);

  const setState = (newState) => {
    const actualNewState =
      newState instanceof Function ? newState(state) : newState;
    stateRef.current = actualNewState;
    _setState(actualNewState);
  };
  return [stateRef, setState];
}
