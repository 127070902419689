import React, { useState, useEffect } from 'react'
import { Dropdown, Spinner } from "react-bootstrap"
import axios from 'axios';
import { SERVER_URL } from '../../../../configs';

const FilterDropdown = ({ show, company, handleCompanySelection }) => {

    const [loading, setLoading] = useState(false)
    const [filteredCustomers, setFilteredCustomers] = useState([])
    const detailsChangedListener = (company) => {
        let filters = { "phoneNumbers": [], "emails": [] }

        filters.customerName = company.customer.name
        filters.customerAddress = company.customer.address

        setLoading(true)
        axios.post(`${SERVER_URL}/api/Customer/FilterCustomers`, filters, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            setFilteredCustomers(res.data)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        detailsChangedListener(company)
    }, [company])

    const handleSelection = (_customer) => {
        handleCompanySelection({ ..._customer })
    }

    return (
        <Dropdown show={show} style={{display: "block"}}>
            <Dropdown.Menu style={{ width: "100%" }} className="jobs-dropdown">
                {loading ? <div style={{ width: "fit-content", margin: "auto" }}>
                    <Spinner animation="grow" />
                </div> : filteredCustomers.map((customer) => {
                    return <Dropdown.Item key={customer.id} onClick={() => handleSelection(customer)}>{customer.name}</Dropdown.Item>
                })
                }
            </Dropdown.Menu>
        </Dropdown >
    )
}

export default FilterDropdown;