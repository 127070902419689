/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} BasementType */
import axios from "../../utils/axios";

/** @returns {Promise<Array<BasementType>>} */
export function getBasementTypes() {
  return axios.get("/BasementType/GetBasementTypes");
}

/**
 * @param {string} name
 * @returns {Promise<BasementType>}
 */
export function createBasementType(name) {
  return axios.post("/BasementType/CreateBasementType", { name });
}
