import { useState, useRef, useEffect } from 'react';
import { Button, Card, Dropdown, Tooltip } from "react-bootstrap";
import Overlay from 'react-bootstrap/Overlay';
import { MoreVertical, X, Copy, Edit, RotateCw, Save } from "react-feather";
import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import './contentHeading.css'
import { cancelSnoozedTicket, getTicketsWithAddress } from '../../services/ticket';
import { ReactComponent as SnoozeAppliedIcon } from "../../assets/icons/snooze_applied.svg";
import { getDateString } from '../../utils';
import { toast } from 'react-toastify';

import axios from "axios";
import { SERVER_URL } from "../../configs";

const dotStyle = {
  marginLeft: '3px',
  height: '18px',
  width: '18px',
  backgroundColor: 'red',
  borderRadius: '50%',
  display: 'inline-block',
  textAlign: 'center',
  color: 'white',
  fontSize: '15px'
}

const Snoozed = ({ ticket, reloadTicket }) => {

  const [cancelSnoozeDisabled, setCancelSnoozeDisabled] = useState(false);
  const cancelSnnozeHandler = async () => {
    const answer = window.confirm("Are you sure you want to cancel snooze on this job?");
    if (!answer) {
      return;
    }

    try {
      setCancelSnoozeDisabled(true);
      const response = await cancelSnoozedTicket(ticket.id);

      // if (response.data.success) {
      //   return;
      // }
      toast.success("Snooze cancelled successfully.");
    }
    catch (e) {

    }
    finally {
      reloadTicket();
    }
  }

  return (
    <>
      {(ticket && ticket.snoozeUntil) ?
        <div className="ms-2">
          <OverlayTrigger
            delay={{ hide: 500, show: 300 }}
            placement="bottom"
            overlay={(props) => (
              <Tooltip {...props}>
                This job is snoozed until {getDateString(ticket.snoozeUntil)}
              </Tooltip>
            )}>
            <SnoozeAppliedIcon height={16} width={16} style={{ cursor: 'pointer' }} />
          </OverlayTrigger>
          <Button variant="warning" className="ms-2" onClick={cancelSnnozeHandler} disabled={cancelSnoozeDisabled}>Cancel Snooze</Button>
        </div> : null}
    </>
  );
}

const ContentHeading = ({
  ticket,
  title,
  ticketStatus,
  status,
  onClose,
  onChangeStatus,
  editLink,
  showHistory,
  copyTicketNameToClipboard,
  similarPropertiesCount,
  placeId,
  reloadTicket
}) => {

  //alert(similarPropertiesCount)
  const [editTitle, setEditTitle] = useState(false);
  const [editableTitle, setEditableTitle] = useState('');
  const [savedTitle, setSavedTitle] = useState('');
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const [ticketsWithSameAddress, setTicketsWithSameAddress] = useState([]);
  useEffect(() => {
    if (!similarPropertiesCount || !ticket) {
      return;
    }

    getTicketsWithAddress(placeId).then(response => {
        response.forEach(g => {
            g.tickets = g.tickets.filter(t => t.id !== ticket.id);
        })
        setTicketsWithSameAddress(response.filter(t => t.tickets.length > 0));
    });

  }, [similarPropertiesCount, placeId, ticket])

  useEffect(() => {
    setEditableTitle(title);
  }, [title])

  const saveTitle = () => {
    axios.post(`${SERVER_URL}/api/Ticket/UpdateCustomTitle`, {
      ticketId: ticket.id,
      title: editableTitle
    }).then(() => {
      setEditTitle(false);
      setSavedTitle(editableTitle);
      toast.success("Title updates successfully !")
    })
      .catch(() => toast.error("Failed to update title, please contact administrator !"))
  }

  return (
    <Card>
      <Card.Body className="d-flex flex-row justify-content-between p-3">
        <h3 className="m-0 pt-1 d-flex align-items-center">
          <Button size="sm" className="p-0 me-3" onClick={e => onClose(savedTitle)}>
            <X size={19} />
          </Button>
          {editTitle &&
            <>
              <input className="form-control"
                value={editableTitle} onChange={(e) => setEditableTitle(e.target.value)}
              />
              <button size="sm" className="copy-btn" onClick={() => {
                setEditableTitle(title);
                setEditTitle(false);
              }} title="Cancel">
                <RotateCw size={19} />
              </button>
              <button size="sm" className="copy-btn" onClick={() => saveTitle()} title="Save">
                <Save size={19} />
              </button>
            </>
          }
          {!editTitle &&
            <>
              <span>{editableTitle}</span>
              <button ref={target} size="sm" className="copy-btn" onClick={() => {
                setShow(!show);
                copyTicketNameToClipboard();
                setTimeout(() => setShow(false), 2000);
              }}>
                <Copy size={19} />
              </button>
              <button size="sm" className="copy-btn" onClick={() => setEditTitle(true)} title="Edit title">
                <Edit size={19} />
              </button>
            </>
          }
          
          <Overlay target={target.current} show={show} placement="right">
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                {...props}
                style={{
                  position: 'absolute',
                  backgroundColor: 'rgba(14, 69, 66, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  borderRadius: 3,
                  ...props.style,
                }}
              >
                Successfully copied!
              </div>
            )}
          </Overlay>

          {similarPropertiesCount &&
            (<OverlayTrigger
              delay={{ hide: 5000, show: 300 }}
              placement="bottom"
              overlay={(props) => (
                <Tooltip {...props}>
                  {ticketsWithSameAddress && ticketsWithSameAddress?.map(t =>
                  (<div key={t.id} style={{ width: '190px' }} className="d-flex justify-content-around">
                    <p>{t.name} {t.tickets[0]?.property?.unit}</p>
                    <Link to={`/dashboard/job/${t.id}/${t.tickets[0].id}`}>Go &rarr;</Link>
                  </div>))}
                </Tooltip>
              )}>
              <span title={`${similarPropertiesCount} ${similarPropertiesCount == 1 ? "property has" : "properties have"} the same address`} style={dotStyle}>{similarPropertiesCount}</span>
            </OverlayTrigger>
            )}
          <Snoozed ticket={ticket} reloadTicket={reloadTicket} />
        </h3>
        <div className="d-flex align-items-center">
          <Button variant="light" className="me-3" onClick={showHistory}>
            View Ticket History
          </Button>
          <span>Status: </span>
          <span className="ms-1 me-3 fw-bold">{status}</span>
          <span className="bg-light me-3" style={{ width: 1, height: "100%" }} />
          <span className="me-2">File Number:</span>
          <Link to={editLink} className="btn btn-light me-3">
            Edit Ticket
          </Link>
          <Button variant="secondary" onClick={onChangeStatus}>
            {ticketStatus}
          </Button>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-list-custom"
              variant="link"
              className="menu-button"
            >
              <MoreVertical size={20} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#">Cancel job</Dropdown.Item>
              <Dropdown.Item href="#">Move out of group</Dropdown.Item>
              <Dropdown.Item href="#">Change due date</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Body>
    </Card>
  )
};

export default ContentHeading;
