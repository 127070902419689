import axios from "../utils/axios";

/**
 * @param {string} ticketId
 * @param {string} folder
 * @param {File} file
 * @param {string} name
 * @param {(progressEvent: any) => void} onUploadProgress
 */
export function uploadFile(ticketId, folder, file, name, onUploadProgress) {
  const formData = new FormData();
  formData.append("ticketId", ticketId);
  formData.append("folder", folder);
  formData.append("file", file);
  if (name) {
    formData.append("name", name);
  }
  //console.log({ticketId, folder, name});
  return axios.post("/MicrosoftTeamsFiles/UploadFile", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

export function uploadCompFile(compId, file, name) {
  const formData = new FormData();
  formData.append("compId", compId);
  formData.append("file", file);

  return axios.post("/MicrosoftTeamsFiles/UploadCompFile", formData);
}

/**
 * @param {{
 *  ticketId: number;
 *  where: string;
 *  folderName: string;
 * }} data
 */
export function createFolder(data) {
  return axios.post("/MicrosoftTeamsFiles/CreateFolder", data);
}

export function deleteFile(ticketId, id) {
  const queryParams = new URLSearchParams({ ticketId, id });
  return axios.post(`/MicrosoftTeamsFiles/DeleteFile?${queryParams}`);
}

export function deleteCompFile(compId, id) {
  const queryParams = new URLSearchParams({ compId, id });
  return axios.post(`/MicrosoftTeamsFiles/DeleteCompFile?${queryParams}`);
}

export function moveFile(ticketId, fileId, toFolder, fileName) {
  const queryParams = new URLSearchParams({
    ticketId,
    fileId,
    toFolder,
    fileName,
  });
  return axios.post(`/MicrosoftTeamsFiles/MoveFile?${queryParams}`);
}

export function getRootDirectoryContents(ticketId, source = 0) {
  return axios.get(
    `/MicrosoftTeamsFiles/GetRootDirectoryContents?ticketId=${ticketId}&source=${source}`
  );
}

export function getDirectoryContents(ticketId, id) {
  return axios.get(
    `/MicrosoftTeamsFiles/GetDirectoryContents?ticketId=${ticketId}&id=${id}`
  );
}

export function getRootDirectoryWebUrl(teamId) {
  return axios.get(`/MicrosoftTeams/GetRootWebUrl?teamId=${teamId}`);
}