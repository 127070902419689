import React from "react";

/** @param {import("react").SVGProps} props */
const DropdownIcon = (props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.125 8.75L10.5 13.125L14.875 8.75" />
  </svg>
);

export default DropdownIcon;
