import React from 'react';

const ChatMessage = ({ position, avatar, name, children, time, index }) => (
    <div key={index} className={`chat-message-${position} pb-2`}>
        {/*<div>
            {avatar && <img
                src={avatar}
                className="rounded-circle me-1"
                alt={name}
                width="40"
                height="40"
            />}
            <div className="text-muted small text-nowrap mt-2">{time}</div>
        </div>*/
        }
        <div
            className={`flex-shrink-1 rounded-lg py-2 px-3 shadow ${position === "right" ? "me-2 border" : "ms-2 bg-light"}`}>
            <div className="fw-bold mb-1">{name}</div>
            {children}
            <div className="text-muted small text-nowrap mt-1" style={{ display: "flex", justifyContent: "end" }}>{time}</div>
        </div>
    </div>
);

export default ChatMessage;
