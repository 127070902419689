import React, { useContext } from "react";
import LoadingWrapper from "../../components/LoadingWrapper";
import JobDetails from "./jobDetails";
import JobView from "../jobViewMobile/JobViewMobile";

import { AuthContext } from "../../contexts/JWTContext";
import { JobDetailsProvider } from "./useJobDetails";
import { JobInspectionDetailsProvider } from "./useJobInspectionDetails";
import useScreenSize from "../../hooks/useScreenSize";

const JobDetailsWrapper = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { isMobile } = useScreenSize();

  if (!isAuthenticated) {
    return <LoadingWrapper className="h-100" />;
  }

  return (
    <JobDetailsProvider>
      <JobInspectionDetailsProvider>
        {isMobile ? <JobView /> : <JobDetails />}
      </JobInspectionDetailsProvider>
    </JobDetailsProvider>
  );
};

export default JobDetailsWrapper;
