/** @param {import("react").SVGProps} props */
const UserIcon = (props) => (
  <svg
    width="22"
    height="27"
    viewBox="0 0 22 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.1802 0.904118C8.68142 1.10593 7.47958 1.69658 6.4427 2.74008C4.14742 5.04857 3.90234 8.79433 5.87242 11.4375C7.76237 13.9774 11.2218 14.696 13.88 13.0963C16.2271 11.6836 17.4384 8.93707 16.9388 6.17574C16.4722 3.62115 14.5021 1.544 12.0513 1.01733C11.5847 0.918884 10.5525 0.854897 10.1802 0.904118Z"
      fill="white"
    />
    <path
      d="M5.74997 14.509C3.66207 14.8929 1.74856 16.2465 0.556144 18.1809C0.306351 18.5894 0 19.2835 0 19.4459C0 19.7708 0.895487 21.0899 1.74384 22.0103C3.81289 24.2598 6.36738 25.5986 9.30835 25.9825C9.92105 26.0662 11.6272 26.0613 12.2305 25.9825C14.6153 25.6626 16.7362 24.7323 18.5696 23.2015C19.1398 22.729 20.2568 21.5231 20.6999 20.9078C21.1948 20.2138 21.5859 19.5542 21.5859 19.4016C21.5859 19.2539 21.322 18.6485 21.0911 18.2744C20.2851 16.9602 19.1634 15.8872 17.905 15.2325C16.986 14.7551 15.8407 14.4253 15.261 14.4696C15.063 14.4844 14.9311 14.5336 14.7425 14.6566C14.0639 15.1046 13.847 15.2276 13.47 15.3999C12.5321 15.8281 11.5753 16.0152 10.5573 15.9709C9.2565 15.9167 8.16307 15.5525 7.07434 14.8043C6.83869 14.6419 6.5889 14.4991 6.5182 14.4794C6.44279 14.4647 6.35324 14.445 6.31554 14.4351C6.27783 14.4253 6.02332 14.4598 5.74997 14.509Z"
      fill="white"
    />
  </svg>
);

export default UserIcon;
