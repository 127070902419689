import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import Select from "react-select";
// commented out for now.
// import CreatableSelect from "react-select/creatable";
import { PlusSquare, Edit2, Trash, ArrowLeft } from "react-feather";
import Popconfirm from "../../../components/Popconfirm";
import FormModal from "../FormModal";
import AddSubItemIcon from "./AddSubItemIcon";
import {
  getInspectionFormQuestions,
  createInspectionFormQuestions,
  updateInspectionQuestionFormQuestion,
  deleteInspectionQuestionFormQuestion,
  //getSubQuestions
} from "../../../services/inspectionQuestionForm";
import { useNavigate, useParams } from "react-router-dom";
import { SortableSelect, arrayMove, SortableMultiValue, SortableMultiValueLabel } from "../../../components/sortableSelect";

const QuestionTypeEnum = {
  1: "Text",
  2: "Dropdown",
  3: "Radio",
  4: "Multiselect"
}

const defaultInitialValues = { type: { value: 1, label: QuestionTypeEnum["1"] } };

const InspectionFormQuestions = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [inspectionFormQuestions, setInspectionQuestions] = useState([]);
  const [show, setShow] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemForSubQuestions, setItemForSubQuestions] = useState();

  const inspectionFormQuestionsToRender = useMemo(() => {
    if (itemForSubQuestions) {
      // render only subQuestions for selected item
      return inspectionFormQuestions.filter(item => item.parentId === itemForSubQuestions.id);
    }

    // if no item is selected for subQuestions, render all items that are not subQuestion
    return inspectionFormQuestions.filter(item => !item.parentId);
  }, [
    inspectionFormQuestions,
    itemForSubQuestions,
  ]);

  useEffect(() => {
    getInspectionFormQuestions(id).then(setInspectionQuestions);
  }, [id]);


  const handleSubmit = useCallback(async (values) => {
    if (values.type === 1) {
      delete values.options;
    } else if (values.options?.length) {
      values.options = values.options.map((option) => option.value).join(",");
    }
    if (values.option && values.option.value) {
      values.option = values.option.value;
    }
    if (values.id) {
      const updatedItem = await updateInspectionQuestionFormQuestion(values);
      setInspectionQuestions((oldValue) =>
        oldValue.map((i) =>
          i.id === updatedItem.id
            ? {
              ...i,
              ...updatedItem,
            }
            : i
        )
      );
      return;
    }
    const newItem = await createInspectionFormQuestions({
      ...values,
      inspectionQuestionFormId: Number(id),
      parentId: itemForSubQuestions?.id,
    });
    setInspectionQuestions((data) => [...data, newItem]);
  }, [itemForSubQuestions?.id]);

  const handleDelete = async () => {
    setInspectionQuestions((oldValue) =>
      oldValue.filter((i) => i.id !== idToDelete)
    );
    setIdToDelete(0);
    await deleteInspectionQuestionFormQuestion(idToDelete);
    getInspectionFormQuestions(id).then(setInspectionQuestions);
  }

  return (
    <>
      <Popconfirm
        title="Are you sure?"
        show={!!idToDelete}
        onCancel={() => setIdToDelete(0)}
        onOk={handleDelete}
      />
      <div className="d-flex justify-content-between mb-3">
        <h2>
          <span
            className="text-info"
            style={{ cursor: "pointer" }}
            onClick={() => {
              itemForSubQuestions ? setItemForSubQuestions(null) : navigate(-1);
            }}
          >
            <ArrowLeft className="me-2" size={28} />
          </span>
          {itemForSubQuestions
            ? `Inspection Form Questions: "${itemForSubQuestions.label}" Sub Questions`
            : "Inspection Form Questions"}
        </h2>
        <Button className="float-end" onClick={() => setShow(true)}>
          <PlusSquare size={20} className="me-2" />
          Add
        </Button>
      </div>
      <FormModal
        show={show}
        close={() => {
          setShow(false);
          setItemToEdit(null);
        }}
        title={`${itemToEdit ? "Update" : "Create"} inspection${itemForSubQuestions ? " sub" : ""
          } form question`}
        initialValues={itemToEdit || defaultInitialValues}
        onSubmit={handleSubmit}
        hasLabel
        hasName={false}
      >
        {({ handleBlur, handleChange, values }) => (
          <>
            {itemForSubQuestions && itemForSubQuestions.options && (
              <Form.Group className="mb-3">
                <Form.Label>Sub question for option</Form.Label>
                <Select
                  className="react-select-container mt-2"
                  classNamePrefix="react-select"
                  value={
                    typeof values.option === "string"
                      ? {
                        value: values.option,
                        label: values.option,
                      }
                      : values.option
                  }
                  placeholder="Option"
                  options={itemForSubQuestions.options
                    .split(",")
                    .map((x) => ({ label: x, value: x }))}
                  onBlur={handleBlur}
                  onChange={(value) =>
                    handleChange({ target: { value, name: "option" } })
                  }
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Select
                className="react-select-container mt-2"
                classNamePrefix="react-select"
                value={
                  typeof values.type === "number"
                    ? {
                      value: values.type,
                      label: QuestionTypeEnum[values.type],
                    }
                    : values.type
                }
                placeholder="Type"
                options={Object.keys(QuestionTypeEnum).map((key) => ({
                  value: +key,
                  label: QuestionTypeEnum[key],
                }))}
                onBlur={handleBlur}
                onChange={(value) =>
                  handleChange({ target: { value, name: "type" } })
                }
              />
            </Form.Group>
            {values.type?.value && values.type.value !== 1 && (
              <Form.Group className="mb-3">
                <Form.Label>Options</Form.Label>
                <SortableSelect
                  styles={{
                    multiValue: (baseStyles, state) => ({
                      ...baseStyles,
                      zIndex: 9999999,
                    }),
                  }}
                  useDragHandle
                  axis="xy"
                  onSortEnd={({ oldIndex, newIndex }) => {
                    const newValue = arrayMove(
                      values.options,
                      oldIndex,
                      newIndex
                    );
                    handleChange({
                      target: { value: newValue, name: "options" },
                    });
                  }}
                  distance={4}
                  getHelperDimensions={({ node }) =>
                    node.getBoundingClientRect()
                  }
                  isMulti
                  options={[]}
                  value={values.options}
                  onChange={(value) => {
                    handleChange({ target: { value, name: "options" } });
                  }}
                  components={{
                    MultiValue: SortableMultiValue,
                    MultiValueLabel: SortableMultiValueLabel,
                  }}
                  closeMenuOnSelect={false}
                />
                {/* <CreatableSelect
                  className="react-select-container mt-2"
                  classNamePrefix="react-select"
                  value={values.options}
                  placeholder="Options"
                  options={[]}
                  isMulti
                  onChange={(value) =>
                    handleChange({ target: { value, name: "options" } })
                  }
                /> */}
              </Form.Group>
            )}
          </>
        )}
      </FormModal>
      <Card>
        <Table striped>
          <thead>
            <tr>
              <th>Label</th>
              <th>Display order</th>
              <th>Options</th>
              {itemForSubQuestions && <th>For option</th>}
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {inspectionFormQuestionsToRender.map((item) => (
              <tr key={item.id}>
                <td>{item.label}</td>
                <td>{item.displayOrder}</td>
                <td>{item.options}</td>
                {itemForSubQuestions && <td>{item.option}</td>}
                <td>{QuestionTypeEnum[item.type]}</td>
                <td>
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => {
                      setItemToEdit({
                        ...item,
                        type: {
                          value: item.type,
                          label: QuestionTypeEnum[item.type],
                        },
                        options:
                          item.options &&
                          item.options.split(",").map((option) => ({
                            value: option.trim(),
                            label: option.trim(),
                          })),
                      });
                      setShow(true);
                    }}
                  >
                    <Edit2 size={18} className="align-middle" />
                  </Button>
                  <Button
                    variant="link"
                    size="sm"
                    onClick={() => setIdToDelete(item.id)}
                  >
                    <Trash size={18} className="align-middle text-danger" />
                  </Button>
                  {!itemForSubQuestions && (
                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => setItemForSubQuestions(item)}
                    >
                      <AddSubItemIcon size={16} />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!!itemForSubQuestions && !inspectionFormQuestionsToRender.length && (
          <div className="py-4 d-flex justify-content-center">
            <h3>No sub questions for "{itemForSubQuestions.label}"</h3>
          </div>
        )}
      </Card>
    </>
  );
};

export default InspectionFormQuestions;
