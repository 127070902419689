import axios from "../utils/axios";

export function getInspectionContactsByTicket(id) {
  return axios.get(`/InspectionContact/GetInspectionContactsByTicket?ticketId=${id}`);
}

export function createInspectionContact(contactForm) {
  return axios.post("/InspectionContact/CreateInspectionContact", contactForm);
}

export function deleteInspectionContact(id) {
  return axios.post(`/InspectionContact/DeleteInspectionContact?inspectionContactId=${id}`);
}

export function updateInspectionContact(contactForm) {
  return axios.post(`/InspectionContact/UpdateInspectionContact`, contactForm);
}
