import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExternalLink, Folder } from "react-feather";
import { useDrop } from "react-dnd";

const BreadItem = ({ path, index, onClick, lastIndex }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "file",
    drop: () => ({ ...path }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    options: { id: path.id },
    canDrop: () => path.id !== "root",
  }));

  const allowDrop = lastIndex !== index && canDrop;

  return (
    <>
      {index !== 0 && <span>/</span>}
      <Button
        ref={drop}
        variant={allowDrop && isOver ? "info" : allowDrop ? "light" : "link"}
        size="sm"
        className={`px-1${index === 0 ? "" : ""}`}
        onClick={() => onClick(path, index)}
      >
        {path.name}
      </Button>
    </>
  );
};

const Breadcrumbs = ({ paths, onClick, onRootClick }) => (
  <div className="breadcrumbs-container">
    {paths.map((path, index) => (
      <React.Fragment key={index}>
        {path.id == 'root' && (
          <OverlayTrigger
            placement="left"
            delay={{ show: 200 }}
            overlay={<Tooltip>Open in SharePoint</Tooltip>}
          >
            <Button
              variant="link"
              size="sm"
              onClick={onRootClick}
            >
              <ExternalLink size={18} />
            </Button>
          </OverlayTrigger>
        )}
        <BreadItem
          key={path.id}
          path={path}
          index={index}
          onClick={onClick}
          lastIndex={paths.length - 1}
        />
      </React.Fragment>
    ))}
  </div>
);

export default Breadcrumbs;
