/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} CompKey */
import axios from "../../utils/axios";

/** @returns {Promise<Array<CompKey>>} */
export function getCompKeys() {
  return axios.get("/CompKey/GetCompKeys");
}

/**
 * @param {Omit<CompKey, 'id'>} data
 * @returns {Promise<CompKey>}
 */
export function createCompKey(data) {
  const queryParams = new URLSearchParams(data);
  return axios.post(`/CompKey/CreateCompKey?${queryParams}`);
}

export function updateCompKey(data) {
  return axios.post(`/CompKey/UpdateCompKey`, data);
}
