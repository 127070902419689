import { Form } from "react-bootstrap"
import { getDataType, getFormattedDateTimeStr } from "../../../utils"

export const columns = [
    {
        name: "Type",
        sortable: false,
        cell: row => row.state === 4 ? "Added" : "Modified"
    },
    {
        name: 'Column',
        sortable: false,
        cell: row => row.column?.replace(/([a-z])([A-Z])/g, '$1 $2')
    },
    {
        name: 'Old Value',
        sortable: false,
        minWidth: '200px',
        cell: row => {
            // add logic to handle the booleans and date fields
            return (<>
                {
                    getDataType(row.oldValue) === "bool" && <Form.Check checked={row.oldValue.toLowerCase() === "true"} disabled={true} />
                }
                {
                    getDataType(row.oldValue) === "dateTime" && getFormattedDateTimeStr(row.oldValue)
                }
                {
                    getDataType(row.oldValue) === "string" && row.oldValue
                }
            </>)
        }
    },
    {
        name: 'New Value',
        sortable: false,
        minWidth: '200px',
        cell: row => {
            // add logic to handle the booleans and date fields
            return (
                <>
                    {
                        getDataType(row.newValue) === "bool" && <Form.Check checked={row.newValue.toLowerCase() === "true"} disabled={true} />
                    }
                    {
                        getDataType(row.newValue) === "dateTime" && getFormattedDateTimeStr(row.newValue)
                    }
                    {
                        getDataType(row.newValue) === "string" && row.newValue
                    }
                </>
            )
        }
    },
    {
        name: 'Date',
        sortable: false,
        minWidth: '200px',
        cell: row => getFormattedDateTimeStr(row.date)
    },
    {
        name: 'User',
        sortable: false,
        cell: row => `${row?.user?.firstName} ${row?.user?.lastName}`
    }
]