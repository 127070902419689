import React, { Fragment } from "react"
import { Form, Row, Col } from "react-bootstrap"

/** Configs && Hooks */
import useAppSelector from "../../hooks/useAppSelector"

/** Third Party Libs */
import { MultiSelect } from "react-multi-select-component"
import Quotes from "./quotes"

const PropertyList = ({ propertyList, setPropertyList }) => {
    const { propertyTypes, assignmentTypes, valueOptions } = useAppSelector(state => state.appData)

    const handlePropertyValueChanged = (index, key, values) => {
        let _propertyList = JSON.parse(JSON.stringify(propertyList))

        if (key === "valueType") {
            let _values = []
            values.forEach((value) => {
                const _valueOption = valueOptions.find((option) => String(option.value) === String(value.value))
                _values.push({ name: _valueOption.label, id: _valueOption.value })
            })
            values = _values
        }
        _propertyList[index][key] = values
        setPropertyList(_propertyList)
    }

    return (
        <>
            <h5>Property List</h5>
            <hr />
            {
                propertyList.map((item, index) => {
                    let _selectedValueTypes = []
                    item.valueType.forEach((id) => {
                        let _values = valueOptions.filter((obj) => obj.value === id.id)
                        _selectedValueTypes.push({ ..._values[0] })
                    })

                    return (
                        <Fragment key={index}>
                            <>
                                <Row>
                                    <Col className="mt-2" xl={4} md={6} sm={12}>
                                        <h5>Address # {index + 1}</h5>
                                        <Form.Control
                                            type="text"
                                            name="address"
                                            label="address"
                                            value={item.address}
                                            onChange={(e) => handlePropertyValueChanged(index, e.target.name, e.target.value)}
                                        />
                                    </Col>
                                    <Col className="mt-2" xl={4} md={6} sm={12}>
                                        <h5>Select Property Type # {index + 1}</h5>
                                        <Form.Select name="propertyType" aria-label="Select Property Type" value={item.propertyType || ""} onChange={(e) => handlePropertyValueChanged(index, e.target.name, e.target.value)}>
                                            <option value="">Select Property Type</option>
                                            {
                                                propertyTypes.map((propertyType, pIndex) => {
                                                    return String(item.pType) === String(propertyType.type) && <option key={pIndex} value={propertyType.id}>{propertyType.name}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Col>
                                    <Col className="mt-2" xl={4} md={6} sm={12}>
                                        <h5>Select Assignment Type # {index + 1}</h5>
                                        <Form.Select name="assignmentType" aria-label="Select Assignment Type" value={item.assignmentType || ""} onChange={(e) => handlePropertyValueChanged(index, e.target.name, e.target.value)}>
                                            <option value="">Select Assignment Type</option>
                                            {assignmentTypes.map((assignment, aIndex) => String(item.pType) === String(assignment.type) && <option key={aIndex} value={assignment.id}>{assignment.name}</option>)}
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="mt-2" xl={4} md={6} sm={12}>
                                        <h5>Select Valuation Date # {index + 1}</h5>
                                        <Form.Control
                                            type="date"
                                            name="valuationDate"
                                            label="Valuation Date"
                                            value={item.valuationDate}
                                            min={new Date().toISOString().split('T')[0]}
                                            onChange={(e) => handlePropertyValueChanged(index, e.target.name, e.target.value)}
                                        />
                                    </Col>
                                    <Col className="mt-2" xl={4} md={6} sm={12}>
                                        <h5>Select Value Type # {index + 1}</h5>
                                        <div className="multi-selectContol">
                                            <MultiSelect
                                                options={valueOptions}
                                                labelledBy="Select"
                                                value={_selectedValueTypes}
                                                onChange={(value) => handlePropertyValueChanged(index, "valueType", value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="mt-2" xl={4} md={6} sm={12}>
                                        <h5>Select Unit # {index + 1}</h5>
                                        <Form.Control
                                            type="text"
                                            name="unit"
                                            label="unit"
                                            value={item.unit}
                                            onChange={(e) => handlePropertyValueChanged(index, e.target.name, e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-2" xl={4} md={6} sm={12}>
                                        <h5>Enter Parcel ID # {index + 1}</h5>
                                        <Form.Control
                                            type="text"
                                            name="parcelId"
                                            label="Parcel ID"
                                            value={item.parcelId}
                                            onChange={(e) => handlePropertyValueChanged(index, e.target.name, e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </>
                            <hr />
                            <Quotes title={`Quotes # ${index + 1}`} quotes={item["quotes"]} setQuotes={(values) => handlePropertyValueChanged(index, "quotes", values)} />
                        </Fragment>
                    )
                }
                )
            }
            <hr />
        </>
    )
}

export default PropertyList