import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { MapPin } from "react-feather";
import CustomCard from "../../../components/CustomCard";
import NotesAndMemos from "../NotesAndMemos";
import GoogleStreetMap from "../../maps/GoogleMaps/Streetview";
import { updateIsRush } from "../../../services/ticket";
import { getFormattedAsOfPreviousDate, getFormattedDateTimeStr, getFormattedDateTimeStrDateOnly, getFormattedDateTimeStrV2 } from "../../../utils";
import moment from "moment";
import useAppSelector from "../../../hooks/useAppSelector";
import { keyBy } from "lodash";

const InfoItem = ({ title, info, ...rest }) => (
  <p {...rest}>
    <b className="me-1">{title}:</b>
    {info || ""}
  </p>
);

const InternalDateInfoItem = ({ title, info, ...rest }) => {

}

const Property = ({
  propertyInfo,
  ticketInfo,
  ticketGroup,
  propertyTypeStr,
  propertySubTypeStr,
  jobPurposeStr,
  assignmentTypeStr,
}) => {
  const [showMaps, setShowMaps] = useState();
  const signingAppraisers = useAppSelector((state) => state.appData.signingAppraisers);
  const keyedSigningAppraisers = keyBy(signingAppraisers, 'id');

  const getValueTypesStr = (valueTypes) => {
    let str = "";
    if (valueTypes && valueTypes.length > 0) {
      valueTypes.forEach((valueType, index) => {
        str += valueType.name;
        if (index < valueTypes.length - 1) str += ", ";
      });
    }
    return str;
  };

  const handleRushChange = (e) => {
    updateIsRush(ticketInfo.id, e.target.checked);
  };

  return (
    <div
      style={{ height: "100%", overflow: "auto" }}
      className="d-flex flex-column"
    >
      {Object.keys(propertyInfo).length > 0 && (
        <CustomCard
          title={
            <>
              <MapPin size={20} className="map-pin-icon me-2" />
              {propertyInfo.address}
            </>
          }
        >
          <InfoItem title="Unit" info={propertyInfo.unit} />
          <InfoItem title="Parcel ID" info={propertyInfo.parcelId} />
          <InfoItem title="Type" info={propertyTypeStr} />
          {!!propertySubTypeStr && (
            <InfoItem title="Sub Type" info={propertySubTypeStr} />
          )}
          <InfoItem title="Notes" info={propertyInfo.propertyNotes[0]?.note} />
          <Form.Check
            id="is-rush-checkbox"
            label={<b>Is Rush</b>}
            defaultChecked={ticketInfo.isRush}
            onChange={handleRushChange}
          />
          <div className="d-flex justify-content-end mb-1">
            <Button
              size="sm"
              variant="link"
              onClick={() => setShowMaps(!showMaps)}
            >
              {showMaps ? "Hide Map" : "Show Map"}
            </Button>
          </div>
          {propertyInfo.googlePlaceId !== "" && showMaps && (
            <GoogleStreetMap
              latPos={propertyInfo?.lat}
              lngPos={propertyInfo?.lng}
            />
          )}
        </CustomCard>
      )}
      {(!!Object.keys(ticketInfo).length ||
        jobPurposeStr ||
        assignmentTypeStr) && (
          <CustomCard>
            <InfoItem title="Job Purpose" info={jobPurposeStr} />
            <InfoItem title="Assignment Type" info={assignmentTypeStr} />
            <InfoItem title="Purpose Note" info={ticketInfo.purposeNote} />
            <InfoItem title="Special Requirements" info={ticketInfo.specialRequirements ?? "N/A"} />
            <InfoItem title="Signing Appraiser" info={ticketInfo.signingAppraiserIds && ticketInfo.signingAppraiserIds.length > 0 ? ticketInfo.signingAppraiserIds.map(signingAppraiserId => `${keyedSigningAppraisers[signingAppraiserId]?.firstName} ${keyedSigningAppraisers[signingAppraiserId]?.lastName}`).join(', ') : "N/A"} />
            <InfoItem title="File Number" info={ticketInfo.fileNumber} />
            <InfoItem
              title="Fee"
              info={ticketInfo?.fee ? `$${ticketInfo.fee}` : ""}
            />
            <InfoItem
              title="Value Types"
              info={getValueTypesStr(ticketInfo.valueTypes)}
            />
            {ticketInfo.valueTypes?.some((obj) => obj.id === 3) && (
              <InfoItem
                title="As Previous Date"
                info={ticketInfo.valueOfPreviousDate ?
                  (<span style={{ backgroundColor: ticketInfo.valueOfPreviousDate ? "#2ec5f8" : "transparent", padding: 4 }}>
                    {getFormattedAsOfPreviousDate(ticketInfo.valueOfPreviousDate)}
                  </span>) : (<span>N/A</span>)
                }
              />
            )}
            {/* Debbie asked to remove  */}
            {/* get */}
            {/* <InfoItem
              title="Actual Due date"
              info={getFormattedDateTimeStrDateOnly(
                ticketGroup.dueDate, { short: true }
              )}
            /> */}
            {(ticketInfo.dueDateOverride || ticketGroup.dueDate) &&
              <InfoItem
                title="Requested Due date"
                info={getFormattedDateTimeStrDateOnly((ticketInfo.dueDateOverride ?? ticketGroup.dueDate), { short: true })}
              />}
            <InfoItem
              title="Internal Due date"
              info={moment(ticketGroup.dueDate) < moment(ticketInfo.internalDueDate) ? (
                <span style={{ backgroundColor: 'red', color: 'white', padding: 4 }}>{getFormattedDateTimeStrDateOnly(
                  ticketInfo.internalDueDate || ticketGroup.dueDate, { short: true }
                )}</span>
              ) : getFormattedDateTimeStrDateOnly(
                ticketInfo.internalDueDate || ticketGroup.dueDate, { short: true }
              )}
            />
            <InfoItem
              title="Created"
              info={getFormattedDateTimeStrDateOnly(
                ticketGroup.created, { short: true }
              )}
            />
            {/* Debbie asked to remove  */}
            {/* <InfoItem
              title="Last Updated Date"
              info={getFormattedDateTimeStr(ticketGroup.lastUpdateDate)}
            /> */}
          </CustomCard>
        )}
      <NotesAndMemos ticketId={ticketInfo.id} />
    </div>
  );
};

export default Property;
