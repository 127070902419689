import React from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Navbar from "../components/header/header";
import Content from "../components/Content";
import Settings from "../components/Settings";

const Dashboard = ({ children }) => (
  <React.Fragment>
    <Wrapper>
      <Main>
        <Navbar />
        <Content>
          {children}
          <Outlet />
        </Content>
        {/* <Footer /> */}
      </Main>
    </Wrapper>
    <Settings />
  </React.Fragment>
);

export default Dashboard;
