import bbPromise from "bluebird";
import JSZip from "jszip";
import FileSaver from "file-saver";
import mime from "mime-types";

export class Downloader {
  downloadCallback;
  files;
  zipName;
  downloadedCount = 0;
  /**
   * @param {Array<{ id: string; url: string; name: string }>} files
   * @param {string} zipName
   */
  constructor(files, zipName) {
    this.files = files;
    this.zipName = zipName;
  }

  /** @param {(percent: number) => void} callback */
  onFileDownloaded(callback) {
    this.downloadCallback = () => {
      this.downloadedCount++;
      const percent = Math.round(
        (this.downloadedCount / this.files.length) * 100
      );
      callback(percent);
    };
  }

  /**
   * @param {{ id: string; url: string }} file
   */
  downloadFile(file) {
    return fetch(file.url).then(async (res) => {
      this.downloadCallback();
      const mimeExtension = mime.extension(res.headers.get("Content-Type"));
      return {
        id: file.id,
        extension: !file.name ? (mimeExtension === "bin" ? "png" : mimeExtension) : null,
        name: file.name ?? res.headers.get("Content-Disposition"),
        blob: await res.blob(),
      };
    });
  }

  /**
   * @param {Array<{ id: string; url: string }>} files
   * @param {number} [filesPerGroup]
   */
  downloadFilesByGroup(files, filesPerGroup = 5) {
    return bbPromise.map(files, (file) => this.downloadFile(file), {
      concurrency: filesPerGroup,
    });
  }

  /**
   * @param {Array<{ id: string; extension: string; name: string | null; blob: Blob }>} files
   * @param {string} zipName
   */
  exportZip(responseFiles) {
    const zip = JSZip();
    responseFiles.forEach((file, i) => {
      const fileNameArray = [];
      fileNameArray.push(file.name || file.id || i);

      if (file.extension) {
        fileNameArray.push(file.extension);
      }

      zip.file(fileNameArray.join("."), file.blob);
    });
    return zip
      .generateAsync({ type: "blob" })
      .then((zipFile) => FileSaver.saveAs(zipFile, `${this.zipName}.zip`));
  }

  downloadFilesAndZip() {
    return this.downloadFilesByGroup(this.files).then((res) =>
      this.exportZip(res)
    );
  }
}
