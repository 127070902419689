import React, { useMemo } from "react"
import { Form } from "react-bootstrap"
import DataTable from "react-data-table-component"
import { getDataType, getFormattedDateTimeStrV2, getFormattedDateTimeStr } from "../../../utils"
import useAppSelector from "../../../hooks/useAppSelector";

const History = ({ data }) => {
    const usersMap = useAppSelector(state => state.appData.usersMap);

    const columns = useMemo(
      () => [
        {
          name: "Field",
          sortable: false,
          cell: (row) => row.column?.replace(/([a-z])([A-Z])/g, "$1 $2"),
        },
        {
          name: "Old Value",
          sortable: false,
          minWidth: "200px",
          cell: (row) => {
            // add logic to handle the booleans and date fields
            return (
              <>
                {getDataType(row.oldValue) === "bool" && (
                  <Form.Check
                    checked={row.oldValue.toLowerCase() === "true"}
                    disabled={true}
                  />
                )}
                {getDataType(row.oldValue) === "dateTime" &&
                  getFormattedDateTimeStrV2(row.oldValue)}
                {getDataType(row.oldValue) === "string" && row.oldValue}
              </>
            );
          },
        },
        {
          name: "New Value",
          sortable: false,
          minWidth: "200px",
          cell: (row) => {
            // add logic to handle the booleans and date fields
            return (
              <>
                {getDataType(row.newValue) === "bool" && (
                  <Form.Check
                    checked={row.newValue.toLowerCase() === "true"}
                    disabled={true}
                  />
                )}
                {getDataType(row.newValue) === "dateTime" &&
                  getFormattedDateTimeStrV2(row.newValue)}
                {getDataType(row.newValue) === "string" && row.newValue}
              </>
            );
          },
        },
        {
          name: "Date",
          sortable: false,
          minWidth: "200px",
          cell: (row) => getFormattedDateTimeStr(row.date),
        },
        {
          name: "User",
          sortable: false,
          cell: (row) =>
            `${usersMap[row.userId]?.firstName || ""} ${usersMap[row.userId]?.lastName || ""}`,
        },
      ],
      [usersMap]
    );

    return (
        <DataTable
            style={{ height: 670, overflow: "auto" }}
            noHeader
            className="react-dataTable"
            columns={columns}
            data={data}
        />
    )
}

export default History;