import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button, Accordion } from "react-bootstrap";
import RDataTable from "react-data-table-component";
import LoadingWrapper from "../../components/LoadingWrapper";
import DataTable from "./history";
import StepsCustomNav from "./StepsCustomNav";
import {
  getChangeHistory,
  getTicketStatusChangeHistory,
} from "../../services/changeHistory";
import useAppSelector from "../../hooks/useAppSelector";
import useKeyBy from "../../hooks/useKeyBy";
import { getFormattedDateTimeStr } from "../../utils";
import useJobDetails from "./useJobDetails";
import { useParams } from "react-router-dom";

const TicketHistoryModal = ({
  show,
  onClose,
  activeStep,
  onActiveStepChange,
  ticketSteps = [],
  propertyId,
}) => {
  const { ticketGroupId, ticketId } = useParams();
  const [questionsHistory, setQuestionsHistory] = useState([]);
  const [ticketStatusHistory, setTicketStatusHistory] = useState([]);
  const [stepHistory, setStepHistory] = useState([]);
  const [ticketHistory, setTicketHistory] = useState([]);
  const [ticketGroupHistory, setTicketGroupHistory] = useState([]);
  const [propertyHistory, setPropertyHistory] = useState([]);

  const [qhLoading, setQHLoading] = useState(false);
  const [tshLoading, setTSHLoading] = useState(false);
  const [shLoading, setSHLoading] = useState(false);
  const [accordionLoading, setAccordionLoading] = useState(false);

  const { questionsByStepId } = useJobDetails();

  const usersMap = useAppSelector((state) => state.appData.usersMap);
  const ticketStatusTypes = useAppSelector(
    (state) => state.appData.ticketStatusTypes
  );

  const stepByTicketStepId = useKeyBy(ticketSteps, "ticketStepTypeId");
  const step = stepByTicketStepId[Number(activeStep)];
  const stepId = step?.id;

  const ticketStatusColumns = useMemo(
    () => [
      {
        name: "Status",
        sortable: false,
        cell: (row) => row.status?.name,
      },
      {
        name: "Created Date",
        sortable: false,
        cell: (row) =>
          row.createdDate ? getFormattedDateTimeStr(row.createdDate) : "-",
      },
      {
        name: "Completed Date",
        sortable: false,
        cell: (row) =>
          row.completedDate ? getFormattedDateTimeStr(row.completedDate) : "-",
      },
      {
        name: "User",
        sortable: false,
        cell: (row) =>
          `${usersMap[row.assignedToId]?.firstName || ""} ${usersMap[row.assignedToId]?.lastName || ""
            }`.trim(),
      },
    ],
    [usersMap]
  );

  useEffect(() => {
    if (!show || !questionsByStepId[stepId]) {
      return;
    }
    setQHLoading(true);
    Promise.all(
      questionsByStepId[stepId].map((question) =>
        getChangeHistory("TicketStepQuestionAnswer", question.id)
      )
    )
      .then((questionsHistory) => {
        setQuestionsHistory(
          questionsHistory.map((item, index) => ({
            title:
              questionsByStepId[stepId][index].ticketStepQuestion.description,
            data: item,
          }))
        );
      })
      .finally(() => setQHLoading(false));
  }, [questionsByStepId, stepId, show]);

  useEffect(() => {
    if (!show || !stepId) {
      return;
    }
    setSHLoading(true);
    getChangeHistory("ticketStep", stepId)
      .then((data) => {
        const history = data.map((record) => {
          if (record.newValue) {
            const user = usersMap[record.newValue];
            if (user) record.newValue = `${user.firstName} ${user.lastName}`;
          }

          if (record.oldValue) {
            const user = usersMap[record.oldValue];
            if (user) record.oldValue = `${user.firstName} ${user.lastName}`;
          }
          return record;
        });
        setStepHistory(history);
      })
      .finally(() => setSHLoading(false));
  }, [stepId, usersMap, show]);

  useEffect(() => {
    if (ticketStatusHistory.length || !ticketId || !show) {
      return;
    }
    setTSHLoading(true);
    getTicketStatusChangeHistory(ticketId)
      .then((data) => {
        const statusHistory = data.map((item) => {
          const status = ticketStatusTypes
            ? ticketStatusTypes.find(
              (status) => status.id === item.ticketStatusTypeId
            )
            : item.ticketStatusTypeId;
          const user = usersMap[item.assignedToId];
          return {
            ...item,
            status,
            user,
          };
        });
        statusHistory.sort((item) => (!!item.completedDate ? -1 : 1));
        setTicketStatusHistory(statusHistory);
      })
      .finally(() => setTSHLoading(false));
  }, [ticketId, show, ticketStatusHistory]);

  useEffect(() => {
    if (!show) {
      return;
    }
    setAccordionLoading(true);
    Promise.all([
      getChangeHistory("Ticket", ticketId),
      getChangeHistory("TicketGroup", ticketGroupId),
      getChangeHistory("Property", propertyId),
    ])
      .then(([ticketH, ticketGroupH, propertyH]) => {
        setTicketHistory(ticketH);
        setTicketGroupHistory(ticketGroupH);
        setPropertyHistory(propertyH);
      })
      .finally(() => setAccordionLoading(false));
  }, [show]);

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton />
      <Modal.Body>
        <h4>Ticket Status History</h4>
        <LoadingWrapper loading={tshLoading}>
          <RDataTable
            data={ticketStatusHistory}
            columns={ticketStatusColumns}
          />
        </LoadingWrapper>
        <LoadingWrapper loading={accordionLoading}>
          <Accordion>
            <Accordion.Item eventKey="ticket">
              <Accordion.Header>Ticket History</Accordion.Header>
              <Accordion.Body>
                <DataTable data={ticketHistory} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="ticketGroup">
              <Accordion.Header>Ticket Group History</Accordion.Header>
              <Accordion.Body>
                <DataTable data={ticketGroupHistory} />
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="property">
              <Accordion.Header>Property History</Accordion.Header>
              <Accordion.Body>
                <DataTable data={propertyHistory} />
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </LoadingWrapper>
        <h4 className="mt-3">Step History</h4>
        <LoadingWrapper loading={shLoading}>
          <StepsCustomNav
            ticketSteps={ticketSteps}
            stepsActiveKey={activeStep}
            setStepsActiveTabKey={onActiveStepChange}
          />
          <DataTable data={stepHistory} />
        </LoadingWrapper>
        <LoadingWrapper loading={qhLoading}>
          {questionsHistory.map((questionHistory, index) => (
            <React.Fragment key={`questions-history-${index}`}>
              <h4 className="mt-3">{questionHistory.title}</h4>
              <DataTable data={questionHistory.data} />
            </React.Fragment>
          ))}
        </LoadingWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TicketHistoryModal;
