import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { Navigation2, Trash2 } from "react-feather";
import NavbarDropdown from "../navbar/NavbarDropdown";
import { TicketRouteContext } from "../../contexts/TicketRouteContext";

const RouteTickets = ({}) => {
  const { tickets, removeTicket, openRoute, clear } = useContext(TicketRouteContext);

  if (!tickets.length) {
    return null;
  }

  return (
    <div>
      <NavbarDropdown
        Icon={() => <Navigation2 color="white" />}
        showBadge
        badgeClassName="bg-white text-dark"
        count={tickets.length}
      >
        <div className="p-2">
          <div className="d-flex justify-content-between mb-3">
            <Button onClick={clear} variant="danger" className="btn-primary">Clear all</Button>
            <Button onClick={openRoute}>Create route</Button>
          </div>
          {tickets.map((ticket, index) => (
            <div key={ticket.id}>
              {!!index && <hr className="my-2" />}
              <div className="d-flex justify-content-between">
                <span>
                  {`${ticket.property.houseNumber || ""} ${
                    ticket.property.street || ""
                  }`.trim()}
                </span>
                <Trash2
                  className="text-danger"
                  size={20}
                  style={{ cursor: "pointer" }}
                  onClick={() => removeTicket(ticket.id)}
                />
              </div>
            </div>
          ))}
        </div>
      </NavbarDropdown>
    </div>
  );
};

export default RouteTickets;
