import moment from 'moment-timezone'
import { toast } from 'react-toastify';
import { TICKET_STATUSES } from '../constants';

const IS_PAID_ENUM = {
    All: null,
    true: true,
    false: false,
};
Object.freeze(IS_PAID_ENUM);

const API_STATUS = {
    UNINITIALIZED: 'uninitialized',
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error'
};
Object.freeze(API_STATUS)

const ticketSteps = [{
    value: 1,
    label: 'Step 1'
}, {
    value: 2,
    label: 'Step 2'
},
{
    value: 3,
    label: 'Step 3'
},
{
    value: 4,
    label: 'Step 4'
},
{
    value: 5,
    label: 'Step 5'
}]
Object.freeze(ticketSteps)

const getCustomerContactObj = () => ({
    id: 0,
    firstName: "",
    lastName: "",
    isDefault: false,
    customerContactEmails: [
        {
            email: ""
        }
    ],
    customerContactPhoneNumbers: [
        {
            phone: ""
        }
    ]
})

const getAppraisalContactObj = () => ({
    id: 0,
    ticketId: 0,
    firstName: "",
    lastName: "",
    notes: "",
    email: "",
    phone: "",
    appraisalContactType: 0
})

const getInspectionFormObj = () => ({
    id: 0,
    inspectorId: "",
    ticketId: 0,
    type: "",
    date: null,
    /** @type {import('moment').Moment|null} */
    time: null,
    note: "",
    completed: false,
    officeReceivedPhotosAndInfo: false,
    scheduled: false,
    done: false
})

const getQuotesObj = (ticketId) => ({
    id: 0,
    ticketId,
    price: 0,
    date: null,
    quoteTimeSlotId: "1",
    isAccepted: false
})

const getInspectionContactObj = () => ({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    note: ""
})

const yesterday = moment().subtract(1, 'day');
const IsFutureDate = current => {
    return current.isAfter(yesterday);
};

const getJobsFilterObj = () => (Object({
    sortOptions: {
        property: "priority",
        type: 0
    },
    name: "",
    dueDateFilterType: 0,
    dueDateDays: 0,
    showMyJobs: true,
    userId: null,
    inspectionFilterType: 0,
    inspectionDateDays: 0,
    inspectorId: "",
    propertyFilter: {
        city: "",
        state: "",
        county: "",
        neighborhood: "",
        parcelId: "",
        fileNumber: "",
        street: "",
        zip: "",
        type: null,
        valueTypeIds: [],
        propertyTypeId: [],
        jobPurposeId: 0
    },
    customerFilter: {
        name: "",
        address: "",
        defaultEmail: "",
        defaultPhone: "",
        firstName: "",
        lastName: "",
        contactEmail: "",
        contactPhone: ""
    },
    totalPrice: {
        value: 0,
        mode: 0
    },

    ticketStatuses: [
        TICKET_STATUSES.ACTIVE,
        TICKET_STATUSES.REVISION,
        /**8,**/
        TICKET_STATUSES.FINALIZE
    ],
    isPaid: null,
    displayRelatedTickets: false,
    ticketStepFilters: [],
    includeInspections: false,
    showSnoozedJobs: false,
    currentView: 0,
    employeeIds: [],
    fromCreatedDate: null,
    toCreatedDate: null
}));

const getDateString = (str) => str ? new Date(str).toLocaleDateString('en-US') : ""

const getDataType = (value) => {
    if (typeof (value) === 'string') {
        if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false')
            return 'bool'
        else if (isNaN(Date.parse(value)) === false) {
            return 'dateTime'
        }
        return 'string'
    }
    return typeof (value)
}

const getTimeFormat = ({ short = false, chat = false }, date) => {
    if (short) {
        return "MM/DD/YYYY";
    }
    if (chat) {
        const today = moment.utc().clone().tz("America/New_York");
        if (today.diff(date, "day") > 1) {
            return "MM/DD/YYYY hh:mm A";
        }
        return "MM/DD/YYYY hh:mm A";
    }
    return "MM/DD/YYYY hh:mm A";
}

const getFormattedDateTimeStrV2 = (dateStr, params = {}) => {
    return dateStr ?? "";
    // console.log(dateStr);

    // //const displayCutoff = utcCutoff.clone().tz("America/New_York");
    // const format = "MM/DD/YYYY hh:mm A"; //getTimeFormat(params, utcCutoff);
    // return utcCutoff.format(format); //? moment.utc(dateStr).local().format('MM/DD/YYYY hh:mm A') : ""
};

const getFormattedInspectionDate = (dateStr) => {
    const utcCutoff = moment(dateStr);
    return utcCutoff.format("MM/DD/YYYY hh:mm A");
}

const getFormattedAsOfPreviousDate = (dateStr) => {
    const utcCutoff = moment(dateStr);
    return utcCutoff.format("MM/DD/YYYY");
}

const getFormattedDateTimeStrDateOnly = (dateStr, params = {}) => {
    const utcCutoff = moment(dateStr, "YYYYMMDD");
    //const displayCutoff = utcCutoff.clone().tz("America/New_York");
    const format = getTimeFormat(params, utcCutoff);
    return utcCutoff.format(format); //? moment.utc(dateStr).local().format('MM/DD/YYYY hh:mm A') : ""
};

const getFormattedDateTimeStr = (dateStr, params = {}) => {
    if (!dateStr) {
        return "N/A";
    }

    const utcCutoff = moment.utc(dateStr, "YYYYMMDD HH:mm:ss");
    const displayCutoff = utcCutoff.clone().tz("America/New_York");
    const format = getTimeFormat(params, displayCutoff);
    return displayCutoff.format(format); //? moment.utc(dateStr).local().format('MM/DD/YYYY hh:mm A') : ""
};

const getFormattedDateTimeStrV3 = (dateStr, params = {}) => {
    if (!dateStr) {
        return "N/A";
    }

    const utcCutoff = moment.utc(dateStr, "YYYYMMDD HH:mm:ss");
    const displayCutoff = utcCutoff.clone();
    const format = getTimeFormat(params, displayCutoff);
    return displayCutoff.format(format);
};

const filtersObj = Object.freeze(getJobsFilterObj())

const stringToJSON = (str) => {
    if (str)
        try {
            return JSON.parse(str);
        } catch (err) {
            localStorage.removeItem("ValuFlow_Filters")
            return null
        }
    return null
}

const getFiltersObjFromStorage = () => {
    return stringToJSON(localStorage.getItem("ValuFlow_Filters")) || filtersObj
}

const keyById = (collection) => {
    const map = {};
    collection.forEach((item) => {
        map[item.id] = item;
    });
    return map;
}

const apiErrorHandler = (err) => {
    const message =
        err?.[0]?.description || err.message || "Something went wrong";
    toast.error(message);
}

function numberWithCommas(x) {
    if (!x) {
        return "N/A";
    }

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const urlToFile = (url, filename, mimeType) =>
    fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }));

const levenshteinDistance = (s, t) => {
    if (!s.length) return t.length;
    if (!t.length) return s.length;
    const arr = [];
    for (let i = 0; i <= t.length; i++) {
        arr[i] = [i];
        for (let j = 1; j <= s.length; j++) {
            arr[i][j] =
                i === 0
                    ? j
                    : Math.min(
                        arr[i - 1][j] + 1,
                        arr[i][j - 1] + 1,
                        arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
                    );
        }
    }
    return arr[t.length][s.length];
};

export {
    IS_PAID_ENUM,
    API_STATUS,
    ticketSteps,
    stringToJSON,
    getFiltersObjFromStorage,
    getCustomerContactObj,
    getJobsFilterObj,
    getAppraisalContactObj,
    getInspectionFormObj,
    getQuotesObj,
    IsFutureDate,
    getDateString,
    getInspectionContactObj,
    getDataType,
    getFormattedDateTimeStr,
    getFormattedDateTimeStrV2,
    getFormattedDateTimeStrV3,
    getFormattedDateTimeStrDateOnly,
    keyById,
    apiErrorHandler,
    urlToFile,
    numberWithCommas,
    getFormattedInspectionDate,
    levenshteinDistance,
    getFormattedAsOfPreviousDate
};