import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import debounce from "lodash/debounce";

const TextQuestion = ({ onChange, value, label, disabled }) => {
  const onChangeDebounce = useCallback(debounce(onChange, 1), [onChange]);

  return (
    <Form.Group className="mb-2">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        onChange={(e) => onChangeDebounce(e.target.value)}
        defaultValue={value || ""}
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default TextQuestion;
