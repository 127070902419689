import React, { useRef, useEffect, memo } from "react";
import ReactDOM from "react-dom";
import { Card } from "react-bootstrap";

import GoogleMapReact from "google-map-react";

let panorama;
const Streetview = ({ latPos, lngPos, children }) => {
  const panoramaRef = useRef(null);

  const getMapOptions = () => {
    return {
      fullscreenControl: true,
      mapTypeControl: true,
      scaleControl: true,
      scrollwheel: false,
      streetViewControl: true,

    };
  };

  useEffect(() => {
    var upd = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
      clearInterval(upd)
    }, 5000)
  }, [])

  function processSVData({ data }) {
    const location = data.location;

    // window.google.map.setCenter({
    //   lat: data?.location?.latLng?.lat() || 40.702159,
    //   lng: data?.location?.latLng?.lng() || -73.9578828,
    // });
    // window.google.map.setZoom(14);

    panorama.setPano(location.pano);
    panorama.setPov({
      heading: 270,
      pitch: 10,
    });
    panorama.setVisible(true);
  }

  const apiIsLoaded = (map, maps) => {
    if (map) {
      const latLng = { lat: latPos || 40.702159, lng: lngPos || -73.9578828 };
      const sv = new maps.StreetViewService();
      panorama = new maps.StreetViewPanorama(
        ReactDOM.findDOMNode(panoramaRef.current),
        {
          position: {
            lat: latPos || 40.702159,
            lng: lngPos || -73.9578828,
          },
          visible: true,
        }
      );

      map.setStreetView(panorama);

      // Set the initial Street View camera to the center of the map
      sv.getPanorama({
        location: latLng,
        radius: 50,
        source: maps.StreetViewSource.OUTDOOR,
      }).then(processSVData);

      // sv.getPanoramaByLocation(latLng, 200).then(processSVData);
    }
  };

  return (
    <Card style={{ marginBottom: 0, height: "100%" }}>
      {/* <Card.Header>
        <Card.Title tag="h5">Streetview</Card.Title>
        <h6 className="card-subtitle text-muted">
          Panoramic 360 degree views from designated roads throughout its
          coverage area.
        </h6>
      </Card.Header> */}
      <Card.Body className="pt-0 pb-0 job-card-body">
        <div style={{ height: 150, width: "100%" }}>
          <GoogleMapReact
            key={latPos}
            ref={panoramaRef}
            options={getMapOptions}
            defaultCenter={{
              lat: latPos || 40.702159,
              lng: lngPos || -73.9578828,
            }}
            defaultZoom={1}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            yesIWantToUseGoogleMapApiInternals={true}
          />
        </div>
        {children}
      </Card.Body>
    </Card>
  );
};

export default memo(Streetview);
