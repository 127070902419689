import React, { useState, useRef, useEffect, useImperativeHandle } from "react";

import { Dropdown, ListGroup } from "react-bootstrap";

const NavbarDropdown = React.forwardRef(({
  children,
  count,
  showBadge,
  footer,
  Icon,
  badgeClassName = "",
  size = "lg",
  toggleAs = "a",
  toggleClassName = "",
  dropdownMenuClassName = "",
  openOnHover = false,
}, ref) => {
  const [show, setShow] = useState(false);

  const hoverableProps = {
    show,
    onMouseOver: () => setShow(true),
    onMouseLeave: () => setShow(false),
  }

  const dropdownRef = useRef(null);

  const hoverablePropsMenu = {
    onClick: () => setShow(false)
  }

  const openDropdown = () => {
      setShow(true);
  };
    
  const closeDropdown = () => {
      setShow(false);
  };

  // Expose bubbleDropdown function through the ref
  useImperativeHandle(ref, () => ({
      bubbleDropdown: () => {
          if (show === false) {
              openDropdown();
              setTimeout(() => {
                  closeDropdown();
              }, 2000)
          }
      },
  }));

  const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          closeDropdown();
      }
  };

  useEffect(() => {
      // Attach the event listener when the component mounts
      document.addEventListener('mousedown', handleClickOutside);

      // Detach the event listener when the component unmounts
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);

  return (
      <Dropdown ref={dropdownRef} show={show} onSelect={closeDropdown}
      className="nav-item"
      align="end"
      {...(openOnHover ? hoverableProps : {})}
    >
      <Dropdown.Toggle  onClick={openDropdown}
        as={toggleAs}
        className={`nav-link nav-icon dropdown-toggle ${toggleClassName}`}
      >
        <div className="position-relative">
          <Icon />
          {showBadge ? (
            <span className={`indicator ${badgeClassName}`}>{count}</span>
          ) : null}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        drop="end"
        className={`dropdown-menu-${size} py-0 ${dropdownMenuClassName}`}
        {...(openOnHover ? hoverablePropsMenu : {})}
      >
        <ListGroup
          className="overflow-auto"
          style={{ maxHeight: "calc(100vh - 100px)" }}
        >
          {children}
        </ListGroup>
        <Dropdown.Header className="dropdown-menu-footer">
          <span className="text-muted">{footer}</span>
        </Dropdown.Header>
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default NavbarDropdown;
