import React, { useEffect, useRef } from "react";
// import { GoogleApiWrapper } from "google-maps-react";
// import { API_KEY, VERSION } from "../../constants/ApiKey";

// const LoadingContainer = (props) => <div>Loading...</div>;

const formatLocation = (location) => {
  if (location?.geometry?.location) {
    const { lat, lng } = location?.geometry?.location;

    const formattedLocation = {
      lat: lat(),
      lng: lng(),
      address: location.formatted_address,
      googlePlaceId: location.place_id,
    };

    let neighborhoodFound = false
    location.address_components.forEach((address_component) => {
      if (address_component.types.includes("street_number")) {
        formattedLocation.houseNumber = address_component.long_name
      }
      if (address_component.types.includes("route")) {
        formattedLocation.street = address_component.long_name
      }
      if (address_component.types.includes("neighborhood")) {
        neighborhoodFound = true
        formattedLocation.neighborhood = address_component.long_name
      }
      if (address_component.types.includes("sublocality_level_1")) {
        formattedLocation.city = address_component.long_name
      }
      else if (address_component.types.includes("political") && address_component.types.includes("locality")) {
        formattedLocation.city = address_component.long_name
      }
      if (address_component.types.includes("administrative_area_level_2")) {
        formattedLocation.county = address_component.long_name
      }
      if (address_component.types.includes("administrative_area_level_1")) {
        formattedLocation.state = address_component.long_name
      }
      if (address_component.types.includes("postal_code")) {
        formattedLocation.zip = address_component.long_name
      }
    })

    if (!neighborhoodFound) {
      let localityComp = location.address_components.find((address) => address.types.includes("locality"))
      formattedLocation.neighborhood = localityComp ? localityComp.long_name : ""
    }

    return formattedLocation;
  }
};

const GoogleAutoComplete = (props) => {
  const { value, onChange, classes = "", name, placeholder, unit, disableUnit = false } = props;
  const placeInputRef = useRef(null);

  useEffect(() => {
    const initPlaceAPI = () => {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef.current
      );
      //   autocomplete.setComponentRestrictions({
      //     country: ["us"],
      //   });
      new window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          let place = autocomplete.getPlace();
          props.onLocationSelect(formatLocation(place));
        }
      );
    };

    initPlaceAPI();
  }, [props.properties]);

  const handleInputFocus = () => {
    placeInputRef.current.setAttribute("autoComplete", "off");
  };

  return (
    <div style={{ display: "flex", background: "#efefef" }}>
      <input
        ref={placeInputRef}
        value={value || ""}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        // required
        className={`form-control ${classes}`}
        type="text"
        autoComplete="off"
        style={disableUnit ? {} : { width: "80%" }}
        onFocus={handleInputFocus}
      />
      {!disableUnit && (
        <h5
          style={{
            maxWidth: 65,
            lineHeight: "35px",
            height: 35,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {unit}
        </h5>
      )}
    </div>
  );
};

export default GoogleAutoComplete;
// export default GoogleApiWrapper({
//   apiKey:API_KEY,
//   // LoadingContainer: LoadingContainer,
//   v: VERSION,
// })(GoogleAutoComplete);
