import axios from "../utils/axios";

export function getQuotesByTicketId(ticketId) {
  return axios.get(`/TicketQuote/GetQuotesByTicketId?ticketId=${ticketId}`);
}

export function updateQuotes(quote) {
  return axios.post("/TicketQuote/UpdateQuotes", quote);
}

export function deleteQuote(quoteId) {
  return axios.post(`/TicketQuote/DeleteQuote?quoteId=${quoteId}`);
}
