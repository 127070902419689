import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getAllCompTypeColumns,
  getCompTypeExportColumns,
  updateCompTypeExportColumns,
} from "../../../services/comp/CompType";
import { loadCompTypes } from "../../../redux/actions/comps";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import CompTypeColumns from "./CompTypeColumns";

const CompTypeExportColumnsPage = () => {
  const params = useParams();
  const compTypeId = Number(params.id);
  const dispatch = useAppDispatch();

  const [allCompTypesColumns, setAllCompTypesColumns] = useState([]);
  const [compTypeExportColumns, setCompTypeExportColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const compTypes = useAppSelector((state) => state.comps.compTypes);

  const compType = useMemo(
    () => compTypes.find((item) => item.id === compTypeId),
    [compTypes, compTypeId]
  );

  useEffect(() => {
    dispatch(loadCompTypes());
    getAllCompTypeColumns().then(setAllCompTypesColumns);
  }, [dispatch]);

  useEffect(() => {
    getCompTypeExportColumns(compTypeId).then(setCompTypeExportColumns);
  }, [compTypeId]);

  const handleUpdate = useCallback(
    (data) => {
      setLoading(true);
      updateCompTypeExportColumns(params.id, data).finally(() =>
        setLoading(false)
      );
    },
    [params.id]
  );

  return (
    <CompTypeColumns
      title="Comp Type Columns"
      columnsTitle={compType?.name}
      allColumnsTitle="All Comp Type Columns"
      compTypeColumns={compTypeExportColumns}
      allCompTypesColumns={allCompTypesColumns}
      onUpdate={handleUpdate}
      loading={loading}
    />
  );
};

export default CompTypeExportColumnsPage;
