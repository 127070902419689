import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTicketById, updateIsPaid, updateInspectionRequired } from "../../services/ticket";
import { getTicketGroupById, updateTicketGroupAffiliateInfo, sendTicketGroupAffiliateEmail } from "../../services/ticketGroup";
import { getTicketStepQuestionAnswers } from "../../services/ticketStepQuestion";
import useAppSelector from "../../hooks/useAppSelector";

export const JobDetailsContext = React.createContext({
  ticketInfo: {},
  ticketGroup: {},
  propertyInfo: {},
  customerInfo: {},
  questionsByStepId: {},
  onQuestionsChange: (value, stepId) => {},
  loading: false,
  loadTicketGroup: () => {},
  loadTicket: (cb) => {},
  updateTicket: (cb) => {},

  propertyTypeStr: "",
  propertySubTypeStr: "",
  jobPurposeStr: "",
  assignmentTypeStr: "",
  jobName: "",

  generalInfoKey: "Property",
  setGeneralInfoKey: (generalInfoKey) => {},
  async handleUpdateIsPaid(isPaid) {},
  async handleUpdateAffiliatePaymentStatus(status) {},
  handleInspectionRequiredUpdate(value) {},
  handleInspectionStatusNoteChange(value) {},
});

export const JobDetailsProvider = ({ children }) => {
  const { ticketGroupId, ticketId } = useParams();
  const propertyTypes = useAppSelector((state) => state.appData.propertyTypes);
  const propertySubTypes = useAppSelector(
    (state) => state.appData.propertySubTypes
  );
  const jobPurposes = useAppSelector((state) => state.appData.jobPurposes);
  const assignmentTypes = useAppSelector(
    (state) => state.appData.assignmentTypes
  );

  const [ticketInfo, setTicketInfo] = useState({});
  const [ticketGroup, setTicketGroup] = useState({});
  const [questionsByStepId, setQuestionsByStepId] = useState({});
  const [generalInfoKey, setGeneralInfoKey] = useState("Property");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const loadTicketGroup = useCallback(() => {
    setLoading(true);
    getTicketGroupById(ticketGroupId)
      .then((data) => {
        if (!data) {
          navigate("/");
          return;
        }
        setTicketGroup(data);
      })
      .catch((err) => {
        console.error(err, "loadTicketGroup");
        navigate("/");
      })
      .finally(() => setLoading(false));
  }, [ticketGroupId]);

  const loadTicket = useCallback(
    (cb) => {
      getTicketById(ticketId)
        .then((data) => {
          if (!data) {
            navigate("/");
            return;
          }
          setTicketInfo(data);
          Promise.all(
            data.ticketSteps.map((step) =>
              getTicketStepQuestionAnswers(step.id)
            )
          ).then((stepQuestions) => {
            setQuestionsByStepId(
              data.ticketSteps.reduce((acc, item, index) => {
                acc[item.id] = stepQuestions[index];
                return acc;
              }, {})
            );
          });

          if (typeof cb === "function") {
            cb();
          }
        })
        .catch((err) => {
          console.error(err, "loadTicket");
          navigate("/");
        });
    },
    [ticketId]
  );

  useEffect(() => {
    loadTicket();
    loadTicketGroup();
  }, [loadTicket, loadTicketGroup]);

  const propertyInfo = ticketInfo.property || {};
  const customerInfo = propertyInfo?.customer || {};

  const propertyTypeStr = useMemo(() => {
    const _propertyType = propertyTypes.find(
      (type) =>
        propertyInfo.propertyTypeId &&
        type.id === Number(propertyInfo.propertyTypeId)
    );
    switch (_propertyType?.type) {
      case 1:
        return `Commercial/${_propertyType.name}`;
      case 2:
        return `Residential/${_propertyType.name}`;
      case 3:
        return _propertyType.name;
      case 4:
        return _propertyType.name;
      default:
        return "";
    }
  }, [propertyInfo.propertyTypeId, propertyTypes]);
  const propertySubTypeStr = useMemo(
    () =>
      (propertySubTypes[propertyInfo.propertyTypeId] || []).find(
        (item) =>
          propertyInfo.propertySubTypeId &&
          item.id === propertyInfo.propertySubTypeId
      )?.name || "",
    [
      propertySubTypes,
      propertyInfo.propertyTypeId,
      propertyInfo.propertySubTypeId,
    ]
  );

  const jobPurposeStr = useMemo(
    () =>
      jobPurposes.find(
        (jobPurpose) =>
          ticketInfo.jobPurposeId &&
          jobPurpose.id === Number(ticketInfo.jobPurposeId)
      )?.name || "",
    [jobPurposes, ticketInfo.jobPurposeId]
  );

  const assignmentTypeStr = useMemo(
    () =>
      assignmentTypes.find(
        (jobPurpose) =>
          ticketInfo.jobAssignmentTypeId &&
          jobPurpose.id === Number(ticketInfo.jobAssignmentTypeId)
      )?.name || "",
    [assignmentTypes, ticketInfo.jobAssignmentTypeId]
  );

  const jobName = useMemo(() => {
    if (ticketInfo.customTitle) {
      return ticketInfo.customTitle;
    }
    if (!propertyInfo.houseNumber && !propertyInfo.street && ticketGroup.name) {
      return ticketGroup.name;
    }
    return [propertyInfo.houseNumber, propertyInfo.street, propertyInfo.unit]
      .filter(Boolean)
      .join(" ");
  }, [propertyInfo.id, ticketGroup.name, ticketInfo]);

  const updateTicket = (cb) => {
    loadTicket(cb);
    loadTicketGroup();
  };

  const handleUpdateIsPaid = async (isPaid) => {
    await updateIsPaid(ticketId, isPaid);
    setTicketInfo((ticket) => ({
      ...ticket,
      isPaid,
    }));
  };
  
  const handleUpdateAffiliateInfo = async (affiliateInfo) => {
    await updateTicketGroupAffiliateInfo(ticketGroupId, affiliateInfo);
    
    setTicketGroup({
      ...ticketGroup,
      affiliateInfo: {...affiliateInfo},
    });
  };
  
  const handleSendTicketGroupAffiliateEmail = async () => {
    await sendTicketGroupAffiliateEmail(ticketGroupId);
  };

  const handleInspectionRequiredUpdate = async (value) => {
    setTicketInfo({ ...ticketInfo, isInspectionRequired: value });
    await updateInspectionRequired(ticketInfo.id, value);
  };

  const handleInspectionStatusNoteChange = (value) => {
    setTicketInfo({ ...ticketInfo, inspectionStatusNotes: value });
  };

  return (
    <JobDetailsContext.Provider
      value={{
        ticketInfo,
        ticketGroup,
        questionsByStepId,
        loading,
        loadTicketGroup,
        loadTicket,

        propertyTypeStr,
        propertySubTypeStr,
        jobPurposeStr,
        assignmentTypeStr,
        jobName,

        propertyInfo,
        customerInfo,
        onQuestionsChange: (value, stepId) => {
          setQuestionsByStepId((oldValue) => ({
            ...oldValue,
            [stepId]: value,
          }));
        },
        generalInfoKey,
        setGeneralInfoKey,
        updateTicket,
        handleUpdateIsPaid,
        handleUpdateAffiliateInfo,
        handleSendTicketGroupAffiliateEmail,
        handleInspectionRequiredUpdate,
        handleInspectionStatusNoteChange,
      }}
    >
      {children}
    </JobDetailsContext.Provider>
  );
};

export default function useJobDetails() {
  return useContext(JobDetailsContext);
}
