import axios from "axios";
import { SERVER_URL, GRAPH_API_URL } from "../configs";
import { setSession } from "./jwt";

export const getMSTeamsIds = async ticketStepId => {
    const url = `${SERVER_URL}/api/TicketStep/GetMSTeamsIds`;
    const response = await axios.get(url, {
        params: {
            ticketStepId
        }
    });

    return response.data;
}

export const createTicketStepConversation = async (ticketStepId, message) => {
    const url = `${SERVER_URL}/api/TicketStep/CreateMSTeamsConversation`;
    const response = await axios.post(url, {}, {
        params: {
            ticketStepId,
            message
        }
    });

    return response.data;
}

const getDefaultRequestOptions = () => {
    const msTeamsToken = window.localStorage.getItem('ms_accessToken');

    return ({
        headers: {
            "Authorization": `Bearer ${msTeamsToken}`,
            "Content-Type": "application/json"
        },
        transformRequest: (data, headers) => {
            delete headers.common['MSAuthorization'];
            return data;
        }
    })
};

export const refreshMSTeamsAccessToken = async () => {
    const msRefreshToken = localStorage.getItem('ms_RefreshToken');
    const token = localStorage.getItem("accessToken");
    if (!msRefreshToken) {
        return;
    }

    const url = `${SERVER_URL}/api/Auth/RefreshMSTeamsAccessToken`;
    const response = await axios.get(url, { params: { refreshToken: msRefreshToken } });

    const { access_token, refresh_token } = response.data;
    //console.error(access_token, refresh_token);
    setSession(token, access_token, refresh_token);
}

export const replyTo = async (teamId, channelId, selectedThread, replyMessage, mentions) => {
    let requestBody = {
        body: {
            contentType: mentions.length > 0 ? 'html' : null,
            content: replyMessage
        },
        mentions
    };

    const requestUrl = `${GRAPH_API_URL}/teams/${teamId}/channels/${channelId}/messages/${selectedThread}/replies`;
    let response = await axios.post(requestUrl, JSON.stringify(requestBody), getDefaultRequestOptions());

    return response.data;
}

export const createTicketStatusConversation = async (ticketId, ticketStatusTypeId) => {
    const params = new URLSearchParams({ ticketId, ticketStatusTypeId });
    const url = `${SERVER_URL}/api/Ticket/CreateTicketStatusConversation?${params}`;
    return await axios.post(url, {});
}

export const replyToRepublic = async (teamId, channelId, selectedThread, replyMessage, mentions, msgId) => {
    let requestBody = {
        id: msgId,
        body: {
            contentType: mentions.length > 0 ? 'html' : null,
            content: replyMessage
        },
        mentions,
        channelIdentity: {
            channelId: channelId
        },
        from: {
            user: { id: '' }
        }
    };

    const requestUrl = `${SERVER_URL}/api/task/CreateTaskFromMSTeamsMessage`;
    let response = await axios.post(requestUrl, JSON.stringify(requestBody), { headers: { "Content-Type": "application/json" } });

    return response.data;
};


export const loadThreads = async (teamId, channelId) => {
    const requestUrl = `${GRAPH_API_URL}/teams/${teamId}/channels/${channelId}/messages`;
    const response = await axios.get(requestUrl, getDefaultRequestOptions());

    return response.data;
}

export const getReplies = async (teamId, channelId, threadId) => {
    const requestUrl = `${GRAPH_API_URL}/teams/${teamId}/channels/${channelId}/messages/${threadId}/replies`;
    const response = await axios.get(requestUrl, getDefaultRequestOptions());
    return response.data;
}