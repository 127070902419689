import React, { useState, useEffect, useContext } from "react";
import "./styles.css";
import { Button, Form, Spinner, Pagination, Card } from "react-bootstrap";
import axios from "../../utils/axios";

//** Icons */
import { Save, Trash2, Download } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// Components
import JobFilters from '../../components/jobFilters';
import TicketList from "./TicketList";
import CustomSwitch from "../../components/CustomSwitch";
import TicketMapView from "./TicketMapView";
import TasksBoardComponent from "../admin/customPages/TasksBoardComponent";

import CustomAccordion from "../../components/accordion";

// Configs
import { API_STATUS, getJobsFilterObj, getFiltersObjFromStorage, stringToJSON } from "../../utils";

// Third Party Imports
import { toast } from "react-toastify"

//** Hooks */
import useAppDispatch from "../../hooks/useAppDispatch";
import { loadTicketGroups, loading } from "../../redux/actions/ticketGroups";
import useAppSelector from "../../hooks/useAppSelector";
import { updateSearchFilter, updateAppliedFilter } from "../../redux/actions/search";
import useLocalStorage from "../../hooks/useLocalStorage";

//** Context */
import { AuthContext } from "../../contexts/JWTContext"
import { useTranslation } from "react-i18next";
import { TicketRouteContext } from "../../contexts/TicketRouteContext";

import { sortBy } from "lodash";
import { getAssignedUserModel } from "./helpers";

const FrontDesk = () => {
  const { ticketGroups, status, totalPages, pageIndex, pageSize, totalCount } = useAppSelector(state => state.ticketGroups)
  const [gsData, handleGSChange] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const [results, setResults] = useState(pageSize)
  const [currentView, setCurrentView] = useLocalStorage(0);
  const [tasksSort, setTasksSort] = useLocalStorage(0);
  const [exportLoading, setExportLoading] = useState(false);

  const { t } = useTranslation();
  const context = useContext(AuthContext);
  const { clear: clearTicketRoutes } = useContext(TicketRouteContext);
  const { filters, appliedFilters } = useAppSelector(state => state.search)
  const {
    users,
    jobPurposes,
    valueOptions,
    portfolios,
    propertyTypesMap,
    propertySubTypes,
    ticketStatusTypes,
    assignmentTypes,
  } = useAppSelector((state) => state.appData);
  const dispatch = useAppDispatch();

  const filtersDefaults = getFiltersObjFromStorage()

  const _selectedSteps = []
  const _selectedWithCompletedSteps = []

  filters?.ticketStepFilters?.forEach((step) => {
    _selectedSteps.push({ label: `Step ${step.ticketStepTypeId}`, value: step.ticketStepTypeId })
  })

  filters?.ticketStepFilters?.forEach((step) => {
    step.completed && _selectedWithCompletedSteps.push({ label: `Step ${step.ticketStepTypeId}`, value: step.ticketStepTypeId })
  })

  useEffect(() => {
    if (currentPage !== pageIndex)
      setCurrentPage(pageIndex)
  }, [pageIndex])

  useEffect(() => {
    if (status === API_STATUS.UNINITIALIZED && context.isAuthenticated && users.length > 0) {
      const _filters = { ...filters };

      setCurrentView(_filters.currentView);
      setTasksSort(_filters.tasksSort);

      dispatch(updateSearchFilter(_filters))
      dispatch(loading())
      dispatch(loadTicketGroups(currentPage, results, _filters))
      clearTicketRoutes();
    }
  }, [currentPage, context.isAuthenticated, results, users])

  const refreshTicketGroups = () => {
    dispatch(loadTicketGroups(currentPage, results, { ...filters }));
  };

  const handlePageChange = (page) => {
    dispatch(loading())
    dispatch(loadTicketGroups(page, results, filters))
    clearTicketRoutes();
    setCurrentPage(page)
  }

  const getPages = () => {

    let startingPoint = currentPage >= 3 ? currentPage - 3 : 0
    const pages = []

    for (let i = startingPoint; i <= currentPage; i++) {
      pages.push(<Pagination.Item key={i} active={currentPage === i} onClick={() => handlePageChange(i)}>{i + 1}</Pagination.Item>)
    }

    if (currentPage < totalPages - 1) {
      let added = 0
      for (let i = currentPage + 1; i < totalPages; i++) {
        pages.push(<Pagination.Item key={i} active={currentPage === i} onClick={() => handlePageChange(i)}>{i + 1}</Pagination.Item>)
        added += 1
        if (added === 3)
          break
      }
    }
    return pages
  }

  const handlePaginationFilterUpdate = (value) => {
    dispatch(loading())
    dispatch(loadTicketGroups(0, value, filters))
    clearTicketRoutes();
    setResults(value)
    localStorage.setItem("ticketGroupSize", value)
  }

  const applyFilters = (newFilters) => {
    let _filters = { ...newFilters }

    if (newFilters.userId === "")
      _filters.userId = null
    if (
      (filters.sortOptions.property !== "priority" &&
        newFilters.sortOptions.property === "priority") ||
      (filters.sortOptions.property === "priority" &&
        filters.sortOptions.type !== newFilters.sortOptions.type)
    ) {
      return;
    }
    dispatch(loading())
    dispatch(loadTicketGroups(0, results, _filters))
    clearTicketRoutes();
  }

  const resetFilters = (e, hard = false) => {
    e.stopPropagation();
    const _filters =
      (!hard && stringToJSON(localStorage.getItem("ValuFlow_Filters"))) ||
      getJobsFilterObj();

    dispatch(updateSearchFilter(_filters))
    applyFilters(_filters)
    dispatch(updateAppliedFilter([]))
    handleGSChange("")
  }

  const handleMyJobs = (checked) => {
    const _filters = getFilterObj("showMyJobs", checked)
    dispatch(updateSearchFilter(_filters))

    applyFilters(_filters)
  }

  const handleViewChange = (event) => {
    let value = +(event.target.value);
    setCurrentView(value);

    const _filters = getFilterObj("currentView", value);
    dispatch(updateSearchFilter(_filters));

    applyFilters(_filters);
  };

  const handleTasksSortChange = (event) => {
    let value = +(event.target.value);
    setTasksSort(value);

    const _filters = getFilterObj("tasksSort", value);
    dispatch(updateSearchFilter(_filters));
  };

  const handleIncludeInspections = (checked) => {
    const _filters = getFilterObj("includeInspections", checked)
    dispatch(updateSearchFilter(_filters))

    applyFilters(_filters)
  }

  const handleDisplayRelatedTickets = (checked) => {
    const _filters = getFilterObj("displayRelatedTickets", checked)
    dispatch(updateSearchFilter(_filters))

    applyFilters(_filters)
  }

  const getFreshFiltersObject = () => {
    return JSON.parse(JSON.stringify(filters))
  }

  const getSubFilterObj = (key1, key2, val) => {
    const _filters = getFreshFiltersObject()

    _filters[key1][key2] = val
    return _filters
  }

  const handlePropertyType = (value) => {
    if (value === "") value = null
    const _filters = getSubFilterObj("propertyFilter", "type", value)
    dispatch(updateSearchFilter(_filters))

    applyFilters(_filters)
  }

  const getFilterObj = (key, value) => {
    const _filters = { ...filters }
    _filters[key] = value

    return _filters
  }

  const updateAppliedFilters = (pairKey, key, value = "") => {
    let _appliedFilters = [...appliedFilters]
    let combinedKey = key
    if (pairKey)
      combinedKey = pairKey + "@" + key
    if (_appliedFilters.indexOf(combinedKey) === -1) {
      _appliedFilters.push(combinedKey)
      dispatch(updateAppliedFilter(_appliedFilters))
    } else {
      let origVal = filtersDefaults[key]
      if (pairKey)
        origVal = filtersDefaults[pairKey][key]
      if ((String(origVal) === String(value) && key !== "ticketStatuses") || (!value.length && key !== "isPaid")) {
        _appliedFilters.splice(_appliedFilters.indexOf(combinedKey), 1)
        dispatch(updateAppliedFilter(_appliedFilters))
      }
    }
  }

  const handleBaseProperty = (key, value) => {
    const _filters = getFilterObj(key, value)
    dispatch(updateSearchFilter(_filters))

    if ([
      "userId",
      "ticketStepFilters",
      "dueDateFilterType",
      "inspectionFilterType",
      "inspectorId",
      "portfolioId",
      "ticketStatuses",
      "isPaid",
      "jobAssignmentTypeId",
      "showSnoozedJobs"
    ].includes(key)) {
      applyFilters(_filters)
    }

    if (
      ["inspectionDateDays", "dueDateDays"].includes(key) ||
      (key === "ticketStatuses" &&
        !value?.length &&
        !appliedFilters.includes("ticketStatuses"))
    ) {
      return;
    }
    updateAppliedFilters(null, key, value)
  }

  const saveFilters = (e) => {
    e.stopPropagation();
    localStorage.setItem("ValuFlow_Filters", JSON.stringify(filters))
    dispatch(updateAppliedFilter([...appliedFilters]))
    toast.success("Filters saved successfully !")
  }

  const handleChildProperty = (pairKey, key, value) => {
    const _filters = getFreshFiltersObject()
    _filters[pairKey][key] = value
    dispatch(updateSearchFilter(_filters))

    if (
      [
        "jobPurposeId",
        "valueTypeIds",
        "mode",
        "property",
        "type",
        "propertyTypeId",
        "propertySubTypeId",
      ].includes(key)
    ) {
      applyFilters(_filters);
    }

    updateAppliedFilters(pairKey, key, value)
  }

  const removeAppliedFilter = (e, key, index) => {
    e.stopPropagation()

    const _filters = getFreshFiltersObject()
    const obj = key.split("@")
    if (obj.length === 1) {
      const propertyName = obj[0]
      _filters[propertyName] = filtersDefaults[propertyName]
      if (propertyName === "ticketStatuses") {
        _filters[propertyName] = [];
      }
    } else if (obj.length === 2) {
      const parentObj = obj[0]
      const propertyName = obj[1]

      _filters[parentObj][propertyName] = filtersDefaults[parentObj][propertyName]
    }

    let _appliedFilters = [...appliedFilters]
    _appliedFilters.splice(index, 1)

    dispatch(updateAppliedFilter(_appliedFilters))
    dispatch(updateSearchFilter(_filters))
    applyFilters(_filters)
  }

  const reloadListWithCurrentFilters = () => {
    applyFilters(filters);
  }

  const getValueFromFltrStr = (fltrStr) => {
    const obj = fltrStr.split("@")
    let propertyValue = ""
    let propertyName = ""

    if (obj.length === 1) {
      propertyName = obj[0]
      propertyValue = filters[propertyName]
    } else if (obj.length === 2) {
      const parentObj = obj[0]
      propertyName = obj[1]

      propertyValue = filters[parentObj][propertyName]
    }

    if (propertyName === "userId" || propertyName === "inspectorId") {
      const _user = users.find((user) => user.id === propertyValue)
      if (_user)
        return `${_user.firstName} ${_user.lastName}`
    } else if (propertyName === "dueDateFilterType") {
      if (propertyValue === "1")
        return "Past due"
      else if (propertyValue === "2")
        return "Due today"
      else if (propertyValue === "3")
        return "Due this week"
      else if (propertyValue === "4")
        return `Due the next ${filters['dueDateDays']} days`
    } else if (propertyName === "jobPurposeId") {
      let _job = jobPurposes.find((job) => job.id === Number(propertyValue))
      return _job?.name
    } else if (propertyName === "propertyTypeId") {
      return filters.propertyFilter.propertyTypeId
        ?.map((id) => propertyTypesMap[id]?.name)
        .join(", ");
    } else if (propertyName === "propertySubTypeId") {
      const propertySubType = (propertySubTypes[filters.propertyFilter.propertyTypeId?.[0]] || []).find((item) => item.id === Number(propertyValue))
      return propertySubType?.name;
    } else if (propertyName === "jobAssignmentTypeId") {
      const assignmentType = (assignmentTypes || []).find((item) => item.id === Number(propertyValue))
      return assignmentType?.name;
    } else if (fltrStr === "totalPrice@mode") {
      if (propertyValue === "1")
        return ` > ${filters["totalPrice"]["value"]}`
      else if (propertyValue === "2")
        return ` < ${filters["totalPrice"]["value"]}`
      else if (propertyValue === "3")
        return ` == ${filters["totalPrice"]["value"]}`
      else if (propertyValue === "4")
        return ` != ${filters["totalPrice"]["value"]}`
    } else if (propertyName === "inspectionFilterType") {
      if (propertyValue === "1")
        return "To Be scheduled"
      else if (propertyValue === "2")
        return "Scheduled Past due"
      else if (propertyValue === "3")
        return "Due today"
      else if (propertyValue === "4")
        return "Due this week"
      else if (propertyValue === "5")
        return `Due the next ${filters['inspectionDateDays']} days`
      else if (propertyValue === "6")
        return "Scheduled"
      else if (propertyValue === "7")
        return "Scheduled For Today"
      else if (propertyValue === "8")
        return "Completed"
    } else if (propertyName === "valueTypeIds") {
      let _selectedValueTypes = []
      filters?.propertyFilter.valueTypeIds.forEach((id) => {
        let _values = valueOptions.find((obj) => obj.value === id)
        _selectedValueTypes.push(_values?.label)
      })

      return _selectedValueTypes.join(', ')
    }
    else if (propertyName === "employeeIds") {
      let _selectedEmployeeIds = []
      filters?.employeeIds.forEach((id) => {
        let _value = users.find((obj) => obj.id === id)
        _selectedEmployeeIds.push(_value?.firstName)
      })

      return _selectedEmployeeIds.join(', ')
    }
    else if (propertyName === "ticketStatuses") {
      const selectedTicketStatuses = []
      filters?.ticketStatuses.forEach((id) => {
        const value = ticketStatusTypes.find((obj) => obj.id === id)
        if (!value) {
          return;
        }
        selectedTicketStatuses.push(value?.name)
      })

      return selectedTicketStatuses.join(', ')
    } else if (propertyName === "ticketStepFilters") {
      return filters?.ticketStepFilters
        ?.map(
          (step) =>
            `Step ${step.ticketStepTypeId} ${step.completed ? "✔" : "✖"}`
        )
        .join(", ");
    } else if (propertyName === "portfolioId") {
      return portfolios.find(item => item.id === +propertyValue)?.name;
    } else if (propertyName === "isPaid") {
      return propertyValue ? "Yes" : propertyValue === false ? "No" : "";
    }
    else if (propertyName === "includeInspections") {
      return propertyValue ? "Yes" : propertyValue === false ? "No" : "";
    }
    else if (propertyName === "showSnoozedJobs") {
      return propertyValue ? "Yes" : propertyValue === false ? "No" : "";
    }
    return propertyValue
  }

  const hardResetFilters = (e) => {
    //e.stopPropagation();
    localStorage.removeItem("ValuFlow_Filters")
    resetFilters(e, true)
    toast.success("Filters set to defaults successfully!")
  }

  const ticketStatusTypesMap = useAppSelector(state => state.appData.ticketStatusTypesMap)

  const getTicketStatusType = (ticket) => {
    const statuses = [...ticket.ticketStatuses];
    const src = sortBy(statuses, 'id');
    return ticketStatusTypesMap[src[(src?.length || 0) - 1]?.ticketStatusTypeId];
  }

  const usersMap = useAppSelector((state) => state.appData.usersMap);

  const [tasksBoardData, setTasksBoardData] = useState();

  useEffect(() => {
    let alltickets = ticketGroups.flatMap(g => g.tickets);

    let data = [];

    alltickets.forEach(ticket => {

      const status = getTicketStatusType(ticket);
      const isStatusForTasks = status && (status.name === 'Active' || status.name === 'Revision' || status.name === 'Finalize');
      const user = getAssignedUserModel(ticket, usersMap)

      if (isStatusForTasks && user) {

        let item = data.find(d => d.user.id === user.id);
        if (item == null) {
          item = { user: user, tickets: [] };
          data.push(item);
        }
        item.tickets.push(ticket);

      }

    })

    if (tasksSort === 0) {
      data.forEach(d => {
        d.tickets = d.tickets.sort((a, b) => {
          let a_date = a.dueDateOverride || a.ticketGroupDueDate;
          let b_date = b.dueDateOverride || b.ticketGroupDueDate;

          if (a_date === null) return 1;
          if (b_date === null) return -1;

          return new Date(a_date) - new Date(b_date);
        });
      })
    }
    else if (tasksSort === 1) {
      data.forEach(d => {
        d.tickets = d.tickets.sort((a, b) => b.priorityIndex - a.priorityIndex);
      })
    }


    data = data.sort((a, b) => b.tickets.length > a.tickets.length);

    setTasksBoardData(data);
  }, [ticketGroups, tasksSort])

  return (
    <div className="frontDesk">
      <div className="px-4" >
        <CustomAccordion header={
          <div className="d-flex justify-content-between w-100 pe-2">
            <div className="d-flex">
              <div className="d-flex align-items-center me-2">
                <Form.Check
                  id="show-my-jobs"
                  className="ps-0"
                  label={<span className="align-middle">Show My Jobs</span>}
                  checked={filters.showMyJobs || (context.isAuthenticated && context.user.roles.includes('restricted'))}
                  disabled={!context.isAuthenticated || context.user.roles.includes('restricted')}
                  onChange={e => handleMyJobs(e.target.checked)}
                />
                {filters.showMyJobs && context.isAuthenticated && context.user.roles.includes('inspector') && (<Form.Check
                  className="ms-2"
                  id="include-inspections"
                  name="includeInspections"
                  label={<span className="align-middle">Inspections</span>}
                  checked={filters.includeInspections}
                  onChange={e => handleIncludeInspections(e.target.checked)}
                />)}
              </div>

              <div className="d-flex align-items-center ms-1">
                <span className="me-1">Property category:</span>
                <Form.Select
                  value={filters.propertyFilter.type === null ? "" : filters.propertyFilter.type}
                  onChange={(e) => handlePropertyType(e.target.value)}
                  className="w-auto"
                  onClick={e => e.stopPropagation()}
                >
                  <option value="">All</option>
                  <option value="1">Commercial</option>
                  <option value="2">Residential</option>
                </Form.Select>
              </div>
              <div className="d-flex align-items-center ms-5">
                <span className="me-1">Rows:</span>
                <Form.Select
                  value={results}
                  onChange={(e) => handlePaginationFilterUpdate(e.target.value)}
                  className="w-auto"
                  onClick={e => e.stopPropagation()}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
              <Button
                className="ms-4 text-black-50"
                variant="light"
                onClick={(e) => {
                  e.stopPropagation();
                  applyFilters(filters);
                }}
              >
                Apply
              </Button>
              <Button className="ms-2 btn-white-custom" onClick={resetFilters} style={{ marginLeft: 10 }} type="reset">Reset</Button>
              <div className="d-flex align-items-center justify-content-center px-3">

                <div className="d-flex" onClick={e => { e.stopPropagation(); }} >
                  <Form.Check type="radio" className="px-2 flex text-nowrap me-3"
                    id="job-list-table-view"
                    name="job-view"
                    value="0"
                    label="Table"
                    checked={currentView === 0}
                    onChange={handleViewChange}
                  />

                  <Form.Check type="radio" className="px-2 flex text-nowrap me-3"
                    id="job-list-tasks-view"
                    name="job-view"
                    value="1"
                    label="Tasks"
                    checked={currentView === 1}
                    onChange={handleViewChange}
                  />

                  <Form.Check type="radio" className="px-2 flex text-nowrap me-3"
                    id="job-list-map-view"
                    name="job-view"
                    value="2"
                    label="Map"
                    checked={currentView === 2}
                    onChange={handleViewChange}
                  />
                </div>

              </div>
              {currentView === 1 &&
                <div className="d-flex align-items-center justify-content-center px-3">
                  <div className="d-flex" onClick={e => { e.stopPropagation(); }} >
                    <Form.Check type="radio" className="px-2 flex text-nowrap me-3"
                      id="tasks-sort-date"
                      name="tasks-sort"
                      value="0"
                      label="By Due Date"
                      checked={tasksSort === 0}
                      onChange={handleTasksSortChange}
                    />
                    <Form.Check type="radio" className="px-2 flex text-nowrap me-3"
                      id="tasks-sort-priority"
                      name="tasks-sort"
                      value="1"
                      label="By Priority"
                      checked={tasksSort === 1}
                      onChange={handleTasksSortChange}
                    />
                  </div>

                </div>
              }
              <div className="d-flex align-items-center justify-content-center" style={{ minWidth: 180 }}>
                <CustomSwitch
                  id="display-related-jobs"
                  name="display-related-jobs"
                  label={"Display related tickets"}
                  checked={filters.displayRelatedTickets}
                  onChange={e => handleDisplayRelatedTickets(e.target.checked)}
                />
              </div>
            </div>
            <div>
              {localStorage.hasOwnProperty("ValuFlow_Filters") && <Button variant="light" style={{ margin: "0px 10px" }} onClick={hardResetFilters} className="text-black-50"><Trash2 size={14} /> Hard Reset Filters</Button>}
              <Button variant="light" onClick={saveFilters} className="text-black-50"><Save size={14} /> Save Filters</Button>
            </div>
          </div>
        }>
          <JobFilters gsData={gsData} handleGSChange={handleGSChange} handleChildProperty={handleChildProperty} handleBaseProperty={handleBaseProperty} />
        </CustomAccordion>
        {!!appliedFilters.length && (
          <Card>
            <Card.Body>
              {appliedFilters.length > 0 && appliedFilters.map((filterStr, index) => (
                <div
                  key={index}
                  className="border border-primary rounded-pill d-inline-block px-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span>
                    {t(filterStr)} : {(getValueFromFltrStr(filterStr) || "")}
                  </span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="align-middle"
                    style={{ cursor: "pointer", marginLeft: 5 }}
                    onClick={(e) => removeAppliedFilter(e, filterStr, index)} />
                </div>
              ))}
            </Card.Body>
          </Card>
        )}
        {
          status === API_STATUS.LOADING || (status === API_STATUS.UNINITIALIZED) ? <div style={{ margin: "auto", width: "fit-content" }}><Spinner animation="grow" /></div> :
            status === API_STATUS.SUCCESS &&
            (
              ticketGroups.length > 0 ?
                <>
                  {filters.currentView === 2 ? (
                    <TicketMapView ticketGroups={ticketGroups} />
                  ) :
                    filters.currentView === 1 ? (
                      <div className="m-3">
                        <TasksBoardComponent data={tasksBoardData} employeeIds={filters?.employeeIds} dragEnabled={tasksSort === 1} />
                      </div>
                    )
                      : (
                        <>
                          <TicketList
                            ticketGroups={ticketGroups}
                            employeeIds={filters?.employeeIds}
                            refreshTicketGroups={refreshTicketGroups}
                            reloadListWithCurrentFilters={reloadListWithCurrentFilters}
                          />
                          <div className="pagination">
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <div style={{ display: "flex", justifyContent: "end" }}>
                                <h5>Total Rows: {totalCount}</h5>
                              </div>
                              <Pagination>
                                <Pagination.First disabled={currentPage === 0} onClick={() => handlePageChange(0)} />
                                <Pagination.Prev disabled={currentPage === 0} onClick={() => handlePageChange(currentPage - 1)} />
                                {getPages()}
                                <Pagination.Next disabled={currentPage === totalPages - 1} onClick={() => handlePageChange(currentPage + 1)} />
                                <Pagination.Last disabled={currentPage === totalPages - 1} onClick={() => handlePageChange(totalPages - 1)} />
                              </Pagination>
                            </div>
                          </div>
                        </>
                      )}

                </> : <div style={{ width: "fit-content", margin: "auto" }}><b style={{ paddingLeft: 20, fontSize: 25 }}>No results found!</b></div>
            )
        }
        {status === API_STATUS.ERROR && context.isAuthenticated && <div style={{ width: "fit-content", margin: "auto" }}><b style={{ paddingLeft: 20, fontSize: 25 }}>Failed to load ticket groups !</b></div>}
      </div>
    </div >

  );
};

export default FrontDesk;
