import { getDateString } from "../utils";
import axios from "../utils/axios";

/**
 * @param {{
 *  image: File | string;
 *  tag?: string;
 *  ticketId: number;
 * }} data
 * @param {(progressEvent: any) => void} [onUploadProgress]
 */
export function uploadInspectionImage(data, onUploadProgress, name) {
  const formData = new FormData();
  formData.append("image", data.image);
  formData.append("tag", data.tag || "");
  formData.append("ticketId", data.ticketId);
  // for sorting purposes
  if (name) {
    formData.append("fileName", name);
  }
  return axios.post("/Inspection/UploadInspectionImage", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

export function scheduleInspection(data) {
  return axios.post("/Inspection/ScheduleInspection", data);
}

export function updateInspection(data) {
  return axios.post("/Inspection/UpdateInspection", data);
}

export function deleteInspection(inspectionId) {
  const params = new URLSearchParams({ inspectionId });
  return axios.post(`/Inspection/DeleteInspection?${params}`);
}

export function getScheduledInspectionByTicketId(ticketId) {
  const params = new URLSearchParams({ ticketId });
  return axios.get(`/Inspection/GetScheduledInspectionsByTicketId?${params}`);
}

export function getScheduledInspectionsByInspectorId(inspectorId, date) {
  const params = new URLSearchParams({ inspectorId });
  if (date) {
    params.append(
      "scheduleDate",
      typeof date === "string" ? date : getDateString(date)
    );
  }
  return axios.get(`/Inspection/GetScheduledInspectionsByInspectorId?${params}`);
}

export function getInspectionChangeHistory(inspectionId) {
  const params = new URLSearchParams({ inspectionId });
  return axios.get(`/Inspection/GetInspectionChangeHistory?${params}`);
}

export function getInspectionImages(ticketId) {
  const params = new URLSearchParams({ ticketId });
  return axios.get(`/Inspection/GetInspectionImages?${params}`);
}

export function getInspectionImagesTags(ticketId) {
  const params = new URLSearchParams({ ticketId });
  return axios.get(`/Inspection/GetInspectionImagesTags?${params}`);
}

export function getInspectionImageBase64(ticketId, tag) {
  const params = new URLSearchParams({ ticketId, tag });
  return axios.get(`/Inspection/GetInspectionImageBase64?${params}`);
}

export function getInspectionImageBase64V2(inspectionImageId) {
  const params = new URLSearchParams({ inspectionImageId });
  return axios.get(`/Inspection/GetInspectionImageBase64V2?${params}`);
}

export function getInspectionImagesBase64V3(ids) {
  return axios.post(`/Inspection/GetInspectionImagesBase64V3`, ids);
}

export function deleteInspectionImage(inspectionImageId) {
  const params = new URLSearchParams({ inspectionImageId });
  return axios.post(`/Inspection/DeleteImage?${params}`);
}

export function updateInspectionImageDescription(id, description) {
  return axios.post(`/Inspection/UpdateInspectionImageDescription`, { id, description });
}

export function updateImageTags(inspectionImageId, tags) {
  const params = new URLSearchParams({ inspectionImageId, tags });
  return axios.post(`/Inspection/UpdateImageTags?${params}`);
}

/**
 * @typedef {{
 *  includeCompleted?: boolean;
 *  keyword?: string;
 *  propertyTypes?: Array<number>
 * }} MyInspectionsFilters
 * @param {MyInspectionsFilters} [params]
 */
export function getMyInspections(params = {}) {
  return axios.post("/Inspection/GetMyInspections", params);
}
