import { useState } from "react";

import {
  Accordion,
  Card,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { Info, Trash2 } from "react-feather";
import Dropzone from "../../../components/dropzone/Dropzone";
import CompDetails from "./CompDetails";
import useAppSelector from "../../../hooks/useAppSelector";
import { useEffect } from "react";
import { deleteCompFile, getRootDirectoryContents } from "../../../services/microsoftTeamsFiles";
import Popconfirm from "../../../components/Popconfirm";
import { toast } from "react-toastify";

const AccordionItem = ({
  i,
  related,
  compId,
  createdById,
  dateOfSale,
  id,
  label,

  accordionArrLength,
  currentComp,
  removeComp,
  removeNotSavedComp,
  handleCompTypeChange,
  compTypeValueByIndex,
  handleRelatedCompDetailsRef,
  handleCompDetailsRef,
  setNotSavedChanges,
  compTypeColumns,
  getCreationDate,
  setActiveKey,
  activeKey,
  filesUpdated
}) => {
  const isCurrent = i === 0 && !related;
  const accordionIndex = related ? i + accordionArrLength : i;
  const compTypes = useAppSelector((state) => state.comps.compTypes);
  const usersMap = useAppSelector((state) => state.appData.usersMap);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [fileIdToDelete, setFileIdToDelete] = useState();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!currentComp || currentComp.id == 0) {
      return;
    }

    getRootDirectoryContents(currentComp?.id, 1).then((data) => {
      const _data = data.map(x => ({ ...x, createdDateTime: new Date(x.createdDateTime) }));
      _data.sort((a, b) => b.createdDateTime - a.createdDateTime);
      setFiles(_data.filter(x => x.file));
    }).catch((e) => {
      // do nothing 
    });
  }, [currentComp])

  const filesUpdatedInternal = (acceptedFiles) => {
    filesUpdated(i, acceptedFiles)
  }

  const deleteFile = async () => {
    const compId = currentComp.id;
    console.log("delete the according gfie", fileIdToDelete)
    try {
      await deleteCompFile(compId, fileIdToDelete);
      getRootDirectoryContents(currentComp?.id, 1).then((data) => {
        const _data = data.map(x => ({ ...x, createdDateTime: new Date(x.createdDateTime) }));
        _data.sort((a, b) => b.createdDateTime - a.createdDateTime);
        setFiles(_data.filter(x => x.file));
      }).catch((e) => {
        // do nothing 
      });
      setShowDeleteDialog(false);
    }
    catch (e) {
      toast.error("Failed to delete the file...");
      setShowDeleteDialog(false);
    }
  }

  const deleteFileHandler = (id) => {
    setFileIdToDelete(id);
    setShowDeleteDialog(true);
  }

  const onCancelFileDelete = () => {
    setFileIdToDelete(null);
    setShowDeleteDialog(false);
  }

  const getEffectiveDate = () => {
    if (isCurrent && currentComp.dateOfSale) {
      return (<div>
        {`Effective date: ${getCreationDate(currentComp.dateOfSale)}`}
      </div>);
    }

    if (!isCurrent && dateOfSale) {
      (<div>
        {`Effective date: ${getCreationDate(dateOfSale)}`}
      </div>);
    }

    return null;
  }

  return (
    <>
      <Popconfirm
        show={showDeleteDialog}
        title={
          <>
            Do you want to delete the file?
          </>
        }
        onCancel={onCancelFileDelete}
        onOk={deleteFile}>

      </Popconfirm>
      <Accordion.Item eventKey={accordionIndex}>

        {compId ? (
            <Accordion.Header
              className="px-4 pt-4"
              onClick={() =>
                setActiveKey(
                  activeKey === accordionIndex ? undefined : accordionIndex
                )
              }
            >
              <div className="d-flex justify-content-between w-100 pe-3">
                <span style={{ margin: "auto 0" }}>
                   {label} {related && <Info size={20} className="ms-2" />}
                </span>
                <div className="d-flex flex-row align-items-center">
                  <span>
                    {compId !== 0 &&
                      (usersMap[isCurrent ? currentComp.createdById : createdById]
                        ?.firstName ? (
                        <div>
                          <div>{`Created By: ${usersMap[
                            isCurrent ? currentComp.createdById : createdById
                          ]?.firstName
                            }`}</div>

                          {getEffectiveDate()}


                        </div>
                      ) : (
                        ""
                      ))}
                  </span>
                  {(related ||
                    accordionArrLength > 1 ||
                    (currentComp.id && isCurrent)) && (
                      <Trash2
                        className="ms-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          related || (currentComp.id && isCurrent)
                            ? removeComp(compId)
                            : removeNotSavedComp(accordionIndex);
                        }}
                      />
                    )}
                </div>
              </div>
            </Accordion.Header>
        ) : (<></>)}
        
        <Accordion.Body>
          <Card className="block-border p-3 mx-4 pt-0 mb-2">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-12 col-lg-12 mt-2">
                <div className="text-dark fw-bold mb-2">Comp type</div>
                <ToggleButtonGroup
                  className="toggle-button-group"
                  type="radio"
                  name={`options-` + accordionIndex}
                  onChange={handleCompTypeChange(accordionIndex)}
                  value={related ? id : compTypeValueByIndex[accordionIndex]}
                >
                  {compTypes.map((compType) => (
                    <ToggleButton
                      id={`select-comp-type-${accordionIndex}-${compType.id}`}
                      key={`select-comp-type-${accordionIndex}-${compType.id}`}
                      value={compType.id}
                      disabled={related}
                    >
                      {compType.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
              {/* <div className="col-12 col-lg-6 mt-2">
              <div className="text-dark fw-bold mb-2">
                Linked to file
              </div>
              <Form.Control
                placeholder="Start typing.."
                autoComplete="off"
              />
            </div> */}
            </div>
          </Card>

          <div className="px-4 mt-2">
            <div className="row">
                <div className="col-9">
                    <CompDetails
                        ref={
                            related ? handleRelatedCompDetailsRef(i) : handleCompDetailsRef(i)
                        }
                        setNotSavedChanges={setNotSavedChanges}
                        compTypeColumns={compTypeColumns[id] || []}
                        compTypeId={id}
                    />
                </div>
                <div className="col-3">
                    <Dropzone allowDrop deleteFile={deleteFileHandler} filesUpdated={filesUpdatedInternal} files={files} />
                </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default AccordionItem;
