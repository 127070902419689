import { createContext, useEffect, useReducer } from "react";
import { SERVER_URL } from "../configs";

import axios from "axios";
import { isValidToken, setSession } from "../utils/jwt";
import { bindTasksTimer, cancelTasksTimer } from "../redux/actions/user";
import useAppDispatch from "../hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../hooks/useScreenSize";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const dispatch2 = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const msToken = window.localStorage.getItem("ms_accessToken");
        const msRefreshToken = window.localStorage.getItem("ms_RefreshToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, msToken, msRefreshToken);

          const response = await axios.get(`${SERVER_URL}/api/Auth/Me`, {
            headers: { "Authorization": `Bearer ${accessToken}` }
          })

          const user = { ...response.data };

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: user,
            },
          });
          if (isMobile && user?.roles?.includes("inspector") && !window.location.href.includes("job")) {
            navigate("/dashboard/event-view")
          }

          bindTasksTimer()(dispatch2)
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    let response = await axios.post(`${SERVER_URL}/api/Auth/Login`, {
      email,
      password,
    });
    const { token, msToken, msRefreshToken } = response.data;

    response = await axios.get(`${SERVER_URL}/api/Auth/Me`, {
      headers: { "Authorization": `Bearer ${token}` }
    })

    const user = { ...response.data };

    setSession(token, msToken, msRefreshToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user: user,
      },
    });
    if (isMobile && user?.roles?.includes("inspector")) {
      navigate("/dashboard/event-view")
    }
    bindTasksTimer()(dispatch2)
  };

  const signOut = async () => {
    setSession(null, null);
    dispatch({ type: SIGN_OUT });

    cancelTasksTimer();
  };

  const signUp = (email, password) => {
    return axios.post(`${SERVER_URL}/api/Auth/Register`, {
      email,
      password
    });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
