import React, { useEffect, useState } from "react"
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { SERVER_URL } from "../../../configs";
import useAppSelector from "../../../hooks/useAppSelector";
import NotesAndMemos from "../NotesAndMemos";
import { createTicketStatusConversation } from "../../../utils/msteams";

const Revision = ({ ticket, loadTicket, loadChatInfo, reloadThreadList, selectConversation }) => {

    const [ticketStatusObject, setTicketStatusObject] = useState();
    const [ticketStatus, setTicketStatus] = useState();
    const [assignedUser, setAssignedUser] = useState();
    const [conversationId, setConversationId] = useState(null);
    const [createConversationDisabled, setCreateConversationDisabled] = useState(false);

    const { users, usersMap } = useAppSelector(state => state.appData)

    const createConversation = async () => {
        setCreateConversationDisabled(true);

        const response = await createTicketStatusConversation(ticket.id, ticketStatus);
        await selectConversation(response.data.id)
        setConversationId(response.data.id);
        setCreateConversationDisabled(false);
    }

    const getAssignedUser = () => {
        if (ticket.ticketStatuses && ticket.ticketStatuses.length > 0)
            return ticket.ticketStatuses[ticket.ticketStatuses.length - 1].assignedToId;
    }

    const handleConfirm = () => {
        let _obj = {
            ticketId: ticket.id,
            ticketStatusTypeId: 4,
            assignedToUserId: assignedUser,
            assignmentNote: ""
        }
        axios.post(`${SERVER_URL}/api/Ticket/ChangeTicketStatus`, _obj, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            loadTicket();
            //updateTicketGroup()
            //dispatch(clearUserTasks());
            //dispatch(loadUserTasks());
            toast.success("Ticket status updated successfully !!");
        }).catch((err) => {
            console.error(err)
            toast.error("Failed to update ticket status, please contact administrator !!!");
        })
    }

    useEffect(() => {
        if (ticket && ticket.ticketStatuses) {
            const statusObject = ticket.ticketStatuses.length > 0 ? ticket.ticketStatuses[ticket.ticketStatuses.length - 1] : null;
            if (statusObject) {
                setConversationId(statusObject.microsoftTeamsConversationId);
            }

            setTicketStatusObject(statusObject);
            setTicketStatus(ticket.ticketStatuses.length > 0 ? ticket.ticketStatuses[ticket.ticketStatuses.length - 1].ticketStatusTypeId : 0)
            setAssignedUser(getAssignedUser())
        }
    }, [ticket])

    return (
        <div className="mb-1">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: 'column' }}>
                {/* <h4>Revision</h4> */}
                {ticketStatus != 4 ?
                    <>
                        <h5>Assign revision</h5>
                        <Form.Select
                            className="mt-2"
                            name="userId"
                            value={assignedUser}
                            onChange={(e) => setAssignedUser(e.target.value)}
                        >
                            <option value="">Select User</option>
                            {users.filter(u => u.active).map((user, index) => (
                                <option key={index} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>
                            ))}
                        </Form.Select>
                        <Button variant="primary" className="mt-2 pull-right" onClick={handleConfirm}>Confirm</Button>
                    </> :
                    <>
                        <h5>Revision active</h5>
                        {usersMap[assignedUser] && <p>Assigned: {usersMap[assignedUser].firstName + " " + usersMap[assignedUser].lastName}</p>}
                    </>
                }
            </div>
            <div className="mt-2">
                {/* 4 - revision */}
                {ticketStatus && <NotesAndMemos ticketId={ticket.id} ticketStatusTypeId={4} isTicketStatusMemo></NotesAndMemos>}
            </div>

            {ticketStatus == 4 && !conversationId && (<div className="mt-2">
                <Button onClick={createConversation} disabled={createConversationDisabled}>Create conversation</Button>
            </div>)}
        </div>
    )
}

export default Revision;