/**
 * @typedef {{
 *  id: number;
 *  note: string;
 *  ticketStepId: number;
 *  created: string;
 *  updated?: string;
 *  userId: string;
 * }} TicketStepNote
 */
import axios from "../utils/axios";

/**
 * @param {number} ticketId
 * @returns {Promise<Array<TicketStepNote>>}
 */
export function getTicketStepNotes(ticketId) {
  const queryParams = new URLSearchParams({ ticketId });
  return axios.get(`/TicketStepNote/GetTicketStepNotes?${queryParams}`);
}

/**
 * @param {number} ticketStepId
 * @param {string} note
 * @returns {Promise<TicketStepNote>}
 */
export function createTicketStepNote(ticketStepId, note) {
  return axios.post("/TicketStepNote/CreateTicketStepNote", { ticketStepId, note });
}

/**
 * @param {number} id
 * @param {string} note
 * @returns {Promise<TicketStepNote>}
 */
export function updateTicketStepNote(id, note) {
  return axios.post("/TicketStepNote/UpdateTicketStepNote", { id, note });
}

/**
 * @param {number} id
 * @returns {Promise<TicketStepNote>}
 */
export function deleteTicketStepNote(id) {
  const queryParams = new URLSearchParams({ id });
  return axios.post(`/TicketStepNote/DeleteTicketStepNote?${queryParams}`);
}
