import React, { useState, useEffect, useContext, useRef } from "react"

/** Supporting Libs */
import { Card, Container, Form } from "react-bootstrap"
import { Helmet } from "react-helmet-async"
import axios from "axios"
import { useNavigate } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


/** FullCalendar */
import FullCalendar from "@fullcalendar/react"
import bootstrapPlugin from "@fullcalendar/bootstrap"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"

/** Configs && Context */
import { SERVER_URL } from "../../configs"
import { AuthContext } from "../../contexts/JWTContext"
import useScreenSize from "../../hooks/useScreenSize"
import moment from "moment-timezone";
import useAppSelector from "../../hooks/useAppSelector";
import LoadingWrapper from "../../components/LoadingWrapper";

/** CSS */
import "./calendar.css"

const EventContent = (eventInfo) => {
  return (
    <div>
      <b>{eventInfo.timeText}</b>
      <i className="eventTitle">{eventInfo.event.title}</i>
      <div>Inspector: {eventInfo.event.extendedProps.name}</div>
    </div>
  )
}

const Calendar = () => {
  const [inspections, setInspections] = useState([])
  const [inspectionEvents, setInspectionEvents] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [inspectorId, setInspectorId] = useState();
  const context = useContext(AuthContext)
  const navigate = useNavigate()
  const { height } = useScreenSize();
  const inspectors = useAppSelector(state => state.appData.inspectors)


  useEffect(() => {
    if (context.isAuthenticated) {
      setLoaded(true)
      axios.get(`${SERVER_URL}/api/Inspection/GetAllInspections`).then((res) => {
        const _inspectionEvents = []
        if (res.data) {
          setTimeout(() => {
            const scrolledCalendar = document.querySelector(".fc-scroller-liquid-absolute")
            scrolledCalendar && scrolledCalendar.scrollTo(0, scrolledCalendar.scrollHeight)
          });
          res.data.forEach((inspection) => {

            if (inspectorId && inspectorId != '' && inspectorId != inspection?.inspector?.id) {
              return;
            }

            _inspectionEvents.push({
              id: inspection.id,
              name: `${inspection?.inspector?.firstName} ${inspection?.inspector?.lastName}`,
              title: inspection.ticket.property.address.split(",")[0],
              start: moment(inspection.date).toISOString() //moment.utc(inspection.date).clone().tz("America/New_York").toISOString()
            });
          });

          setLoaded(false);
        }
        setInspections(res.data)
        setInspectionEvents(_inspectionEvents)
      })
    }
  }, [context, inspectorId])

  const goBack = () => {
    navigate("/");
  };

  const handleEventClick = (info) => {
    const _inspection = inspections.find((_ins) => String(_ins.id) === info.event.id)
    navigate(`/dashboard/job/${_inspection.ticket.ticketGroupId}/${_inspection.ticket.id}?selectedInfoTab=2`, { state: { from: window.location.pathname } });
  }

  return (
    <React.Fragment>
      <Helmet title="Calendar" />
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between calendar-header">
          <div className="d-flex">
            <h1 className="h3 mb-3">Inspections</h1>
            <FontAwesomeIcon
              style={{ marginLeft: 10, height: 25, width: 25, cursor: "pointer" }}
              icon={faTimes}
              onClick={goBack}
            />
          </div>
          <div className="d-flex">
            <Form.Select name="userId" className="users-dropdown" value={inspectorId} onChange={(e) => setInspectorId(e.target.value)}>
              <option value="">All Inspectors</option>
              {inspectors.map((user, index) => (
                <option key={index} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>
              ))}
            </Form.Select>
          </div>

        </div>
        <Card>
          <Card.Body>
            <LoadingWrapper loading={loaded}>
              <FullCalendar
                plugins={[bootstrapPlugin, dayGridPlugin, timeGridPlugin]}
                themeSystem="bootstrap"
                initialView="dayGridMonth"
                eventTimeFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  meridiem: "short",
                }}
                height={height - 200}
                initialDate={new Date().toISOString().split("T")[0]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                events={inspectionEvents}
                eventContent={EventContent} // custom render function
                eventClick={handleEventClick}
                bootstrapFontAwesome={false}
                eventDidMount={(eventArg) => {
                  if (eventArg.isToday) {
                    eventArg.el.scrollIntoView();
                  }
                }}
              />
            </LoadingWrapper>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Calendar;
