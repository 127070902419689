import { generateCRUD } from "../utils/axios";
import { createInspectionForm, getInspectionQuestionForms, updateInspectionQuestionForm, deleteInspectionQuestionForm } from "./inspectionQuestionForm";

const adminService = {
  ValueType: generateCRUD("ValueType"),
  QuoteTimeSlot: generateCRUD("QuoteTimeSlot"),
  PropertyType: generateCRUD("PropertyType"),
  JobPurpose: generateCRUD("JobPurpose"),
  JobAssignmentType: generateCRUD("JobAssignmentType"),
  Portfolio: generateCRUD("Portfolio"),
  UnitType: generateCRUD("UnitType"),
  LeaseType: generateCRUD("LeaseType"),
  BasementType: generateCRUD("BasementType"),
  BedroomsClassification: generateCRUD("BedroomsClassification"),
  LotShape: generateCRUD("LotShape"),
  LotType: generateCRUD("LotType"),
  RentRegulationStatus: generateCRUD("RentRegulationStatus"),
  BuildingClass: generateCRUD("BuildingClass"),
  OutdoorSpaceType: generateCRUD("OutdoorSpaceType"),
  Zone: generateCRUD("Zone"),
  FloorLocation: generateCRUD("FloorLocation"),
  ZoneOverlay: generateCRUD("ZoneOverlay"),
  CompCondition: generateCRUD("CompCondition"),
  CompType: generateCRUD("CompType"),
  CompKey: generateCRUD("CompKey"),
  InspectionQuestionForm: {
    get: getInspectionQuestionForms,
    create: createInspectionForm,
    update: updateInspectionQuestionForm,
    delete: deleteInspectionQuestionForm
  },
};

export default adminService;
