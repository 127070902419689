import { useMemo } from "react";

/**
 * @template T
 * @param {Array<T>} collection
 * @param {keyof T} key
 * @returns {Record<string, T>}
 */
export default function useKeyBy(
  collection,
  key
) {
  return useMemo(() => {
    const map = {};
    collection.forEach((item) => {
      map[item[key]] = item;
    });
    return map;
  }, [key, collection]);
}
