import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import {
  getQuotesByTicketId,
  updateQuotes,
  deleteQuote,
} from "../../../services/ticketQuote";
import { AuthContext } from "../../../contexts/JWTContext";
import useAppSelector from "../../../hooks/useAppSelector";
import { API_STATUS, getQuotesObj } from "../../../utils";
import ConfirmationModal from "../../../components/jobs/confirmationModal";
import useJobDetails from "../useJobDetails";
import axios from "axios";
import { SERVER_URL } from "../../../configs";
import DocumentGeneration from "../../../components/document";
import { toast } from "react-toastify";

const Quote = () => {
  const propertyTypes = useAppSelector(state => state.appData.propertyTypes);
  const assignmentTypes = useAppSelector(state => state.appData.assignmentTypes);
  const quoteTimeSlots = useAppSelector(state => state.appData.quoteTimeSlots);
  const { isAuthenticated } = useContext(AuthContext);
  const { ticketInfo, ticketGroup } = useJobDetails();
  const [quotes, setQuotes] = useState([]);
  const [qApiStatus, setQApiStatus] = useState(API_STATUS.UNINITIALIZED);
  const [disableDelete, setDisableDelete] = useState(false);
  const { ticketId } = useParams();
  const [generateDocumentModal, setGenerateDocumentModal] = useState(false)
  const [documentData, setDocumentData] = useState({})

  useEffect(() => {
    if (isAuthenticated) {
      setQApiStatus(API_STATUS.LOADING);
      loadQuotesByTicket(true);
    }
  }, [isAuthenticated]);

  const saveQuotes = (quotes) => {
    updateQuotes(quotes)
      .then(setQuotes)
      .catch((err) => console.error(err));
  };

  const handleQuoteDelete = (index) => {
    if (disableDelete) return;

    const quoteId = quotes[index]?.id;
    quotes.splice(index, 1);
    setQuotes([...quotes]);

    if (quoteId > 0) {
      deleteQuote(quoteId).catch((err) => console.error(err));
    }
  };

  const handleQuoteValueChange = (key, value, index) => {
    validateAndSaveData(handleValueChange(key, value, index));
  };

  const validateQuotesData = (_quotes) => {
    const validationResult = _quotes.every((quote) => !!quote.price);
    return validationResult;
  };

  const handlePriceChange = (key, value, index) => {
    handleValueChange(key, value === "" ? value : Math.abs(value), index);
  };

  const validateAndSaveData = (_quotes) => {
    if (validateQuotesData(_quotes)) saveQuotes(_quotes);
  };

  const loadQuotesByTicket = (loading) => {
    setDisableDelete(true);
    getQuotesByTicketId(ticketId)
      .then((data) => {
        setQuotes(data);
        if (loading) {
          setQApiStatus(API_STATUS.SUCCESS);
        }
      })
      .catch((err) => {
        console.error(err);
        setQApiStatus(API_STATUS.ERROR);
      })
      .finally(() => {
        setDisableDelete(false);
      });
  };

  const addQuote = () => {
    let _quotes = [...quotes];
    _quotes.push(getQuotesObj(ticketId));
    setQuotes(_quotes);
  };

  const handleValueChange = (key, value, index) => {
    const _quotes = [...quotes];
    if (key === "isAccepted")
      _quotes.forEach((quote) => (quote.isAccepted = false));

    _quotes[index][key] = value;
    setQuotes(_quotes);
    return _quotes;
  };

  const handleGenerateDocument = () => {
    let _documentData = JSON.parse(JSON.stringify(documentData))

    for (let key of Object.keys(documentData["customer"]))
        _documentData[key] = documentData["customer"][key] || ""
    _documentData["date"] = new Date().toISOString()

    _documentData.propertyList.forEach((property) => {
        if (property.propertyType !== "") {
            const _property = propertyTypes.find((type) => String(type.id) === String(property.propertyType))
            if (_property) {
                property["propertyType"] = _property.name
            }
        }
        if (property.assignmentType !== "") {
            const _assignmentType = assignmentTypes.find((type) => String(type.id) === String(property.assignmentType))
            if (_assignmentType) {
                property["assignment"] = _assignmentType.name
            }
        }

        if (property.valueType) {
            let _valueTypes = property.valueType.map((item, index) => `${index + 1}. ${item['name']}`)
            property["valueType"] = _valueTypes.toString()
        }

        property?.quotes.forEach((quote) => {
            let _timeSlot = quoteTimeSlots.find((timeSlot) => String(timeSlot.id) === String(quote.quoteTimeSlotId))
            quote["date"] = (_timeSlot?.name || "")

            delete quote['created']
            delete quote['id']
            delete quote['quoteTimeSlot']
            delete quote['quoteTimeSlotId']
            delete quote['ticketId']
        })
    })

    delete _documentData["customer"]
    delete _documentData["id"]

    axios.post(`${SERVER_URL}/api/Proposal/GenerateProposalDocument`, _documentData, { responseType: "blob" }).then((res) => {
        let url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers["content-type"] }));
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = "download.docx";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        toast.success("Document Generated Successfully!")
    }).catch((err) => {
        console.error(err)

        toast.error("Failed to generate the document, please contact administrator !")
    })
    console.debug("Document Data :", documentData)
    setGenerateDocumentModal(false)
}

  return (
    <>
    <ConfirmationModal
                className="generate-document-modal"
                title="Generate Document"
                show={generateDocumentModal}
                setShow={setGenerateDocumentModal}
                handleConfirm={handleGenerateDocument}
            >
                <DocumentGeneration
                    ticketGroup={ticketGroup}
                    ticketInfo={ticketInfo}
                    documentData={documentData}
                    setDocumentData={setDocumentData}
                />
            </ConfirmationModal>
      {
        <>
          {qApiStatus === API_STATUS.SUCCESS && (
            <>
              {quotes.length === 0 ? (
                <>
                  <h4>There are no quotes to show</h4>
                </>
              ) : (
                <>
                  <div className="d-flex quote-table-header">
                    <Col xl={3} md={3} sm={3}>
                      <h5 style={{ width: "fit-content", margin: "auto" }}>
                        Price
                      </h5>
                    </Col>
                    <Col xl={5} md={5} sm={5}>
                      <h5 style={{ width: "fit-content", margin: "auto" }}>
                        Timeslot
                      </h5>
                    </Col>
                    <Col xl={2} md={2} sm={2}>
                      <h5 style={{ width: "fit-content", margin: "auto" }}>
                        Accepted
                      </h5>
                    </Col>
                    <Col xl={2} md={2} sm={2}>
                      <h5 style={{ width: "fit-content", margin: "auto" }}>
                        Delete
                      </h5>
                    </Col>
                  </div>
                  <div className="quote-table-body">
                    <hr />
                    {quotes.map((quote, index) => (
                      <div key={index} className="mb-2 d-flex quote-table-row">
                        <Col
                          xl={3}
                          md={3}
                          sm={3}
                          className="px-2 quote-table-cell quote-table-price-cell"
                        >
                          <Form.Control
                            type="number"
                            className={
                              !quote.price || quote.price === ""
                                ? "invalidValue"
                                : ""
                            }
                            name="price"
                            placeholder="Price"
                            value={quote.price || ""}
                            min="0"
                            onBlur={() => validateAndSaveData(quotes)}
                            onChange={(e) =>
                              handlePriceChange(
                                e.target.name,
                                e.target.value,
                                index
                              )
                            }
                            autoComplete="off"
                          />
                        </Col>
                        <Col
                          xl={5}
                          md={5}
                          sm={5}
                          className="px-2 quote-table-cell quote-table-timeslot-cell"
                        >
                          <Form.Select
                            name="quoteTimeSlotId"
                            aria-label="Select Ticket Status"
                            value={quote.quoteTimeSlotId}
                            onChange={(e) =>
                              handleQuoteValueChange(
                                e.target.name,
                                e.target.value,
                                index
                              )
                            }
                          >
                            {quoteTimeSlots.map((timeslot) => (
                              <option key={timeslot.id} value={timeslot.id}>
                                {timeslot.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col
                          xl={2}
                          md={2}
                          sm={2}
                          className="mt-1 quote-table-cell quote-table-accepted-cell"
                        >
                          <Form.Check
                            type="radio"
                            name="quotes-radio"
                            checked={quote.isAccepted}
                            onChange={(e) =>
                              handleQuoteValueChange(
                                "isAccepted",
                                e.target.checked,
                                index
                              )
                            }
                            style={{ width: "fit-content", margin: "auto" }}
                          />
                        </Col>
                        <Col
                          xl={2}
                          md={2}
                          sm={2}
                          className="mt-1 quote-table-cell quote-table-delete-cell"
                        >
                          <div
                            style={{ marginLeft: 10, cursor: "pointer" }}
                            onClick={() => handleQuoteDelete(index)}
                          >
                            <FontAwesomeIcon
                              style={{ height: 20, width: 20 }}
                              icon={faTimes}
                            />
                          </div>
                        </Col>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <Button
                className="generate-document-button"
                onClick={() => setGenerateDocumentModal(true)}
                style={{ float: "right", marginLeft: 15 }}
              >
                Generate Document
              </Button>
              <FontAwesomeIcon
                className="add-new-field-button"
                style={{
                  width: 50,
                  height: 20,
                  float: "right",
                  marginTop: 5,
                  cursor: "pointer",
                }}
                icon={faPlusCircle}
                size="lg"
                onClick={addQuote}
              />
            </>
          )}
        </>
      }
      {qApiStatus === API_STATUS.ERROR && <h4>Failed to load the quotes</h4>}
      {qApiStatus === API_STATUS.LOADING && (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <Spinner animation="grow" variant="secondary" />
        </div>
      )}
    </>
  );
};

export default Quote;
