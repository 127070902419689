import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import CustomMarker from "../../../components/CutomMarker";
import { MAP_DEFAULT_CENTER } from "../../../constants";

/** @param {{ comps: Array<import("../../../services/comp").Comp> } & React.HTMLAttributes<HTMLDivElement>} props */
const CompsMap = ({ comps, full, compClick, ...restProps }) => {
  const [maps, setMaps] = useState();
  const [mapCenter, setMapCenter] = useState();
  const [zoom, setZoom] = useState(14);
  const mapRef = useRef();
  /** @type {React.MutableRefObject<GoogleMapReact.Maps} */
  const mapsRef = useRef();
  /** @type {React.MutableRefObject<HTMLDivElement} */
  const mapWrapperRef = useRef();

  useEffect(() => {
    if (!comps.length || !maps) {
      return;
    }

    centerMap(
      comps.map((comp) => ({
        lat: comp?.compAddress?.lat,
        lng: comp?.compAddress?.lng,
      }))
    );
  }, [comps, maps]);

  const centerMap = (newMarkers) => {
    try {
      const bounds = new mapsRef.current.LatLngBounds();

      for (const { lat, lng } of newMarkers) {
        bounds.extend(new mapsRef.current.LatLng(lat, lng));
      }
      if (newMarkers.length === 1) {
        setMapCenter({
          lat: newMarkers[0].lat,
          lng: newMarkers[0].lng,
        });
        setZoom(14);
      } else {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        const mapWrapperRect = mapWrapperRef.current?.getBoundingClientRect();

        const { center, zoom } = fitBounds(
          {
            ne: { lat: ne.lat(), lng: ne.lng() },
            sw: { lat: sw.lat(), lng: sw.lng() },
          },
          {
            width: mapWrapperRect?.width || 640,
            height: mapWrapperRect?.height || 380,
          }
        );
        setMapCenter(center);
        zoom !== 1 && setZoom(zoom - 1);
      }
    } catch (err) {
      console.error("render marker error", err);
    }
  };

  return (
    <div ref={mapWrapperRef} {...restProps} className="d-flex" style={{ height: full ? 500 : 150 }}>
      <GoogleMapReact
        options={(maps) => ({
          fullscreenControl: true,
          mapTypeControl: true,
          mapTypeId: maps.MapTypeId.ROADMAP,
          scaleControl: true,
          scrollwheel: false,
          streetViewControl: true,
        })}
        // bootstrapURLKeys={{
        //   key: "AIzaSyA-aWrwgr64q4b3TEZwQ0lkHI4lZK-moM4",
        // }}
        defaultCenter={MAP_DEFAULT_CENTER}
        center={mapCenter}
        onChange={({ center, zoom }) => {
          mapCenter && setMapCenter(center);
          setZoom(zoom);
        }}
        defaultZoom={14}
        zoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          mapsRef.current = maps;
          setMaps(maps);
        }}
      >
        {comps.map((comp) => (
          <CustomMarker
            onClick={e => compClick(comp)}
            key={comp.id}
            lat={comp?.compAddress?.lat}
            lng={comp?.compAddress?.lng}
            title={comp?.compAddress?.fullAddress}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default CompsMap;
