import { Container, Card, Col, Form, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import GoogleAutoComplete from "../Tickets/PropertyInfo/GoogleAutoComplete";

const SearchByAddress = ({ searchAddress, searchFilters, handleRadiusChange, setSearchAddress, handleAddressSelect }) => {
    return (
        <div style={{minWidth: 220}} className="border-bottom border-right px-2">
            <div className="d-flex flex-wrap text-dark mb-1">
                <span>Within</span>
                <Form.Control
                    type="number"
                    min="1"
                    className="custom-inp"
                    value={searchFilters.searchWithin?.radius}
                    onChange={handleRadiusChange}
                />
                <span>Miles Radius of:</span>
            </div>
            <Form.Group className="mb-2" controlId="addressDetails">
                <GoogleAutoComplete
                    disableUnit
                    name="fullAddress"
                    value={searchAddress}
                    onChange={(e) => setSearchAddress(e.target.value)}
                    onLocationSelect={handleAddressSelect}
                />
            </Form.Group>
        </div>);
}

export default SearchByAddress;