const commonCalculation = (propNameLower, value, state, changed) => {
    if (propNameLower == "dateofsale" && value) {
        changed["saleStatus"] = "Closed";
    }

    if (propNameLower == "lotsizeacres") {
        changed["lotSizeSF"] = value * 43650;;
    }

    if (propNameLower == "egi") {
        changed["legalAndAccounting"] = Number((state["egi"] ?? 0) * 0.01).toFixed(2);
    }

    if (propNameLower == "insurance" ||
        propNameLower == "realestatetax" ||
        propNameLower == "management" ||
        propNameLower == "repairsandmaintenance" ||
        propNameLower == "waterandsewer" ||
        propNameLower == "commonareautilities" ||
        propNameLower == "gasandheat" ||
        propNameLower == "utilities" ||
        propNameLower == "legalandaccounting" ||
        propNameLower == "otherandmiscellaneous") {
        let totalExpenses =
            Number(state["insurance"] ?? 0) +
            Number(state["realEstateTax"] ?? 0) +
            Number(state["management"] ?? 0) +
            Number(state["repairsAndMaintenance"] ?? 0) +
            Number(state["waterAndSewer"] ?? 0) +
            Number(state["commonAreaUtilities"] ?? 0) +
            Number(state["gasAndHeat"] ?? 0) +
            Number(state["utilities"] ?? 0) +
            Number(state["legalAndAccounting"] ?? 0) +
            Number(state["otherAndMiscellaneous"] ?? 0)

        changed["totalExpenses"] = totalExpenses;
    }

    return changed;
}

// Calculation per comp type
const condoFieldCalculation = (propNameLower, value, state) => {

}

const fieldCalculation = (propName, value, state, compTypeId) => {
    let _name = propName.toLowerCase();
    let changed = {};

    changed = commonCalculation(_name, value, state, changed);

    switch (compTypeId) {
        // condo
        case 2:
            break;
        default:
            break;
    }

    // this needs to be refactored...
    if (_name == "saleprice" && value != 0) {
        if (state["numberOfUnits"]) {
            changed["pricePerUnit"] = Number(state["salePrice"] ?? 0) / Number(state["numberOfUnits"] ?? 0).toFixed(2);
        }

        // condo
        if (compTypeId == 3) {
            if (state['unitSF'] && state['unitSF'] != 0) {
                changed["pricePerSF"] = Number(Number(state["salePrice"] ?? 0) / Number(state["unitSF"] ?? 0)).toFixed(2);
            }
        }
        else {
            if (state["grossBuildingAreaSF"]) {
                changed["pricePerSF"] = Number(Number(state["salePrice"] ?? 0) / Number(state["grossBuildingAreaSF"] ?? 0)).toFixed(2);
            }
        }

        if (state["insurance"]) {
            changed["insurancePerUnit"] = Number((state["insurance"] ?? 0) / (state["numberOfUnits"] ?? 1)).toFixed(2);
        }

        if (state["realEstateTax"]) {
            changed["realEstateTaxPerUnit"] = Number((state["realEstateTax"] ?? 0) / (state["numberOfUnits"] ?? 0)).toFixed(2);
        }

        if (state["rentStabilized"]) {
            changed["rentStabilizedPercentage"] = (state["rentStabilized"] ?? 0) / (state["numberOfUnits"] ?? 0);
        }

        if (state['buildable']) {
            changed['pricePerBuildableSF'] = Number(Number(state['salePrice'] ?? 0) / Number(state['buildable'] ?? 0)).toFixed(2);
        }
    }

    // Number of units handling
    if (_name == "numberofunits") {
        if (state["grossBuildingAreaSF"]) {
            changed["averageUnitSize"] = Number((state["grossBuildingAreaSF"] ?? 0) / (state["numberOfUnits"] ?? 0)).toFixed(2);
        }

        if (state["management"]) {
            changed["managementPerUnit"] = (Number(state["management"] ?? 0) / Number(state["numberOfUnits"] ?? 0)).toFixed(2);
        }

        if (state["salePrice"]) {
            changed["pricePerUnit"] = Number(state["salePrice"] ?? 0) / Number(state["numberOfUnits"] ?? 0).toFixed(2);
        }
    }

    if (_name == "far" || _name == "lotsizesf") {
        changed["buildable"] = Number((state["far"] ?? 0) * (state["lotSizeSF"] ?? 0)).toFixed(2);

        if (changed["buildable"] && state["grossBuildingAreaSF"]) {
            let remaining = Number(changed["buildable"] ?? 0) - Number(state["grossBuildingAreaSF"] ?? 0).toFixed(2)
            if (remaining < 0) {
                remaining *= -1;
            }
            changed["totalRemaining"] = remaining.toFixed(2);
            changed["totalRemainingPercentage"] = ((Number(changed["totalRemaining"] ?? 0) / Number(changed["buildable"] ?? 0)) * 100).toFixed(2);
        }

        if (changed['buildable'] && state['salePrice']) {
            changed['pricePerBuildableSF'] = Number(Number(state['salePrice'] ?? 0) / Number(changed['buildable'] ?? 0)).toFixed(2);
        }
    }

    if (_name == 'grossbuildingareasf') {
        if (state["numberOfUnits"]) {
            changed["averageUnitSize"] = Number((state["grossBuildingAreaSF"] ?? 0) / (state["numberOfUnits"] ?? 0)).toFixed(2);
        }

        if (state["insurance"]) {
            changed["insurancePerSF"] = Number((state["insurance"] ?? 0) / (state["grossBuildingAreaSF"] ?? 1)).toFixed(2);
        }

        if (state["realEstateTax"]) {
            changed["realEstateTaxPerSF"] = Number((state["realEstateTax"] ?? 0) / (state["grossBuildingAreaSF"] ?? 0)).toFixed(2);
        }

        if (state["buildingReservesCostPerSF"]) {
            changed["buildingReserves"] = Number(Number(state["buildingReservesCostPerSF"] ?? 0) * Number(state['grossBuildingAreaSF'] ?? 0)).toFixed(2);
        }

        if (state["salePrice"]) {
            changed["pricePerSF"] = Number(Number(state["salePrice"] ?? 0) / Number(state["grossBuildingAreaSF"] ?? 0)).toFixed(2);
        }

        if (state["buildable"]) {
            let remaining = Number(state["buildable"] ?? 0) - Number(state["grossBuildingAreaSF"] ?? 0).toFixed(2)
            if (remaining < 0) {
                remaining *= -1;
            }

            changed["totalRemaining"] = remaining.toFixed(2);
            changed["totalRemainingPercentage"] = ((Number(changed["totalRemaining"] ?? 0) / Number(state["buildable"] ?? 0)) * 100).toFixed(2);
        }

        if (state['lotSizeSF']) {
            changed["landBuildingRatio"] = (Number(state['lotSizeSF'] ?? 0) / Number(state['grossBuildingAreaSF'] ?? 0)).toFixed(2)
        }
    }

    if (_name == "lotsizesf") {
        changed["lotSizeAcres"] = Number(value / 43650).toFixed(2);

        if (state["numberOfUnits"]) {
            changed["realEstateTaxPerSF"] = Number(state["realEstateTax"] / value).toFixed(2);
        }

        if (state["monthlyRent"]) {
            changed["rentPerSF"] = (Number(state["monthlyRent"] ?? 0) / state["lotSizeSF"]).toFixed(2);
        }

        if (state["managementPercentage"]) {
            changed["managementPercentagePerSF"] = (Number(state["managementPercentage"] ?? 0) / Number(state["lotSizeSF"] ?? 0)).toFixed(2);
        }

        if (state["management"]) {
            changed["managementPerSF"] = (Number(state["management"] ?? 0) / Number(state["lotSizeSF"] ?? 0)).toFixed(2);
        }

        if (state['grossBuildingAreaSF']) {
            changed["landBuildingRatio"] = (Number(state['lotSizeSF'] ?? 0) / Number(state['grossBuildingAreaSF'] ?? 0)).toFixed(2)
        }
    }

    if (_name == "insurance") {
        if (state["numberOfUnits"]) {
            changed["insurancePerUnit"] = Number((state["insurance"] ?? 0) / (state["numberOfUnits"] ?? 1)).toFixed(2);
        }

        if (state["grossBuildingAreaSF"]) {
            changed["insurancePerSF"] = Number((state["insurance"] ?? 0) / (state["grossBuildingAreaSF"] ?? 1)).toFixed(2);
        }
    }

    if (_name == "realestatetax") {
        if (state["numberOfUnits"]) {
            changed["realEstateTaxPerUnit"] = Number((state["realEstateTax"] ?? 0) / (state["numberOfUnits"] ?? 0)).toFixed(2);
        }

        if (state["grossBuildingAreaSF"]) {
            changed["realEstateTaxPerSF"] = Number((state["realEstateTax"] ?? 0) / (state["grossBuildingAreaSF"] ?? 0)).toFixed(2);
        }
    }

    if (_name == "monthlyrent") {
        changed["annualRent"] = (Number(state["monthlyRent"] ?? 0) * 12).toFixed(2);

        if (state["lotSizeSF"]) {
            changed["rentPerSF"] = (Number(state["monthlyRent"] ?? 0) / state["lotSizeSF"]).toFixed(2);
        }
    }

    if (_name == "management") {
        if (state["numberOfUnits"]) {
            changed["managementPerUnit"] = (Number(state["management"] ?? 0) / Number(state["numberOfUnits"] ?? 0)).toFixed(2);
        }

        if (state["lotSizeSF"]) {
            changed["managementPerSF"] = (Number(state["management"] ?? 0) / Number(state["lotSizeSF"] ?? 0)).toFixed(2);
        }
    }

    if (_name == "managementpercentage") {
        if (state["numberOfUnits"]) {
            changed["managementPercentagePerUnit"] = (Number(state["managementPercentage"] ?? 0) / Number(state["numberOfUnits"] ?? 0)).toFixed(2);
        }

        if (state["lotSizeSF"]) {
            changed["managementPercentagePerSF"] = (Number(state["managementPercentage"] ?? 0) / Number(state["lotSizeSF"] ?? 0)).toFixed(2);
        }
    }

    if (_name == "rentstabilized") {
        if (state["numberOfUnits"]) {
            changed["rentStabilizedPercentage"] = (state["rentStabilized"] ?? 0) / (state["numberOfUnits"] ?? 0);
        }
    }

    if (_name == "buildingreservescostpersf" || _name == "grossbuildingareasf") {
        changed["buildingReserves"] = (state["buildingReservesCostPerSF"] ?? 0) * (state["grossBuildingAreaSF"] ?? 0);
    }

    if (_name == "unitsf" && value != 0) {
        if (state['salePrice']) {
            changed["pricePerSF"] = Number(Number(state["salePrice"] ?? 0) / Number(state["unitSF"] ?? 0)).toFixed(2);
        }
    }

    return changed;
}

export default fieldCalculation;