import Icon from "./Icon";

/** @type {typeof Icon} */
const ContactDetails = (props) => (
  <Icon {...props}>
    <path d="M6.36919 0.0521431C5.35228 0.20796 4.35151 0.704399 3.70586 1.37477C2.12804 3.02353 2.21681 5.40064 3.90762 6.91895C5.5944 8.43363 8.24966 8.51335 10.0696 7.10738C10.6345 6.67254 11.1511 5.96593 11.3851 5.32092C12.0671 3.44387 11.139 1.38564 9.202 0.47611C8.31422 0.0593901 7.30135 -0.089179 6.36919 0.0521431ZM7.5919 1.19721C9.32306 1.43638 10.5377 2.90757 10.2875 4.45487C9.94854 6.52397 7.38609 7.61107 5.41281 6.52035C4.28291 5.89708 3.67761 4.68678 3.92377 3.54171C4.26273 1.9763 5.8567 0.958055 7.5919 1.19721Z" />
    <path d="M11.3449 7.53868C11.2521 7.57854 11.1391 7.66551 11.0987 7.72711C10.8929 8.03512 10.9494 8.23442 11.361 8.63302C12.2649 9.51719 12.8057 10.6405 12.8904 11.8327C12.9267 12.3074 12.8904 12.4016 12.5676 12.6734C11.3328 13.7097 7.70094 14.1953 4.65425 13.7279C3.33468 13.5286 2.16847 13.1263 1.6479 12.6951C1.30893 12.4089 1.26858 12.3146 1.30086 11.8544C1.38157 10.6695 1.92231 9.51719 2.79798 8.66926C3.12081 8.35038 3.19748 8.22355 3.16923 8.01338C3.12081 7.62565 2.63253 7.37562 2.26128 7.54955C2.10793 7.62203 1.66405 8.04237 1.3735 8.39749C0.550286 9.39399 0.0781489 10.5934 0.0055124 11.8653C-0.0308058 12.5538 0.106396 12.9125 0.594675 13.38C2.32584 15.0324 7.6727 15.594 11.3691 14.5069C12.382 14.2098 13.1204 13.833 13.6006 13.3727C14.0849 12.9125 14.2221 12.5538 14.1858 11.8653C14.097 10.3361 13.415 8.88668 12.2851 7.82857C11.8816 7.45171 11.6798 7.39011 11.3449 7.53868Z" />
  </Icon>
);

export default ContactDetails;
