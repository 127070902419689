import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { PlusSquare } from "react-feather";
import { Helmet } from "react-helmet-async";
import Popconfirm from "../../components/Popconfirm";
import adminService from "../../services/admin";
import FormModal from "./FormModal";
import List from "./List";
import startCase from "lodash/startCase";

export const TypeEnum = {
  1: "Commercial",
  2: "Residential",
};

/**
 * @param {Object} props
 * @param {keyof typeof adminService} props.path
 * @param {boolean} [props.hasType]
 * @param {boolean} [props.hasDays]
 * @param {boolean} [props.hasLabel]
 * @param {boolean} [props.hasDisplayOrder]
 * @param {boolean} [props.hasCreated]
 * @param {boolean} [props.hasUpdate]
 * @param {boolean} [props.hasDelete]
 * @param {import("react").FC} [props.CustomAction]
 * @param {string} [props.idKey]
 */
export default function AdminPage(props) {
  const [data, setData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState({});
  const [itemIdToDelete, setItemIdToDelete] = useState(0);

  const title = useMemo(() => `${startCase(props.path)}${props.path.endsWith('s') ? 'es' : 's'}`, [props.path]);

  useEffect(() => {
    // setData([]);
    adminService[props.path].get().then(setData);
  }, [props.path]);

  const handleSubmit = useCallback(
    async (values) => {
      if (itemToEdit.id) {
        const updatedItem = await adminService[props.path].update({
          ...itemToEdit,
          ...values,
        });
        setData((data) => {
          const itemIndex = data.findIndex(
            (item) => updatedItem.id === item.id
          );
          data[itemIndex] = updatedItem;
          return [...data];
        });
      } else {
        const newItem = await adminService[props.path].create(values);
        setData((data) => [...data, newItem]);
      }
    },
    [itemToEdit.id, props.path]
  );

  const handleItemDelete = useCallback(async () => {
    if (itemIdToDelete) {
      await adminService[props.path].delete({ [props.idKey]: itemIdToDelete });
      setData((data) => {
        const itemIndex = data.findIndex((item) => itemIdToDelete === item.id);
        data.splice(itemIndex, 1);
        return [...data];
      });
      setItemIdToDelete(null);
    }
  }, [itemIdToDelete, props.idKey, props.path]);

  const handleModalClose = useCallback(() => {
    setShowAddModal(false);
    setItemToEdit({});
  }, []);

  return (
    <>
      <Helmet title={title} />
      <FormModal
        show={showAddModal || itemToEdit.id}
        title={`${!!itemToEdit.id ? "Update" : "Add"} ${title}`}
        close={handleModalClose}
        onSubmit={handleSubmit}
        initialValues={{
          displayOrder: data.length + 1,
          ...itemToEdit,
        }}
        hasType={props.hasType}
        hasDays={props.hasDays}
        hasLabel={props.hasLabel}
        hasDisplayOrder={props.hasDisplayOrder}
      />
      <Popconfirm
        show={!!itemIdToDelete}
        onCancel={() => setItemIdToDelete(0)}
        title="Delete this item?"
        onOk={handleItemDelete}
      />
      <div className="d-flex justify-content-between mb-3">
        <h2>{title}</h2>
        <Button className="float-end" onClick={() => setShowAddModal(true)}>
          <PlusSquare size={20} className="me-2" />
          Add
        </Button>
      </div>
      <List
        data={data}
        onDelete={(item) => setItemIdToDelete(item.id)}
        onEdit={setItemToEdit}
        hasType={props.hasType}
        hasDays={props.hasDays}
        hasLabel={props.hasLabel}
        hasDisplayOrder={props.hasDisplayOrder}
        hasCreated={props.hasCreated}
        hasUpdate={props.hasUpdate}
        hasDelete={props.hasDelete}
        CustomAction={props.CustomAction}
      />
    </>
  );
}
