import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Download, Edit3, Slash } from "react-feather";
import LoadingWrapper from "../../../components/LoadingWrapper";
import Popconfirm from "../../../components/Popconfirm";
import {
  getInspectionImages,
  uploadInspectionImage,
  deleteInspectionImage,
  updateImageTags,
  updateInspectionImageDescription,
  getInspectionImagesBase64V3,
} from "../../../services/inspection";
import ProgressiveImage from "react-progressive-graceful-image";
import { useParams } from "react-router-dom";
import useJobInspectionDetails from "../../jobDetails/useJobInspectionDetails";
import { urlToFile } from "../../../utils";
import { deleteFile } from "../../../services/microsoftTeamsFiles";
import Popup from "../Popup";
import "./../jobView.css";


import loadingIcon from "../../../assets/icons/loading.svg";
import { ReactComponent as FilesIcon } from "../../../assets/icons/files.svg";
import { ReactComponent as CameraIcon } from "../../../assets/icons/camera.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload.svg";
import { ReactComponent as LeftArrow } from "../../../assets/icons/leftarrow.svg"
import { ReactComponent as RightArrow } from "../../../assets/icons/rightarrow.svg"
import useScreenSize from "../../../hooks/useScreenSize";
import Compressor from 'compressorjs';
import { toast } from "react-toastify";
import UploadProgressToast from "../../../utils/uploadProgressToast";
import DropUpload from "../../jobDetails/tabs/components/DropUpload";
import { Downloader } from "../../../utils/download";
import useJobDetails from "../../jobDetails/useJobDetails";

function compress(file) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.3,
      success: resolve,
      error: reject,
    });
  });
}

/**
 * @param {Object} props
 * @param {boolean} props.uploadEnabled `false` by default
 * @returns
 */
const Files = (props) => {
  const { ticketId } = useParams();
  const { jobName } = useJobDetails();
  const {
    scheduledInspection,
    inspectionTags,
    inspectionImages,
    setInspectionImages,
    inspectionImagesLoading,
    setInspectionImagesLoading,
    initInspectionImagesData,
  } = useJobInspectionDetails();
  const [show, setShow] = useState(false);
  const [previewModalShow, setPreviewModalShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [imageToDelete, setImageToDelete] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedTag, setSelectedTag] = useState();
  const containers = useRef([]);
  const inspectionImagesRef = useRef(inspectionImages);
  inspectionImagesRef.current = inspectionImages;
  const { isMobile, height, width } = useScreenSize();
  const [pWidth, setPWidth] = useState(100);
  const [pHeight, setPHeight] = useState(100);
  const [downloadLoading, setDownloadLoading] = useState();

  const handleClose = () => setShow(false);
  const handleShowPreview = (image, index) => {
    setPreviewModalShow(true);
    setSelectedImage({ ...image, index });
  };
  const handleShow = (image, index) => {
    setShow(true);
    setSelectedImage({ ...image, index });
  };
  const handleDeleteUnavailableImage = (image) => {
    setImageToDelete(image);
    setDeleteConfirm(true);
  };

  useEffect(() => {
    initInspectionImagesData();
  }, [initInspectionImagesData]);

  useEffect(() => {
    if (width > 300 && width < 375) {
      setPWidth(90);
      setPHeight(90);
    }
    else if (width >= 375 && width <= 420) {
      setPWidth(100);
      setPHeight(100);
    }
    // else if (width > 420 && width < 500) {
    //   setPWidth(130);
    //   setPHeight(130);
    // }
    else {
      setPWidth(130);
      setPHeight(130);
    }
  }, [height, width])

  // useEffect(() => {
  //   const drake = dragula(containers.current);

  //   drake.on("drag", (_el, source) => {
  //     const dataType = source.getAttribute("data-type");

  //     if (dataType === "image") {
  //       drake.cancel();
  //     }
  //   });

  //   drake.on("drop", (el, target) => {
  //     const tag = el.getAttribute("data-tag");
  //     const imageId = target.getAttribute("data-id");

  //     const imageIndex = inspectionImagesRef.current.findIndex(
  //       (image) => image.id === Number(imageId)
  //     );
  //     const image = inspectionImagesRef.current[imageIndex];

  //     if ((image.tag || "").split(",").includes(tag)) {
  //       return;
  //     }

  //     const newTags = `${tag},${inspectionImagesRef.current[imageIndex].tag}`;

  //     inspectionImagesRef.current[imageIndex] = {
  //       ...inspectionImagesRef.current[imageIndex],
  //       tag: newTags,
  //     };

  //     setInspectionImagesLoading(true);
  //     setInspectionImages([...inspectionImagesRef.current]);
  //     updateImageTags(imageId, newTags).finally(() =>
  //       setInspectionImagesLoading(false)
  //     );
  //   });
  // }, []);

  const initRef = (ref) => containers.current.push(ref);

  /** @param {React.ChangeEvent<HTMLInputElement>} e */
  const fileUploadMiddleware = (e) => {
    if (!e.target.files?.length) {
      return;
    }
    onSelectFile([...e.target.files]);
    e.target.value = null;
  }

  /** @param {Array<File>} files */
  const onSelectFile = (files) => {
    if (files.length === 1) {
      new Compressor(files[0], {
        quality: 0.3,
        success: (compressedResult) => {
          const previewImage = URL.createObjectURL(compressedResult);
          setSelectedImage({ src: previewImage, name: files[0].name });
          setShow(true);
        },
      });
    } else {
      setInspectionImagesLoading(true);

      Promise.all(files.map(compress))
        .then((compressResult) => {
          const uploadProgressToast = new UploadProgressToast({
            onRetry(id) {
              uploadInspectionImage(
                {
                  image: compressResult[id],
                  ticketId: ticketId,
                },
                uploadProgressToast.onUploadProgress(id),
                compressResult[id]?.name,
              ).catch(() => {
                uploadProgressToast.onUploadFail(id);
              });
            },
            items: compressResult.map((_item, index) => ({
              index,
              name: `Image ${index + 1}`,
              icon: URL.createObjectURL(_item)
            })),
          });
          Promise.allSettled(
            compressResult.map((image, index) => {
              return uploadInspectionImage(
                {
                  image,
                  ticketId: ticketId,
                },
                uploadProgressToast.onUploadProgress(index),
                image.name
              );
            })
          )
            .then(async (response) => {
              const err = [];
              for (const index in response) {
                if (response[index]?.status === "rejected") {
                  uploadProgressToast.onUploadFail(index);
                  err.push(response[index]);
                }
              }
              if (err.length > 0) {
                setInspectionImagesLoading(false);
                const msg = err.map((x) => x.reason).join(", ");
                toast.error(`Failed to upload images ${msg}`);
                return;
              }

              const imageIds = inspectionImages.map((item) => item.id);
              const images = await getInspectionImages(scheduledInspection.id);
              const filteredImages = images.filter(
                (item) => !imageIds.includes(item.id)
              );

              const newIds = filteredImages.map((x) => x.id);
              newIds.sort((a, b) => a.id - b.id);
              const result = await getInspectionImagesBase64V3(newIds);

              const newImages = [
                ...inspectionImages,
                ...result.map((item, index) => ({
                  ...filteredImages[index],
                  src: item?.value ? item?.value : item || "",
                })),
              ];

              newImages.sort((a, b) => a.id - b.id);
              setInspectionImages(newImages);
              setInspectionImagesLoading(false);
            })
            .catch(() => {
              setInspectionImagesLoading(false);
              toast.error("Failed to upload images");
            });
        })
        .catch(() => {
          setInspectionImagesLoading(false);
          toast.error("Failed to compress images");
        });
    }
  };

  const handleSubmit = async (dataUrl, tag) => {
    setInspectionImagesLoading(true);
    setShow(false);

    if (selectedImage.microsoftTeamsFileId) {
      await deleteInspectionImage(selectedImage.id);
    }
    if (selectedImage.microsoftTeamsFileId) {
      await deleteFile(ticketId, selectedImage.microsoftTeamsFileId);
    }
    const uniqueId = Date.now() + Math.random().toString().replace(".", "");
    const file = await urlToFile(
      dataUrl,
      uniqueId,
      "image/png"
    );
    setSelectedImage({});
    const uploadProgressToast = new UploadProgressToast({
      items: [{ index: 0, name: "image" }],
      icon: <img src={dataUrl} height={36} />,
      onRetry: () => handleSubmit(dataUrl, tag),
    });
    uploadInspectionImage(
      {
        image: file,
        ticketId: ticketId,
        tag: tag?.replaceAll(",", "_"),
      },
      uploadProgressToast.onUploadProgress()
    )
      .catch(() => uploadProgressToast.onUploadFail())
      .finally(async (result = {}) => {
        setInspectionImages([
          ...inspectionImages,
          {
            id: Number(uniqueId),
            src: dataUrl,
            tag,
            ...result,
          },
        ]);
        setInspectionImagesLoading(false);
      });
  };

  const handleImageDelete = async () => {
    const id = selectedImage.id || imageToDelete.id;
    const microsoftTeamsFileId =
      selectedImage.microsoftTeamsFileId || imageToDelete.microsoftTeamsFileId;
    if (!microsoftTeamsFileId && !id) {
      return;
    }
    setInspectionImagesLoading(true);
    setDeleteConfirm(false);
    try {
      if (id) {
        await deleteInspectionImage(id);
        setInspectionImages(
          inspectionImages.filter((image) => image.id !== id)
        );
      }
      if (microsoftTeamsFileId) {
        deleteFile(ticketId, microsoftTeamsFileId);
      }
    } catch (err) { }
    setSelectedImage({});
    setImageToDelete({});
    setInspectionImagesLoading(false);
    handleClose();
  };

  const handleSelectedImageTagsUpdate = (tags) => {
    if (!selectedImage.id) {
      return;
    }

    updateImageTags(selectedImage.id, tags).then(() => {
      const imageIndex = inspectionImages.find(
        (item) => item.id === selectedImage.id
      );
      inspectionImages[imageIndex] = {
        ...inspectionImages[imageIndex],
        tag: tags,
      };
      setInspectionImages([...inspectionImages]);
      initInspectionImagesData(true)
    });
  };

  const prev = (isPreview = false) => {
    const currentItemIndex = inspectionImagesRef.current.findIndex(
      (x, i) => x.id == selectedImage.id || i == selectedImage.index
    );

    if (![0, -1].includes(currentItemIndex)) {
      setShow(false);
      setSelectedImage({});
      setTimeout(() => {
        (isPreview ? handleShowPreview : handleShow)(
          inspectionImagesRef.current[currentItemIndex - 1],
          currentItemIndex - 1
        );
      }, 250);
    }
  }

  const next = (isPreview = false) => {
    const currentItemIndex = inspectionImagesRef.current.findIndex(
      (x, i) => x.id == selectedImage.id || i == selectedImage.index
    );

    if (![inspectionImagesRef.current.length - 1, -1].includes(currentItemIndex)) {
      setShow(false);
      setSelectedImage({});
      setTimeout(() => {
        (isPreview ? handleShowPreview : handleShow)(
          inspectionImagesRef.current[currentItemIndex + 1],
          currentItemIndex + 1
        );
      }, 250);
    }
  }

  const onUpdateDescription = async (description) => {
    await updateInspectionImageDescription(selectedImage.id, description);
  }

  const tagSelectedHandler = (tag) => {
    if (selectedTag === tag) {
      setSelectedTag(undefined);
      return;
    }

    setSelectedTag(tag);
  }

  const handleDownloadPhotos = () => {
    const toastId = toast("Downloading, please wait...", {
      progress: 0,
      progressStyle: { background: "#44AAA4" },
      autoClose: false,
      closeButton: false,
      closeOnClick: false,
    });
    const downloader = new Downloader(
      inspectionImages.map((image) => ({
        id: image.microsoftTeamsFileId,
        url: image.src,
        name: image.fileName
      })),
      `${jobName} - inspection images`
    );
    downloader.onFileDownloaded((percent) => {
      toast.update(toastId, { progress: percent / 100 });
    });
    setDownloadLoading(true);
    downloader
      .downloadFilesAndZip()
      .catch(() => toast.error("Failed to download!"))
      .finally(() => {
        setDownloadLoading(false);
        toast.dismiss(toastId);
      });
  };

  return (
    <LoadingWrapper loading={inspectionImagesLoading}>
      <Popconfirm
        title="Are you sure?"
        show={deleteConfirm}
        onCancel={() => setDeleteConfirm(false)}
        onOk={handleImageDelete}
      />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <FilesIcon width={20} className="me-1" />
          <span className="fs-3 font-weight-bold text-brown">
            Files &amp; Photos
          </span>
        </div>
        <Button
          size="sm"
          onClick={handleDownloadPhotos}
          disabled={downloadLoading}
        >
          {downloadLoading ? (
            <Spinner size="sm" animation="border" />
          ) : (
            <Download size={18} />
          )}
        </Button>
      </div>
      <Modal show={previewModalShow} onHide={() => setPreviewModalShow(false)}>
        <Modal.Header closeButton />
        <Modal.Body className="text-center p-3" style={{ height: "85vh" }}>
          <ProgressiveImage
            src={selectedImage.src}
            style={{ width: "100%", height: "80%", minHeight: "80%" }}
            placeholder={loadingIcon}
          >
            {(src, loading) => (
              <img
                style={{ height: "80%", minHeight: "80%" }}
                width={500}
                height={'80%'}
                src={src}
              />
            )}
          </ProgressiveImage>
          <div className="d-flex justify-content-evenly mb-1 mt-1">
            <button
              className="arrow-btn"
              onClick={() => prev(true)}
              style={{
                opacity:
                  (selectedImage.index || selectedImage.index == 0) &&
                    selectedImage.index == 0
                    ? 0.5
                    : 1,
              }}
              disabled={
                (selectedImage.index || selectedImage.index == 0) &&
                selectedImage.index == 0
              }
            >
              <LeftArrow />
            </button>
            <button
              className="arrow-btn"
              onClick={() => next(true)}
              style={{
                opacity:
                  (selectedImage.index || selectedImage.index == 0) &&
                    selectedImage.index + 1 === inspectionImages?.length
                    ? 0.5
                    : 1,
              }}
              disabled={
                (selectedImage.index || selectedImage.index == 0) &&
                selectedImage.index + 1 === inspectionImages?.length
              }
            >
              <RightArrow />
            </button>
          </div>
          <Button
            className="w-100 mt-3"
            onClick={() => {
              setPreviewModalShow(false);
              setShow(true);
            }}
          >
            <Edit3 className="me-2" size={16} /> Edit
          </Button>
        </Modal.Body>
      </Modal>

      {!inspectionImagesLoading && (
        <div className="files-container rounded-lg p-2 ">
          <div ref={initRef} className="tags-container d-flex flex-wrap">
            {inspectionTags.map((tagTitle) => (
              <Tag
                tagSelectedHandler={tagSelectedHandler}
                checked={selectedTag == tagTitle}
                quantity={
                  inspectionImages?.filter(
                    (x) => x.tag && x.tag.includes(tagTitle)
                  )?.length
                }
                key={tagTitle}
                title={tagTitle}
              />
            ))}
          </div>

          {inspectionImages && inspectionImages.length == 0 && (
            <p className="text-center fs-10">
              <b>There are no images uploaded yet.</b>
            </p>
          )}
          {inspectionImages && inspectionImages.length > 0 && (
            <div className="grid-scrollable mt-2">
              <div className="grid">
                {inspectionImages
                  .filter((x) =>
                    selectedTag == "" || !selectedTag
                      ? true
                      : x.tag === selectedTag
                  )
                  .map((photo, i) => (
                    <div
                      key={i}
                      ref={initRef}
                      data-id={photo.id}
                      data-type="image"
                      onClick={() =>
                        photo.src
                          ? handleShowPreview(photo, i)
                          : handleDeleteUnavailableImage(photo)
                      }
                      className="position-relative rounded-lg overflow-hidden justify-self"
                    >
                      {photo.src ? (
                        <ProgressiveImage
                          src={photo.src}
                          placeholder={"https://via.placeholder.com/120"}
                        >
                          {(src, loading) => (
                            <img
                              className={`${loading ? "img-loading" : "img-loaded"
                                }`}
                              width={pWidth}
                              height={pHeight}
                              src={src}
                            />
                          )}
                        </ProgressiveImage>
                      ) : (
                        // <img src={photo.src} alt="" height={80}  />
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                          <Slash size={56} color="#d30000" />
                          <span>Not available</span>
                        </div>
                      )}
                      {!!photo.tag && (
                        <div className="position-absolute photo-badge">
                          {photo.tag}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
      {props.uploadEnabled && (
        <DropUpload
          path="Inspection"
          multiple
          onUpload={onSelectFile}
          style={{ minHeight: 80, marginTop: 25, marginBottom: 15 }}
        >
          <div className="files-btns d-flex justify-content-center">
            {!props.hideCamera && (
              <>
                <input
                  id="image-upload-camera"
                  type="file"
                  hidden
                  disabled={!scheduledInspection.id}
                  accept="image/*"
                  capture="camera"
                  onChange={fileUploadMiddleware}
                />
                <label
                  htmlFor="image-upload-camera"
                  className={`${isMobile ? "files-btn-mobile" : "files-btn"
                    } d-flex flex-column align-items-center justify-content-center`}
                  style={{ opacity: !scheduledInspection.id ? 0.5 : 1 }}
                >
                  <CameraIcon className="mb-1" height={isMobile ? 25 : 25} />
                  <span className="files-btn__title">Launch Camera</span>
                </label>
              </>
            )}

            <input
              id="image-upload"
              type="file"
              hidden
              // disabled={!scheduledInspection.id}
              accept="image/*"
              multiple="multiple"
              onChange={fileUploadMiddleware}
            />
            <label
              htmlFor="image-upload"
              className={`${isMobile ? "files-btn-mobile" : "files-btn"
                } d-flex flex-column align-items-center justify-content-center`}
            // style={{ opacity: !scheduledInspection.id ? 0.5 : 1 }}
            >
              <UploadIcon className="mb-1" height={isMobile ? 25 : 25} />
              <span className="files-btn__title">Upload file</span>
            </label>
          </div>
        </DropUpload>
      )}

      {selectedImage && (
        <Popup
          id={selectedImage.id}
          microsoftTeamsFileId={selectedImage.microsoftTeamsFileId}
          currentItemIndex={
            selectedImage.index ??
            inspectionImages?.indexOf(selectedImage) ??
            -1
          }
          totalCount={inspectionImages?.length ?? 0}
          description={selectedImage.description}
          onUpdateDescription={onUpdateDescription}
          prev={prev}
          next={next}
          show={show && !deleteConfirm}
          onHide={handleClose}
          url={selectedImage.src}
          allTags={inspectionTags}
          tags={(selectedImage.tag || "").split(",").filter(Boolean)}
          onSubmit={handleSubmit}
          onDelete={() => setDeleteConfirm(true)}
          onTagsChange={(tags) => handleSelectedImageTagsUpdate(tags)}
        />
      )}
    </LoadingWrapper>
  );
};

const Tag = ({ checked, tagSelectedHandler, title, quantity }) => {
  return (<div className={checked ? "selected-tag" : "tag"} data-tag={title} data-type="tag" onClick={() => tagSelectedHandler(title)}>
    <div className="tag-title text-black">{title}</div>
    {!!quantity && <div className="tag-quantity text-white">{quantity}</div>}
  </div>
  );
}

export default Files;
