/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} UnitType */
import axios from "../../utils/axios";

/** @returns {Promise<Array<UnitType>>} */
export function getUnitTypes() {
  return axios.get("/UnitType/GetUnitTypes");
}

/**
 * @param {string} name
 * @returns {Promise<UnitType>}
 */
export function createUnitType(name) {
  return axios.post("/UnitType/CreateUnitType", { name });
}
