import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Building, Pin } from "./icons";

const InspectionListItem = memo(
  ({ inspection }) => {
    const { id: ticketId, ticketGroupId } = inspection.ticket || {};

    return (
      <Link
        to={`/dashboard/job/${ticketGroupId}/${ticketId}`}
        className="d-flex bg-light px-1 rounded mt-3"
        style={{ fontSize: 11, color: "#4F4443" }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            width: 64,
            borderRight: "1px solid rgba(255, 255, 255, 0.33)",
          }}
        >
          <b className="text-black" style={{fontSize: '10px'}}>{inspection.hour}</b>
        </div>
        <div className="py-1">
          <div className="px-2">
            <Pin />
            <span className="ms-2">{inspection.formattedAddress}</span>
          </div>
          <div className="px-2">
            <Building />
            <span className="ms-2">{inspection.propertyTypeString}</span>
          </div>
        </div>
      </Link>
    );
  },
  (prevProps, nextProps) => prevProps.inspection.id === nextProps.inspection.id
);

export default InspectionListItem;
