import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

const DropdownQuestion = ({ onChange, value, label, options, disabled }) => (
  <Form.Group className="mb-2">
    <Form.Label className="me-1">{label}:</Form.Label>
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      value={value ? { value, label: value } : undefined}
      placeholder={label}
      options={[{ value: 'None', label: 'None' }, ...(options || "").split(",").map((option) => ({
        value: option,
        label: option,
      }))]}
      onChange={(e) => onChange(e.value)}
      isDisabled={disabled}
    />
  </Form.Group>
);

export default DropdownQuestion;
