/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} LotType */
import axios from "../../utils/axios";

/** @returns {Promise<Array<LotType>>} */
export function getLotTypes() {
  return axios.get("/LotType/GetLotTypes");
}

/**
 * @param {string} name
 * @returns {Promise<LotType>}
 */
export function createLotType(name) {
  return axios.post("/LotType/CreateLotType", { name });
}
