import { forwardRef } from "react";
import { MapPin } from "react-feather";

const pinSize = 22;
const SHOW_INFO_ON_HOVER = false;

const CustomMarker = forwardRef(({ title, onClick }, ref) => (
  <div
    ref={ref}
    onClick={onClick}
    title={SHOW_INFO_ON_HOVER ? title : undefined}
  >
    <MapPin
      size={`${pinSize}px`}
      color="#e90a2e"
      style={{ position: "absolute", top: -pinSize, left: -pinSize / 2 }}
    />
    {!SHOW_INFO_ON_HOVER && (
      <span
        style={{
          whiteSpace: "nowrap",
          color: "black",
          backgroundColor: "white",
          padding: "1px 4px",
        }}
      >
        {title}
      </span>
    )}
  </div>
));

export default CustomMarker;
