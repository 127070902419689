import React, { useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";

import FiltersIcon from "./FiltersIcon";

function ContextAwareToggle({ children, activeKey }) {
  return (
    <div className="d-flex justify-content-between">
        {children}
        <div className="d-flex justify-content-end">
          <Button
            className={`custom-accordion-button btn-white-custom${
              activeKey !== "0" ? " collapsed" : ""
            }`}
          >
            <span>
              <FiltersIcon size={18} className="me-2" />
              {activeKey !== "0" ? "Show all Filters" : "Hide Filters"}
            </span>
          </Button>
        </div>
    </div>
  );
}

const CustomAccordion = ({ header, children }) => {
  const [activeKey, setActiveKey] = useState(null);

  const toggle = () => {
    setActiveKey((oldValue) => (oldValue === "0" ? null : "0"));
  };

  return (
    <Accordion activeKey={activeKey}>
      <Card style={{ background: "#ECECEC" }}>
        <Card.Header className="bg-white rounded-lg border" onClick={toggle}>
          <ContextAwareToggle activeKey={activeKey}>
            {header}
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body style={{ background: "#ECECEC" }}>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default CustomAccordion;
