import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, Spinner, Modal } from "react-bootstrap";
import { ArrowLeft } from "react-feather";

import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import axios from "axios"
import moment from "moment"

import CustomerInfo from "./CustomerInfo";
import PropertyInfo from "./PropertyInfo";
import { AuthContext } from "../../../contexts/JWTContext";
import AppraisalDetails from "./AppraisalDetails";

//** Configs and Hooks */
import { SERVER_URL } from "../../../configs";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import { loading, loadTicketGroups } from "../../../redux/actions/ticketGroups";
import { getAppraisalContactObj, getCustomerContactObj } from "../../../utils/index"
import { getIntakeFormByTicketId, processIntake, updateTicketGroupByIntakeForm, getCustomerJobsByAddress } from "../../../services/intake";


const customerInitialValues = (
  customer = {
    id: 0,
    name: "",
    address: "",
  },
  _firstObj = [getCustomerContactObj()],
  customerRules = [],
) => ({
  customer,
  customerContacts: _firstObj,
  customerRules,
});

//TODO: Multi Property Notes Support in future

const createNewProp = () => ({
  id: 0, address: '', propertyTypeId: 0, lat: 0, lng: 0, fileNumber: "", clientReference: "", fee: 0, isInspectionRequired: false,
  houseNumber: "", street: "", neighborhood: "", unit: "", city: "", county: "", state: "", zip: "", type: "1",
  googlePlaceId: "", propertyNotes: [{ id: 0, propertyId: 0, note: "" }], parcelId: '', valueOfPreviousDate: null,
  jobAssignmentTypeId: 0, jobPurposeId: 0, purposeNote: '', inspectionType: -1, valueTypeIds: [], inspectionContacts: [getAppraisalContactObj()]
})

export default function Tickets() {

  //Update Call: ID passed in path param
  const { ticketGroupId, ticketId } = useParams()
  const navigate = useNavigate()

  //Data related to properties and Their Info
  const [properties, setProperties] = useState([createNewProp()]);
  //Data related to ticket
  const [appraisalProps, setAppraisalProps] = useState({
    name: '',
    requestedDueDate: null,
    generalNotes: '',
    addressedToOther: false,
    addressedToFirstName: '',
    addressedToLastName: '',
    addressedToEmail: '',
    addressedToPhone: ''
  });

  const [contactIndex, setContactIndex] = useState();
  const [affiliateId, setAffiliateId] = useState();
  const [affiliatePaymentMethod, setAffiliatePaymentMethod] = useState();

  //Flag for identifying jobName is updated or not
  const [jobNameUpdated, jobNameUpdatedFlag] = useState(false);

  //Filtered Records based on first customer contact Details
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  //Customer Data
  const [customerData, setCustomerData] = useState(customerInitialValues())

  //Customer Selection Identifier Flag
  const [companySelectedFlag, setCompanySelectedFlag] = useState(false);

  //loader state
  const [sLoading, setLoading] = useState(false);
  const [quoteLoading, setQuoteLoading] = useState(false);

  //dataloder
  const [dataLoader, setDataLoader] = useState(true)
  const [similarJobPopupVisible, setSimilaryJobPopupVisible] = useState(false);
  const [similarJobs, setSimilarJobs] = useState();

  // Custom Hooks
  const dispatch = useAppDispatch()

  const authContext = useContext(AuthContext);
  const { propertyTypes, assignmentTypes } = useAppSelector(state => state.appData)
  const { filters } = useAppSelector(state => state.search)

  const handleTypeChange = (value, index) => {
    let _properties = [...properties]
    _properties[index].type = value
    let _propertyTypes = propertyTypes.find((type) => String(type.type) === value)
    let _assignmentTypes = assignmentTypes.find((type) => String(type.type) === value)

    if (_propertyTypes)
      _properties[index]['propertyTypeId'] = _propertyTypes.id

    if (_assignmentTypes)
      _properties[index]['jobAssignmentTypeId'] = _assignmentTypes.id

    setProperties(_properties)

  }

  useEffect(() => {
    if (ticketGroupId && propertyTypes && authContext.isAuthenticated) {
      getIntakeFormByTicketId(ticketGroupId).then((data) => {
        let _properties = [...data.properties]

        _properties.forEach((property) => {
          let _propertyTypes = propertyTypes.find((type) => String(type.id) === String(property.propertyTypeId))
          if (_propertyTypes)
            property['type'] = String(_propertyTypes.type)
          else
            property['type'] = "1"

          if (property.valueOfPreviousDate)
            property.valueOfPreviousDate = moment(property.valueOfPreviousDate).format('YYYY-MM-DD')
        })

        setProperties(_properties)

        setCustomerData(customerInitialValues({ ...data.customer }, data.customerContacts, data.customerRules))
        setContactIndex(data.customerContactIndex)
        setAffiliateId(data.affiliateId)
        setAffiliatePaymentMethod(data.affiliatePaymentMethod)
        let _requestedDueDate = data.requestedDueDate
        if (_requestedDueDate)
          _requestedDueDate = moment(_requestedDueDate).format('YYYY-MM-DD')
        setAppraisalProps({
          name: data.name,
          requestedDueDate: _requestedDueDate,
          generalNotes: data.generalNotes || "",
          addressedToOther: data.addressedToOther ?? false,
          addressedToFirstName: data.addressedToFirstName,
          addressedToLastName: data.addressedToLastName,
          addressedToEmail: data.addressedToEmail,
          addressedToPhone: data.addressedToPhone
        })
      }).catch((err) => {
        console.error(err)
        toast.error(err)
      }).finally(() => {
        setDataLoader(false)
      })
    }
  }, [ticketGroupId, propertyTypes, authContext.isAuthenticated])

  const handleCompanySelection = (customerInfo) => {
    if (customerInfo) {
      const customerDetails = customerInitialValues()
      customerDetails.customer.id = customerInfo.id
      customerDetails.customer.name = customerInfo.name
      customerDetails.customer.address = customerInfo.address

      axios.get(`${SERVER_URL}/api/Customer/GetCustomerContacts?customerId=${customerInfo.id}`).then((res) => {
        customerDetails.customerContacts = [...res.data]
        setCustomerData(customerDetails)
        setContactIndex(0)
        setCompanySelectedFlag(true)
      })
    }
  }
  
  const handleAffiliateSelection = (affiliate) => {
    setAffiliateId(affiliate?.id);
    setAffiliatePaymentMethod(affiliate?.paymentMethod || '');
  }
  
  const handleUpdateAffiliatePaymentMethod = (paymentMethod) => {
    setAffiliatePaymentMethod(paymentMethod);
  }

  const detailsChangedListener = (company) => {
    const filters = { "phoneNumbers": [], "emails": [] };

    if (company.customer.name === "" && company.customer.address === "")
      return;

    //Filter Logic based on first customer contact details
    if (company.customerContacts.length > 0) {
      if (company.firstName !== "") {
        filters.firstName = company.customerContacts[0].firstName
      }
      if (company.customerContacts[0].lastName !== "") {
        filters.lastName = company.customerContacts[0].lastName
      }
      company.customerContacts[0].customerContactPhoneNumbers.forEach((phoneObj) => {
        filters.phoneNumbers.push(phoneObj.phone)
      })
      company.customerContacts[0].customerContactEmails.forEach((emailsObj) => {
        filters.emails.push(emailsObj.email)
      })
    }

    //Filter Logic based on customer details
    if (company.customer.name !== "") {
      filters.customerName = company.customer.name
    }
    if (company.customer.address !== "") {
      filters.customerAddress = company.customer.address
    }

    axios.post(`${SERVER_URL}/api/Customer/FilterCustomers`, filters, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setFilteredCustomers(res.data)
    })
  }

  useEffect(() => {
    if (!jobNameUpdated) {
      let name = ""//properties[0].address

      if (properties[0]?.houseNumber)
        name = name + `${properties[0].houseNumber}`

      if (properties[0]?.street)
        name = name + ` ${properties[0].street}`

      if (properties[0]?.unit)
        name = name + ` ${properties[0].unit}`
      setAppraisalProps({ ...appraisalProps, name })
    }
  }, [properties[0].address, properties[0].unit]);

  useEffect(() => {

    // this should not be available in edit mode
    if (ticketGroupId || ticketId) {
      return;
    }

    if (!properties[0].googlePlaceId) {
      return;
    }

    const body = {
      customerId: null,
      googlePlaceId: properties[0].googlePlaceId,
      unit: properties[0].unit,
    }

    getCustomerJobsByAddress(body.customerId, body.googlePlaceId, body.unit).then(x => {
      setSimilaryJobPopupVisible(x.length != 0);
      setSimilarJobs(x);
    }).catch(e => {

    })
  }, [properties[0].googlePlaceId])

  const updateAppraisalProps = (name, value) => {
    setAppraisalProps({ ...appraisalProps, [name]: value })
  }

  const getData = () => {
    const ticketData = { ...customerData, affiliateId: affiliateId, affiliatePaymentMethod: affiliatePaymentMethod, customerContactIndex: contactIndex, properties: [...properties] };
    Object.keys(appraisalProps).forEach((key) => {
      ticketData[key] = appraisalProps[key]
    })

    console.log("Ticket Data: ", ticketData)
    return ticketData
  }

  const createTicket = (isReadyForQuote) => {
    const ticketData = getData()
    ticketData.isReadyForQuote = isReadyForQuote

    if (!ticketData.name || ticketData.name.length < 3) {
      toast.error('Please specify the job name...');
      return;
    }

    if (ticketData.properties.some(property => !property.propertyTypeId)) {
      toast.error("Please select Property Type!")
      return;
    }

    if (!ticketData.requestedDueDate || ticketData.requestedDueDate == '') {
      toast.error("Please specify the due date...");
      return;
    }

    if (ticketData.properties.some(x => !x.internalDueDate)) {
      toast.error("Please specify the internal due date...");
      return;
    }

    if (ticketData.properties.some(x => x.inspectionType == -1)) {
      toast.error("Please specify the inspection type...");
      return;
    }

    toast.info("Creating tickets. Please wait...")
    if (isReadyForQuote) {
      setQuoteLoading(true)
    } else
      setLoading(true)

    processIntake(ticketData).then(() => {
      updateReduxWithUpdatedTicket(`Ticket${ticketData.properties.length > 1 ? 's were' : ''} created successfully!`)
    }).catch((err) => {
      console.error(err)
      toast.error("Failed to create the ticket, please contact Administrator !!!");
    }).finally(() => {
      if (isReadyForQuote) {
        setQuoteLoading(false)
      } else
        setLoading(false)
    })
  }

  const updateReduxWithUpdatedTicket = (onSuccMessage, navigateBack = false, page = 0, pageSize = 5) => {
    dispatch(loading())
    dispatch(loadTicketGroups(page, pageSize, filters))
    toast.success(onSuccMessage);
    navigate(navigateBack ? -1 : "/");
  }

  const selectContactListener = (index) => {
    setContactIndex(index);
  }

  const saveTicket = () => {
    const ticketData = getData()
    ticketData.ticketGroupId = ticketGroupId

    if (ticketData.properties.some(property => !property.propertyTypeId)) {
      toast.error("Please select Property Type!")
      return;
    }

    toast.info("Updating tickets, please wait...");
    setLoading(true)
    updateTicketGroupByIntakeForm(ticketData).then(() => {
      updateReduxWithUpdatedTicket("Ticket Updated Successfully !!!", true)
    }).catch((err) => {
      console.error(err)
      toast.error("Failed to update the ticket, please contact Administrator !!!");
    }).finally(() => {
      setLoading(false)
    })
  }

  const addNewProperty = () => {
    let _properties = [...properties]
    _properties.push(createNewProp())
    setProperties(_properties)
  }

  const navigateToJob = (ticketGroupId, ticketId) => {
    navigate(`/ dashboard / job / ${ticketGroupId} / ${ticketId}`);
  }

  return (
    <React.Fragment>
      <Modal show={similarJobPopupVisible} size="md">
        <Modal.Title className="p-2">Jobs with same address found</Modal.Title>
        <Modal.Body>
          <p>Below are the jobs that were found by the given address</p>
          {(similarJobs && similarJobs.length > 0) ? similarJobs.map(x =>
          (<Row>
            <Col md={10}>
              {x.fileNumber} - {x.property.address}
            </Col>
            <Col md={2}>
              <Button onClick={e => navigateToJob(x.ticketGroupId, x.id)}>Navigate</Button>
            </Col>
          </Row>
          )) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={e => { setSimilaryJobPopupVisible(false); setSimilarJobs(); }}>Close</Button>
        </Modal.Footer>
      </Modal>

      {(ticketId && dataLoader) ? <div style={{ width: "fit-content", margin: "auto" }}><Spinner animation="grow" /></div> : <>
        <Helmet title="Default Dashboard" />
        {ticketId && <h5 className="customLink custom-back-button" onClick={() => navigate(-1)}>
          <ArrowLeft size={20} className="me-1" /> Back
        </h5>
        }
        <Container fluid className="p-0 tickets">
          <Row>
            <Col xs={12} md={4} className="jobs-wrapper">
              <div>
                <CustomerInfo selectedContactIndex={contactIndex} selectContactListener={selectContactListener} data={customerData} dataAffiliateId={affiliateId} affiliatePaymentMethod={affiliatePaymentMethod} companySelectedFlag={companySelectedFlag} setData={setCustomerData} handleCompanySelection={handleCompanySelection} handleAffiliateSelection={handleAffiliateSelection} handleUpdateAffiliatePaymentMethod={handleUpdateAffiliatePaymentMethod} filteredCustomers={filteredCustomers} detailsChangedListener={detailsChangedListener} />
              </div>
            </Col>
            <Col xs={12} md={4} className="jobs-wrapper">
              <PropertyInfo handleTypeChange={handleTypeChange} setProperties={setProperties} addProperty={addNewProperty} properties={properties} />
            </Col>
            <Col xs={12} md={4} className="jobs-wrapper">
              <AppraisalDetails ticketId={ticketId} appraisalProps={appraisalProps} updateAppraisalProps={updateAppraisalProps} jobNameUpdatedFlag={jobNameUpdatedFlag} data={properties} setData={setProperties} />
            </Col>
          </Row>
          <div className="mb-0 mt-2 mt-md-1 sticked-note-section" style={{ height: 50 }}>
            <div>
            </div>
            {ticketId ? <div>
              <Button className="py-2 base-width mb-1" onClick={saveTicket} disabled={sLoading}>
                {sLoading && <Spinner animation="border" style={{ height: 13, width: 13, marginRight: 5 }} />}
                Save
              </Button>
            </div> :
              <div>
                <Button className="py-1 px-3 mx-3 base-width mb-0" onClick={() => createTicket(true)} disabled={quoteLoading || sLoading}>
                  {quoteLoading && <Spinner animation="border" style={{ height: 13, width: 13, marginRight: 5 }} />}
                  Ready for quote
                </Button>
                <Button className="py-1 px-3 base-width mb-0" disabled={quoteLoading || sLoading} onClick={() => createTicket(false)}> {sLoading && <Spinner animation="border" style={{ height: 13, width: 13 }} />} Create ticket</Button>
              </div>
            }
          </div>
        </Container>
      </>
      }
    </React.Fragment>
  );
}
