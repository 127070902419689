/**
 * @typedef {{
 *  id: number;
 *  note: string;
 *  inspectionId: number;
 *  created: string;
 *  updated?: string;
 *  userId: string;
 * }} InspectionNote
 */
import axios from "../utils/axios";

/**
 * @param {number} inspectionId
 * @returns {Promise<Array<InspectionNote>>}
 */
export function getInspectionNotes(inspectionId) {
  const queryParams = new URLSearchParams({ inspectionId });
  return axios.get(`/InspectionNote/GetInspectionNotes?${queryParams}`);
}

/**
 * @param {number} ticketId
 * @param {string} note
 * @returns {Promise<InspectionNote>}
 */
export function createInspectionNote(inspectionId, note) {
  return axios.post("/InspectionNote/CreateInspectionNote", { inspectionId, note });
}

/**
 * @param {number} id
 * @param {string} note
 * @returns {Promise<InspectionNote>}
 */
export function updateInspectionNote(id, note) {
  return axios.post("/InspectionNote/UpdateInspectionNote", { id, note });
}

/**
 * @param {number} id
 * @returns {Promise<InspectionNote>}
 */
export function deleteInspectionNote(id) {
  const queryParams = new URLSearchParams({ id });
  return axios.post(`/InspectionNote/DeleteInspectionNote?${queryParams}`);
}
