const compFieldTypes = {
    "borough": "string",
    "block": "string",
    "lot": "string",
    "isEstimated": "boolean",
    "dateOfSale": "date",
    "salePrice": "number",
    "saleStatus": "string",
    "grantor": "string",
    "grantee": "string",
    "yearBuilt": "number",
    "capRate": "number",
    "lotSizeSF": "number",
    "lotSizeAcres": "number",
    "outdoorSpaceSF": "number",
    "wideStreet": "boolean",
    "far": "number",
    "buildable": "number",
    "totalBuildable": "number",
    "totalRemainingPercentage": "number",
    "numberOfUnits": "number",
    "elevator": "number",
    "grossBuildingAreaSF": "number",
    "pricePerSF": "number",
    "financedBy": "string",
    "mortgageAmount": "number",
    "landBuildingRatio": "number",
    "ceilingHeight": "number",
    "squareFeet": "number",
    "bedrooms": "number",
    "bathrooms": "number",
    "pricePerBuildableSF": "number",
    "averageUnitSize": "number",
    "rentStabilized": "number",
    "rentStabilizedPercentage": "number",
    "pricePerUnit": "number",
    "realEstateTaxReimbursement": "number",
    "insuranceReimbursement": "number",
    "otherReimbursement": "number",
    "pgi": "number",
    "residentialVacancyAndCollectionLoss": "number",
    "commercialVacancyAndCollectionLoss": "number",
    "egi": "number",
    "realEstateTax": "number",
    "realEstateTaxPerUnit": "number",
    "realEstateTaxPerSF": "number",
    "insurance": "number",
    "insurancePerUnit": "number",
    "insurancePerSF": "number",
    "managementPercentage": "number",
    "managementPercentagePerUnit": "number",
    "managementPercentagePerSF": "number",
    "management": "number",
    "managementPerUnit": "number",
    "managementPerSF": "number",
    "repairsAndMaintenance": "number",
    "waterAndSewer": "number",
    "commonAreaUtilities": "number",
    "gasAndHeat": "number",
    "utilities": "number",
    "buildingReservesCostPerSF": "number",
    "buildingReserves": "number",
    "legalAndAccounting": "number",
    "otherAndMiscellaneous": "number",
    "totalExpenses": "number",
    "incomeAndExpenseRatio": "number",
    "netOperatingIncome": "number",
    "overallRate": "number",
    "unitNumber": "string",
    "unitSF": "number",
    "monthlyRent": "number",
    "annualRent": "number",
    "leaseStart": "date",
    "leaseEnd": "date",
    "floorLocation": "number",
    "rentPerSF": "number",
    "rentalType": "string",
    "residentialIncome": "number",
    "presentValue": "number",
    "notes": "string"
}

export default compFieldTypes;
