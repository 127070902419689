import React from "react";
import { Form } from "react-bootstrap";

const RadioQuestion = ({ onChange, value, label, options, disabled }) => (
  <Form.Group className="mb-2">
    <Form.Label className="me-1">{label}:</Form.Label>
    {['None', ...(options || "")
      .split(",")]
      .map(
        (option, index) =>
          option && (
            <Form.Check
              key={index}
              inline
              label={option}
              name={label}
              checked={option == value}
              type="radio"
              id={`inspection-question-${label}-${option}`}
              onChange={() => onChange(option)}
              disabled={disabled}
            />
          )
      )}
  </Form.Group>
);

export default RadioQuestion;
