import axios from "../utils/axios";

export function getIntakeFormByTicketId(ticketGroupId) {
  const params = new URLSearchParams({ ticketGroupId });
  return axios.get(`/Intake/GetIntakeFormByTicketId?${params}`);
}

export function updateTicketGroupByIntakeForm(ticketData) {
  return axios.post("/Intake/UpdateTicketGroupByIntakeForm", ticketData);
}

export function processIntake(ticketData) {
  return axios.post("/Intake/ProcessIntake", ticketData);
}

export function getCustomerJobsByAddress(customerId, googlePlaceId, unit = null) {
  const params = new URLSearchParams({ customerId, googlePlaceId, unit });
  return axios.get(`/TicketGroup/getCustomerJobsByAddress?${params}`);
}
