import React, { useState, useEffect } from "react"
import { Row, Col, Button, Form } from "react-bootstrap"
//** Configs and local imports */
import { getColumns } from "./columns"

//** Router */
import { useParams } from "react-router"

//** Third Party */
import DataTable from "react-data-table-component"
import { toast } from 'react-toastify';
// services
import {
  deleteCustomerContact,
  getCustomerById,
  getCustomerContacts,
  updateCustomer,
  toggleArchived
} from "../../../services/customer";

const CustomerContacts = () => {
    const { id } = useParams()
    const [customerContacts, setCustomerContacts] = useState([])
    const [customerInfo, setCustomerInfo] = useState({})

    const [customerLoaded, setCustomerLoaded] = useState(false)

    const handleCustomerInfoChange = e => {
        setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value })
    }

    const updateCustomerInfo = () => {
        updateCustomer(customerInfo).then(() => {
            toast.success("Customer Details Updated Successfully !!");
        });
    };
    
    const toggleCustomerArchived = () => {
        toggleArchived(customerInfo).then(() => {
            setCustomerInfo({ ...customerInfo, archived: !customerInfo.archived })
            toast.success("Success");   
        });
    };

    useEffect(() => {
        getCustomerById(id).then((data) => {
            setCustomerInfo({ ...data });
            setCustomerLoaded(true);
        });
    }, [id]);

    useEffect(() => {
        getCustomerContacts(id).then((data) => {
            setCustomerContacts([...data]);
        });
    }, [id]);

    const getDataToRender = () => {
        return customerContacts
    }

    const handleDeleteContact = (id) => {
        const customerContact = customerContacts.find((contact) => contact.id === id)
        deleteCustomerContact(customerContact).then(() => {
            const _contacts = customerContacts.filter((contact) => {
                return contact.id !== id;
            });
            setCustomerContacts(_contacts);
            toast.success("Contact deleted successfully !!");
        }).catch((err) => {
            toast.error("Failed to delete customer contact, please contact administrator !!!");
        })
    }

    return (<>
        <h3>
            Company Info
        </h3>
        <Row className="my-1 card" style={{ flexDirection: "row", paddingBottom: 10 }}>
            <Col md={12} lg={6}>
                <h5 className="my-2">Company Name</h5>
                <Form.Group className="mb-2">
                    <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter Company name"
                        value={customerInfo.name || ""}
                        onChange={handleCustomerInfoChange}
                    />
                </Form.Group>
            </Col>
            <Col md={12} lg={6}>
                <h5 className="my-2">Company Address</h5>
                <Form.Group className="mb-2">
                    <Form.Control
                        type="text"
                        name="address"
                        placeholder="Enter Company address"
                        value={customerInfo.address || ""}
                        onChange={handleCustomerInfoChange}
                    />
                </Form.Group>
            </Col>
            <Col md={12} lg={6}>
                <h5 className="my-2">Company Email</h5>
                <Form.Group className="mb-2">
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter Company email"
                        value={customerInfo.email || ""}
                        onChange={handleCustomerInfoChange}
                    />
                </Form.Group>
            </Col>
            <Col md={12} lg={6}>
                <h5 className="my-2">Company Phone</h5>
                <Form.Group className="mb-2">
                    <Form.Control
                        type="text"
                        name="phone"
                        placeholder="Enter Company phone"
                        value={customerInfo.phone || ""}
                        onChange={handleCustomerInfoChange}
                    />
                </Form.Group>
            </Col>
            {customerLoaded && <Col md={12} lg={12} className="d-flex justify-content-between">
                <Button style={{ width: 120 }} onClick={updateCustomerInfo}>
                    Save
                </Button>
                <Button variant={customerInfo.archived ? "success" : "danger"} style={{ width: 120 }} onClick={toggleCustomerArchived}>
                    {customerInfo.archived ? "Restore" : "Archive"}
                </Button>
            </Col>
            }
        </Row>
        <h3>
            Contacts
        </h3>
        <DataTable
            style={{ height: 670, overflow: "auto" }}
            noHeader
            columns={getColumns(handleDeleteContact)}
            className="react-dataTable"
            data={getDataToRender()}
        />
    </>)
}

export default CustomerContacts