
import axios from "axios";
import { SERVER_URL } from "../../../configs";

export const LOAD_USER_TASKS = "LOAD_USER_TASKS";
export const LOADING_USER_TASKS = "LOADING_USER_TASKS";

export const clearUserTasks = () => (dispatch) => {
    dispatch({
        type: LOAD_USER_TASKS,
        payload: [],
    });
};

export const loadUserTasks =
    () =>
    (dispatch) => {
        const resolved = localStorage.getItem("show-unresolved") === "false"
        dispatch({
            type: LOADING_USER_TASKS,
            payload: true,
        });
        axios
            .get(`${SERVER_URL}/api/Task/${resolved ? "GetResolvedTasks" : "GetMyTasks"}`)
            .then((res) => {
                dispatch({
                    type: LOAD_USER_TASKS,
                    payload: res.data,
                });
            })
            .finally(() => {
                dispatch({
                    type: LOADING_USER_TASKS,
                    payload: false,
                });
            });
    };

export const bindTasksTimer = () => (dispatch) => {
    loadUserTasks()(dispatch)
    timer = setInterval(() => {
        loadUserTasks()(dispatch)
    }, 60000);
}

export const cancelTasksTimer = () => {
    timer && clearTimeout(timer)
}

let timer = null
