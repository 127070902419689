import React from "react";
import { Container } from "react-bootstrap";

import { Helmet } from "react-helmet-async";
import Properties from "./Properties";

const PropertyInfo = (props) => {
  return (
    <React.Fragment>
      <Helmet title="Projects" />
      <Container fluid className="p-0">
        <h1 className="h4 title">Property Info</h1>
        <Properties {...props} />
      </Container>
    </React.Fragment>
  );
};

export default PropertyInfo;
