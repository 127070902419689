import React, { useState } from "react";

const initialState = {
  draggingFileId: "",
  isDragging: false,
};

const DragContext = React.createContext(initialState);

function DragProvider({ children }) {
  const [state, setState] = useState(initialState);

  const setLocalDragState = (draggingFileId, isDragging) => {
    setState({ draggingFileId, isDragging });
  };

  return (
    <DragContext.Provider
      value={{
        ...state,
        setLocalDragState,
      }}
    >
      {children}
    </DragContext.Provider>
  );
}

export { DragProvider, DragContext };
