import axios from "../utils/axios";

export function getTicketById(id) {
  const params = new URLSearchParams({ id });
  return axios.get(`/Ticket/GetTicketById?${params}`);
}

export function updateIsPaid(ticketId, isPaid) {
  const params = new URLSearchParams({ ticketId, isPaid });
  return axios.post(`/Ticket/UpdateIsPaid?${params}`);
}

export function updateInspectionRequired(ticketId, required) {
  const params = new URLSearchParams({ ticketId, required });
  return axios.post(`/Inspection/UpdateIsInspectionRequired?${params}`);
}

export function updateInspectionNotes(ticketId, notes) {
  return axios.post("/Ticket/UpdateInspectionNotes", {
    ticketId,
    notes,
  });
}

export function deleteTicket(id) {
  const params = new URLSearchParams({ ticketId: id });
  return axios.post(`/Ticket/DeleteTicket?${params}`);
}

export function changeTicketStatus(data) {
  return axios.post("/Ticket/ChangeTicketStatus", data);
}

export function updatePriority(ticketId, priority) {
  const params = new URLSearchParams({ ticketId, priority });
  return axios.post(`/Ticket/UpdatePriority?${params}`);
}

export function updateAllPriority(ticketIds) {
  let body = ticketIds;
  return axios.post(`/Ticket/UpdateAllPriority`, body);
}

export function updateTicketEventNote(ticketId, note) {
  let body = { id: ticketId, calendarNote: note };
  return axios.post(`/Ticket/UpdateCalendarNote`, body);
}

export function updateIsRush(ticketId, isRush) {
  const params = new URLSearchParams({ ticketId, isRush });
  return axios.post(`/Ticket/UpdateIsRush?${params}`);
}

export function getTicketsWithAddress(googlePlaceId, unit) {
  const params = new URLSearchParams({ address: googlePlaceId, unit });
  return axios.get(`/TicketGroup/GetTicketsWithAddress?${params}`)
}

export function getTicketGroupId(ticketId) {
  const params = new URLSearchParams({ ticketId });
  return axios.get(`/Ticket/GetTicketGroupId?${params}`)
}

export function snoozeTicket(ticketId, snoozeUntil, snoozeReason) {
  const body = {
    ticketId,
    snoozeUntil,
    snoozeReason
  };

  return axios.post('/Ticket/SnoozeTicket', body);
}

export function cancelSnoozedTicket(ticketId) {
  return axios.post('/Ticket/CancelSnooze', { ticketId })
}