import { useState } from "react";
import { Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component"

const MultiSelectableQuestion = ({ value, options, onChange, disabled, label }) => {
    const _options = options.split(',').map(x => ({ label: x, value: x }));

    let state = [];
    if (value) {
        const selectedValues = value.split(',');
        state = _options.filter(x => selectedValues.includes(x.value));
    }

    const [selectedValues, setSelectedValues] = useState(state);
    
    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <MultiSelect
                disabled={disabled}
                options={_options}
                labelledBy="Select"
                value={selectedValues}
                onChange={(value) => { setSelectedValues(value); onChange(value.map(x => x.value).join(',')) }} />
        </Form.Group>
    )
};

export default MultiSelectableQuestion;