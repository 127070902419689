import React, { useState } from "react";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import LoadingWrapper from "../../../components/LoadingWrapper";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import { addCompKey } from "../../../redux/actions/comps";
import { levenshteinDistance } from "../../../utils";

const CreateKeyCard = ({ onCompKeyAdd }) => {
  const [compKeyName, setCompKeyName] = useState("");
  const [compKeyValue, setCompKeyValue] = useState("");
  const [compKeySearch, setCompKeySearch] = useState("");
  const loadingCompKeys = useAppSelector(
    (state) => state.comps.loadingCompKeys
  );
  const compKeys = useAppSelector((state) => state.comps.compKeys);
  const dispatch = useAppDispatch();

  const handleCompKeyCreate = () => {
    const newCompKey = { name: compKeyName };
    dispatch(addCompKey(newCompKey));
    onCompKeyAdd(compKeyName, compKeyValue);
    setCompKeyName("");
    setCompKeyValue("");
  };

  const handleCompKeySelect = (item) => {
    onCompKeyAdd(item.name, "");
    setCompKeySearch("");
  };

  const filteredCompKeys = compKeys.filter(
    (item) => {
      // console.log(levenshteinDistance(compKeySearch, item.name), compKeySearch, item.name);
      return levenshteinDistance(compKeySearch, item.name) < item.name.length;
      // return !!compKeySearch &&
      //   item.name.toLowerCase().includes(compKeySearch.toLowerCase())
    }
  );

  return (
    <LoadingWrapper loading={loadingCompKeys}>
      <Card className="block-border p-3 pt-0">
        <Row>
          <Col md={4} className="position-relative">
            <div className="text-dark fw-bold my-2">Search field</div>
            <Form.Control
              value={compKeySearch}
              onChange={(e) => setCompKeySearch(e.target.value)}
              autoComplete="off"
            />
            {compKeySearch && (
              <div
                className="border position-absolute rounded mt-2"
                style={{
                  backgroundColor: "white",
                  width: "calc(100% - 24px)",
                  padding: "8px 12px",
                  zIndex: 999,
                }}
              >
                {!filteredCompKeys.length && <div>No results!</div>}
                {filteredCompKeys.map((item, index) => (
                  <div key={item.id}>
                    {!!index && <hr className="my-1" />}
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleCompKeySelect(item)}
                    >
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Col>
          <Col md={8}>
            <div className="text-dark fw-bold my-2">Create new field</div>
            <div className="d-flex align-items-center">
              <Form.Control
                type="text"
                className=""
                placeholder="Field name"
                value={compKeyName}
                onChange={(e) => setCompKeyName(e.target.value)}
              />
              <Form.Control
                type="number"
                className="mx-3"
                placeholder="Value....."
                value={compKeyValue}
                onChange={(e) => setCompKeyValue(e.target.value)}
              />
              <Button
                className="me-auto btn-sm px-3"
                onClick={handleCompKeyCreate}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </LoadingWrapper>
  );
};

export default CreateKeyCard;
