import { getDateString } from "../../../utils"

export const columns = [
    {
        name: 'Address',
        sortable: false,
        cell: row => row.ticket?.property?.address
    },
    {
        name: 'Type',
        sortable: false,
        cell: row => <div style={{ wordBreak: "initial" }}>{row.type === 2 ? "Interior and Exterior" : (row.type === 1 ? "Exterior Only" : "")}</div>
    },
    {
        name: 'Date',
        sortable: false,
        cell: row => getDateString(row.date)
    },
    {
        name: 'Note',
        sortable: false,
        cell: row => row.note
    }
]