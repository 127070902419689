import React, { useState } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { createPortfolio } from "../../services/portfolio";
import useAppDispatch from "../../hooks/useAppDispatch";
import { loadPortfolios } from "../../redux/actions/app";
import { toast } from "react-toastify";

const CreatePortfolioModal = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const close = () => setShow(false);
  const open = () => setShow(true);

  const handleSubmit = async () => {
    if (!name) {
      return;
    }
    setLoading(true);
    try {
      await createPortfolio(name);
      dispatch(loadPortfolios());
    } catch (err) {
      const message =
        err?.[0]?.description || err.message || "Something went wrong";
      toast.error(message);
    }
    close();
    setName("");
    setLoading(false);
  };

  return (
    <div className="d-flex justify-content-end">
      <Modal show={show} onHide={close} size="sm">
        <Modal.Header closeButton>
          <h4>Create Portfolio</h4>
        </Modal.Header>
        <Modal.Body className="m-3">
          <Form.Group className="mb-3">
            <Form.Label>Portfolio Name</Form.Label>
            <Form.Control
              size="lg"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={close}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!name || loading}>
            {loading && (
              <Spinner
                animation="border"
                size="sm"
                className="me-2 align-middle"
              />
            )}
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Button variant="light" onClick={open}>
        New Portfolio
      </Button>
    </div>
  );
};

export default CreatePortfolioModal;
