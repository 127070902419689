import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Upload, X } from "react-feather";
import { toast } from "react-toastify";
import LoadingButton from "../../../components/LoadingButton";
import { uploadInspectionImage } from "../../../services/inspection";

const ImageUpload = ({ ticketId }) => {
  const [showModal, setShowModal] = useState(false);
  const [tag, setTag] = useState("");
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!image) {
      setPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const onSelectFile = (e) => {
    if (!e.target.files?.length) {
      setImage(null);
      return;
    }

    setImage(e.target.files[0]);
    e.target.value = null;
  };

  const handleClose = () => {
    setShowModal(false);
    setImage(null);
    setPreview(null);
  };

  const handleUpload = async () => {
    if (!image || !tag) {
      return;
    }
    setLoading(true);
    try {
      await uploadInspectionImage({ image, tag, ticketId });
      handleClose();
    } catch (err) {
      const message =
        err?.[0]?.description || err.message || "Something went wrong";
      toast.error(message);
    }
    setLoading(false);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="sm">
        <Modal.Header closeButton>
          <h4 className="m-0">Upload Image</h4>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            value={tag}
            placeholder="Tag"
            onChange={(e) => setTag(e.target.value)}
          />
          <div className="d-flex justify-content-between">
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              capture="camera"
              hidden
              onChange={onSelectFile}
            />
            <label
              htmlFor="image-upload"
              className="btn btn-primary btn-sm mt-2"
              style={{ cursor: "pointer" }}
            >
              <Upload size={24} />
            </label>
            {preview && (
              <Button
                variant="light"
                size="sm"
                className="mt-2"
                onClick={() => setImage(null)}
              >
                <X size={24} />
              </Button>
            )}
          </div>
          {preview && <img src={preview} className="w-100 mt-2" />}
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton
            loading={loading}
            disabled={!image || !tag}
            onClick={handleUpload}
          >
            Upload
          </LoadingButton>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        size="lg"
        className="w-100"
        variant="light"
        onClick={() => setShowModal(true)}
      >
        Upload Image
      </Button>
    </>
  );
};

export default ImageUpload;
