import { Navigate } from "react-router";

const DueDateWrapper = (props) => {
    const type = localStorage.getItem('DD_type') ?? 'regular';
    if (type == 'regular') {
        return <Navigate to="/calendar/dueDate-commercial" />
    }
    else {
        return <Navigate to="/calendar/internal-dueDate-commercial" />
    }
}

export default DueDateWrapper;