import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Edit2, X, MapPin, Info, Trash2 } from "react-feather";
import SidePopup from "../../../components/SidePopup";
import Popconfirm from "../../../components/Popconfirm";
import CompsMap from "./CompsMap";
import compFields from "../Comps/compFields";
import { deleteComp, getRelatedComps } from "../../../services/comp";
import useAppSelector from "../../../hooks/useAppSelector";
import { getFormattedDateTimeStr, getFormattedDateTimeStrDateOnly, numberWithCommas } from "../../../utils";
import startCase from "lodash/startCase";
import Dropzone from "../../../components/dropzone/Dropzone";
import { getRootDirectoryContents } from "../../../services/microsoftTeamsFiles";
import CompExportUrl from "./CompExportUrl";
import RelatedTicket from "./RelatedTicket";

const defaultValue = "-";

const getValueByType = (value, type, name) => {
    if (name === "salePrice" && value) {
        return `$${numberWithCommas(value)}`;
    }

    if (name.toLowerCase().includes('year')) {
        return value;
    }

    switch (type) {
        case "boolean": {
            if (typeof value !== "boolean") {
                return defaultValue;
            }
            return value ? "Yes" : "No";
        }
        case "date": {
            return value ? getFormattedDateTimeStrDateOnly(value, { short: true }) : defaultValue;
        }
        case "number":
            return numberWithCommas(value);
        default: {
            return value || defaultValue;
        }
    }
};

/**
 * @param {Object} props
 * @param {import("../../../services/comp").Comp} props.comp
 * @param {Array<import("../../../services/comp").Comp>} props.otherComps
 * @param {boolean} props.show
 * @param {() => void} props.onDelete
 */
const CompPopupSmall = ({ comp, otherComps, show, onHide, onDelete, onAddToExport }) => {
    const compTypesMap = useAppSelector((state) => state.comps.compTypesMap);

    const [relatedCompsCount, setRelatedCompsCount] = useState(0);
    const [deleteCompPopup, setDeleteCompPopup] = useState(false);

    const onKeyPress = (key) => {
        if (key.code.toLowerCase() == 'escape') {
            onHide();
        }
    }

    useEffect(() => {
        if (!show || !comp.id) {
            setRelatedCompsCount(0);
            return;
        }
        getRelatedComps(comp.compAddress.placeId).then(relatedComps => {
            setRelatedCompsCount(
                relatedComps.filter((item) => item.id !== comp.id).length
            );
        });

        document.addEventListener("keydown", onKeyPress, false);

        return () => {
            document.removeEventListener("keydown", onKeyPress, false);
        }
    }, [comp.id, show]);

    const addressParts = [
        comp.compAddress?.city,
        comp.compAddress?.state,
        comp.compAddress?.zip,
        comp.compAddress?.county,
    ].filter(Boolean);

    const handleCompDelete = async () => {
        await deleteComp(comp.id);
        onDelete();
        setDeleteCompPopup(false);
    };

    return (
        <div style={{ overflowY: 'scroll', minHeight: 300 }}>
            <Popconfirm
                title="Are you sure?"
                show={deleteCompPopup}
                onCancel={() => setDeleteCompPopup(false)}
                onOk={handleCompDelete}
            />
            <Col
                style={{
                    backgroundColor: "#F2F2F2",
                    padding: 16,
                    paddingTop: 12,
                    minHeight: "100%",
                }}
            >
                <div
                    className="d-flex justify-content-between"
                    style={{
                        padding: 3,
                        backgroundColor: "#DEDEDE",
                        borderRadius: 4,
                    }}
                >
                    <div className="d-flex">
                        <Button size="sm" className="p-0 me-3" onClick={onHide}>
                            <X size={19} />
                        </Button>
                        <span className="text-primary mb-0 h4" style={{ lineHeight: 1.5 }}>
                            {comp.compAddress?.fullAddress}
                        </span>
                    </div>
                    <div className="d-flex">
                        {!!relatedCompsCount && (
                            <div className="text-secondary me-2">
                                <Info size={22} className="me-2 pb-1" />
                                <big style={{ fontWeight: "500" }}>
                                    {relatedCompsCount} related Comp{relatedCompsCount > 1 && "s"}
                                </big>
                            </div>
                        )}
                        <Trash2
                            className="ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => setDeleteCompPopup(true)}
                        />
                    </div>
                </div>
                <div
                    className="mt-3 text-black"
                    style={{ fontSize: 10, fontWeight: "500" }}
                >
                    <MapPin className="me-2 mb-1" />
                    {addressParts.map((item, index) => (
                        <React.Fragment key={index}>
                            {!!index && <span className="mx-2">|</span>}
                            <span>{item}</span>
                        </React.Fragment>
                    ))}
                </div>
                <CompsMap
                    comps={[comp, ...otherComps]}
                    className="mt-3"
                />
                <CompInfoCard
                    comp={comp}
                    compTypeName={compTypesMap[comp.compTypeId]?.name}
                    defaultExpanded
                />
                {otherComps.map((comp) => (
                    <CompInfoCard
                        key={comp.id}
                        comp={comp}
                        compTypeName={compTypesMap[comp.compTypeId]?.name}
                    />
                ))}
                <div className="mt-2 p-2 d-flex flex-row" style={{ backgroundColor: '#FFFFFF' }}>
                    <Button variant="primary" onClick={e => onAddToExport(comp.id)}>Export</Button>
                    <CompExportUrl compId={comp.id} />
                    <RelatedTicket ticketId={comp.ticketId} />
                </div>
            </Col>
        </div>
    );
};

const CompInfoCard = ({ comp, compTypeName, defaultExpanded = false }) => {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const [files, setFiles] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!comp) {
            return;
        }

        getRootDirectoryContents(comp?.id, 1).then((data) => {
            const _data = data.map(x => ({ ...x, createdDateTime: new Date(x.createdDateTime) }));
            _data.sort((a, b) => b.createdDateTime - a.createdDateTime);
            setFiles(_data.filter(x => x.file));
        }).catch((e) => {
            // do nothing 
        });

    }, [comp])

    return (
        <Card className="mt-3" style={{ borderRadius: 4 }}>
            <Card.Header
                className="d-flex justify-content-between"
                style={{ backgroundColor: "#DEDEDE" }}
                onClick={() => setExpanded(!expanded)}
            >
                <Button variant="info">{compTypeName}</Button>
                <Button
                    className="py-1 px-2"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(`/dashboard/update-comp?compId=${comp.id}`);
                    }}
                >
                    <Edit2 size={16} />
                </Button>
            </Card.Header>
            {expanded && (
                <Card.Body>
                    <Row>
                        {compFields.map((field) => (
                            <React.Fragment key={field.name}>
                                {!!comp[field.name] && (
                                    <Col xs={6} lg={6} style={{ fontWeight: "500" }}>
                                        <Row>
                                            <Col xs={6} lg={6} className="d-flex align-items-center">
                                                <div
                                                    className="bg-info me-1 rounded"
                                                    style={{ width: 4, height: 4 }}
                                                />
                                                {startCase(field.name)}
                                            </Col>
                                            <Col xs={6} lg={6} className="text-black">
                                                {getValueByType(
                                                    comp[field.name],
                                                    field.type,
                                                    field.name
                                                )}
                                            </Col>
                                        </Row>
                                        <hr className="my-1" />
                                    </Col>
                                )}
                            </React.Fragment>
                        ))}
                    </Row>
                    {files && files.length != 0 &&
                        (<Row className="mt-4">
                            <h4>Files</h4>
                            <Dropzone files={files} />
                        </Row>)
                    }
                </Card.Body>
            )}
        </Card>
    );
};

export default CompPopupSmall;
