import { getComps } from "../../../services/comp";
import { getCompConditions } from "../../../services/comp/CompCondition";
import { getCompKeys, createCompKey } from "../../../services/comp/CompKey";
import { getCompTypes } from "../../../services/comp/CompType";
import { getUnitTypes } from "../../../services/comp/UnitType";
import { getLeaseTypes } from "../../../services/comp/LeaseType";
import { getBasementTypes } from "../../../services/comp/BasementType";
import { getBedroomsClassifications } from "../../../services/comp/BedroomsClassification";
import { getLotShapes } from "../../../services/comp/LotShape";
import { getLotTypes } from "../../../services/comp/LotType";
import { getRentRegulationStatuses } from "../../../services/comp/RentRegulationStatus";
import { getBuildingClasses } from "../../../services/comp/BuildingClass";
import { getOutdoorSpaceTypes } from "../../../services/comp/OutdoorSpaceType";
import { getZones } from "../../../services/comp/Zone";
import { getZoneOverlays } from "../../../services/comp/ZoneOverlay";
import { getPropertyRights } from "../../../services/comp/PropertyRights";
import { getFloorLocations } from "../../../services/comp/FloorLocation";

const loader = (api, KEY, LOADING_KEY) => (...args) => (dispatch) => {
  dispatch({ type: LOADING_KEY, payload: true });
  api(...args)
    .then((payload) => {
      dispatch({ type: KEY, payload });
    })
    .finally(() => {
      dispatch({ type: LOADING_KEY, payload: false });
    });
};

export const LOAD_COMPS = "LOAD_COMPS";
export const LOADING_COMPS = "LOADING_COMPS";

export const loadComps = loader(getComps, LOAD_COMPS, LOADING_COMPS);

export const LOAD_COMP_KEYS = "LOAD_COMP_KEYS";
export const LOADING_COMP_KEYS = "LOADING_COMP_KEYS";
export const ADD_COMP_KEY = "ADD_COMP_KEY";

export const loadCompKeys = loader(getCompKeys, LOAD_COMP_KEYS, LOADING_COMP_KEYS);
export const addCompKey = loader(createCompKey, ADD_COMP_KEY, LOADING_COMP_KEYS);

export const LOAD_COMP_TYPES = "LOAD_COMP_TYPES";
export const LOADING_COMP_TYPES = "LOADING_COMP_TYPES";

export const loadCompTypes = loader(getCompTypes, LOAD_COMP_TYPES, LOADING_COMP_TYPES);

export const LOAD_COMP_CONDITIONS = "LOAD_COMP_CONDITIONS";
export const LOADING_COMP_CONDITIONS = "LOADING_COMP_CONDITIONS";

export const loadCompConditions = loader(getCompConditions, LOAD_COMP_CONDITIONS, LOADING_COMP_CONDITIONS);

export const LOAD_UNIT_TYPES = "LOAD_UNIT_TYPES";
export const LOADING_UNIT_TYPES = "LOADING_UNIT_TYPES";

export const loadUnitTypes = loader(getUnitTypes, LOAD_UNIT_TYPES, LOADING_UNIT_TYPES);

export const LOAD_LEASE_TYPES = "LOAD_LEASE_TYPES";
export const LOADING_LEASE_TYPES = "LOADING_LEASE_TYPES";

export const loadLeaseTypes = loader(getLeaseTypes, LOAD_LEASE_TYPES, LOADING_LEASE_TYPES);

export const LOAD_BASEMENT_TYPES = "LOAD_BASEMENT_TYPES";
export const LOADING_BASEMENT_TYPES = "LOADING_BASEMENT_TYPES";
export const loadBasementTypes = loader(getBasementTypes, LOAD_BASEMENT_TYPES, LOADING_BASEMENT_TYPES);

export const LOAD_BEDROOMS_CLASSIFICATIONS = "LOAD_BEDROOMS_CLASSIFICATIONS";
export const LOADING_BEDROOMS_CLASSIFICATIONS = "LOADING_BEDROOMS_CLASSIFICATIONS";
export const loadBedroomsClassifications = loader(getBedroomsClassifications, LOAD_BEDROOMS_CLASSIFICATIONS, LOADING_BEDROOMS_CLASSIFICATIONS);

export const LOAD_LOT_SHAPES = "LOAD_LOT_SHAPES";
export const LOADING_LOT_SHAPES = "LOADING_LOT_SHAPES";
export const loadLotShapes = loader(getLotShapes, LOAD_LOT_SHAPES, LOADING_LOT_SHAPES);

export const LOAD_LOT_TYPES = "LOAD_LOT_TYPES";
export const LOADING_LOT_TYPES = "LOADING_LOT_TYPES";
export const loadLotTypes = loader(getLotTypes, LOAD_LOT_TYPES, LOADING_LOT_TYPES);

export const LOAD_RENT_REGULATION_STATUSES = "LOAD_RENT_REGULATION_STATUSES";
export const LOADING_RENT_REGULATION_STATUSES = "LOADING_RENT_REGULATION_STATUSES";
export const loadRentRegulationStatuses = loader(getRentRegulationStatuses, LOAD_RENT_REGULATION_STATUSES, LOADING_RENT_REGULATION_STATUSES);

export const LOAD_BUILDING_CLASSES = "LOAD_BUILDING_CLASSES";
export const LOADING_BUILDING_CLASSES = "LOADING_BUILDING_CLASSES";
export const loadBuildingClasses = loader(getBuildingClasses, LOAD_BUILDING_CLASSES, LOADING_BUILDING_CLASSES);

export const LOAD_OUTDOOR_SPACE_TYPES = "LOAD_OUTDOOR_SPACE_TYPES";
export const LOADING_OUTDOOR_SPACE_TYPES = "LOADING_OUTDOOR_SPACE_TYPES";

export const loadOutdoorSpaceTypes = loader(getOutdoorSpaceTypes, LOAD_OUTDOOR_SPACE_TYPES, LOADING_OUTDOOR_SPACE_TYPES);

export const LOAD_ZONES = "LOAD_ZONES";
export const LOADING_ZONES = "LOADING_ZONES";

export const loadZones = loader(getZones, LOAD_ZONES, LOADING_ZONES);

export const LOAD_ZONE_OVERLAYS = "LOAD_ZONE_OVERLAYS";
export const LOADING_ZONE_OVERLAYS = "LOADING_ZONE_OVERLAYS";

export const loadZoneOverlays = loader(getZoneOverlays, LOAD_ZONE_OVERLAYS, LOADING_ZONE_OVERLAYS);

export const LOAD_PROPERTY_RIGHTS = "LOAD_PROPERTY_RIGHTS";
export const LOADING_PROPERTY_RIGHTS = "LOADING_PROPERTY_RIGHTS";

export const loadPropertyRights = loader(getPropertyRights, LOAD_PROPERTY_RIGHTS, LOADING_PROPERTY_RIGHTS);

export const SET_COMP_FILTERS = "SET_COMP_FILTERS";

export const setCompFilters = (newFilters) => (dispatch) => {
  dispatch({ type: SET_COMP_FILTERS, payload: newFilters });
}

export const LOAD_FLOOR_LOCATIONS = "LOAD_FLOOR_LOCATIONS";
export const LOADING_FLOOR_LOCATIONS = "LOADING_FLOOR_LOCATIONS";

export const loadFloorLocations = loader(getFloorLocations, LOAD_FLOOR_LOCATIONS, LOADING_FLOOR_LOCATIONS);