import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const CreateFolderModal = forwardRef(({ onSubmit }, ref) => {
  const [show, setShow] = useState(false);
  const [path, setPath] = useState("");
  const [folder, setFolder] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      open(path) {
        setShow(true);
        setPath(path);
      },
    }),
    []
  );

  const handleSubmit = () => {
    onSubmit(folder);
    setFolder("");
    setShow(false);
  };

  return (
    <Modal size="sm" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <h3>Create a folder</h3>
      </Modal.Header>
      <Modal.Body className="text-center m-3">
        <p className="text-lg text-start">
          in <code>{path}</code>
        </p>
        <Form.Control
          value={folder}
          onChange={(e) => setFolder(e.target.value)}
          placeholder="Folder name"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
});

export default CreateFolderModal;
