/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} LotShape */
import axios from "../../utils/axios";

/** @returns {Promise<Array<LotShape>>} */
export function getLotShapes() {
  return axios.get("/LotShape/GetLotShapes");
}

/**
 * @param {string} name
 * @returns {Promise<LotShape>}
 */
export function createLotShape(name) {
  return axios.post("/LotShape/CreateLotShape", { name });
}
