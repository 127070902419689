import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import LoadingWrapper from "../../../components/LoadingWrapper";
import { filterChangeHistory } from "../../../services/changeHistory"
import useAppSelector from "../../../hooks/useAppSelector";
import { Form, Table } from "react-bootstrap";
import { getFormattedInspectionDate } from "../../../utils"

const ChangeHistoryPage = () => {

  const [loading, setLoading] = useState(false);

  const users = useAppSelector((state) => state.appData.users);

  const [data, setData] = useState();
  const [userId, setUserId] = useState();
  const [table, setTable] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const loadData = () => {
    setLoading(true);

    let filter = {
      userId: userId,
      table: table,
      fromDate: fromDate != null ? new Date(fromDate) : '',
      toDate: toDate != null ? new Date(toDate) : '',
    };

    filterChangeHistory(filter).then(data => {
      setData(data);
      setLoading(false);
    });
  }

  return (
    <div className="h-100 mt-4">
      <Helmet title='Change History' />
      <LoadingWrapper loading={loading}>

        <div className="d-flex mb-3">
          <div>
            <div className="input-group">
              <span className="input-group-text">Table</span>
              <Form.Select
                value={table}
                onChange={(e) => setTable(e.target.value)}
              >
                <option value="">All Tables</option>
                <option value="BasementType">BasementType</option>
                <option value="BasementTypeMapping">BasementTypeMapping</option>
                <option value="BedroomsClassificationMapping">BedroomsClassificationMapping</option>
                <option value="ChangeHistory">ChangeHistory</option>
                <option value="Comp">Comp</option>
                <option value="CompAddress">CompAddress</option>
                <option value="CompCondition">CompCondition</option>
                <option value="CompExportMappings">CompExportMappings</option>
                <option value="CompKey">CompKey</option>
                <option value="CompToken">CompToken</option>
                <option value="CompType">CompType</option>
                <option value="CompTypeMapping">CompTypeMapping</option>
                <option value="Customer">Customer</option>
                <option value="CustomerContact">CustomerContact</option>
                <option value="CustomerContactEmail">CustomerContactEmail</option>
                <option value="CustomerContactPhoneNumber">CustomerContactPhoneNumber</option>
                <option value="CustomerCustomerRule">CustomerCustomerRule</option>
                <option value="CustomerRule">CustomerRule</option>
                <option value="FloorLocation">FloorLocation</option>
                <option value="Inspection">Inspection</option>
                <option value="InspectionContact">InspectionContact</option>
                <option value="InspectionFile">InspectionFile</option>
                <option value="InspectionNote">InspectionNote</option>
                <option value="InspectionQuestion">InspectionQuestion</option>
                <option value="InspectionQuestionAnswer">InspectionQuestionAnswer</option>
                <option value="InspectionQuestionForm">InspectionQuestionForm</option>
                <option value="InspectionQuestionFormQuestion">InspectionQuestionFormQuestion</option>
                <option value="InspectionQuestionFormQuestionAnswer">InspectionQuestionFormQuestionAnswer</option>
                <option value="JobAssignmentType">JobAssignmentType</option>
                <option value="JobPurpose">JobPurpose</option>
                <option value="LeaseType">LeaseType</option>
                <option value="LotShape">LotShape</option>
                <option value="LotShapeMapping">LotShapeMapping</option>
                <option value="LotType">LotType</option>
                <option value="LotTypeMapping">LotTypeMapping</option>
                <option value="MicrosoftTeamsMessage">MicrosoftTeamsMessage</option>
                <option value="OutdoorSpaceType">OutdoorSpaceType</option>
                <option value="Portfolio">Portfolio</option>
                <option value="Property">Property</option>
                <option value="PropertyNote">PropertyNote</option>
                <option value="PropertyRights">PropertyRights</option>
                <option value="PropertySubType">PropertySubType</option>
                <option value="PropertyType">PropertyType</option>
                <option value="Quote">Quote</option>
                <option value="QuoteTimeSlot">QuoteTimeSlot</option>
                <option value="RentRegulationStatus">RentRegulationStatus</option>
                <option value="RentRegulationStatusMapping">RentRegulationStatusMapping</option>
                <option value="Ticket">Ticket</option>
                <option value="TicketGroup">TicketGroup</option>
                <option value="TicketGroupStatus">TicketGroupStatus</option>
                <option value="TicketGroupStatusAssignmentDetail">TicketGroupStatusAssignmentDetail</option>
                <option value="TicketNote">TicketNote</option>
                <option value="TicketQuestion">TicketQuestion</option>
                <option value="TicketQuestionAnswer">TicketQuestionAnswer</option>
                <option value="TicketQuote">TicketQuote</option>
                <option value="TicketStatus">TicketStatus</option>
                <option value="TicketStatusAssignmentDetail">TicketStatusAssignmentDetail</option>
                <option value="TicketStatusNote">TicketStatusNote</option>
                <option value="TicketStatusType">TicketStatusType</option>
                <option value="TicketStep">TicketStep</option>
                <option value="TicketStepNote">TicketStepNote</option>
                <option value="TicketStepQuestion">TicketStepQuestion</option>
                <option value="TicketStepQuestionAnswer">TicketStepQuestionAnswer</option>
                <option value="TicketStepTypeUserMapping">TicketStepTypeUserMapping</option>
                <option value="TicketTask">TicketTask</option>
                <option value="UnitType">UnitType</option>
                <option value="ValueType">ValueType</option>
                <option value="Zone">Zone</option>
                <option value="ZoneMapping">ZoneMapping</option>
                <option value="ZoneOverlay">ZoneOverlay</option>
                <option value="ZoneOverlayMapping">ZoneOverlayMapping</option>

              </Form.Select>
              <span className="input-group-text">Select User</span>
              <Form.Select
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              >
                <option value="">All Users</option>
                {users.map((user, index) => (
                  <option key={index} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>
                ))}
              </Form.Select>
              <span className="input-group-text">From</span>
              <Form.Control
                type="date"
                onChange={(e) => setFromDate(e.target.value)}
                value={fromDate || ""}
              />
              <span className="input-group-text">To</span>
              <Form.Control
                type="date"
                onChange={(e) => setToDate(e.target.value)}
                value={toDate || ""}
              />
              <button className="btn btn-primary" onClick={() => loadData()}>Filter</button>
            </div>
          </div>
        </div>

        {
          data ?
            <Table striped>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Table</th>
                  <th>Column</th>
                  <th>entityId</th>
                  <th>state</th>
                  <th>User</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.map(h =>
                    <tr key={h.id}>
                      <td>{getFormattedInspectionDate(h.date)}</td>
                      <td>{h.table}</td>
                      <td>{h.column}</td>
                      <td>{h.entityId}</td>
                      <td>{h.state === 4 ? 'Add' : h.state === 3 ? 'Edit' : 'Other'}</td>
                      <td>{h.user ? `${h.user.firstName} ${h.user.lastName}` : '-'}</td>
                      <td>{h.oldValue}</td>
                      <td>{h.newValue}</td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
            :
            <></>
        }
        

      </LoadingWrapper>
    </div>
  );
};

export default ChangeHistoryPage;
