import moment from "moment";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useScreenSize from "../../hooks/useScreenSize";
import {
  getScheduledInspectionByTicketId,
  getScheduledInspectionsByInspectorId,
  scheduleInspection as scheduleInspectionApi,
  updateInspection as updateInspectionApi,
  deleteInspection as deleteInspectionApi,
  getInspectionImagesTags,
  getInspectionImages,
  getInspectionImageBase64V2,
  getInspectionImagesBase64V3
} from "../../services/inspection";
import { getInspectionFormObj } from "../../utils";
import useJobDetails from "./useJobDetails";

const defaultValue = {
  scheduledInspection: {},
  setScheduledInspection: (inspection) => { },
  scheduledInspections: [],
  inspectionForm: getInspectionFormObj(),
  handleInspectionFormChange: (key, value, cb) => { },
  inspectorInspections: [],

  editingInspection: false,
  setEditingInspection(editingInspection) { },

  scheduleInspection: () => { },
  updateInspection: (scheduledInspection, showToast = false) => { },
  deleteScheduledInspection: () => { },

  inspectionTags: [],
  inspectionImages: [],
  inspectionImagesLoading: false,
  setInspectionImagesLoading: (value) => { },
  setInspectionImages: (value) => { },
  initInspectionImagesData: (forceLoad = false) => { },
};

const JobInspectionDetailsContext = createContext(defaultValue);

export const JobInspectionDetailsProvider = ({ children }) => {
  const { generalInfoKey, updateTicket } = useJobDetails();
  const { ticketId } = useParams();
  const { isMobile } = useScreenSize();

  const [scheduledInspection, setScheduledInspection] = useState({});
  const [scheduledInspections, setScheduledInspections] = useState([]);
  const [inspectionForm, setInspectionForm] = useState(
    defaultValue.inspectionForm
  );
  const [inspectorInspections, setInspectorInspections] = useState([]);
  const [editingInspection, setEditingInspection] = useState(false);
  const [inspectionTags, setInspectionTags] = useState([]);
  const [inspectionImages, setInspectionImages] = useState([]);
  const [inspectionImagesLoading, setInspectionImagesLoading] = useState(false);
  const inspectionImagesInitedRef = useRef(false);

  const getInpectorInspections = (inspectorId, date) => {
    getScheduledInspectionsByInspectorId(inspectorId, date).then((res) => {
      setInspectorInspections(res.data);
    });
  };

  const initInspectionImagesData = useCallback(
    async (forceLoad = false) => {
      if (
        !scheduledInspection.id ||
        (inspectionImagesInitedRef.current && !forceLoad)
      ) {
        return;
      }
      const tags = await getInspectionImagesTags(ticketId);
      const formattedTags = tags
        .map((tag) => (tag || "").split(","))
        .flat()
        .filter(Boolean)
        .map((tag) => tag.trim());
      const uniqueTags = [...new Set(formattedTags)];
      setInspectionTags(uniqueTags);
      setInspectionImagesLoading(true);
      const images = await getInspectionImages(ticketId);

      // const sortedImages = images.sort((a, b) => (a.id - b.id));
      // console.log(images.map(item => item.id));

      const result = await getInspectionImagesBase64V3(images.map(item => item.id));

      // const transform = [];
      // for (let item in images.map(item => item.id)) {
      //   console.log(item, result.map(x => x.key));
      //   const h = result.find(x => x.key == item)[0];
      //   transform.push(h);
      // }
      // console.log(transform);

      // console.log(result, "BASE");

      // const result = await Promise.all(
      //   images.map((item) => getInspectionImageBase64V2(item.id))
      // );
      // console.log(images);

      // console.log(result);
      setInspectionImages(
        result.map((item, index) => ({
          ...images[index],
          src: item?.value ? item?.value : item || "",
        }))
      );
      setInspectionImagesLoading(false);
      inspectionImagesInitedRef.current = true;
    },
    [scheduledInspection.id]
  );

  useEffect(() => {
    if (inspectionForm.inspectorId)
      getInpectorInspections(inspectionForm.inspectorId, inspectionForm.date);
  }, [inspectionForm.inspectorId, inspectionForm.date]);

  useEffect(() => {
    if (scheduledInspection.inspectorId)
      getInpectorInspections(
        scheduledInspection.inspectorId,
        scheduledInspection.date
      );
  }, [scheduledInspection.inspectorId, scheduledInspection.date]);

  const loadCurrentTicketInspection = useCallback(() => {
    getScheduledInspectionByTicketId(ticketId)
      .then((data) => {
        if (data) {
          data.time = moment(data.date);
          data.date = moment(data.date).format("YYYY-MM-DD");

          setScheduledInspections(data);

          if (data.length == 1) {
            const inspection = data[0] ?? {};
            setScheduledInspection(inspection);
            if (inspection && !inspection.inspectorId) {
              setEditingInspection(inspection);
            }
          }
          else {
            console.log(data[0]);
            setScheduledInspection(data.find(x => !x.completed) ?? (data[0] ?? {}));
          }

        } else {
          setScheduledInspections([]);
        }
      })
      .catch(() => {
        setScheduledInspections([]);
      });
  }, [ticketId]);

  useEffect(() => {
    if (!isMobile && generalInfoKey !== "Inspection") {
      return;
    }
    loadCurrentTicketInspection();
  }, [loadCurrentTicketInspection, generalInfoKey, isMobile]);

  const handleInspectionFormChange = (key, value, cb) => {
    if (!!scheduledInspection.id) {
      const newScheduledInspection = { ...scheduledInspection, [key]: value };
      setScheduledInspection(newScheduledInspection);
      if (cb) cb(newScheduledInspection);
    } else {
      const newInspectionForm = { ...inspectionForm, [key]: value };

      setInspectionForm(newInspectionForm);
      if (cb) cb(newInspectionForm);
    }
  };

  const scheduleInspection = () => {
    const data = {
      ...inspectionForm,
      ticketId: Number(ticketId),
      type: Number(inspectionForm.type),
    };

    if (inspectionForm.time) {
      const _dateStr = new Date(inspectionForm.date)
        .toISOString()
        .split("T")[0];
      // TODO: ask Narek about this logic
      data.date = `${_dateStr}T${data.time.format("HH:mm:ss")}.00`;
    }

    scheduleInspectionApi(data)
      .then(() => {
        toast.success("Inspection scheduled successfully!");
        loadCurrentTicketInspection();
        updateTicket();
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Failed to schedule the inspection, please contact administrator!"
        );
      });
  };

  const updateInspection = (scheduledInspection, showToast = false) => {
    const _temp = {
      ...scheduledInspection,
      ticketId: Number(ticketId),
      type: Number(scheduledInspection.type),
    };

    if (scheduledInspection.time) {
      const _dateStr = new Date(scheduledInspection.date)
        .toISOString()
        .split("T")[0];
      _temp.date = `${_dateStr}T${_temp.time.format("HH:mm:ss")}.00`;
    }

    updateInspectionApi(_temp)
      .then(() => {
        if (showToast) toast.success("Inspection updated successfully !");
        setEditingInspection(false);
        loadCurrentTicketInspection();
        getInpectorInspections(
          scheduledInspection.inspectorId,
          scheduledInspection.date
        );
        updateTicket();
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Failed to update the inspection, please contact administrator !!!"
        );
      });
  };

  const deleteScheduledInspection = () => {
    deleteInspectionApi(scheduledInspection.id)
      .then(() => {
        toast.success("Inspection deleted successfully !");
        setEditingInspection(false);
        loadCurrentTicketInspection();
        getInpectorInspections(
          scheduledInspection.inspectorId,
          scheduledInspection.date
        );
        updateTicket();
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Failed to delete inspection, please contact administrator !"
        );
      });
  };

  return (
    <JobInspectionDetailsContext.Provider
      value={{
        scheduledInspection,
        setScheduledInspection,
        scheduledInspections,
        handleInspectionFormChange,
        inspectionForm,
        inspectorInspections,

        editingInspection,
        setEditingInspection,

        scheduleInspection,
        updateInspection,
        deleteScheduledInspection,

        inspectionTags,
        inspectionImages,
        inspectionImagesLoading,
        setInspectionImagesLoading,
        setInspectionImages,
        initInspectionImagesData,
      }}
    >
      {children}
    </JobInspectionDetailsContext.Provider>
  );
};

export default function useJobInspectionDetails() {
  return useContext(JobInspectionDetailsContext);
}
