import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { DragContext } from "../../../../contexts/DragContext";
import "./dropUploadStyles.css"

const DropUpload = ({
  children,
  path,
  onUpload,
  multiple = false,
  style,
}) => {
  const { isDragging } = useContext(DragContext);
  const [highlighted, setHighlighted] = useState(false);
  const [loading, setLoading] = useState(false);

  const highlight = (e) => {
    if (isDragging) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setHighlighted(true);
  };

  const unhighlight = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlighted(false);
  };

  async function handleDrop(e) {
    const files = e.dataTransfer?.files;
    if (isDragging) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    if (files.length > 1 && !multiple) {
      setHighlighted(false);
      setLoading(false);
      toast.error("Please upload only one file at once!");
      return;
    }

    await onUpload(multiple ? [...files] : files[0]);
    setHighlighted(false);
    setLoading(false);
  }

  return (
    <div
      className={`drop-area${highlighted ? " active" : ""} h-100 mb-2`}
      onDragOver={highlight}
      onDragEnter={highlight}
      onDragLeave={unhighlight}
      onDrop={handleDrop}
      style={style}
    >
      {highlighted ? (
        <div
          style={style}
          className="d-flex h-100 flex-column justify-content-center align-items-center border rounded-lg"
        >
          <h4>{loading ? "Uploading" : "Release to upload"}</h4>
          <p className="text-lg">
            in <code>{path}</code>
          </p>
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default DropUpload;
