/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} ZoneOverlay */
import axios from "../../utils/axios";

/** @returns {Promise<Array<ZoneOverlay>>} */
export function getZoneOverlays() {
  return axios.get("/ZoneOverlay/GetZoneOverlays");
}

/**
 * @param {string} name
 * @returns {Promise<ZoneOverlay>}
 */
export function createZoneOverlay(name) {
  return axios.post("/ZoneOverlay/CreateZoneOverlay", { name });
}
