import React from "react"

//** Third Party Components */
import { Row, Col, Form } from "react-bootstrap"
import useAppSelector from "../../hooks/useAppSelector"

const Quotes = ({ title, quotes, setQuotes }) => {
    const { quoteTimeSlots } = useAppSelector(state => state.appData)

    const handleValueChange = (key, value, index) => {
        const _quotes = JSON.parse(JSON.stringify(quotes))
        if (key === "isAccepted")
            _quotes.forEach((quote) => quote.isAccepted = false)

        _quotes[index][key] = value
        setQuotes(_quotes)
    }

    const handlePriceChange = (key, value, index) => {
        handleValueChange(key, value === "" ? value : Math.abs(value), index)
    }

    return (
        <>
            <h5>{title}</h5>
            <hr />
            <Row>
                <Col xl={4} md={4} sm={4}>
                    <h5 style={{ width: "fit-content", margin: "auto" }}>Price</h5>
                </Col>
                <Col xl={4} md={4} sm={4}>
                    <h5 style={{ width: "fit-content", margin: "auto" }}>Timeslot</h5>
                </Col>
                <Col xl={4} md={4} sm={4}>
                    <h5 style={{ width: "fit-content", margin: "auto" }}>Accepted</h5>
                </Col>
            </Row>
            {quotes?.map((quote, index) => (
                <Row key={index} className="mb-2">
                    <Col xl={4} md={4} sm={4}>
                        <Form.Control
                            type="number"
                            name="price"
                            placeholder="Price"
                            value={quote.price}
                            min="0"
                            onChange={(e) => handlePriceChange("price", e.target.value, index)}
                            autoComplete="off"
                        />
                    </Col>
                    <Col xl={4} md={4} sm={4}>
                        <Form.Select name="quoteTimeSlotId" aria-label="Select Ticket Status" value={quote.quoteTimeSlotId} onChange={(e) => handleValueChange(e.target.name, e.target.value, index)}>
                            {
                                quoteTimeSlots.map((timeslot) => <option key={timeslot.id} value={timeslot.id}>{timeslot.name}</option>)
                            }
                        </Form.Select>
                    </Col>
                    <Col xl={4} md={4} sm={4} className="mt-1">
                        <Form.Check
                            type="radio"
                            name="quotes-docGen-radio"
                            checked={quote.isAccepted}
                            onChange={(e) => handleValueChange("isAccepted", e.target.checked, index)}
                            style={{ width: "fit-content", margin: "auto" }}
                        />
                    </Col>
                </Row>
            ))
            }
            <hr />
        </>
    )
}

export default Quotes