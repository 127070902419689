/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} PropertyRights */
import axios from "../../utils/axios";

/** @returns {Promise<Array<CompCondition>>} */
export function getPropertyRights() {
    return axios.get("/PropertyRights/GetPropertyRights");
}

// /**
//  * @param {Omit<CompCondition, 'id'>} data
//  * @returns {Promise<CompCondition>}
//  */
// export function createCompCondition(data) {
//     const queryParams = new URLSearchParams(data);
//     return axios.post(`/CompCondition/CreateCompCondition?${queryParams}`);
// }

// export function updateCompCondition(data) {
//     return axios.post(`/CompCondition/UpdateCompCondition`, data);
// }
