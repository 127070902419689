import { useEffect, useRef } from "react";

function useCustomCss(css) {
    const element = document.createElement('style');
    element.setAttribute('type', 'text/css');

    useEffect(() => {
        if ('textContent' in element) {
            element.textContent = css;
        } else {
            element.styleSheet.cssText = css;
        }

        document.getElementsByTagName('head')[0].appendChild(element);
        console.log("Custom CSS applied")
        return () => {
            element.remove();
            console.log("Custom CSS removed")
        }
    }, []);

    return null;
}

export default useCustomCss;
