import { useState, useContext } from "react";
import { Button } from "react-bootstrap";
import { SERVER_URL } from "../../../configs";
import { getCompExportURL } from '../../../services/comp'
import NotyfContext from "../../../contexts/NotyfContext";

const CompExportUrl = ({ compId }) => {

    const notyf = useContext(NotyfContext);
    const [loading, setLoading] = useState(false);

    const copyExportUrl = async () => {
        setLoading(true)
        const token = await getCompExportURL(compId);
        const url = `${SERVER_URL}/api/Comp/GetCompByToken?token=${token}`;

        if (navigator.clipboard) {
            navigator.clipboard.writeText(url);
        }

        notyf.open({ message: 'Link copied to clipboard', type: "success" });
        setLoading(false)
    }

    return (
        <Button disabled={loading} className="ms-2" onClick={copyExportUrl}>Copy Export URL</Button>
    )
}

export default CompExportUrl;