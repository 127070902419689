import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import LoadingWrapper from "../../../components/LoadingWrapper";
import { GetUsersTickets } from "../../../services/tasksBoard"
import './tasks-board.css'
import TasksBoardComponent from "./TasksBoardComponent";

const TasksBoardPage = () => {

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);

    GetUsersTickets().then(data => {
      setData(data);
      setLoading(false);
    });

  }, []);
  
  return (
    <div className="h-100 mt-4">
      <Helmet title='Tasks Board' />
      <LoadingWrapper loading={loading}>
        <TasksBoardComponent data={data} />
      </LoadingWrapper>
    </div>
  );
};

export default TasksBoardPage;