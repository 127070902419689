/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} BuildingClass */
import axios from "../../utils/axios";

/** @returns {Promise<Array<BuildingClass>>} */
export function getBuildingClasses() {
    return axios.get("/BuildingClass/GetBuildingClasses");
}

/**
 * @param {string} name
 * @returns {Promise<BuildingClass>}
 */
export function createBuildingClass(name) {
  return axios.post("/BuildingClass/CreateBuildingClass", { name });
}
