import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { Download } from "react-feather";
import { Helmet } from "react-helmet-async";
import LoadingWrapper from "../../../components/LoadingWrapper";
import axios from "../../../utils/axios";

const ReportsPage = () => {

    const [loading, setLoading] = useState(false);

    const downloadResponse = (response, fileName) => {
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([response]));
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    }

    const handleExportCompleted = async (e) => {
        e.stopPropagation();
        setLoading(true);

        try {
            const response = await axios.post("/Ticket/ExportCompleted", {}, { responseType: "blob" });
            downloadResponse(response, `completed.xlsx`)
        } catch (e) {

        }
        finally {
            setLoading(false);
        }
    };
    
    const handleExportReviews = async (e) => {
        e.stopPropagation();
        setLoading(true);

        try {
            const response = await axios.post("/Ticket/ExportReviews", {}, { responseType: "blob" });
            downloadResponse(response, `reviews.xlsx`)
        } catch (e) {

        }
        finally {
            setLoading(false);
        }
    };

    return (
        <Container className="h-100">
            <Helmet title='Reports' />
            <div className="d-flex justify-content-between mb-3">
                <h2>Reports</h2>
            </div>
            <LoadingWrapper loading={loading}>

                <div className="d-flex gap-3">
                    <Button variant="secondary" onClick={handleExportCompleted} disabled={loading}
                        className="d-flex align-items-center gap-2">
                        <Download size={16} />
                        Export Completed
                    </Button>

                    <Button variant="secondary" onClick={handleExportReviews} disabled={loading}
                        className="d-flex align-items-center gap-2">
                        <Download size={16} />
                        Export Reviews
                    </Button>
                </div>

            </LoadingWrapper>
        </Container>
    );
};

export default ReportsPage;
