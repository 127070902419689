import { useMemo } from "react";

/**
 * @template T
 * @param {Array<T>} collection
 * @param {keyof T} key
 * @returns {Record<string, Array<T>>}
 */
export default function useGroupBy(collection, key) {
  return useMemo(() => {
    const map = {};
    collection.forEach((item) => {
      if (!map[item[key]]) {
        map[item[key]] = [];
      }
      map[item[key]].push(item);
    });
    return map;
  }, [key, collection]);
}
