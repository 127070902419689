/** @param {{ size?: number; } & import("react").SVGProps} props */
const FiltersIcon = ({ size = 16, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.6876 2.62969C2.58447 2.68613 2.52822 2.7459 2.47822 2.85547C2.40635 3.00156 2.40635 3.00156 2.40635 4.77461V6.54765L2.3376 6.56758C2.29697 6.57754 2.15635 6.64394 2.02197 6.71699C1.5876 6.94609 1.25322 7.37773 1.0751 7.93554C0.993848 8.19121 0.993848 8.80879 1.0751 9.06445C1.25322 9.62226 1.5876 10.0539 2.02197 10.283C2.15635 10.3561 2.29697 10.4225 2.3376 10.4324L2.40635 10.4523V12.2254C2.40635 13.9984 2.40635 13.9984 2.47822 14.1445C2.56885 14.3371 2.76572 14.4533 2.95947 14.4334C3.10322 14.4168 3.28447 14.2939 3.3501 14.1678C3.38135 14.1113 3.39385 13.6531 3.40635 12.2719L3.42197 10.449L3.58135 10.3926C4.12822 10.1934 4.61572 9.61562 4.76572 8.98144C4.8251 8.7291 4.8251 8.2709 4.76572 8.01855C4.61572 7.38769 4.13447 6.81328 3.59072 6.61074L3.42197 6.54765L3.40635 4.7248C3.39385 3.34687 3.38135 2.88867 3.3501 2.83222C3.28447 2.70605 3.10322 2.5832 2.95947 2.5666C2.8626 2.55664 2.79072 2.57324 2.6876 2.62969ZM3.22822 7.59023C3.71572 7.76621 3.96572 8.41699 3.7376 8.91172C3.45947 9.51933 2.70947 9.65215 2.2626 9.17402C1.8001 8.68262 1.99072 7.82265 2.61572 7.58359C2.77822 7.52383 3.05322 7.52715 3.22822 7.59023Z"
      fill="#898989"
    />
    <path
      d="M7.53136 2.61011C7.20949 2.69976 6.91261 2.87906 6.67199 3.13472C6.28762 3.53648 6.12824 3.915 6.10324 4.48277C6.08449 4.89449 6.13137 5.14019 6.29699 5.49878C6.50324 5.93375 6.86574 6.28902 7.28136 6.46168L7.50011 6.55132V10.29C7.50011 13.866 7.50324 14.0353 7.55949 14.1449C7.73136 14.4902 8.17824 14.53 8.40011 14.2179L8.48449 14.1017L8.49386 10.3265L8.50011 6.55132L8.68449 6.47828C9.09699 6.3189 9.49386 5.93707 9.70011 5.5021C9.86886 5.14019 9.91886 4.89781 9.89699 4.48277C9.87199 3.915 9.71261 3.53648 9.32824 3.13472C9.08136 2.87574 8.78449 2.69644 8.45324 2.60679C8.22199 2.54371 7.75949 2.54703 7.53136 2.61011ZM8.32511 3.68589C8.65324 3.81871 8.87199 4.13414 8.90011 4.51929C8.91886 4.80152 8.85011 5.02398 8.68449 5.2232C8.50011 5.44898 8.29386 5.54527 8.00011 5.54527C7.70636 5.54527 7.50011 5.44898 7.31574 5.2232C7.15011 5.02398 7.08136 4.80152 7.10011 4.51929C7.14386 3.86519 7.74699 3.45679 8.32511 3.68589Z"
      fill="#898989"
    />
    <path
      d="M12.8906 2.60336C12.775 2.65649 12.6719 2.77602 12.6281 2.89887C12.6031 2.96195 12.5937 3.85512 12.5937 5.67465V8.35746L12.4625 8.40063C12.2719 8.45707 12.1375 8.53676 11.9187 8.71274C11.4625 9.07465 11.1906 9.67231 11.1875 10.2998C11.1875 10.6418 11.2312 10.851 11.3781 11.1864C11.5469 11.5715 11.8781 11.9268 12.25 12.1194C12.3937 12.1924 12.5312 12.2522 12.5531 12.2522C12.5844 12.2522 12.5937 12.4315 12.5937 13.1321C12.5937 14.1182 12.6031 14.1713 12.7937 14.3274C13.0375 14.5233 13.3844 14.4403 13.525 14.1448C13.5906 14.002 13.5937 13.9655 13.5937 13.1288V12.2588L13.7125 12.219C13.9 12.1526 14.1969 11.9666 14.3687 11.8039C14.5875 11.5948 14.8156 11.2196 14.9094 10.9207C15.0125 10.5821 15.0156 10.0508 14.9156 9.71215C14.7406 9.12113 14.2281 8.55336 13.7312 8.39731L13.5937 8.35414V5.67797V3.0018L13.525 2.8557C13.4062 2.61 13.1187 2.49711 12.8906 2.60336ZM13.4719 9.41996C13.55 9.45649 13.675 9.55278 13.7437 9.63246C14.1656 10.0973 14.0406 10.8743 13.4906 11.1797C13.4312 11.2129 13.2906 11.2395 13.1375 11.2495C12.8469 11.2661 12.6906 11.2096 12.4906 11.0237C12.2906 10.8411 12.2094 10.6551 12.1937 10.3563C12.1812 10.1106 12.1875 10.0774 12.2781 9.87817C12.45 9.50961 12.75 9.32035 13.125 9.34028C13.2375 9.34692 13.3937 9.38344 13.4719 9.41996Z"
      fill="#898989"
    />
  </svg>
);

export default FiltersIcon;
