import React, { useState } from "react";
import { Row, Col, Button, Spinner, ListGroup } from "react-bootstrap";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { getFormattedDateTimeStr } from "../../utils";

const NavbarDropdownItem = ({
  id: taskId,
  resolveTask,
  unresolveTask,
  text,
  created,
  ticketId,
  ticketGroupId,
  spacing,
  propertyAddress,
  showUnresolved,
  fromUserId,
  usersMap,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [disabledBtn, setDisabledBtn] = useState(false);

  const navigateTo = () => {
    let newPath = `/dashboard/job/${ticketGroupId}/${ticketId}`;
    
    if (!window.location.pathname.endsWith(newPath)) {
      const from = location.state?.from;
      navigate(
        generatePath("/dashboard/job/:ticketGroupId/:ticketId", {
          ticketGroupId,
          ticketId,
        }),
        { replace: true, state: { from } }
      );
    }
  };

  return (
    <ListGroup.Item style={{ cursor: "pointer" }} onClick={navigateTo}>
      <Row className="align-items-center g-0">
        <Col xs={12} className={spacing ? "pl-2" : ""}>
          <div
            className="text-dark"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {propertyAddress && (
            <div className="small text-dark">{propertyAddress}</div>
          )}
          {created && (
            <small className="text-muted mt-1">{`Created at: ${getFormattedDateTimeStr(
              created
            )}`} | {usersMap[fromUserId] && <>Assigned by: {usersMap[fromUserId]?.firstName} {usersMap[fromUserId]?.lastName}</>}</small>
          )}
          
        </Col>
        <Col xs={3} className="text-start">
          <Button
            className="rounded-pill"
            size="sm"
            disabled={disabledBtn}
            onClick={(e) => {
              e.stopPropagation();
              showUnresolved
                ? resolveTask(taskId, setDisabledBtn)
                : unresolveTask(taskId, setDisabledBtn)
              }
            }
          >
            {disabledBtn && (
              <Spinner
                animation="border"
                style={{ height: 10, width: 10, marginRight: 5 }}
              />
            )}
            {showUnresolved ? "Resolve" : "Unresolve"}
          </Button>
          {usersMap[fromUserId] && <small>Assigned by: {usersMap[fromUserId]?.firstName} {usersMap[fromUserId]?.lastName}</small>}
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default NavbarDropdownItem;
