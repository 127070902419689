import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";

function SignUp() {
  const navigate = useNavigate();
  const { signUp } = useAuth();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        passwordConfirmation: "",
        submit: true,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(6, "Must be at least 12 characters")
          .max(255)
          .required("Required"),
        passwordConfirmation: Yup.string()
          .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value
          })
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        signUp(
          values.email,
          values.password
        ).then(() => {
          toast.success("User Created Successfully !")
          navigate("/auth/sign-in");
        }).catch((err) => {
          toast.error("Failed to create new user, please contact administrator !!!")
          // const message = err.message || "Something went wrong";

          setStatus({ success: false });
          //setErrors({ submit: message });
          setSubmitting(false);
        })
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              {errors.submit}
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Email address"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="passwordConfirmation"
              placeholder="Confirm Password"
              value={values.passwordConfirmation}
              isInvalid={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.passwordConfirmation}
              </Form.Control.Feedback>
            )}
            <small>
              <Link to="/auth/sign-in">Back to login</Link>
            </small>
          </Form.Group>
          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Sign up
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignUp;
