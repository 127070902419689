import { useCallback, useEffect, useRef, useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { X } from "react-feather";
import { generalSearch } from "../../services/generalSearch";

const GeneralSearch = ({ gsData, handleGSChange }) => {
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const timeoutRef = useRef();

  useEffect(() => {
    gsData === "" && setActiveKey("")
  }, [gsData])

  const closeAccordion = useCallback(() => {
    handleGSChange("");
    setActiveKey("");
  }, []);

  const handleKeyPressed = useCallback((e) => {
    if (e.key === "Enter" && e.target.value != '') {
      clearTimeout(timeoutRef.current);
      setLoading(true);
      setActiveKey("loading");
      generalSearch(e.target.value)
        .then((res) => {
          setSearchData(res);
          if (!res.length) {
            setActiveKey("no-result");
            timeoutRef.current = setTimeout(closeAccordion, 5000);
            return;
          }
          setActiveKey("result");
        })
        .catch((error) => {
          setErrorMessage(
            error[0]?.description ||
            error.message ||
            (typeof error === "string" && error) ||
            "Something went wrong"
          );
          setActiveKey("error");
          timeoutRef.current = setTimeout(closeAccordion, 7500);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <Row className="general-search-row">
      <Col className="mt-2 general-search-input">
        <Form.Control
          placeholder="General Search"
          autoComplete="off"
          onChange={(e) => handleGSChange(e.target.value)}
          value={gsData}
          disabled={loading}
          onKeyPress={handleKeyPressed}
        />
      </Col>
      <Accordion activeKey={activeKey} className="general-search-accordion">
        <Accordion.Collapse eventKey="loading">
          <Card>
            <Card.Body className="d-flex justify-content-center">
              <Spinner animation="grow" />
            </Card.Body>
          </Card>
        </Accordion.Collapse>
        <Accordion.Collapse eventKey="result">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-end">
                <Button variant="link" size="sm" onClick={closeAccordion}>
                  <X size={18} color="black" />
                </Button>
              </div>
              <ul
                dangerouslySetInnerHTML={{
                  __html: searchData.map((item) => item.key).join(""),
                }}
              />
            </Card.Body>
          </Card>
        </Accordion.Collapse>
        <Accordion.Collapse eventKey="no-result">
          <Card>
            <Card.Body className="d-flex justify-content-center">
              No result!
            </Card.Body>
          </Card>
        </Accordion.Collapse>
        <Accordion.Collapse eventKey="error">
          <Alert variant="danger" dismissible onClose={closeAccordion}>
            <div className="alert-message">{errorMessage}</div>
          </Alert>
        </Accordion.Collapse>
      </Accordion>
    </Row>
  );
};

export default GeneralSearch;
