import React from "react"
import { Button, Modal } from "react-bootstrap"

const ConfirmationModal = ({ show, title, setShow, handleConfirm, handleCancel, children, deleteBtn, handleDelete, className = "" }) => {

    const cancel = () => {
        if (setShow) {
          setShow(false)
        }
        if (handleCancel) {
            handleCancel()
        }
    }

    return (
        <Modal
            show={show}
            contentClassName={className}
            onHide={cancel}
        >
            <Modal.Header closeButton className={className ? `${className}-header` : ""}>
                <Modal.Title className={className ? `${className}-title` : ""}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={className ? `${className}-body` : ""}>
                {children}
            </Modal.Body>
            <Modal.Footer className={className ? `${className}-footer` : ""}>
                {deleteBtn && <Button variant="primary" onClick={handleDelete}>Delete</Button>}
                <Button variant="primary" onClick={handleConfirm}>Confirm</Button>
                <Button variant="secondary" onClick={cancel}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal;