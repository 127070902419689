import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import propertiesReducer from "./properties"
import ticketGroupsReducer from "./reducers/ticketGroups";
import appReducer from "./reducers/app";
import searchReducer from "./reducers/search";
import compsReducer from "./reducers/comps";
import userReducer from "./reducers/user";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    properties: propertiesReducer,
    ticketGroups: ticketGroupsReducer,
    appData: appReducer,
    user: userReducer,
    comps: compsReducer,
    search: searchReducer
  }
});
