import React from "react"
import { Form, Row, Col } from "react-bootstrap"

const Customer = ({ customer, setCustomer }) => {

    const handleBasePropertyChanged = (key, value) => {
        setCustomer({ ...customer, [key]: value })
    }

    return (
        <>
            <h4>Customer</h4>
            <hr />
            <Row>
                <Col className="mt-2" xl={6} md={6} sm={12}>
                    <h5>Name</h5>
                    <Form.Control
                        type="text"
                        name="fullName"
                        label="Name"
                        value={customer.fullName || ""}
                        onChange={(e) => handleBasePropertyChanged(e.target.name, e.target.value)}
                    />
                </Col>
                <Col className="mt-2" xl={6} md={6} sm={12}>
                    <h5>Address</h5>
                    <Form.Control
                        type="text"
                        name="address"
                        label="Address"
                        value={customer.address || ""}
                        onChange={(e) => handleBasePropertyChanged(e.target.name, e.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="mt-2" xl={4} lg={4} md={6} sm={12}>
                    <h5>Contact Name</h5>
                    <Form.Control
                        type="text"
                        name="contactName"
                        label="contactName"
                        value={customer.contactName || ""}
                        onChange={(e) => handleBasePropertyChanged(e.target.name, e.target.value)}
                    />
                </Col>
                <Col className="mt-2" xl={4} lg={4} md={6} sm={12}>
                    <h5>Contact Email</h5>
                    <Form.Control
                        type="email"
                        name="email"
                        label="email"
                        value={customer.email || ""}
                        onChange={(e) => handleBasePropertyChanged(e.target.name, e.target.value)}
                    />
                </Col>
                <Col className="mt-2" xl={4} lg={4} md={6} sm={12}>
                    <h5>Contact Phone</h5>
                    <Form.Control
                        type="text"
                        name="phone"
                        label="phone"
                        value={customer.phone || ""}
                        onChange={(e) => handleBasePropertyChanged(e.target.name, e.target.value)}
                    />
                </Col>
            </Row>
            <hr />
        </>)
}

export default Customer