import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { USER_ROLES } from "../../constants";

// For routes that can only be accessed by admin users
function AdminGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  if (isInitialized && (!isAuthenticated || !user?.roles.includes(USER_ROLES.ADMIN))) {
    return <Navigate to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AdminGuard;
