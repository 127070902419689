import { Button, Spinner } from "react-bootstrap";

/**
 * @type {import("react").FC<{ loading: boolean; } & import("react-bootstrap").ButtonProps>}
 */
const LoadingButton = ({ loading, disabled, children, ...rest }) => (
  <Button disabled={loading || disabled} {...rest}>
    {loading && (
      <Spinner animation="border" size="sm" className="me-2 align-middle" />
    )}
    {children}
  </Button>
);

export default LoadingButton;
