import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Alert, Button, Form, Col } from "react-bootstrap";
import Popconfirm from "../../components/Popconfirm";
import { Formik } from "formik";
import * as Yup from "yup";
import { changePassword } from "../../services/users";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState(false);
  /** @type {React.MutableRefObject<import("formik").FormikProps<{}>>} */
  const formikRef = useRef();

  const closeConfirmDialog = () => setConfirmDialog(false);

  const onDialogOk = () => {
    formikRef.current.submitForm();
    closeConfirmDialog();
  };

  return (
    <div className="d-flex justify-content-center">
      <Helmet title="Change Password" />
      <Popconfirm
        title="Enter your current password to change it. Chaging the password will
          change it only in the RepublicValuations system. You have to change
          your MS teams password manually in order to use the integrated
          communications/files."
        show={confirmDialog}
        onOk={onDialogOk}
        onCancel={closeConfirmDialog}
      />
      <Col sm="10" md="8" lg="6">
        <div className="text-center mt-4">
          <h1 className="h2">Change password</h1>
          <p className="lead">
            Enter your current password to change it. Chaging the password will
            change it only in the RepublicValuations system. You have to change
            your MS teams password manually in order to use the integrated
            communications/files.
          </p>
        </div>

        <Card>
          <Card.Body>
            <div className="m-sm-4">
              <Formik
                innerRef={formikRef}
                initialValues={{
                  currentPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                  submit: false,
                }}
                validationSchema={Yup.object().shape({
                  currentPassword: Yup.string().required(
                    "Current password is required!"
                  ),
                  newPassword: Yup.string()
                    .required("Password is required!")
                    .min(
                      8,
                      "Password is too short - should be 8 chars minimum!"
                    )
                    .matches(
                      /(?=.*[0-9])/,
                      "Password must contain at least one number!"
                    )
                    .matches(
                      /(?=.*[!@#$%^&*])/,
                      "Password must contain at least one special character!"
                    )
                    .matches(
                      /(?=.*[A-Z])/g,
                      "Password must contain at least one uppercase letter!"
                    )
                    .matches(
                      /(?=.*[a-z])/g,
                      "Password must contain at least one lowercase letter!"
                    ),
                  confirmPassword: Yup.string().oneOf(
                    [Yup.ref("newPassword"), null],
                    "Passwords don't match!"
                  ),
                })}
                onSubmit={async (
                  { currentPassword, newPassword },
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    await changePassword(currentPassword, newPassword);
                    toast.success("Password successfully changed!");
                    navigate(-1);
                  } catch (error) {
                    const message =
                      error[0]?.description ||
                      error.message ||
                      (typeof error === "string" && error) ||
                      "Something went wrong";

                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                      </Alert>
                    )}
                    <Form.Group className="mb-3">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="currentPassword"
                        label="Current Password"
                        value={values.currentPassword}
                        isInvalid={Boolean(
                          touched.currentPassword && errors.currentPassword
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.currentPassword && (
                        <Form.Control.Feedback type="invalid">
                          {errors.currentPassword}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        autoComplete="new-password"
                        name="newPassword"
                        label="New Password"
                        value={values.newPassword}
                        isInvalid={Boolean(
                          touched.newPassword && errors.newPassword
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.newPassword && (
                        <Form.Control.Feedback type="invalid">
                          {errors.newPassword}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        autoComplete="off"
                        label="Confirm Password"
                        value={values.confirmPassword}
                        isInvalid={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.confirmPassword && (
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmPassword}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <div className="text-center mt-3">
                      <Button
                        type="button"
                        variant="primary"
                        size="lg"
                        disabled={isSubmitting}
                        onClick={() => setConfirmDialog(true)}
                      >
                        Change password
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default ChangePassword;
