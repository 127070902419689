import axios from "../utils/axios";

export function GetAppSettings() {
  return axios.get(`/AppSettings/GetAppSettings`);
}

export function UpdateInspectionDoneNotificationUserId(userId) {
  return axios.post(`/AppSettings/UpdateInspectionDoneNotificationUserId?userId=${userId}`, {});
}

export function UpdatePendingJobsUserId(userId) {
    return axios.post(`/AppSettings/UpdatePendingJobsUserId?userId=${userId}`, {});
}