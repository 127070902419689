import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { getTicketGroupId } from "../../../services/ticket";

const RelatedTicket = ({ ticketId }) => {
    const navigate = useNavigate()
    const [jobUrl, setJobUrl] = useState();

    useEffect(() => {

        if (!ticketId) {
            return;
        }

        setJobUrl();
        if (ticketId) {
            getTicketGroupId(ticketId).then(response => {
                const url = `/dashboard/job/${response.id}/${ticketId}`;
                setJobUrl(url);
            });
        }
    }, [ticketId]);

    return (<>
        {jobUrl ? (<Button className="ms-2" onClick={e => navigate(jobUrl)}>Open Related Job</Button>) : null}
    </>);
}

export default RelatedTicket