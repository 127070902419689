import React from "react";
import { Accordion } from "react-bootstrap";
import Quote from "../../jobDetails/tabs/Quote";
import Files from "../../jobDetails/tabs/Files";
import Finalization from "../../jobDetails/finalization";
import useJobDetails from "../../jobDetails/useJobDetails";

const QuotesFilesAndFinalization = () => {
  const { ticketInfo, handleUpdateIsPaid } = useJobDetails();

  return (
    <div>
      <Accordion defaultActiveKey="files">
        <Accordion.Item eventKey="files" className="bg-white">
          <Accordion.Header>Files</Accordion.Header>
          <Accordion.Body>
            <Files />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="quote" className="bg-white">
          <Accordion.Header>Quotes</Accordion.Header>
          <Accordion.Body>
            <Quote />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="finalization" className="bg-white">
          <Accordion.Header>Finalization</Accordion.Header>
          <Accordion.Body>
            <Finalization
              ticket={ticketInfo}
              ticketId={ticketInfo.id}
              isPaid={ticketInfo.isPaid}
              handleUpdateIsPaid={handleUpdateIsPaid}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default QuotesFilesAndFinalization;
