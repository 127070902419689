/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} RentRegulationStatus */
import axios from "../../utils/axios";

/** @returns {Promise<Array<RentRegulationStatus>>} */
export function getRentRegulationStatuses() {
  return axios.get("/RentRegulationStatus/GetRentRegulationStatuses");
}

/**
 * @param {string} name
 * @returns {Promise<RentRegulationStatus>}
 */
export function createRentRegulationStatus(name) {
  return axios.post("/RentRegulationStatus/CreateRentRegulationStatus", { name });
}
