import { Spinner } from "react-bootstrap";

/**
 * @typedef {{ loading?: boolean } & React.HTMLAttributes<HTMLDivElement>} LoadingWrapperProps
 * @type {import("react").FC<LoadingWrapperProps>}
 */
const LoadingWrapper = ({
  children,
  loading = true,
  className = "",
  ...rest
}) => (
  <div className={`${className} loading-container`} {...rest}>
    <div className={loading ? "transparent-wrapper-view" : ""}>{children}</div>
    {loading && <Spinner animation="grow" className="spinner" />}
  </div>
);

export default LoadingWrapper;