import { keyById } from "../../../utils";
import {
  LOAD_PROPERTIES,
  LOAD_PROPERTY_SUB_TYPES,
  LOAD_JOB_ASSIGNMENT_TYPES,
  LOAD_JOB_PURPOSES,
  LOAD_QUOTE_TIME_SLOTS,
  LOAD_VALUE_OPTIONS,
  LOAD_USERS,
  LOAD_INSPECTORS,
  LOAD_PORTFOLIOS,
  LOAD_TICKET_STATUS_TYPES,
  LOAD_TICKET_STEP_TYPES,
  LOAD_TICKET_GROUP_STATUS_TYPES,
  LOAD_CUSTOMER_RULES,
  LOAD_SIGNING_APPRAISERS,
  UPDATE_TICKET_STATUS_TYPE
} from "../../actions/app";

/**
 * @typedef {Object} User
 * @property {string} User.color
 * @property {string} User.email
 * @property {string} User.firstName
 * @property {string} User.id
 * @property {string} User.lastName
 * @property {string} User.microsoftUserId
 * @property {Array<string>} User.roles see enum `USER_ROLES`
 * @property {string} User.username
 */
/**
 * @typedef {{
 *  propertyTypes: Array<import("../../../services/comp").PropertyType>;
 *  propertyTypesMap: Record<string, import("../../../services/comp").PropertyType>;
 *  propertySubTypes: Record<string, Array<import("../../../services/comp").PropertySubType>>;
 *  usersMap: Record<string, User>;
 *  ticketStatusTypesMap: Record<string, any>;
 *  jobPurposes: Array<any>;
 *  assignmentTypes: Array<any>;
 *  quoteTimeSlots: Array<any>;
 *  valueOptions: Array<any>;
 *  users: Array<User>;
 *  inspectors: Array<any>;
 *  signingAppraisers: Array<any>;
 *  portfolios: Array<any>;
 *  ticketStatusTypes: Array<any>;
 *  ticketStepTypes: Array<any>;
 *  ticketGroupStatusTypes: Array<any>;
 *  customerRules: Array<any>;
 * }} AppDataStateType
 */
const data = {
  propertyTypes: [],
  propertyTypesMap: [],
  propertySubTypes: {},
  usersMap: {},
  ticketStatusTypesMap: {},
  jobPurposes: [],
  assignmentTypes: [],
  quoteTimeSlots: [],
  valueOptions: [],
  users: [],
  inspectors: [],
  signingAppraisers: [],
  portfolios: [],
  ticketStatusTypes: [],
  ticketStepTypes: [],
  ticketGroupStatusTypes: [],
  customerRules: [],
};

const appReducer = (state = data, action) => {
  switch (action.type) {
    case LOAD_PROPERTIES:
      return {
        ...state,
        propertyTypes: action.payload,
        propertyTypesMap: keyById(action.payload),
      };
    case LOAD_PROPERTY_SUB_TYPES:
      const propertySubTypes = {};
      action.payload.forEach((item) => {
        if (!propertySubTypes[item.propertyTypeId]) {
          propertySubTypes[item.propertyTypeId] = [];
        }
        propertySubTypes[item.propertyTypeId].push(item);
      });
      return { ...state, propertySubTypes };
    case LOAD_JOB_ASSIGNMENT_TYPES:
      return { ...state, assignmentTypes: action.payload };
    case LOAD_JOB_PURPOSES:
      return { ...state, jobPurposes: action.payload };
    case LOAD_QUOTE_TIME_SLOTS:
      return { ...state, quoteTimeSlots: action.payload };
    case LOAD_VALUE_OPTIONS:
      return { ...state, valueOptions: action.payload };
    case LOAD_USERS:
      return {
        ...state,
        users: action.payload,
        usersMap: keyById(action.payload),
      };
    case LOAD_INSPECTORS:
      return { ...state, inspectors: action.payload };
    case LOAD_SIGNING_APPRAISERS:
      return { ...state, signingAppraisers: action.payload };
    case LOAD_PORTFOLIOS:
      return { ...state, portfolios: action.payload };
    case LOAD_TICKET_STATUS_TYPES:
      return {
        ...state,
        ticketStatusTypes: action.payload,
        ticketStatusTypesMap: keyById(action.payload),
      };
    case LOAD_TICKET_STEP_TYPES:
      return { ...state, ticketStepTypes: action.payload };
    case LOAD_TICKET_GROUP_STATUS_TYPES:
      return { ...state, ticketGroupStatusTypes: action.payload };
    case LOAD_CUSTOMER_RULES:
      return { ...state, customerRules: action.payload };
    case UPDATE_TICKET_STATUS_TYPE:
      return {
        ...state,
        ticketStatusTypes: state.ticketStatusTypes.map((statusType) =>
          statusType.id === action.payload.id
            ? { ...statusType, affiliateAlias: action.payload.affiliateAlias }
            : statusType
        ),
      };
    default:
      return state;
  }
};

export default appReducer;
