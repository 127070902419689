/** @typedef {{ id: number; name: string; displayOrder: number; label: string }} Zone */
import axios from "../../utils/axios";

/** @returns {Promise<Array<Zone>>} */
export function getZones() {
  return axios.get("/Zone/GetZones");
}

/**
 * @param {string} name
 * @returns {Promise<Zone>}
 */
export function createZone(name) {
  return axios.post("/Zone/CreateZone", { name });
}
