import Icon from "./Icon";

/** @type {typeof Icon} */
const Inspection = (props) => (
  <Icon {...props}>
    <path d="M2.16444 2.16889L0 4.33333V12.1689V20H7.33333H14.6667V17.36V14.72L14.9689 14.6667C15.5156 14.56 16.0844 14.3378 16.5556 14.04L16.8533 13.8533L19.3022 16.3022C20.6533 17.6533 21.7778 18.7556 21.8 18.7556C21.8578 18.7556 22.7556 17.8578 22.7556 17.8C22.7556 17.7733 21.6533 16.6533 20.3067 15.3067L17.8533 12.8533L18.0578 12.5289C18.6756 11.5289 18.8978 10.2533 18.6489 9.07555C18.2622 7.26222 16.7778 5.80889 14.9689 5.46667L14.6667 5.41333V2.70667V0H9.49778H4.33333L2.16444 2.16889ZM13.2889 3.39111V5.44889L13.0311 5.49778C11.6578 5.75556 10.2444 6.94667 9.72889 8.28889C9.44889 9.00889 9.40889 9.24 9.40889 10.0667C9.40889 10.8933 9.44889 11.1244 9.72889 11.8444C10.2444 13.1867 11.6578 14.3778 13.0311 14.6356L13.2889 14.6844V16.6756V18.6667H7.31111H1.33333V12V5.33333H3.33333H5.33333V3.33333V1.33333H9.31111H13.2889V3.39111ZM4 3.17778V4H3.16889H2.33333L3.15556 3.17778C3.60889 2.72444 3.98222 2.35555 3.98667 2.35555C3.99556 2.35555 4 2.72444 4 3.17778ZM14.9111 6.87111C15.8044 7.10667 16.64 7.8 17.04 8.63556C17.28 9.13778 17.3511 9.45778 17.3511 10.0667C17.3511 10.4356 17.3244 10.6889 17.2711 10.8889C16.96 12.0356 16.04 12.9556 14.8889 13.2711C14.4933 13.3822 13.64 13.3822 13.2444 13.2711C12.3067 13.0133 11.4978 12.3467 11.0889 11.4933C10.8489 10.9911 10.7822 10.6711 10.7822 10.0667C10.7822 9.69333 10.8044 9.44889 10.8622 9.24444C11.2044 7.98667 12.2444 7.03111 13.52 6.8C13.8356 6.74667 14.5822 6.78222 14.9111 6.87111Z" />
    <path d="M5.74666 6.58667L4.66666 7.66667L4.24444 7.24445L3.82222 6.82222L3.32 7.32445L2.82222 7.82222L3.74666 8.74222L4.66666 9.66667L6.25333 8.08L7.84444 6.48889L7.35555 6C7.08889 5.73333 6.85778 5.51111 6.84444 5.51111C6.83111 5.51111 6.33778 5.99556 5.74666 6.58667Z" />
    <path d="M5.74666 10.5867L4.66666 11.6667L4.24444 11.2444L3.82222 10.8222L3.32 11.3244L2.82222 11.8222L3.74666 12.7422L4.66666 13.6667L6.25333 12.08L7.84444 10.4889L7.35555 10C7.08889 9.73333 6.85778 9.51111 6.84444 9.51111C6.83111 9.51111 6.33778 9.99556 5.74666 10.5867Z" />
    <path d="M5.74666 14.5867L4.66666 15.6667L4.24444 15.2444L3.82222 14.8222L3.32 15.3244L2.82222 15.8222L3.74666 16.7422L4.66666 17.6667L6.25333 16.08L7.84444 14.4889L7.35555 14C7.08889 13.7333 6.85778 13.5111 6.84444 13.5111C6.83111 13.5111 6.33778 13.9956 5.74666 14.5867Z" />{" "}
  </Icon>
);

export default Inspection;
