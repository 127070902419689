import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container, Nav, Tab } from "react-bootstrap";
import Switch from "./Switch";
import Details from "./tabs/Details";
import Files from "./tabs/Files";
import Notes from "./tabs/Notes";
import Questions from "./tabs/Questions";
import QuotesFilesAndFinalization from "./tabs/QuotesFilesAndFinalization";
import { LayoutContext } from "../../contexts/LayoutContext";
import useJobDetails from "../jobDetails/useJobDetails";
import useJobInspectionDetails from "../jobDetails/useJobInspectionDetails";

import "./jobView.css";

import { ReactComponent as InfoIcon } from "./../../assets/icons/info.svg";
import { ReactComponent as FilesIcon } from "./../../assets/icons/files.svg";
import { ReactComponent as NotesIcon } from "./../../assets/icons/notes.svg";
import { ReactComponent as ListIcon } from "./../../assets/icons/list.svg";
import { ReactComponent as BackIcon } from "./../../assets/icons/back.svg";
import { ReactComponent as ClockIcon } from "./../../assets/icons/clock.svg";
import { ReactComponent as CalendarIcon } from "./../../assets/icons/calendar-icon.svg";


const JobView = () => {
  const { showNavbar } = useContext(LayoutContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('activeTab') ?? "details";

  const navigate = useNavigate();
  const { jobName, propertyInfo } = useJobDetails();
  const { scheduledInspection: data, updateInspection } =
    useJobInspectionDetails();

  const [checked, setChecked] = useState(false);
  const [inspectionTime, setInspectionTime] = useState();
  const [inspectionDate, setInspectionDate] = useState();
  const [eventKey, setEventKey] = useState();

  useEffect(() => {
    if (activeTab) {
      setEventKey(activeTab);
    }
    if (!data || !data.time) {
      return;
    }

    setChecked(data.completed);
    setInspectionDate(data.time.format("MM/DD/YYYY"));
    setInspectionTime(data?.time?.format("hh:mm a"));

  }, [data]);

  const selectTabHandler = (tab) => {
    setEventKey(tab);
    setSearchParams({ activeTab: tab });
  }

  const { county, state, unit } = propertyInfo;

  const handleCompletedChange = (e) => {
    const inspection = {
      ...data,
      completed: e.target.checked,
    };

    updateInspection(inspection);
    setChecked(!checked);
  };

  return (
    <>
      <Container fluid className="h-100 p-0 job-container">
        <div className="h-100 d-flex flex-column">
          {!showNavbar && (
            <div className="header d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <Nav.Link
                  href="#"
                  onClick={() => navigate('/dashboard/event-view')}
                  className="ps-0"
                >
                  <BackIcon />
                </Nav.Link>
                <div>
                  <div className="font-weight-bold text-black fs-5">
                    {jobName}
                  </div>
                  <div className="fs-6">
                    {county} {state} {unit ? "," : ""} {unit}
                  </div>
                </div>
              </div>

              <div className="d-flex justfy-content-center">
                <div className="d-flex align-items-center gap-3 me-2">
                  <div className="time-container d-flex flex-column align-items-center">
                    <CalendarIcon />
                    <div className="time-text text-brown">{inspectionDate}</div>
                  </div>
                </div>

                <div className="d-flex align-items-center gap-3">
                  <div className="time-container d-flex flex-column align-items-center">
                    <ClockIcon />
                    <div className="time-text text-brown">{inspectionTime}</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Tab.Container activeKey={eventKey} onSelect={selectTabHandler}>
            <Tab.Content className={`flex-fill my-4 py-3 tab-content`} style={{ overflowY: eventKey == 'notes' ? 'hidden' : 'scroll' }}>
              <Tab.Pane eventKey="details">
                <Details checked={checked} handleCompletedChange={handleCompletedChange} />
              </Tab.Pane>
              <Tab.Pane eventKey="files">
                <Files uploadEnabled />
              </Tab.Pane>
              <Tab.Pane eventKey="notes" style={{ height: "inherit" }}>
                <Notes />
              </Tab.Pane>
              <Tab.Pane eventKey="questions">
                <Questions  />
              </Tab.Pane>
              <Tab.Pane eventKey="quotes-files-finalization">
                <QuotesFilesAndFinalization />
              </Tab.Pane>
            </Tab.Content>

            <Nav
              variant="pills"
              justify
              defaultActiveKey="details"
              className="footer-menu d-flex justify-content-between align-items-center"
            >
              <Nav.Item>
                <Nav.Link eventKey="details">
                  <InfoIcon className="menu-icon" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="files">
                  <FilesIcon className="menu-icon" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="notes">
                  <NotesIcon className="menu-icon" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="questions">
                  <ListIcon className="menu-icon" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="quotes-files-finalization">
                  <FolderIcon
                    className="menu-icon"
                    color={
                      eventKey === "quotes-files-finalization"
                        ? "white"
                        : "#404040"
                    }
                    fill={
                      eventKey === "quotes-files-finalization"
                        ? "#0E4542"
                        : "#f1f1f1"
                    }
                  />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
      </Container>
    </>
  );
};

/** @param {{ size?: number; } & import("react").SVGProps} props */
const FolderIcon = ({ size = 24, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    stroke={props.color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path
      d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"
      fill={props.fill}
    />
  </svg>
);

export default JobView;
