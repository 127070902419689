import React, { useState, useEffect } from "react"
import { PlusCircle, MinusCircle } from "react-feather";
import { Button, Row, Col, Form, FormGroup } from "react-bootstrap";
import { useParams } from "react-router"
import { SERVER_URL } from "../../../configs";
import axios from "axios";
import { toast } from "react-toastify"

const EditContacts = () => {
    const { id } = useParams()
    const [contactInfo, setContactInfo] = useState({ firstName: "", lastName: "", customerContactPhoneNumbers: [], customerContactEmails: [] })
    const [contactLoaded, setContactLoaded] = useState(false)

    useEffect(() => {
        axios.get(`${SERVER_URL}/api/Customer/GetCustomerContactById?customerContactId=${id}`).then((res) => {
            setContactInfo({ ...res.data })
            setContactLoaded(true)
        })
    }, [id])

    const updateContactInfo = () => {
        axios.post(`${SERVER_URL}/api/Customer/UpdateCustomerContact`, contactInfo, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            toast.success("Contact Details Updated Successfully !!");
        })
    }

    const handleNameChange = (e) => {
        let _contactInfo = JSON.parse(JSON.stringify(contactInfo))

        _contactInfo[e.target.name] = e.target.value
        setContactInfo(_contactInfo)
    }
    const handleContactDetailsChange = (key, value, index) => {
        let _contactInfo = JSON.parse(JSON.stringify(contactInfo))

        let _objKey = "customerContactEmails"
        if (key === "phone")
            _objKey = "customerContactPhoneNumbers"

        _contactInfo[_objKey][index][key] = value
        setContactInfo(_contactInfo)
    }

    const removeItemFromCustomerContact = (key, index) => {
        let _contactInfo = JSON.parse(JSON.stringify(contactInfo))

        let _objKey = "customerContactEmails"
        if (key === "phone")
            _objKey = "customerContactPhoneNumbers"

        _contactInfo[_objKey].splice(key, 1)
        setContactInfo(_contactInfo)
    }

    const addItemToCustomerContacts = (key) => {
        let _contactInfo = JSON.parse(JSON.stringify(contactInfo))

        let _objKey = "customerContactEmails"
        if (key === "phone")
            _objKey = "customerContactPhoneNumbers"
        _contactInfo[_objKey].push({ [key]: "" })
        setContactInfo(_contactInfo)
    }

    return (<>
        <h3>Update Contact Details</h3>
        <Row className="my-1 card" style={{ flexDirection: "row", paddingBottom: 10 }}>
            <Col md={12} lg={6}>
                <h5 className="my-2">First Name</h5>
                <Form.Group className="mb-2">
                    <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="Enter First Name"
                        value={contactInfo.firstName}
                        onChange={handleNameChange}
                    />
                </Form.Group>
            </Col>
            <Col md={12} lg={6}>
                <h5 className="my-2">Last Name</h5>
                <Form.Group className="mb-2">
                    <Form.Control
                        type="text"
                        name="lastName"
                        placeholder="Enter Last Name"
                        value={contactInfo.lastName}
                        onChange={handleNameChange}
                    />
                </Form.Group>
            </Col>
            <Col md={12} lg={6}>
                {
                    contactInfo?.customerContactPhoneNumbers.length > 0 ? contactInfo.customerContactPhoneNumbers.map((obj, index1) => (
                        <div key={index1} style={{ display: "flex" }}>
                            <Col xs={11} lg={11} md={12}>
                                <FormGroup className="mb-2">
                                    <Form.Control
                                        type="number"
                                        value={obj["phone"]}
                                        name="phone"
                                        placeholder="Phone Number"
                                        onChange={(e) => handleContactDetailsChange("phone", e.target.value, index1)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xs={1} lg={1} style={{ paddingLeft: 15 }}>
                                <FormGroup className="mb-2">
                                    {index1 === contactInfo.customerContactPhoneNumbers.length - 1 ? (
                                        <PlusCircle
                                            type="button"
                                            className="align-middle my-1"
                                            size={22}
                                            onClick={() => addItemToCustomerContacts("phone")}
                                        />
                                    ) : (
                                        <MinusCircle
                                            type="button"
                                            className="align-middle my-1"
                                            size={22}
                                            onClick={() => removeItemFromCustomerContact("phone", index1)}
                                        />
                                    )}
                                </FormGroup>
                            </Col>
                        </div>
                    )) : <a className="my-2" onClick={() => addItemToCustomerContacts("phone")}>Add Phone</a>
                }
            </Col>
            <Col md={12} lg={6}>
                {
                    contactInfo?.customerContactEmails.length > 0 ? contactInfo.customerContactEmails.map((obj, index1) => (
                        <div key={index1} style={{ display: "flex" }}>
                            <Col xs={11} lg={11} md={12}>
                                <FormGroup className="mb-2">
                                    <Form.Control
                                        type="text"
                                        value={obj["email"]}
                                        name={"email"}
                                        placeholder="Email"
                                        onChange={(e) => handleContactDetailsChange("email", e.target.value, index1)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col xs={1} lg={1} style={{ paddingLeft: 15 }}>
                                <FormGroup className="mb-2">
                                    {index1 === contactInfo?.customerContactEmails.length - 1 ? (
                                        <PlusCircle
                                            type="button"
                                            className="align-middle my-1"
                                            size={22}
                                            onClick={() => addItemToCustomerContacts("email")}
                                        />
                                    ) : (
                                        <MinusCircle
                                            type="button"
                                            className="align-middle my-1"
                                            size={22}
                                            onClick={() => removeItemFromCustomerContact("email", index1)}
                                        />
                                    )}
                                </FormGroup>
                            </Col>
                        </div>
                    )) : <a className="my-2" onClick={() => addItemToCustomerContacts("email")}>Add Email</a>
                }
            </Col>
            {contactLoaded && <Col md={12} lg={6}>
                <Button style={{ width: 200 }} onClick={updateContactInfo}>
                    Update Contact Details
                </Button>
            </Col>
            }
        </Row>
    </>)
}

export default EditContacts