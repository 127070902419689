import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import useAppSelector from "../../hooks/useAppSelector";

const AddToPortfolioModal = ({ show, onCancel, onOk }) => {
  const { portfolios } = useAppSelector(state => state.appData)
  const [portfolio, setPortfolio] = useState();
  const [loading, setLoading] = useState(false);

  const handleAdd = async () => {
    if (!portfolio || +portfolio === 0) {
      return;
    }
    setLoading(true);
    await onOk(+portfolio);
    setLoading(false);
  };

  return (
    <Modal size="sm" show={show} onHide={onCancel}>
      <Modal.Header closeButton>Select Portfolio</Modal.Header>
      <Modal.Body className="text-center m-3">
        <Form.Select name="type" onChange={(e) => setPortfolio(e.target.value)}>
          <option value="0">Select portfolio</option>
          {portfolios.map((option) => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleAdd} disabled={!portfolio || +portfolio === 0 || loading}>
          {loading && <Spinner animation="border" size="sm" className="me-2 align-middle" />}
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddToPortfolioModal;
