import { LOAD_USER_TASKS, LOADING_USER_TASKS } from "../../actions/user"

const data = { loading: false, tasks: [] }

const userReducer = (state = data, action) => {
    switch (action.type) {
        case LOAD_USER_TASKS:
            return { ...state, tasks: action.payload }
        case LOADING_USER_TASKS:
            return { ...state, loading: action.payload }
        default:
            return state
    }
}

export default userReducer