import React, { useState } from "react";

const mapDirUrl = "https://www.google.com/maps/dir/";
export const openRoute = (tickets) => {
  const ticketDirs = tickets
    .map((ticket) => `${ticket.property.lat},${ticket.property.lng}`)
    .join("/");

  window.open(`${mapDirUrl}${ticketDirs}`);
};

const initialState = {
  tickets: [],
  addTicket: (ticket) => {},
  removeTicket: (id) => {},
  clear: () => {},
  openRoute: () => {},
  isTicketOnRoute: (id) => {},
};

const TicketRouteContext = React.createContext(initialState);

function TicketRouteProvider({ children }) {
  const [tickets, setTickets] = useState([]);
  const [ticketsOnRoute, setTicketsOnRoute] = useState({});

  const addTicket = (ticket) => {
    setTicketsOnRoute((oldValue) => ({ ...oldValue, [ticket.id]: true }));
    setTickets((oldValue) => [...oldValue, ticket]);
  };

  const removeTicket = (id) => {
    setTicketsOnRoute((oldValue) => ({ ...oldValue, [id]: false }));
    setTickets((oldValue) => oldValue.filter((item) => item.id !== id));
  };

  const clear = () => {
    setTickets([]);
    setTicketsOnRoute({})
  };

  const isTicketOnRoute = (id) => !!ticketsOnRoute[id];

  return (
    <TicketRouteContext.Provider
      value={{
        tickets,
        addTicket,
        removeTicket,
        clear,
        openRoute: () => openRoute(tickets),
        isTicketOnRoute,
      }}
    >
      {children}
    </TicketRouteContext.Provider>
  );
}

export { TicketRouteProvider, TicketRouteContext };
