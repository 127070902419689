import { useEffect, useCallback, useState } from "react";

/** @param {(event: MouseEvent) => void} [cb] */
const useContextMenu = (cb) => {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);

  const handleContextMenu = useCallback(
    /** @param {MouseEvent} event */
    (event) => {
      if (show) {
        setShow(false);
        return
      }
      if (cb && !cb(event)) {
        return;
      }
      event.preventDefault();
      setAnchorPoint({ x: event.pageX, y: event.pageY });
      setShow(true);
    },
    [show, cb]
  );

  const handleClick = useCallback(() => setShow(false), [show]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [handleClick, handleContextMenu]);

  return { anchorPoint, show };
};

export default useContextMenu;
