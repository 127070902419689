import axios from "../utils/axios";

/**
 * @param {{
 *  label: string;
 *  displayOrder: number;
 *  type: number;
 *  options: string;
 *  inspectionQuestionFormId: number;
 * }} data
 */
export async function createInspectionFormQuestions(data) {
  const result = await axios.post(
    "/InspectionQuestionForm/CreateInspectionQuestionFormQuestions",
    [data]
  );
  return result?.[0];
}

export async function updateInspectionQuestionForm(data) {
  const result = await axios.post(
    "/InspectionQuestionForm/UpdateInspectionQuestionForm",
    data
  );

  return result;
}

export async function deleteInspectionQuestionForm(data) {
  const queryParams = new URLSearchParams({ id: data.id });
  const result = await axios.post(
    `/InspectionQuestionForm/DeleteInspectionQuestionForm?${queryParams}`
  );

  return result;
}

export async function deleteInspectionQuestionFormQuestion(id) {
  const queryParams = new URLSearchParams({ id });
  const result = await axios.post(
    `/InspectionQuestionForm/DeleteInspectionQuestionFormQuestion?${queryParams}`
  );

  return result;
}

export async function updateInspectionQuestionFormQuestion(data) {
  const result = await axios.post(
    `/InspectionQuestionForm/UpdateInspectionQuestionFormQuestion`,
    data
  ); 

  return result;
}

export function getInspectionFormQuestions(inspectionQuestionFormId) {
  const queryParams = new URLSearchParams({ inspectionQuestionFormId });
  return axios.get(
    `/InspectionQuestionForm/GetInspectionFormQuestions?${queryParams}`
  );
}

export function getInspectionQuestionForms() {
  return axios.get("/InspectionQuestionForm/GetInspectionQuestionForms");
}

export function getInspectionQuestionAnswers(inspectionId) {
  const queryParams = new URLSearchParams({ inspectionId });
  return axios.get(`/InspectionQuestionForm/GetInspectionQuestionAnswers?${queryParams}`);
}

export function createInspectionForm(data) {
  return axios.post("/InspectionQuestionForm/CreateInspectionForm", data);
}

export function answerInspectionQuestionFormQuestion(
  inspectioonQuestionFormAnswerId,
  answer
) {
  const queryParams = new URLSearchParams({
    inspectioonQuestionFormAnswerId,
    answer,
  });
  return axios.post(
    `/InspectionQuestionForm/AnswerInspectionQuestionFormQuestion?${queryParams}`
  );
}

export function answerMultipleQuestions(data) {
  return axios.post(`/InspectionQuestionForm/AnswerMultipleQuestions`, data);
}

export function apiUseInspectionFormForInspection(inspectionId, inspectionQuestionFormId) {
  const queryParams = new URLSearchParams({ inspectionId, inspectionQuestionFormId });
  return axios.post(`/InspectionQuestionForm/UseInspectionFormForInspection?${queryParams}`);
}

export function getSubQuestions(parentId) {

}

export function saveSubQuestions(parentId, subQuestions) {
  
}