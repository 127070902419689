import { React, useState, useEffect } from "react"
import { Form, Row, Col, Button, Spinner } from "react-bootstrap"
import DataTable from "react-data-table-component"
import { toast } from "react-toastify"
import Popconfirm from "../../../components/Popconfirm";

import { columns } from "./columns"
import { getInspectionContactObj } from "../../../utils"
import {
    createInspectionContact,
    getInspectionContactsByTicket,
    deleteInspectionContact,
    updateInspectionContact
} from "../../../services/inspectionContact";

const InspectionContactsModal = ({ id }) => {
    const [loading, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [addContact, setAddContact] = useState(false)
    const [contactForm, setContactForm] = useState(getInspectionContactObj())
    const [contactToDelete, setContactToDelete] = useState(0);

    const loadInspectionContacts = (id) => {
        setLoader(true)
        getInspectionContactsByTicket(id)
            .then(setData)
            .finally(() => {
                setLoader(false)
            });
    }

    useEffect(() => {
        loadInspectionContacts(id)
    }, [id])

    const addNewContact = () => {
        setLoader(true);
        createInspectionContact({ ...contactForm, ticketId: id }).then(() => {
            loadInspectionContacts(id)
            setContactForm(getInspectionContactObj())
            setAddContact(false)
            toast.success("Inspection contact created successfully !")
            setLoader(false);
        }).catch((err) => {
            console.error(err)
            toast.error("Failed to create inspection contact, please contact administrator !")
            setLoader(false);
        })
    }

    const handleFormDataChange = (key, value) => {
        let _contactForm = { ...contactForm }

        _contactForm[key] = value
        setContactForm(_contactForm)
    }

    const cancelNewContact = () => {
        setAddContact(false)
        setContactForm(getInspectionContactObj())
    }

    const setContactToEdit = (row) => {
        setAddContact(true);
        setContactForm(row)
    }

    const handleContactUpdate = () => {
        setLoader(true);
        updateInspectionContact({ ...contactForm, ticketId: id }).then(() => {
            loadInspectionContacts(id)
            setContactForm(getInspectionContactObj())
            setAddContact(false)
            toast.success("Inspection contact updated successfully !")
            setLoader(false);
        }).catch((err) => {
            toast.error("Failed to update inspection contact, please contact administrator !")
            setLoader(false);
        })
    }

    const handleContactDelete = () => {
        setLoader(true);
        setContactToDelete(0);
        deleteInspectionContact(contactToDelete)
            .then(() => {
                const index = data.findIndex(item => item.id === contactToDelete);
                data.splice(index, 1);
                setData([...data]);
            })
            .catch((error) => {
                const message =
                    error?.[0]?.description ||
                    error.message ||
                    "Something went wrong";
                toast.error(message);
            })
            .finally(() => setLoader(false));
    }

    return (
        <>
            <Popconfirm
                show={!!contactToDelete}
                title="Are you sure?"
                onCancel={() => setContactToDelete(0)}
                onOk={handleContactDelete}
            />
            {loading ? <div className="spinnerContainer">
                <Spinner animation="grow" variant="secondary" />
            </div> :
                <>
                    {addContact ? <>
                        <Row className="mb-2">
                            <Col xl={4} md={6} sm={12} className="mt-2">
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={contactForm.firstName}
                                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                />
                            </Col>
                            <Col xl={4} md={6} sm={12} className="mt-2">
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={contactForm.lastName}
                                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                />
                            </Col>
                            <Col xl={2} md={6} sm={12} className="mt-2">
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    placeholder="Phone"
                                    value={contactForm.phone}
                                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                />
                            </Col>
                            <Col xl={2} md={6} sm={12} className="mt-2">
                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    value={contactForm.email}
                                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                />
                            </Col>
                            <Col xl={6} md={12} sm={12} className="mt-2">
                                <Form.Control
                                    type="text"
                                    name="note"
                                    placeholder="Note"
                                    value={contactForm.note}
                                    onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
                                    style={{
                                        paddingTop: '24px'
                                    }}
                                />
                            </Col>
                            <Col xl={6} md={12} sm={12} className="mt-2">
                                <Form.Group>
                                    <Form.Select
                                        placeholder="Type"
                                        name="appraisalContactType"
                                        onChange={(e) => handleFormDataChange(e.target.name, Number(e.target.value))}
                                        value={contactForm.appraisalContactType}
                                        style={{
                                            margin: '0',
                                        }}
                                    >
                                        <option value={0}>Inspection Contact</option>
                                        <option value={1}>Get Information from Contact</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="mb-2">
                            <Button onClick={() => contactForm.id ? handleContactUpdate() : addNewContact()}>
                                Save
                            </Button>
                            <Button style={{ marginLeft: 10 }} onClick={cancelNewContact}>
                                Cancel
                            </Button>
                        </div>
                    </> :
                        <h5 className="customLink" style={{ float: "right" }} onClick={() => setAddContact(true)}>Add New Inspection Contact</h5>
                    }<DataTable
                        style={{ height: 670, overflow: "auto" }}
                        noHeader
                        className="react-dataTable"
                        columns={[...columns, {
                            id: "actions",
                            name: "Actions",
                            cell: row => (
                                <div className="d-flex justify-content-between w-100">
                                    <Button variant="danger" onClick={() => setContactToDelete(row.id)}>Delete</Button>
                                    <Button variant="secondary" onClick={() => setContactToEdit(row)}>Edit</Button>
                                </div>)
                        }]}
                        data={data}
                    />
                </>
            }
        </>
    )
}

export default InspectionContactsModal;