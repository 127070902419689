import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { FolderPlus, Upload } from "react-feather";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";
import {
  getRootDirectoryContents,
  getDirectoryContents,
  createFolder,
  uploadFile,
  deleteFile,
  moveFile,
  getRootDirectoryWebUrl
} from "../../../services/microsoftTeamsFiles";
import Popconfirm from "../../../components/Popconfirm";
import CreateFolderModal from "./components/CreateFolderModal";
import DropUpload from "./components/DropUpload";
import FolderItem from "./components/FolderItem";
import FileItem from "./components/FileItem";
import Breadcrumbs from "./components/Breadcrumbs";
import { AuthContext } from "../../../contexts/JWTContext";
import { UtilityContext } from "../../../contexts/UtilityContext";
import NotyfContext from "../../../contexts/NotyfContext";
import useStateWithRef from "../../../hooks/useStateWithRef";
import "./styles.css";
import useJobDetails from "../useJobDetails";
import { toast } from "react-toastify";
import UploadProgressToast from "../../../utils/uploadProgressToast";

const Files = () => {
  const [files, setFiles] = useState([]);
  const [paths, setPaths] = useStateWithRef([]);
  const [loading, setLoading] = useState(true);
  const [fileToUpload, setFileToUpload] = useState();
  const [showConfirmLargeFile, setShowConfirmLargeFile] = useState(false);
  const [showConfirmFileWithSameName, setShowConfirmFileWithSameName] =
    useState(false);
  const { setFilesCount } = useContext(UtilityContext);
  const dataByPath = useRef({});
  const modalRef = useRef({ open() { } });
  const { ticketId } = useParams();
  const notyf = useContext(NotyfContext);
  const { user } = useContext(AuthContext);
  const { ticketGroup, ticketInfo } = useJobDetails();

  useEffect(() => {
    setFilesCount(0);
    getRootDirectoryContents(ticketId)
      .then((data) => {
        dataByPath.current.root = data;
        setPaths([{ name: "root", id: "root" }]);

        setFilesCount(getFileCount(data))
        setFiles(data);

        if (ticketInfo && ticketInfo.property) {
          const { address } = ticketInfo.property;
          const currentTicketFolder = data.find(x => x.folder && x.name == address);
          if (currentTicketFolder) {
            moveToCurrentTicketFolder({ id: currentTicketFolder.id, name: currentTicketFolder.name });
          }
        }
      })
      .finally(() => setLoading(false));
  }, [ticketId, ticketInfo]);

  const moveToCurrentTicketFolder = async path => {
    setLoading(true);
    const data =
      dataByPath.current[path.id] ||
      (await getDirectoryContents(ticketId, path.id));
    setFilesCount(getFileCount(data));
    setFiles([...data]);
    dataByPath.current[path.id] = data;
    setPaths((oldPaths) => [{ id: 'root', name: 'root' }, path]);
    setLoading(false);
  }

  const getFileCount = (data) => {
    let fileCount = data.filter(x => x.folder == null).length;
    for (let item of data) {
      if (item.folder) {
        fileCount += item.folder.childCount;
      }
    }

    return fileCount;
  }

  const getFolderFiles = async (path) => {

    setLoading(true);
    const data =
      dataByPath.current[path.id] ||
      (await getDirectoryContents(ticketId, path.id));
    setFiles([...data]);

    setFilesCount(getFileCount(data));
    dataByPath.current[path.id] = data;
    setPaths((oldPaths) => [...oldPaths, path]);
    setLoading(false);
  };

  const handleBreadClick = async (path, index) => {
    setLoading(true);
    const data =
      dataByPath.current[path.id] ||
      (await getDirectoryContents(ticketId, path.id));
    setFiles([...data]);
    dataByPath.current[path.id] = data;
    setPaths((oldPaths) => {
      oldPaths.splice(index + 1);
      return oldPaths;
    });
    setLoading(false);
  };

  const refreshFolder = async () => {
    const path = paths.current[paths.current.length - 1];
    const data = await getDirectoryContents(ticketId, path.id);
    setFiles([...data]);
    dataByPath.current[path.id] = data;
  };

  const handleError = (error) => {
    console.log("The Error", error);
    const message =
      error[0]?.description ||
      error.message ||
      (typeof error === "string" && error) ||
      "Something went wrong";
    notyf.open({ message, type: "danger" });
  };

  const deleteFileApi = async (id) => {
    setLoading(true);
    try {
      await deleteFile(ticketId, id);
      setFiles((oldValue) => {
        oldValue.splice(
          oldValue.findIndex((i) => i.id === id),
          1
        );
        return [...oldValue];
      });
    } catch (err) {
      handleError(err);
    }
    setLoading(false);
  };

  const handleFileUpload = async (file, name = "") => {
    const uploadProgressToast = new UploadProgressToast({
      items: [{ name: name || "file" }],
      onRetry: () => handleFileUpload(file, name),
    });
    setLoading(true);
    try {
      await uploadFile(
        ticketId,
        paths.current
          .filter((path) => path.id !== "root")
          .map((path) => path.name)
          .join("/"),
        file,
        name,
        uploadProgressToast.onUploadProgress()
      );
      await refreshFolder();
    } catch (err) {
      handleError(err);
      uploadProgressToast.onUploadFail()
    }
    setLoading(false);
  };

  const closeConfirmPopup = () => {
    setShowConfirmLargeFile(false);
    setShowConfirmFileWithSameName(false);
    setFileToUpload(null);
  };

  const handleFileWithSameNameCancel = async () => {
    let newName;

    const fileNameArray = fileToUpload.name.split(".");
    let [fileExt] =
      fileNameArray.length > 1
        ? fileNameArray.splice(fileNameArray.length - 1)
        : [""];

    if (fileExt) {
      fileExt = `.${fileExt}`;
    }
    for (let i = 1; i < 500; i++) {
      newName = `${fileNameArray.join(".")} (${i})${fileExt}`;
      if (!files.some((item) => item.name === newName)) {
        break;
      }
    }
    handleFileUpload(fileToUpload, newName);

    closeConfirmPopup();
  };

  const handleFileWithSameNameOk = async () => {
    const fileWithSameName = files.find((item) => item.name === fileToUpload.name)

    await deleteFileApi(fileWithSameName.id);
    handleFileUpload(fileToUpload);
    closeConfirmPopup();
  };

  const handleFileUploadConfirm = () => {
    handleFileUpload(fileToUpload);
    closeConfirmPopup();
  };

  /** @param {File} file */
  const fileUploadMiddleware = (file) => {
    if (files.some((item) => item.name === file.name)) {
      setShowConfirmFileWithSameName(true);
      setFileToUpload(file);
    } else if (file.size < 3145728) {
      handleFileUpload(file);
    } else {
      setFileToUpload(file);
      setShowConfirmLargeFile(true);
    }
  };

  const handleModalOpen = () => {
    const folderPath = paths.current.map((path) => `${path.name}/`);
    modalRef.current.open(folderPath);
  };

  const handleFolderCreate = async (folderName) => {
    setLoading(true);
    const data = { ticketId, folderName };
    if (paths.current.length > 1) {
      data.where = paths.current[paths.current.length - 1].id;
    }
    try {
      await createFolder(data);
      await refreshFolder();
    } catch (err) {
      handleError(err);
    }
    setLoading(false);
  };

  const handleFileMove = async (file, folder) => {
    if (folder.id === paths.current[paths.current.length - 1].id) {
      return;
    }
    setLoading(true);
    try {
      await moveFile(ticketId, file.id, folder.id, file.name);
      setFiles((oldValue) => {
        oldValue.splice(
          oldValue.findIndex((i) => i.id === file.id),
          1
        );
        return [...oldValue];
      });
      delete dataByPath.current[folder.id];
      delete dataByPath.current[paths.current[paths.current.length - 1].id];
    } catch (err) {
      handleError(err);
    }
    setLoading(false);
  };

  const onRootClick = async () => {
    const { microsoftTeamsTeamId } = ticketGroup;
    if (!microsoftTeamsTeamId) {
      toast.error("Teams is not initialized yet.");
      return;
    }

    try {
      const url = await getRootDirectoryWebUrl(ticketGroup.microsoftTeamsTeamId);
      window.open(`${url}/Shared%20Documents/Forms/AllItems.aspx`);
    }
    catch (e) {
      toast.error("An error occured. Please try again later");
    }
  }

  return (
    <>
      {(!ticketGroup.microsoftTeamsTeamId || !ticketInfo.microsoftTeamsChannelId) && <h4>Team or channel is not initialized yet.</h4>}
      {(ticketGroup.microsoftTeamsTeamId && ticketInfo.microsoftTeamsChannelId) &&
        <DndProvider backend={HTML5Backend}>
          <div className="tab-files-container h-100 d-flex flex-column">
            Current User Id - {user?.microsoftADDPrincipalName}
            <CreateFolderModal ref={modalRef} onSubmit={handleFolderCreate} />
            <Popconfirm
              show={showConfirmLargeFile}
              title={
                <>
                  Uploading this file may take long
                  <br />
                  Are you sure?
                </>
              }
              onCancel={closeConfirmPopup}
              onOk={handleFileUploadConfirm}
            />
            <Popconfirm
              show={showConfirmFileWithSameName}
              title={
                <>
                  There is already a file named <i>"{fileToUpload?.name}"</i> in
                  this folder
                  <br />
                  Do you want to replace it?
                </>
              }
              onCancel={handleFileWithSameNameCancel}
              onOk={handleFileWithSameNameOk}
            />
            {loading && (
              <div className="spinner-container">
                <Spinner animation="border" />
              </div>
            )}
            <h1>Files</h1>
            <DropUpload
              path={paths.current.map((path) => path.name).join("/")}
              onUpload={fileUploadMiddleware}
            >
              <div className="d-flex justify-content-between">
                <Breadcrumbs paths={paths.current} onClick={handleBreadClick} onRootClick={onRootClick} />
                {!loading && (
                  <div>
                    <Button variant="link" size="sm" onClick={handleModalOpen}>
                      <FolderPlus size={18} />
                    </Button>
                    <input
                      id="file-upload"
                      type="file"
                      hidden
                      onChange={(e) => {
                        fileUploadMiddleware(e.target.files[0]);
                        e.target.value = null;
                      }}
                    />
                    <label
                      htmlFor="file-upload"
                      className="btn-link btn-sm file-upload-label"
                    >
                      <Upload size={18} />
                    </label>
                  </div>
                )}
              </div>
              {files.map((item) =>
                !!item.folder ? (
                  <FolderItem
                    key={item.id}
                    item={item}
                    getFolderFiles={getFolderFiles}
                  />
                ) : (
                  <FileItem
                    key={item.id}
                    item={item}
                    onMove={handleFileMove}
                    onDelete={() => deleteFileApi(item.id)}
                  />
                )
              )}
            </DropUpload>
          </div>
        </DndProvider>}
    </>

  );
};

export default Files;
