import { Badge } from "react-bootstrap";

const getColorsForStep = (step) => {
  if (step) {
    const { completedDate, assignedToId } = step;
    const date = new Date(completedDate);

    const _date = new Date(null);

    if (_date < date) {
      return { bg: "primary-light" };
    }
    if (assignedToId && !completedDate) {
      return { bg: "secondary" };
    }
  }
  return { bg: "light", text: "dark" };
};

// const ConditionalTooltip = ({ children, assignedTo }) =>
//   assignedTo ? (
//     <OverlayTrigger
//       overlay={
//         <Tooltip id="step-badge-tooltip">
//           {assignedTo.firstName} {assignedTo.lastName}
//         </Tooltip>
//       }
//     >
//       {children}
//     </OverlayTrigger>
//   ) : (
//     children
//   );

// const StepBadge = ({ step, index }) => (
//   <ConditionalTooltip assignedTo={step.assignedTo}>
//     <Badge
//       className="me-1 p-1 rounded-circle"
//       style={{ width: 20, height: 20 }}
//       {...getColorsForStep(step)}
//     >
//       {index + 1}
//     </Badge>
//   </ConditionalTooltip>
// );

const StepBadge = ({ step, index, assignedUserName }) => (
  <div className="step-badge-wrapper">
    <div className="step-badge-relative-wrapper">
      <Badge
        className={`me-1 step-badge${assignedUserName ? " expandable" : ""}`}
        {...getColorsForStep(step)}
      >
        <span className="align-middle">{index + 1}</span>
        <div className="assigned-user">{assignedUserName || "-"}</div>
      </Badge>
    </div>
  </div>
);

export default StepBadge;
