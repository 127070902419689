import axios from "axios"
import { toast } from "react-toastify"

/** Local Configs and utils */
import { SERVER_URL } from "../../../configs"
import { API_STATUS, getJobsFilterObj } from "../../../utils"

/** Actions */
export const LOAD_TICKET_GROUPS = "LOAD_TICKET_GROUPS"
export const UPDATE_TICKET_GROUPS = "UPDATE_TICKET_GROUPS"
export const UPDATE_API_STATUS = "UPDATE_API_STATUS"

const getPageSize = () => {
    return localStorage.getItem("ticketGroupSize") || "20"
}
export const loadTicketGroups = (pageIndex = 0, pageSize = getPageSize(), filters = getJobsFilterObj()) => dispatch => {
    if (axios.defaults.headers.common.Authorization) {
        axios.post(`${SERVER_URL}/api/TicketGroup/GetPagedTicketGroups?pageIndex=${pageIndex}&pageSize=${pageSize}`, filters).then((res) => {
            dispatch({ type: LOAD_TICKET_GROUPS, payload: res.data })
        }).catch(() => {
            dispatch({ type: UPDATE_API_STATUS, payload: API_STATUS.ERROR })
            toast.error("Failed to load the ticket groups, please contact administrator !!!")
        })
    }
}

export const loading = () => dispatch => {
    dispatch({ type: UPDATE_API_STATUS, payload: API_STATUS.LOADING })
}

export const updateTicketGroups = (ticketGroups) => dispatch => {
    dispatch({ type: UPDATE_TICKET_GROUPS, payload: ticketGroups })
}
