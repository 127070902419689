import React from "react"
import { Form, Row } from "react-bootstrap"

//** Icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

//** Data */
import { getNewParagraph } from "./defaultData"

const deposit50 = "A 50% deposit is required prior to commencement of the assignment and the balance is due upon completion of the report.";
const fullPayment = "Full Payment is required  prior to commencement of the assignment.";

const AddNewParagraph = ({ index, addNewParagraph }) => (
    <div style={{ display: "flex" }}>
        <hr style={{ width: "90%", margin: "auto", marginTop: 15 }} />
        <FontAwesomeIcon style={{ marginTop: 5, cursor: "pointer" }} onClick={() => addNewParagraph(index)} icon={faPlusCircle} size="lg" />
    </div>
)

const Paragraphs = ({ paragraphs, setParagraphs }) => {

    const handleContentChanged = (key, value, index) => {
        let _paragraphs = [...paragraphs]
        _paragraphs[index][key] = value
        setParagraphs(_paragraphs)
    }

    const handlePaymentOption = (index, from, to) => {
        let _paragraphs = [...paragraphs]
        _paragraphs[index].text = _paragraphs[index].text.replace(from, to);
        setParagraphs(_paragraphs)
    }

    const addNewParagraph = (index) => {
        let _paragraphs = [...paragraphs]
        let newParagraph = getNewParagraph(paragraphs.length + 1)
        index === paragraphs.length ? _paragraphs.push(newParagraph) : _paragraphs.splice(index, 0, newParagraph)
        setParagraphs(_paragraphs)
    }

    const deleteParagraph = (index) => {
        let _paragraphs = [...paragraphs]
        _paragraphs.splice(index, 1)
        setParagraphs(_paragraphs)
    }

    return (
        <>
            <h5>Paragraphs</h5>
            {
                paragraphs.map((paragraph, index) => (
                    <Row key={index}>
                        <AddNewParagraph index={index} addNewParagraph={addNewParagraph} />
                        <div className="mt-2">
                            <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                <h5>
                                    Header
                                </h5>
                                <FontAwesomeIcon style={{ marginTop: 5, cursor: "pointer" }} icon={faTimes} onClick={() => deleteParagraph(index)} size="lg" />
                            </div>
                            <Form.Control
                                name="header"
                                value={paragraph.header}
                                placeholder="Header"
                                onChange={(e) => handleContentChanged(e.target.name, e.target.value, index)}
                            />
                            {(paragraph.text.includes(deposit50) || paragraph.text.includes(fullPayment)) && (
                                <>
                                    <h5>
                                        Payment Option
                                    </h5>
                                    <Form.Check
                                        id="radio-payment-option-50"
                                        type="radio"
                                        label={"50% deposit"}
                                        checked={paragraph.text.includes(deposit50)}
                                        onClick={() => handlePaymentOption(index, fullPayment, deposit50)}
                                    />
                                    <Form.Check
                                        id="radio-payment-option-full"
                                        type="radio"
                                        label={"Full payment"}
                                        checked={paragraph.text.includes(fullPayment)}
                                        onClick={() => handlePaymentOption(index, deposit50, fullPayment)}
                                    />
                                </>
                            )}
                            <h5 className="mt-1">
                                Body
                            </h5>
                            <Form.Control
                                name="text"
                                as="textarea"
                                placeholder="Body"
                                value={paragraph.text}
                                style={{ minHeight: 150 }}
                                onChange={(e) => handleContentChanged(e.target.name, e.target.value, index)}
                            />
                        </div>
                    </Row >
                ))
            }
            <AddNewParagraph index={paragraphs.length} addNewParagraph={addNewParagraph} />
        </>
    )
}

export default Paragraphs