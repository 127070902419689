import React, { useEffect } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import CustomCard from "../../../components/CustomCard";
import LoadingWrapper from "../../../components/LoadingWrapper";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import { loadCompTypes } from "../../../redux/actions/comps";

const CompTypeFilter = ({ handleCompTypeFilterChange }) => {
  const dispatch = useAppDispatch();
  const compTypes = useAppSelector((state) => state.comps.compTypes);
  const compFilters = useAppSelector((state) => state.comps.compFilters);
  const loading = useAppSelector((state) => state.comps.loadingCompTypes);
  const initialLoading = loading && !compTypes.length;

  useEffect(() => {
    dispatch(loadCompTypes());
  }, [dispatch]);

  return (
    <LoadingWrapper loading={initialLoading} className=" border-bottom border-right">
      <div className="d-flex flex-row justify-content-between py-3 me-1">
        {!initialLoading && (
          <>
            <div className="d-flex align-self-center me-2">
              Comp Type
            </div>

            <ToggleButtonGroup
              className="toggle-button-group toggle-button-group-checkboxes mt-n2"
              name="options"
              type="checkbox"
              value={compFilters.compTypeIds}
              onChange={handleCompTypeFilterChange}
            >
              {compTypes.map((compType) => (
                <ToggleButton
                  size="sm"
                  key={compType.id}
                  id={`comp-type-${compType.id}`}
                  value={compType.id}
                >
                  {compType.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </>
        )}
      </div>
    </LoadingWrapper>
  );
};

export default CompTypeFilter;
